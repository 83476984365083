import React from 'react';
import SelectLessor from './SelectLessor';
import { Button, MenuItem, Menu, Avatar } from '@material-ui/core';
import { getGlobalCookie } from '../../utils';
import { trackActivity } from '../../utils/mixpanel';
import config from '../../config';
const UserDropDownMenu = ({logout}) => {
  const userName = getGlobalCookie(config.cookiesNaming.key).user
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ search, setSearch] = React.useState('')
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let baseUrl = config.domain.subDomian;
  return(
    <div className="user-menu-dropdown">
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{padding:'0px 8px'}}
      >
        <Avatar alt={getGlobalCookie(config.cookiesNaming.key).user.name ? getGlobalCookie(config.cookiesNaming.key).user.name:'--'} style={{marginRight: '10px', width: '34px', height: '34px'}} src={getGlobalCookie(config.cookiesNaming.key).user.profile_pic ? getGlobalCookie(config.cookiesNaming.key).user.profile_pic:''} />
        <div className="" style={{textAlign: 'left'}}>
          <h3>{userName && userName.name ? userName.name :'--'}</h3>
          <span>{userName && userName.designation ? userName.designation:''}</span>
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => {handleClose(); window.open(baseUrl+'user-profile', '_blank').focus(); trackActivity('Item Clicked',{page_title:'Desktop Main Header', item_type:'Profile', event_desc:'Clicked on Profile from Desktop main Header'})}}>Profile</MenuItem>
        <MenuItem onClick={() => {handleClose(); window.open(baseUrl+'assets-listing?addUser=true', '_blank').focus();trackActivity('Item Clicked',{page_title:'Desktop Main Header', item_type:'Invite User', event_desc:'Clicked on Invite Userfrom Desktop main Header'})}}>Invite User</MenuItem>
        {
          getGlobalCookie(config.cookiesNaming.key).lessor ?
            <SelectLessor  handleClose={() => handleClose()} search={search} setSearch={setSearch} handleClick={() => setAnchorEl(false)}/>
            :null
        }
        <MenuItem onClick={() => {handleClose();logout()}}>Logout</MenuItem>
      </Menu>
    </div>
  )
}
export default UserDropDownMenu;
