import React, { Component } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';

class BasicFormDetails extends Component {
  constructor(props){
    super(props)
  }
  render(){
    return(
      <form className="">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 margin="normal"
                 format={'YYYY'}
                 placeholder='Date of Accomplishment'
                 fullWidth
                 InputLabelProps={{shrink: true}}
                 inputProps={{readOnly: true}}
                 clearable={true}
                 // minDate={minYear}
                 // maxDate={maxYear}
                 // views={['year']}
                 // value={filter_start ? `01-01-${filter_start}`:null}
                 // onChange={(data, value) => this.onFieldChange('filter_start', data ? moment(data).year():data)}
               />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
              <TextField
                id="tsn"
                label="Total TSN at Event"
                fullWidth
                inputProps={{ maxLength: 20 }}
                margin="normal"
                InputLabelProps={{shrink: true}}
              />
          </Grid>
          <Grid item xs={12}>
              <TextField
                id="csn"
                label="Total CSN at Event"
                fullWidth
                inputProps={{ maxLength: 20 }}
                margin="normal"
                InputLabelProps={{shrink: true}}
              />
          </Grid>
        </Grid>
        <Button color="primary" variant="contained" style={{width: '100%'}}>Fetch</Button>
      </form>
    )
  }
}
export default BasicFormDetails;
