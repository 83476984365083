import React, { Fragment, useState, useEffect } from 'react';
import { Popover, MenuItem, TextField, CircularProgress } from '@material-ui/core';
import { getLessorListApi } from '../apiServices';
import { changeLessorApi } from '../apiServices';
import debounce from 'lodash.debounce'

import { PageLoader } from '..';
const SelectLessor = ({ handleClose, handleClick  }) => {
    const [selectLessorEl, setAnchorEl] = useState(false)
    const [lessorList, setLessor] = useState([])
    const [loader, setLoader] = useState(false)
    const [search , setSearch] = useState('')
    useEffect(() => {
        getLessorListApi(setLessor)
    }, [])
    return (
        <Fragment>
            <MenuItem onClick={() => { setAnchorEl(selectLessorEl => true) }}>
                Switch Account
            </MenuItem>
            <Popover
                open={selectLessorEl}
                anchorEl={selectLessorEl}
                onClose={() => {setAnchorEl(selectLessorEl => false); setSearch('');}}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div style={{padding:'10px'}}>
                    <TextField
                      fullWidth
                      id="search"
                      size="small"
                      autofocus
                      placeholder="Search Asset"
                      autoComplete='off'
                      onKeyDown={() => console.log('key down')}
                      onChange={debounce(evt => {
                         setSearch(search => evt.target.value)
                      }, 500)}
                    />
                </div>
                <div className="switch-lessor-item">
                {
                  lessorList.length ? lessorList.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).length ?
                    lessorList.filter(item => item.name.toLowerCase().includes(search.toLowerCase())).map(item =>
                    <MenuItem onClick={() => changeLessorApi({ lessor_id: item.id }, setLoader, setAnchorEl, handleClick)}>
                      {item.name}
                    </MenuItem>)
                    : <div className="no-record-list">No record found!</div>
                  : <div className="loader-list"><CircularProgress /></div>
                }
                </div>
            </Popover>
            {loader ? <PageLoader /> : null}
        </Fragment>

    )
}
export default SelectLessor;
