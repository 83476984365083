import React, { Fragment } from 'react';
import { Paper, Grid, TextField, Button, InputAdornment, Tooltip, FormControl, FormLabel,RadioGroup,FormControlLabel, Radio } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { regexConstants } from '../../../constants/regEx';
import { fieldDateFormat, backendDateFormat } from '../../../constants';

const PropellerInitiate = ({propellerGenInfo, error, updatePropellerInfoError, genericHlaInfo, updateErrorField, engineGenInfo, updateCheckBox, updatePropellerInfoField}) => {
  return (
    <Fragment>
      <Paper className="hla-initiate-card">
        {  propellerGenInfo && propellerGenInfo.propellerInfo ?  propellerGenInfo.propellerInfo.map((propeller, index) =>
            <Fragment>
              {
                propellerGenInfo.identical_data && engineGenInfo.no_of_engines !== 1 ? null :
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <h4 style={isMobile?{float:'left', textDecoration:'underline', marginBottom:'auto'}:{float:'left', textDecoration:'underline', marginBottom:'-15px', marginTop:'15px'}} ><strong>Propeller #{index+1}</strong></h4>
                  </Grid>
                </Grid>
              }
              <Grid container spacing={isMobile? 1:3} style={isMobile?{padding:'0px'}:{padding:'10px'}}>
                <Grid item xs={12} md={4}>
                  <TextField
                    required
                    id="tsn"
                    label='Time Since New'
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: 10 }}
                    InputLabelProps={{shrink: true}}
                    value={propeller.tsn}
                    onChange={(e) =>  { regexConstants.numberWithDot.test(e.target.value) ? updatePropellerInfoField(e, 'tsn', e.target.value, index):e.preventDefault()}}
                    error={error && error[`propeller${index}`] && error[`propeller${index}`].tsnPropeller ? true : false }
                    helperText={ error && error[`propeller${index}`] && error[`propeller${index}`].tsnPropeller ? error[`propeller${index}`].tsnPropeller : '' }
                    onFocus={(e) => error && error[`propeller${index}`] && error[`propeller${index}`].tsnPropeller ? updatePropellerInfoError('tsnPropeller', '', index) : e.preventDefault() }
                    InputProps={{
                      endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    id="csn"
                    label='Cycle Since New'
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: 10 }}
                    InputLabelProps={{shrink: true}}
                    value={propeller.csn}
                    onChange={(e) =>  { regexConstants.onlyNumeric.test(e.target.value) ? updatePropellerInfoField(e, 'csn', e.target.value, index):e.preventDefault()}}
                    error={error && error[`propeller${index}`] && error[`propeller${index}`].csnPropeller ? true : false }
                    helperText={ error && error[`propeller${index}`] && error[`propeller${index}`].csnPropeller ? error[`propeller${index}`].csnPropeller : '' }
                    onFocus={(e) => error && error[`propeller${index}`] && error[`propeller${index}`].csnPropeller ? updatePropellerInfoError('csnPropeller', '', index) : e.preventDefault() }
                    InputProps={{
                      endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                       required
                       margin="normal"
                       id="utilization_as_of_date"
                       label=" Utilization as of date"
                       placeholder="DD-MM-YYYY"
                       fullWidth
                       format={fieldDateFormat}
                       minDate={genericHlaInfo.date_of_manufacture ? moment(genericHlaInfo.date_of_manufacture) : moment().subtract(25, 'years') }
                       maxDate={genericHlaInfo.report_date ? moment(genericHlaInfo.report_date) : moment()}
                       InputLabelProps={{shrink: true}}
                       onChange={(data, value) => updatePropellerInfoField(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat):data,index)}
                       value={propeller.utilization_as_of_date}
                       error={error && error[`propeller${index}`] && error[`propeller${index}`].utilization_as_of_date_propeller ? true : false }
                       helperText={ error && error[`propeller${index}`] && error[`propeller${index}`].utilization_as_of_date_propeller ? error[`propeller${index}`].utilization_as_of_date_propeller : '' }
                       onFocus={(e) => error && error[`propeller${index}`] && error[`propeller${index}`].utilization_as_of_date_propeller ? updatePropellerInfoError('utilization_as_of_date_propeller', '', index) : e.preventDefault() }
                     />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Fragment>
          ) : null
        }
      </Paper>
    </Fragment>
  )

}
export default PropellerInitiate
