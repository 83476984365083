import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';

import CreateLog from './containers/CreateLog';
import LeaseEncumberedValue from './containers/LeaseEncumberedValue';
import UpdateLog from './containers/UpdateLog';

export const LeaseRoutes = [
  {
    path:'/lease-encumbered/create-log',
    component:pageLayoutHoc(userRoleBaseAccessHoc(CreateLog,[]), {apps: 'LeaseEncumberedValue', layoutPhase: 1}),
    key:'CreateLog'
  },
  {
    path:'/lease-encumbered/update-log/:id',
    component:pageLayoutHoc(userRoleBaseAccessHoc(UpdateLog,[]), {apps: 'LeaseEncumberedValue', layoutPhase: 1}),
    key:'UpdateLog'
  },
  {
    path:'/lease-encumbered/output/:id',
    component:pageLayoutHoc(userRoleBaseAccessHoc(LeaseEncumberedValue,[]), {apps: 'LeaseEncumberedValue', layoutPhase: 1}),
    key:'LeaseEncumberedValue'
  },
]
