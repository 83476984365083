import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { userCru, errorCodeUser } from '..';
import { AutocompleteCreatable } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import { fieldValidation } from '../../../utils/formValidation';
import { getUserEmailApi, getGroupsListApi, createUserApi } from '../apiServices';
import { checkApiStatus } from '../../../utils';
class AddEditUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user : {},
      error: {},
      userEmail:[],
      securityGroupList:[],
      modal:false
    }
    this.getUserEmailApi = getUserEmailApi.bind(this)
    this.getGroupsListApi = getGroupsListApi.bind(this)
    this.createUserApi =createUserApi.bind(this)
  }
  componentDidMount() {
    this.getUserEmailApi()
    this.getGroupsListApi(this.props)
  }
  updateErrorField = (key, message) => {
    this.setState(prevState =>({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  onFieldChange = (event, keyParam, data) => {
    if(keyParam == 'email'){
      this.setState(prevState => ({
        ...prevState,
        user:{
          ...prevState.user,
          email:data ? data.name :''
        }
      }))
      if(keyParam == "security_groups"){
        this.setState(prevState => ({
          ...prevState,
          user:{
            ...prevState.user,
            security_groups:[...prevState.security_groups, data]
          }
        }))
      }
    }else {
      this.setState(prevState => ({
        ...prevState,
        user:{
          ...prevState.user,
          [keyParam]:data
        }
      }))
    }

  }
  createUser = () => {
    const { user } = this.state
    let validationInputs = {
      email:errorCodeUser['email'][fieldValidation({...errorCodeUser['emailObj'], fieldval:user.email})],
      contact_no:errorCodeUser['contact_no'][fieldValidation({...errorCodeUser['contact_noObj'], fieldval:user.contact_no})],
      reason_for_creation:errorCodeUser['reason_for_creation'][fieldValidation({...errorCodeUser['reason_for_creationObj'], fieldval:user.reason_for_creation})]
    }
    if(Object.keys(validationInputs).every((k)=> { return validationInputs[k] === ''})){
      this.createUserApi(user)
      .then(response => {
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{vertical:'top', horizontal:'right'}})
          this.setState({modal:false, user:{}})
          this.props.getResponseBack()
        }else {
          this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{vertical:'top', horizontal:'right'}})
        }
      })
    }else {
      this.setState({
        error:validationInputs
      })
    }
  }
  render() {
    const { modal, user, error, userEmail, securityGroupList } = this.state
    return(
      <Fragment>
      <Button className="" color="primary" variant="contained" onClick={()=> this.setState({modal: true, user:userCru})}>Add User</Button>
      {
        modal ?
        <Dialog
          open={modal}
          onClose={()=> this.setState({modal:false})}
          aria-labelledby='scroll-dialog-title'
        >
        <DialogTitle id="scroll-dialog-title">
          Add User
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutocompleteCreatable
                options={userEmail}
                paramsKey='email'
                optionKey="name"
                label='Email'
                value={user.email }
                required={true}
                error={error.email ? true : false}
                onFieldChange={this.onFieldChange}
              />
          </Grid>
            <Grid item xs={6} >
              <TextField
                required
                id="first_name"
                label="First Name"
                fullWidth
                margin="normal"
                value={user.first_name}
                onChange={(e, value)=> this.onFieldChange(e, 'first_name', e.target.value)}
                error={error.first_name ? true: false}
                onFocus={()=> this.updateErrorField('first_name','')}
                InputLabelProps={{shrink: true}}
                helperText={error.first_name ? error.first_name : ''}
              />
            </Grid>
            <Grid item xs={6} >
              <TextField
                id="last_name"
                label="Last Name"
                fullWidth
                margin="normal"
                value={user.last_name}
                onChange={(e, value)=> this.onFieldChange(e, 'last_name', e.target.value)}
                InputLabelProps={{shrink: true}}
              />
            </Grid>
            <Grid item xs={6} >
              <TextField
                id="contact_no"
                label="Contact Number"
                fullWidth
                margin="normal"
                value={user.contact_no}
                onChange={(e, value) => this.onFieldChange(e,'contact_no', e.target.value)}
                error={error.contact_no ? true: false}
                onFocus={()=> this.updateErrorField('contact_no', '')}
                InputLabelProps={{shrink: true}}
                helperText={error.contact_no ? error.contact_no : ''}
              />
            </Grid>
            <Grid item xs={6} >
              <TextField
                id="designation"
                label="Designation"
                fullWidth
                margin="normal"
                value={user.designation}
                onChange={(e, value)=> this.onFieldChange(e, 'designation', e.target.value)}
                InputLabelProps={{shrink: true}}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options = {securityGroupList}
                getOptionLabel={option => option.name}
                id="security_groups"
                filterSelectedOptions={true}
                value={user.security_groups}
                onChange={(e, value) => this.onFieldChange(e, 'security_groups',value.id )}
                renderInput={params =>
                  <TextField  required {...params} label='Security Groups' margin="normal"  fullWidth InputLabelProps={{shrink: true}} />}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id='organisation'
                label='Organization'
                fullWidth
                margin="unormal"
                multiline
                rows="3"
                value={user.organisation}
                onChange={(e, value)=> this.onFieldChange(e, 'organisation', e.target.value)}
                InputLabelProps={{shrink: true}}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="reason_for_creation"
                label='Reason for creation'
                fullWidth
                margin="normal"
                multiline
                rows="3"
                value={user.reason_for_creation}
                onChange={(e, value)=> this.onFieldChange(e, 'reason_for_creation', e.target.value)}
                InputLabelProps={{shrink: true}}
              />
            </Grid>
            <Grid item xs={12} >
              <FormControl component="fieldset">
                <FormLabel className="MuiInputLabel-shrink" component="label">User Type</FormLabel>
                <RadioGroup row aria-label="" name="User type" defaultValue="top">
                  <FormControlLabel value="" control={<Radio  color="primary" />} label="Acumen" />
                  <FormControlLabel value="" control={<Radio  color="primary" />} label="Client" />
                  <FormControlLabel value="" control={<Radio  color="primary" />} label="Contractor" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button  onClick={()=> this.setState({ modal: false})} color="primary">Cancel</Button>
          <Button variant="contained" onClick={()=>this.createUser()} color="primary" >Save</Button>
        </DialogActions>

      </Dialog> : null
      }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(AddEditUser));
