import moment from 'moment';
import { backendDateFormat } from '../../constants';
import { regexConstants, errorMsg1 } from '../../constants/regEx';

export const leaseEnCrud = {
  base_value_date: moment().format(backendDateFormat),                             // Report Date
  serial_number: "",
  assumptions:"",
  aircraft_type_id: null,
  date_of_manufacture: null,
  engine_type_id: null,
  mtow: '',
  mtow_kgs:'',                                        // in lbs
  lessee_id: '',                                     // Lessee/Operator ID
  mods: [],
  registration_number: null,
  lease_start_date: null,
  lease_end_date: null,
  rental_type: null,                                  // 1: Fixed || 2: Stepped
  rental_payment_type: 1,                            // 1: In Advance || 2: In Arrears
  rent:'' ,
  annual_discount:7.5,                               // Percentage
  markdown_on_residual_value: "",
  annual_inflation_rate: "",                         // Percentage
  asset_management_charges: '',
  is_full_life_maintenance_condition:false,
}

export const LEVListHd = [
  { label: 'Date of Payment', sortOption: false },
  { label: 'No. of Payment', sortOption: false },
  { label: 'Rent', sortOption: false },
  { label: 'Present Value of Rent', sortOption: false },
];
export const LEVListPerHd = [
  { label: 'Date of Payment', sortOption: false },
  { label: 'No of Payment', sortOption: false },
  { label: 'Rent', sortOption: false },
  { label: 'Rent Post Deduction of Asset Management Expenses', sortOption: false },
  { label: 'Present Value Factor', sortOption: false },
  { label: 'Present Value of Rent', sortOption: false },
];
export const LEVErrorCode = {
  serial_number:{
    0:'',
    4: errorMsg1
  },
  serial_numberObj:{
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  aircraft_type_id:{
    0:'',
    1:'Please select Aircraft Type'
  },
  aircraft_type_idObj:{
    required:true
  },
  date_of_manufacture:{
    0:'',
    1:'Please enter Date of Manufacture',
    5:'Please enter Valid Date'
  },
  date_of_manufactureObj:{
    required:true,
    datePattern:true
  },
  base_value_date:{
    0:'',
    1:'Please enter Report Date',
    5:'Please enter Valid Date'
  },
  base_value_dateObj:{
    required:true,
    datePattern:true
  },
  engine_type_id:{
    0:'',
    1:'Please select Engine Type'
  },
  engine_type_idObj:{
    required:true
  },
  mtow_lbs:{
    0:'',
    1:'Please enter Max Take Off Weight(Lbs)'
  },
  mtow_lbsObj:{
    required:true,
  },
  mtow_kgs:{
    0:'',
    1:'Please enter Max Take Off Weight(Kgs)'
  },
  mtow_kgsObj:{
    required:true,
  },
  lessee_id:{
    0:'',
    1:'Please select Operator / Lessee'
  },
  lessee_idObj:{
    required:true
  },
  lease_start_date:{
    0:'',
    1:'Please enter Lease Start Date',
    5:'Please enter Valid Date'
  },
  lease_start_dateObj:{
    required:true,
    datePattern:true
  },
  lease_end_date:{
    0:'',
    1:'Please enter Lease End Date',
    5:'Please enter Valid Date'
  },
  lease_end_dateObj:{
    required:true,
    datePattern:true
  },
  rental_type:{
    0:'',
    1:'Please select Rental Type'
  },
  rental_typeObj:{
    required:true
  },
  rent:{
    0:'',
    1:'Please enter Rent'
  },
  rentObj:{
    required:true
  },
  markdown_on_residual_value:{
    0:'',
    1:'Please enter Markdown Residual Value',
  },
  markdown_on_residual_valueObj:{
    required:true,
  },
  start_date:{
    0:'',
    1:'Please enter Start Date',
    5:'Please enter Valid Date'
  },
  start_dateObj:{
    required:true,
    datePattern:true
  },
  end_date:{
    0:'',
    1:'Please enter End Date',
    5:'Please enter Valid Date'
  },
  end_dateObj:{
    required:true,
    datePattern:true
  },
  stepRent:{
    0:'',
    1:'Please enter Rent'
  },
  stepRentObj:{
    required:true
  },
  country:{
    0:'',
    1:'Please enter Country'
  },
  countryObj:{
    required:true
  },
  contact_address:{
    0:'',
    1:'Please enter Contact Address'
  },
  contact_addressObj:{
    required:true
  },
  logo:{
    0:'',
    1:'Please upload Logo'
  },
  logoObj:{
    required:true
  },
  maintenance_program:{
    0:'',
    1:'Please select Maintenance Program'
  },
  maintenance_programObj:{
    required:true
  }
}
