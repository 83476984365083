import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { TableListComp, EmptyCollection, DeletePopUp } from '../../../shared_elements';
import AvailabilityTrackerList from './AvailabilityTrackerList';
import { STableLoader } from '../../../shared_elements/loaders';
import AddEditAvailabilityTracker from './AddEditAvailabilityTracker';
import { userStatusListApi, deleteUserStatusApi } from '../apiServices';
import Skeleton from '@material-ui/lab/Skeleton';
import { checkApiStatus } from '../../../utils';
class AvailabilityTracker extends Component {
  constructor(props) {
    super(props)
    this.state = {
        userStatusList:{
          list:[],
          pagination:{}
        },
        deleteModal:false,
        deleteId: '',
        skeletonLoader:false
    }
    this.userStatusListApi = userStatusListApi.bind(this)
    this.deleteUserStatusApi = deleteUserStatusApi.bind(this)
  }
  componentDidMount() {
    this.userStatusListApi(this.props)
  }
  deleteAvailabilityStatus = (id) => {
    this.setState(prevState => ({
      ...prevState,
      deleteModal:!prevState.deleteModal,
      deleteId:id
    }))
  }
  deleteAvailabilityStatusFn = () => {
    const { deleteId } = this.state
    this.deleteUserStatusApi(this.props,deleteId)
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{ vertical:'top',horizontal:'right'}})
        this.setState({deleteModal:false, deleteId:''})
        this.userStatusListApi(this.props)
      }else {
        this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{ vertical:'top', horizontal:'right'}})
      }
    })

  }
  render() {
    const { userStatusList, id, deleteModal, skeletonLoader } = this.state
    return (
      <Fragment>
        { skeletonLoader ? <Skeleton width={140} height={50} /> : <AddEditAvailabilityTracker mode="Add" getResponseBack={()=> this.userStatusListApi(this.props)} />}
        { skeletonLoader ? <STableLoader count={3} /> :
          <TableListComp
            heads={[
              {label:'Availability Type', sortOption:false},
              {label:'From', sortOption:false},
              {label:'To', sortOption:false},
              {label:'Remarks', sortOption:false},
              {label:'', sortOption:false},
            ]}
            data={ userStatusList.list.length ? userStatusList.list.map((status, index)=>
                <AvailabilityTrackerList
                  item={status}
                  key={index}
                  getResponseBack={()=> this.userStatusListApi(this.props)}
                  deleteAvailabilityStatus={(id)=> this.deleteAvailabilityStatus(id)}
                />
            ): null
            }
            noRecord={userStatusList.list.length ? null : <EmptyCollection title="No records found" />}
          />
        }

      <DeletePopUp
        modal={deleteModal}
        toggleModalFn={(id) => this.deleteAvailabilityStatus(id)}
        title="Delete Availability Status"
        deleteRecordFn={() => this.deleteAvailabilityStatusFn()}
        content={<h4>Are you sure you want to delete</h4>}
      />
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(AvailabilityTracker));
