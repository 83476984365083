import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Button, TextField, CircularProgress, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockIcon from '@material-ui/icons/Lock';
import config from '../../../config';
export default function LoginForm({ error, authInfo, handleChange, onLogin, formSubmitLoader }) {
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <form onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); onLogin() }}>
      <TextField
        id="email"
        label="Registered Email Address"
        fullWidth
        margin="normal"
        placeholder='johndoe@doe.com'
        className="email-textfield"
        value={authInfo.email}
        error={error.email ? true : false}
        helperText={error.email}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => handleChange(e.target.value, 'email')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutlineIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        id="password"
        type={values.showPassword ? 'text' : 'password'}
        label={
          <p>Password
          </p>
        }
        fullWidth
        margin="normal"
        value={authInfo.password}
        placeholder='Enter Your Password'
        error={error.password ? true : false}
        helperText={error.password}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
          endAdornment: <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>,
        }}
        onChange={(e) => handleChange(e.target.value, 'password')}
      />
      {config.api.networkInterface.includes('dev.beta') ? null :
        <Fragment>
          <a className="forgot-pwd-link" href={`${config.domain.subDomian}forgot-password`} target="_blank" rel="noreferrer">Forgot Password?</a>
        </Fragment>
      }
      <div className="text-center" style={{ marginTop: '20px' }}>
        <p className="terms-and-condition">
          By clicking Login, you agree to our&nbsp;
          <a className="url" href={`${config.domain.subDomian}termscondition`} target="_blank" rel="noreferrer" > Terms, </a>
          <a className="url" href={`${config.domain.subDomian}data-policy`} target="_blank" rel="noreferrer" >&nbsp;Data Policy </a> and <br />
          <a className="url" href="https://acumenaviation.ie/privacy-policy/" target="_blank" rel="noreferrer"> Privacy Policy</a>.
        </p>
      </div>
      <div className="text-center" style={{ marginTop: '10px' }}>
        <Button onClick={() => onLogin()} style={{ width: '100%' }} variant="contained" color="primary" type="button" disabled={formSubmitLoader}>{formSubmitLoader ? <CircularProgress color="#ffffff" size={24} /> : 'Login'}</Button>
      </div>
    </form>
  )
}
