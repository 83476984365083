import React, { Fragment } from 'react'
import { Paper, Grid, TextField, Button, InputAdornment, Tooltip, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { regexConstants } from '../../../constants/regEx';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
const LgHlaInitiate = ({lgGenInfo,genericHlaInfo, updateLgInfoError, onFieldChange,  error, title, mlgUtilization, changeUtilizationFlag, keyIndex}) => {
  return (
    <Fragment>
      <Paper className="hla-initiate-card lg-initiate-card" style={{marginBottom:'3px'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={mlgUtilization ? 2 : 1} style={isMobile ? {marginBottom:'auto'} : {marginBottom:'-20px'}} >
            <h4 ><strong>{title}</strong></h4>
          </Grid>
          {
            title.includes('LH') ?
            <Grid xs={12} md={6} >
              <ul className="list-inline">
                <li className="list-inline-item" style={{marginTop:'13px',marginLeft:'3px'}}>
                  <FormLabel component="legend">Does LG MLG and RH MLG have Same Utilization ? </FormLabel>
                </li>
                <li className="list-inline-item" style={{marginLeft:'5px'}}>
                  <FormControl component="fieldset" >
                    <RadioGroup aria-label="engine" name="lg" value="lg" row>
                      <FormControlLabel value={lgGenInfo.mlg_utilization_flag}  control={<Radio  checked={lgGenInfo.mlg_utilization_flag}  color="primary"  />} onChange={(e) => changeUtilizationFlag(e, 'mlg_utilization_flag', true,'landing_gear')}  color="primary" label="Yes"   />
                      <FormControlLabel value={lgGenInfo.mlg_utilization_flag}  control={<Radio checked={!lgGenInfo.mlg_utilization_flag}  color="primary"  />} onChange={(e) => changeUtilizationFlag(e, 'mlg_utilization_flag', false,'landing_gear')}  color="primary" label="No"   />
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
            </Grid> : null
          }
        </Grid>
        <Grid container spacing={isMobile? 2:3} style={isMobile?{padding:'0px'}:{padding:'10px'}}>
          <Grid item xs={6} md={4}>
          <TextField
            id="tsn"
            label='Time Since New'
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{shrink: true}}
            value={lgGenInfo && lgGenInfo.lgInfo && lgGenInfo.lgInfo[keyIndex] ?  lgGenInfo.lgInfo[keyIndex].tsn : null}
            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn', e.target.value):e.preventDefault()}}
            error={error.tsnLg ? true:false}
            helperText={error.tsnLg?error.tsnLg:''}
            onFocus={() => updateLgInfoError('tsnLg','', keyIndex)}
            InputProps={{
                endAdornment: <InputAdornment position="end">FH</InputAdornment>
              }}
          />
          </Grid>
          <Grid item xs={6} md={4}>
          <TextField
            required
            id="csn"
            label='Cycles Since New'
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{shrink: true}}
            value={lgGenInfo && lgGenInfo.lgInfo && lgGenInfo.lgInfo[keyIndex] ?  lgGenInfo.lgInfo[keyIndex].csn : null}
            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'csn', e.target.value):e.preventDefault()}}
            error={error && error[keyIndex] && error[keyIndex].csnLg  ? true : false}
            helperText={error && error[keyIndex] ? error[keyIndex].csnLg :''}
            onFocus={() => updateLgInfoError('csnLg','', keyIndex)}
            InputProps={{
                endAdornment: <InputAdornment position="end">FC</InputAdornment>
              }}
          />
          </Grid>
          <Grid item xs={12} md={4}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
               required
               margin="normal"
               id="utilization_as_of_date"
               label="Utilization as of date"
               placeholder="DD-MM-YYYY"
               fullWidth
               format={fieldDateFormat}
               disableFuture={true}
               minDate={genericHlaInfo.date_of_manufacture ? moment(genericHlaInfo.date_of_manufacture) : moment().subtract(25, 'years') }
               maxDate={genericHlaInfo.report_date ? moment(genericHlaInfo.report_date) : moment()}
               InputLabelProps={{shrink: true}}
               onChange={(data, value) => onFieldChange(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat):data)}
               value={lgGenInfo && lgGenInfo.lgInfo && lgGenInfo.lgInfo[keyIndex] ?  lgGenInfo.lgInfo[keyIndex].utilization_as_of_date : null}
               error={error && error[keyIndex] && error[keyIndex].utilization_as_of_dateLg ? true: false}
               helperText={error && error[keyIndex] ? error[keyIndex].utilization_as_of_dateLg:''}
               onFocus={() => updateLgInfoError('utilization_as_of_dateLg','', keyIndex)}
               onOpen={() => updateLgInfoError('utilization_as_of_dateLg','', keyIndex)}
             />
          </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  )
}
export default LgHlaInitiate;
