import react, { Fragment } from 'react'
import { Tabs, Tab, Paper, Grid, TextField, Button, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { LabelValueCard } from '../../../shared_elements';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { regexConstants } from '../../../constants/regEx';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import EditIcon from '@material-ui/icons/Edit';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat, checkUserAccessPermission, getGlobalCookie } from '../../../utils';
import InfoIcon from '@material-ui/icons/Info';
import config from '../../../config';
const PropellerIntermediate = ({ hlaLogDetail,propellerWarningText, propellerEdit, togglePropellerEdit, updatePropellerLog, onCloseEdit, propellerTabIndex, tabColor, changePropellerTabs, skeletonLoader, propellerUpdateFn, error, updatePropellerError, isLocked, applicationPermission }) => {
  return (
    <Paper>
      <Grid container spacing={0} style={{ padding: isMobile ? '10px 15px 15px' : '25px 15px', minHeight: isMobile ? 'auto' : '355px' }} >
        <Grid item xs={5} sm={6} md={6} style={isMobile ? { marginBottom: '30px' } : { marginBottom: '15px' }}>
          <ul className='list-inline'>
            <li className='list-inline-item'>
              <h4 className="hla-initiate-card-title"><strong>Propeller</strong></h4>
            </li>
            {
              propellerWarningText ? 
              <li className='list-inline-item'>
              <p className='warning-text'>{propellerWarningText}</p>
            </li> : null
            }
            
          </ul>
        </Grid>
        <Grid item xs={7} sm={6} md={6}>
          <div style={{ float: 'right' }}>
            {checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'U') ?
              !propellerEdit ?
                !isLocked ?
                  <EditIcon color="primary" style={{ cursor: 'pointer', zIndex: '1000' }} onClick={togglePropellerEdit} /> : null :
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Button size="small" color="primary" variant="contained" onClick={updatePropellerLog}>Save</Button>
                  </li>
                  <li className="list-inline-item" style={{ marginLeft: '5px' }}>
                    <Button size="small" color="primary" variant="outlined" onClick={onCloseEdit}>Cancel</Button>
                  </li>
                </ul>
              : null
            }
          </div>
        </Grid>
        {hlaLogDetail.propeller.length > 1 ?
          <Grid item xs={12} md={12}>
            <Tabs
              value={propellerTabIndex}
              indicatorColor={tabColor}
              textColor={tabColor}
              onChange={changePropellerTabs}
              aria-label="disabled tabs example"
              variant="scrollable"
              scrollButtons={isMobile ? 'on' : 'auto'}
            >
              {
                hlaLogDetail.propeller.map((item, index) =>
                  <Tab label={`propeller #${index + 1}`} />)
              }
            </Tabs>
          </Grid>
          : null
        }
        {
          hlaLogDetail.propeller.map((propeller, index) => {
            if (propeller.position == (propellerTabIndex + 1)) {
              return (
                <Grid container spacing={4} style={{ marginLeft: '0px' }}>
                  {propellerEdit ?
                    <Fragment>
                      <Grid item xs={6} md={3}>
                        <TextField
                          id="serial_number"
                          label="Serial Number"
                          fullWidth
                          margin="normal"
                          inputProps={{ maxLength: 10 }}
                          value={propeller.serial_number}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => { propellerUpdateFn(e, 'serial_number', e.target.value, index) }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          required
                          id="tsn"
                          label="Time Since New"
                          fullWidth
                          margin="normal"
                          inputProps={{ maxLength: 10 }}
                          value={propeller.tsn}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? propellerUpdateFn(e, 'tsn', e.target.value, index) : e.preventDefault() }}
                          error={error && error[index] && error[index].tsn ? true : false}
                          helperText={error && error[index] && error[index].tsn ? error[index].tsn : ''}
                          onFocus={(e) => error && error[index] && error[index].tsn ? updatePropellerError('tsn', '', index) : e.preventDefault()}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          id="csn"
                          label="Cycles Since New"
                          fullWidth
                          margin="normal"
                          value={propeller.csn}
                          inputProps={{ maxLength: 10 }}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? propellerUpdateFn(e, 'csn', e.target.value, index) : e.preventDefault() }}
                          error={error && error[index] && error[index].csn ? true : false}
                          helperText={error && error[index] && error[index].csn ? error[index].csn : ''}
                          onFocus={(e) => error && error[index] && error[index].csn ? updatePropellerError('csn', '', index) : e.preventDefault()}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            required
                            margin="normal"
                            id="as_of_date"
                            label="Utilization as of date"
                            placeholder="DD-MM-YYYY"
                            fullWidth
                            minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                            maxDate={hlaLogDetail.generic.report_date ? moment(hlaLogDetail.generic.report_date) : moment()}
                            format={fieldDateFormat}
                            InputLabelProps={{ shrink: true }}
                            onChange={(data, value) => propellerUpdateFn(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat) : data, index)}
                            value={propeller.utilization_as_of_date}
                            error={error && error[index] && error[index].utilization_as_of_date_propeller ? true : false}
                            helperText={error && error[index] && error[index].utilization_as_of_date_propeller ? error[index].utilization_as_of_date_propeller : ''}
                            onFocus={(e) => error && error[index] && error[index].utilization_as_of_date_propeller ? updatePropellerError('utilization_as_of_date_propeller', '', index) : e.preventDefault()}
                            onOpen={(e) => error && error[index] && error[index].utilization_as_of_date_propeller ? updatePropellerError('utilization_as_of_date_propeller', '', index) : null}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          id="tsn_at_lsv"
                          label={
                            <p>TSN at LSV {propeller.tsn_at_lsv || propeller.lsv_date ? <sup>*</sup> : null}
                              <Tooltip
                                title="Time Since New at Last Shop Visit"
                                arrow
                                placement="top"
                              >
                                <InfoIcon style={{ fontSize: '18px', marginLeft: '3px' }} />
                              </Tooltip>
                            </p>
                          }
                          fullWidth
                          margin="normal"
                          inputProps={{ maxLength: 10 }}
                          value={propeller.tsn_at_lsv}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? propellerUpdateFn(e, 'tsn_at_lsv', e.target.value, index) : e.preventDefault() }}
                          error={error && error[index] && error[index].propeller_tsn_at_lsv ? true : false}
                          helperText={error && error[index] && error[index].propeller_tsn_at_lsv ? error[index].propeller_tsn_at_lsv : ''}
                          onFocus={(e) => error && error[index] && error[index].propeller_tsn_at_lsv ? updatePropellerError('propeller_tsn_at_lsv', '', index) : e.preventDefault()}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <TextField
                          id="csn_at_lsv"
                          label={
                            <p>CSN at LSV
                              <Tooltip
                                title="Cycles Since New at Last Shop Visit"
                                arrow
                                placement="top"
                              >
                                <InfoIcon style={{ fontSize: '18px', marginLeft: '3px' }} />
                              </Tooltip>
                            </p>
                          }
                          fullWidth
                          margin="normal"
                          inputProps={{ maxLength: 10 }}
                          value={propeller.csn_at_lsv}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? propellerUpdateFn(e, 'csn_at_lsv', e.target.value, index) : e.preventDefault() }}
                          error={error && error[index] && error[index].propeller_csn_at_lsv ? true : false}
                          helperText={error && error[index] && error[index].propeller_csn_at_lsv ? error[index].propeller_csn_at_lsv : ''}
                          onFocus={(e) => error && error[index] && error[index].propeller_csn_at_lsv ? updatePropellerError('propeller_csn_at_lsv', '', index) : e.preventDefault()}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            margin="normal"
                            id="lsv_date"
                            label={
                              <p>LSV date {propeller.tsn_at_lsv || propeller.lsv_date ? <sup>*</sup> : ''}
                                <Tooltip
                                  title="Last Shop Visit details to be entered before the report date"
                                  arrow
                                  placement="top"
                                >
                                  <InfoIcon style={{ fontSize: '18px', verticalAllign: 'bottom' }} />
                                </Tooltip>
                              </p>
                            }
                            placeholder="DD-MM-YYYY"
                            fullWidth
                            minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                            maxDate={propeller.utilization_as_of_date ? moment(propeller.utilization_as_of_date) : moment()}
                            format={fieldDateFormat}
                            InputLabelProps={{ shrink: true }}
                            value={propeller.lsv_date}
                            onChange={(data, value) => propellerUpdateFn(value, 'lsv_date', data ? moment(data).format(backendDateFormat) : data, index)}
                            error={error && error[index] && error[index].prop_lsv_date ? true : false}
                            helperText={error && error[index] && error[index].prop_lsv_date ? error[index].prop_lsv_date : ''}
                            onFocus={(e) => error && error[index] && error[index].prop_lsv_date ? updatePropellerError('prop_lsv_date', '', index) : e.preventDefault()}
                            onOpen={(e) => error && error[index] && error[index].prop_lsv_date ? updatePropellerError('prop_lsv_date', '', index) : null}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      {
                        checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') &&
                          (getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == "Acumen") ?
                          <Grid item xs={6} md={3}>
                            <TextField
                              required
                              id="interval_fh"
                              label="Interval FH"
                              value={propeller.interval_fh}
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 10 }}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? propellerUpdateFn(e, 'interval_fh', e.target.value, index) : e.preventDefault(); propellerUpdateFn(e, 'propellerRemarksFlag', true, index) }}
                              error={error && error[index] && error[index].interval_fh_propeller ? true : false}
                              helperText={error && error[index] && error[index].interval_fh_propeller ? error[index].interval_fh_propeller : ''}
                              onFocus={(e) => error && error[index] && error[index].interval_fh_propeller ? updatePropellerError('interval_fh_propeller', '', index) : e.preventDefault()}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                              }}
                            />
                          </Grid>
                          : <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Interval FH' value={propeller.interval_fh ? `${propeller.interval_fh} ` : '--'} />
                      }
                      {
                        checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') &&
                          (getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == "Acumen") ?

                          <Grid item xs={6} md={3}>
                            <TextField
                              required
                              id="interval_mo"
                              label="Interval MO"
                              value={propeller.interval_mo}
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 10 }}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? propellerUpdateFn(e, 'interval_mo', e.target.value, index) : e.preventDefault(); propellerUpdateFn(e, 'propellerRemarksFlag', true, index) }}
                              error={error && error[index] && error[index].interval_mo_propeller ? true : false}
                              helperText={error && error[index] && error[index].interval_mo_propeller ? error[index].interval_mo_propeller : ''}
                              onFocus={(e) => error && error[index] && error[index].interval_mo_propeller ? updatePropellerError('interval_mo_propeller', '', index) : e.preventDefault()}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">MO</InputAdornment>,
                              }}
                            />
                          </Grid>
                          : <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Interval MO' value={propeller.interval_mo ? `${propeller.interval_mo} ` : '--'} />
                      }
                      {
                        checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') &&
                          (getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == "Acumen") ?
                          <Grid item xs={6} md={3}>
                            <TextField
                              required
                              id="cost"
                              label="Event Cost"
                              fullWidth
                              margin="normal"
                              value={propeller.cost}
                              inputProps={{ maxLength: 12 }}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? propellerUpdateFn(e, 'cost', e.target.value, index) : e.preventDefault(); propellerUpdateFn(e, 'propellerRemarksFlag', true, index) }}
                              error={error && error[index] && error[index].cost_propeller ? true : false}
                              helperText={error && error[index] && error[index].cost_propeller ? error[index].cost_propeller : ''}
                              onFocus={(e) => error && error[index] && error[index].cost_propeller ? updatePropellerError('cost_propeller', '', index) : e.preventDefault()}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                            />
                          </Grid>
                          :
                          (checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') ||
                            [13].includes(getGlobalCookie(config.cookiesNaming.key).lessor.id)) ?
                            <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Event Cost' value={propeller.cost ? <NumericalLabel params={getCurrencyFormat({ shortFormat: false })}>{propeller.cost}</NumericalLabel> : '--'} />
                            : null
                      }
                      {
                        checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') ?
                          <Grid item xs={12} md={3}>
                            <TextField
                              required={checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') && (propeller.propellerRemarksFlag)}
                              id="remarks"
                              label="Remarks"
                              fullWidth
                              margin="normal"
                              multiline
                              rows={isMobile ? 1 : 4}
                              inputProps={{ maxLength: 250 }}
                              value={propeller.remarks}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => propellerUpdateFn(e, 'remarks', e.target.value, index)}
                              error={error && error[index] && error[index].remarks_propeller ? true : false}
                              helperText={error && error[index] && error[index].remarks_propeller ? error[index].remarks_propeller : ''}
                              onFocus={(e) => error && error[index] && error[index].remarks_propeller ? updatePropellerError('remarks_propeller', '', index) : e.preventDefault()}
                            />
                          </Grid>
                          : null
                      }

                    </Fragment> :
                    <Fragment>
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Serial Number' value={propeller.serial_number ? propeller.tsn : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Time Since New' value={propeller.tsn ? `${propeller.tsn} FH` : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label="Cycles Since New" value={propeller.csn ? `${propeller.csn} FC` : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Utilization as of date' value={propeller.utilization_as_of_date ? moment(propeller.utilization_as_of_date).format(displayDateFormatShort) : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3}
                        label={
                          <p>TSN at LSV
                            <Tooltip
                              title="Time Since New at Last Shop Visit"
                              arrow
                              placement="top"
                            >
                              <InfoIcon style={{ fontSize: '18px', marginLeft: '3px' }} />
                            </Tooltip>
                          </p>
                        }
                        value={propeller.tsn_at_lsv ? `${propeller.tsn_at_lsv} FH` : '--'}
                      />
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3}
                        label={
                          <p>CSN at LSV
                            <Tooltip
                              title="Cycles Since New at Last Shop Visit"
                              arrow
                              placement="top"
                            >
                              <InfoIcon style={{ fontSize: '18px', marginLeft: '3px' }} />
                            </Tooltip>
                          </p>
                        }
                        value={propeller.csn_at_lsv ? `${propeller.csn_at_lsv} FC` : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='LSV date' value={propeller.lsv_date ? moment(propeller.lsv_date).format(displayDateFormatShort) : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Interval FH' value={propeller.interval_fh ? `${propeller.interval_fh} ` : '--'} />
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Interval MO' value={propeller.interval_mo ? `${propeller.interval_mo} ` : '--'} />
                      {
                        (checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') ||
                          [13].includes(getGlobalCookie(config.cookiesNaming.key).lessor.id)) ?
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Event Cost' value={propeller.cost ? <NumericalLabel params={getCurrencyFormat({ shortFormat: false })}>{propeller.cost}</NumericalLabel> : '--'} />
                          : null
                      }
                      {checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') ?
                        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label="Remarks" value={propeller.remarks ? propeller.remarks : '--'} /> : null
                      }

                    </Fragment>
                  }


                </Grid>
              )
            } else {
              return null
            }
          })
        }
      </Grid>
    </Paper>
  )
}
export default PropellerIntermediate
