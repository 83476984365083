import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Container, Grid, Tooltip, IconButton, TextField, Button, Badge } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import UserDropDownMenu from './UserDropDownMenu';
import WhatsNewCompleteList from './WhatsNewCompleteList';
import FAQsList from './FAQsList';
import ReportAnIssue from './ReportAnIssue';
import PageLoader from './PageLoader';
import { getGlobalCookie } from '../../utils';
import { getWhatsNewFeaturesListApi, getFaqsListApi, getHeaderCartCountApi } from '../apiServices';
import { lessorUpdateApi } from '../../application/auth/apiServices'
import { globalGetService } from '../../utils/globalApiServices';
import * as actions from '../actions';
import Cookies from 'universal-cookie';
import config from '../../config';
import ApplicationSwitcher from './ApplicationSwitcher';
const cookies = new Cookies();
class DesktopHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      lessorList:[],
      formSubmitLoader:false,
      whtsNewModal:false,
      whtsNewList:[],
      faqsModal:false,
      faqsList:[],
      headerCount:{}
    }
    this.getWhatsNewFeaturesListApi = getWhatsNewFeaturesListApi.bind(this);
    this.getFaqsListApi = getFaqsListApi.bind(this);
    this.lessorUpdateApi = lessorUpdateApi.bind(this);
    this.getHeaderCartCountApi = getHeaderCartCountApi.bind(this)
  }
  componentDidMount(){

  }

  logout = () => {
    // cookies.remove(config.cookiesNaming.key , {path: "/", domain: "sparta.aero"});
    cookies.remove(config.cookiesNaming.key);
    this.props.history.push('/login')
    // trackLogOut()
  }
  render(){
    const { whtsNewModal, whtsNewList, faqsModal, faqsList, pageLoader, formSubmitLoader, headerCount, lessorList } = this.state;
    let baseUrl = config.domain.subDomian;
    let currentLessor = getGlobalCookie(config.cookiesNaming.key) && getGlobalCookie(config.cookiesNaming.key).lessor ? {id:getGlobalCookie(config.cookiesNaming.key).lessor.id, name:getGlobalCookie(config.cookiesNaming.key).lessor.name}:null
    return(
      <Fragment>
        <div style={{height:'53px'}}></div>
        <header className="desktop-header">
          <Container maxWidth={false}  style={{padding:'6px 5px'}}>
            <Grid container  spacing={0} alignItems="center" justify="center">
              <Grid item xs={5}>
                <ul className="list-inline app-logo-search flex-centered">
                  <li className="list-inline-item app-logo">
                    <Link to="/logs">
                      <img src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/sparta_logo.png" alt="Sparta" />
                    </Link>
                  </li>
                  <li>
                    <ApplicationSwitcher />
                  </li>
                </ul>
              </Grid>
              <Grid item xs={7}>
                <ul className="list-inline header-right-nav flex-centered">
                  <li className="list-inline-item">
                    <ReportAnIssue type="head" />
                  </li>
                  <li className="list-inline-item">
                    <Tooltip title="FAQs" arrow>
                      <IconButton size="small" color="primary" onClick={() => this.getFaqsListApi(this.props)}>
                        <QuestionAnswerIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </li>
                  <li className="list-inline-item">
                    <Tooltip title="Notifications" arrow>
                      <IconButton size="small" color="primary">
                        <span href={`${baseUrl}notifications`} target="_blank" >
                          <Badge max={99} badgeContent={headerCount.notification_count ? headerCount.notification_count : 0} color="secondary">
                            <NotificationsActiveIcon color="primary" fontSize="small" />
                          </Badge>
                        </span>
                      </IconButton>
                    </Tooltip>
                  </li>
                  <li className="list-inline-item">
                    {getGlobalCookie(config.cookiesNaming.key).lessor.name}
                  </li>
                  <li className="list-inline-item">
                    <UserDropDownMenu
                      logout={this.logout}
                    />
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Container>
          { whtsNewModal ?
            <WhatsNewCompleteList toggleModalFn={() => this.setState({whtsNewModal:false})} modal={whtsNewModal} whtsNewList={whtsNewList} />:null
          }
          { faqsModal ?
            <FAQsList medialLink={this.state.medialLink} toggleModalFn={() => this.setState({faqsModal:false})} modal={faqsModal} faqsList={faqsList} />:null
          }
        </header>
        <ReportAnIssue type="foot" />
        { pageLoader || formSubmitLoader ? <PageLoader/>:null }
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleTheAppSwitcher: (flag) => dispatch({type:actions.APPS_SWITCHER, payload:flag}),
  }
}
export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(DesktopHeader)))
