import React from 'react';
import { TableRow, TableCell, TextField, InputAdornment } from '@material-ui/core';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat, checkUserAccessPermission, getGlobalCookie } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
import { RevisionHistory } from '../../../shared_elements';
import HistoryIcon from '@material-ui/icons/History';
import config from '../../../config';
const SecondaryLLPList = ({item, enginesEdit, llpIndex, onChangeLLP, engineIndex, cyclesRemaining, llpCsnValFlag, applicationPermission}) => {
  const style = {
    color: item.module == "Fan" ? 'rgb(74, 153, 254)' : item.module == 'HPC' ? 'rgb(151, 152, 156)': item.module == 'HPT' ? 'rgb(93, 173, 94)':item.module == "LPT" ? 'rgb(252, 152, 37)':''
  }
  let remainingCycle= cyclesRemaining.filter(item =>  item )
  const cycleRem = item.cycles_limit - item.csn
  let rowStyle  = {}
  if(parseInt(item.cycles_limit) - parseInt(item.csn) < 0 ){
    rowStyle={
      color:'red'
    }
  }
  if(!item.status){
    rowStyle={
      opacity:'0.3'
    }
  }
  return(
    <TableRow hover tabIndex={-1} style={rowStyle}>
      <TableCell style={rowStyle}>{item.module}</TableCell>
      <TableCell style={rowStyle}>{item.description}</TableCell>
      <TableCell style={rowStyle}>
        { enginesEdit && checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') ?
          <TextField
            id="life_limit"
            margin="normal"
            InputLabelProps={{shrink: true}}
            value={item.cycles_limit}
            inputProps={{maxLength:10}}
            disabled={!item.status}
            onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value)  ? onChangeLLP(e, 'cycles_limit', e.target.value, llpIndex, engineIndex, 'secondary') : e.preventDefault()}}
            error={item.status && item.lifeLimitvalFlag ? true :false}
            helperText={item.status && item.lifeLimitvalFlag ? 'Please enter Life Limit' : ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">FC</InputAdornment>,
            }}
          />
          : `${item.cycles_limit} FC`
        }
      </TableCell>
      <TableCell style={rowStyle}>{`${parseFloat(item.cycles_limit - item.csn)} FC`}</TableCell>
      <TableCell style={rowStyle}>
        { enginesEdit ?
          <TextField
            id="csn"
            margin="normal"
            color={parseInt(item.cycles_limit) - parseInt(item.csn) < 0 ? 'secondary' :''}
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{shrink: true}}
            value={item.csn}
            disabled={!item.status}
            onChange={(e) => {regexConstants.onlyNumeric.test(e.target.value) ? onChangeLLP(e, 'csn', e.target.value, llpIndex, engineIndex, 'secondary') : e.preventDefault()}}
            error={item.status && ((parseInt(item.cycles_limit) - parseInt(item.csn) < 0) || item.llpCsnValFlag)  ? true : false}
            helperText={item.status && ((parseInt(item.cycles_limit) - parseInt(item.csn) < 0) || item.llpCsnValFlag) ? 'Please enter valid CSN' :''}
            InputProps={{
              endAdornment: <InputAdornment position="end">FC</InputAdornment>,
            }}
          /> :
           item.csn ? `${item.csn} FC` : `0 FC`
        }
      </TableCell>
      {
        enginesEdit && checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') ?
        <TableCell>
          <TextField
            id="cost"
            margin="normal"
            InputLabelProps={{shrink: true}}
            value={item.cost}
            disabled={!item.status}
            onChange={(e) => {regexConstants.numberWithDotUpto4.test(e.target.value) ? onChangeLLP(e, 'cost', e.target.value  , llpIndex, engineIndex, 'secondary') : e.preventDefault()}}
            error={item.status && item.llpCostFlag ? true: false}
            helperText={item.status && item.llpCostFlag ? "Please enter Cost": ''}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </TableCell> :
        (checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') ||
        [13].includes(getGlobalCookie(config.cookiesNaming.key).lessor.id)) ?
        <TableCell style={rowStyle}><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{item.cost}</NumericalLabel></TableCell> : null
      }
      <TableCell>
        {
          item.status ?
          <RevisionHistory
            url={`audit/valuation/vtenginellplogs/`}
            queryParams={{action:1, object_id:item.id}}
            buttonType={true}
            component={true}
            exportRevisionHistory={true}
            exportQueryparam={{action:1, object_id:item.id, download:'xls'}}
            moduleType={"Secondary LLP"}
            exportPermission={ checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'EXP_PDF') }
          />: <HistoryIcon color="primary" fontSize="small" style={{opacity:'0.3'}}/>
        }
      </TableCell>
    </TableRow>
  )
}
export default SecondaryLLPList;
