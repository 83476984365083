import React,{ Component, Fragment } from 'react';
import { TableListComp } from '../../../shared_elements';
import PreviousInspectionList from './PreviousInspectionList';
import { getConstantsApi } from '../apiServices';
class PreviousInspections extends Component {
  constructor(props) {
    super(props)
    this.state = {
      constantTypesList:[]
    }
    this.getConstantsApi = getConstantsApi.bind(this)
  }
  componentDidMount() {
    this.getConstantsApi({constant_types:['project_name']})
  }
  render() {
    const { projectType, getResponseBack } = this.props
    const { constantTypesList } = this.state
    return (
      <Fragment>
        <TableListComp
          heads={[
            { label:'Inspections Type', sortOption:false },
            { label:'No. of Projects', sortOption:false },
            { label:'', sortOption:false },
            { label:'', sortOption:false },
          ]}
          data= { constantTypesList.length ? constantTypesList.map((constant, index) =>
                  <PreviousInspectionList
                    project={constant}
                    key={index}
                    getResponseBack={getResponseBack}
                    projectType={projectType}
                  />
          ):null}
        />
      </Fragment>
    )
  }
}
export default PreviousInspections;
