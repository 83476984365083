import React, {  Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { getGlobalCookie, checkUserAccessPermission } from '../utils';
import { isMobile } from 'react-device-detect';
import restrictIcon from '../assets/images/restrict_icon.svg';
import config from '../config';
export default function userRoleBaseAccessHoc(HocComponent, extraProps=[]){
  class UserLayout extends Component{
    render(){
      if(getGlobalCookie(config.cookiesNaming.key) && getGlobalCookie(config.cookiesNaming.key).user && checkUserAccessPermission(this.props.applicationPermission, 'valuation')){
        return (
          <HocComponent {...this.props} />
        )}
      else {
        if(!getGlobalCookie(config.cookiesNaming.key)){
          <div className="page-not-found">Loading...</div>
        }else{
          return (
            <div>
              <div className="page-not-found">
                <Grid container spacing={0}>
                  <Grid xs={12}>
                    <img src={restrictIcon} alt="Icon"/>
                    <h2>You’re not permitted to see this.</h2>
                    <p>The page you’re trying to access has restricted access,<br/>
                      please contact <a style={{color:'#3F51B5', textDecoration:'auto'}} href="mailto:support@sparta.aero">support@sparta.aero</a> to access this application.
                    </p>
                    <Link className="start-over btn btn-default return-home" onClick={() => window.location.assign(`${config.domain.subDomian}`)}>Return Home</Link>
                  </Grid>
                </Grid>
              </div>
            </div>
          )
        }
      }
    }
  }
  const mapStateToProps = (state) => ({
    applicationPermission: state.shareReducer.applicationPermission,
  });
  return connect(mapStateToProps, null)(UserLayout)
}
