import React, { Component,Fragment } from 'react';
import { Tabs, Tab, Paper, Grid, TextField, Button, IconButton, Dialog,DialogTitle, DialogActions, DialogContent, Tooltip, TableRow, TableCell, InputAdornment, FormControl, FormLabel, RadioGroup, FormControlLabel,Radio, Divider, Checkbox, FormGroup } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { withSnackbar } from 'notistack';
import { LabelValueCard, TableListComp, EmptyCollection } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { BasicFormDetails, EngineLLPList, LLPTabs, AddLLPModal, SecondaryLLPList, StaticLLPList, EnableDisableButton, ConfirmationModal } from '../components';
import { displayDateFormat } from '../../../constants';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { regexConstants } from '../../../constants/regEx';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat, getGlobalCookie, checkUserAccessPermission } from '../../../utils'
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';
import {importLLPApi, exportLLPApi } from '../apiServices';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import ImportIcon from '@material-ui/icons/Publish';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from '../../../config';
class EngineHlaHsiIntermediate extends Component {
  constructor(props){
    super(props)
    this.state={
      tabIndex:0,
      openModal:false,
      shopVisitModal:false,
      llpTabIndex:0,
      files:[]
    }
    this.exportLLPApi = exportLLPApi.bind(this);
  }
  openConfirmationModal=(e)=>{
    this.setState(prevState => ({
      ...prevState,
      confirmationModal:true,
    }))
}
handleEventValue = (e) => {
}
  render(){
    const { skeletonLoader, hlaLogDetail,  updateEngineField, updateEngineLog, enginesEdit, toggleEngineEdit, onCloseEdit, onChangeLLP, updateLlpCsn, updateHSINone, updatePrsvNone, error, updateEngineErrorField, tabIndex, changeEngineTabs, toggleLLpModal, llpModal, closeLLLpModal, updateLLP, engineTabColor, engineLlpList, llpCsnValidationFlag, updateHSIEngineField, secondaryLlpList, staticLlpList, llpStaticValidationFlag, llpSecondaryValidationFlag, selectedStaticLLps, changeLLPTabs, llpTabIndex, pageLoader, llpTabName, secondaryllpUpdate, secondaryLLpEnableFlag, staticLLpUpdate, checkBoxLoader, updateEngineValidatorField, isLocked, importLLP, handleLLpImport, confirmationModal} = this.props
    const { engine, shopVisitModal, files } = this.state;
    const llpHlaHeads=[
         { label: 'Module', sortOption:false},
         { label: 'Description', sortOption: false },
         { label: 'Life Limit', sortOption: false },
         { label: 'Cycles Remaining', sortOption: false },
         { label: 'Cycles Since New', sortOption: false },
         { label: 'LLP Cost', sortOption: false },
         { label: 'Revision History', sortOption:false}
      ]
      const secondaryLLpHeads=[
        { label: 'Module', sortOption:false},
        { label: 'Description', sortOption: false },
        { label: 'Life Limit', sortOption: false },
        { label: 'Cycles Remaining', sortOption: false },
        { label: 'Cycles Since New', sortOption: false },
        { label: 'LLP Cost', sortOption: false },
        { label: 'Revision History', sortOption:false}
     ]
   const staticLLpHeads = [
        { label: enginesEdit ?  'Click to Enable or Disable' : '', sortOption:false },
        { label: 'Module', sortOption:false},
        { label: 'Description', sortOption: false },
        { label: 'Life Limit', sortOption: false },
        { label: 'Cycles Remaining', sortOption: false },
        { label: 'Cycles Since New', sortOption: false },
        { label: 'LLP Cost', sortOption: false },
        { label: 'Revision History', sortOption:false}
     ]
    let staticLLpFilterHeads= staticLLpHeads.filter(item => !['LLP Cost'].includes(item.label))
    let secondaryFilterHeads= secondaryLLpHeads.filter(item => !['LLP Cost'].includes(item.label))
    let filteredHeads = llpHlaHeads.filter(item => !['LLP Cost'].includes(item.label))
    return(
      <Paper>
        <Grid container spacing={0} style={isMobile ? {marginBottom:'auto'}:{marginBottom:'15px'}, {padding:'10px'}}>
          <Grid item xs={5} sm={6} md={6}>
            <h4 className="hla-initiate-card-title"><strong>Engine</strong></h4>
          </Grid>
          <Grid item xs={7} sm={6} md={6}>
            <div style={{float:'right'}}>
              { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'U') ?
                !enginesEdit ?
                !isLocked ?
                <EditIcon color="primary" style={{cursor:'pointer'}} onClick={toggleEngineEdit} />: null :
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Button size="small" color="primary" variant="contained" onClick={updateEngineLog}>Save</Button>
                  </li>
                  <li className="list-inline-item" style={{marginLeft:'5px'}}>
                    <Button size="small" color="primary" variant="outlined" onClick={onCloseEdit} >Cancel</Button>
                  </li>
                </ul>
                 : null
              }
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{padding:'10px'}}>
          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Engine Type' value={hlaLogDetail.engine.engine_type}/>
        </Grid>
          <Grid item xs={12} md={12}>
            <Tabs
              value={tabIndex}
              onChange={changeEngineTabs}
              indicatorColor={engineTabColor}
              textColor={engineTabColor}
              variant="scrollable"
              scrollButtons={isMobile ? 'on':'auto'}
            >
              {
                hlaLogDetail.engine.engineInfo.map((item, index) =>
                <Tab label={`Engine #${index +1}` } />)
              }
            </Tabs>
          </Grid>
          {hlaLogDetail.engine.engineInfo.map((engine, engineIndex) => {
            //  sort llp
              // let sortEngineLLp = engine.llp.sort((a,b) => (a.module > b.module) ? 1 : ((b.module > a.module) ? -1 : 0))
              // primary llp sum
              const llpcostArr = engineLlpList.map(item => parseFloat(item.cost))
              const llpCostSum = llpcostArr.reduce((a, b) => a + b, 0).toFixed(2)
              // Secondary llp sum
              const llpSecondaryArr = secondaryLlpList.length ? secondaryLlpList.map(item => item.cost ?  parseFloat(item.cost) : 0) : []
              const llpSecondaryCostSum = llpSecondaryArr.length ? llpSecondaryArr.reduce((a, b) => a + b, 0).toFixed(2) : 0
              // Static llp sum
              const llpStaticArr = staticLlpList.length ? staticLlpList.map(item => item.cost ?  parseFloat(item.cost) : 0) : []
              const llpStaticCostSum = llpStaticArr.length ? llpStaticArr.reduce((a, b) => a + b, 0 ).toFixed(2) : 0

              // cycles remaining
              let cyclesRemaining =  engineLlpList.map(item =>  parseInt(item.cycles_limit) - parseInt(item.csn))
              // event cost
              let eventCost = ''
              if(engine.last_performed_sv){
                if(engine.last_performed_sv.name == "Mature Run"){
                  eventCost = engine.engine_prsv.find(item => item.event == engine.last_performed_sv.name).cost
              }else {
                let eventIndex = engine.engine_prsv.findIndex(item => item.event == engine.last_performed_sv.name)
                eventCost = engine.engine_prsv.find((item, index) => index == (eventIndex + 1)).cost
              }
            }
            // llp close flagCheck
            let flagCheckArry = engineLlpList.map(item =>  parseInt(item.cycles_limit) - parseInt(item.csn) < 0)
            let flagSecondaryCheckArry = []
            if(secondaryLlpList.every(item =>  item.status)){
              flagSecondaryCheckArry = secondaryLlpList.map(item =>  parseInt(item.cycles_limit) - parseInt(item.csn) < 0)
            }
            let flagStaticCheckArry = []
            if(staticLlpList.every(item =>  item.status)){
              flagStaticCheckArry = staticLlpList.map(item =>  parseInt(item.cycles_limit) - parseInt(item.csn) < 0)
            }
             return(
               tabIndex == engineIndex ?
                 <section style={{padding:'10px 20px 20px', position:'relative'}}>
                   {!enginesEdit ?
                     <Fragment>
                       <Grid container spacing={4}>
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Serial Number' value={engine.serial_number ? engine.serial_number : '--'}/>
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Time Since New' value={engine.tsn ? `${engine.tsn} FH` : '--'}/>
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Cycles Since New' value={engine.csn ? `${engine.csn} FC` : '--'}/>
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Utilization as of date' value={engine.utilization_as_of_date ? moment(engine.utilization_as_of_date).format(displayDateFormatShort) : '--'}/>
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3}
                            label={
                                    <p>TSN at Last HSI
                                      <Tooltip
                                        title="Time Since New at Last HSI"
                                        arrow
                                        placement="top"
                                      >
                                      <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                      </Tooltip>
                                    </p>
                                  }
                            value={engine.tsn_at_hsi ? `${engine.tsn_at_hsi} FH` : '--'}
                          />
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3}
                            label={
                                <p>CSN at Last HSI
                                  <Tooltip
                                    title="Cycles Since New at Last HSI"
                                    arrow
                                    placement="top"
                                  >
                                  <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                  </Tooltip>
                                </p>
                                }
                            value={engine.csn_at_hsi ? `${engine.csn_at_hsi} FC` : '--'}
                          />
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Last HSI Event Date' value={engine.last_hsi_event_date ? moment(engine.last_hsi_event_date).format(displayDateFormatShort) : '--'}/>
                          <LabelValueCard
                            skeletonLoader={skeletonLoader} xs={6} md={3}
                            label={
                              <p>Last Performed HSI
                                {/*<span style={{textDecoration:'underline', color:'teal', marginLeft:'3px', cursor:'pointer', color:'#3f51b5', display:'none'}} onClick={() =>  this.setState({shopVisitModal:true})}> View PRSV Data</span>*/}
                              </p>
                            }
                            value={engine.last_performed_hsi && engine.last_performed_hsi.name ? engine.last_performed_hsi.name : 'No HSI Performed'}
                          />
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='HSI Interval' value={engine.interval_fh_hsi ? `${engine.interval_fh_hsi} FH` : '--'}/>
                          {
                            (checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') &&
                            getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == 'Acumen')?
                              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='HSI Cost' value={<NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{engine.cost_hsi}</NumericalLabel>}   />
                              : null
                          }
                          { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') ?
                            <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={6} label="Remarks" value={engine.remarks_hsi ? engine.remarks_hsi :'--'} />
                            : null
                          }
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3}
                            label={
                                    <p>TSN at Last OVH
                                      <Tooltip
                                        title="Time Since New at Last OVH"
                                        arrow
                                        placement="top"
                                      >
                                      <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                      </Tooltip>
                                    </p>
                                  }
                            value={engine.tsn_at_lsv ? `${engine.tsn_at_lsv} FH` : '--'}
                          />
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3}
                            label={
                                <p>CSN at Last OVH
                                  <Tooltip
                                    title="Cycles Since New at Last OVH"
                                    arrow
                                    placement="top"
                                  >
                                  <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                  </Tooltip>
                                </p>
                                }
                            value={engine.csn_at_lsv ? `${engine.csn_at_lsv} FC` : '--'}
                          />
                        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Last OVH Event Date' value={engine.last_event_date ? moment(engine.last_event_date).format(displayDateFormatShort) : '--'}/>
                        <LabelValueCard
                          skeletonLoader={skeletonLoader} xs={6} md={3}
                          label={
                            <p>Last Performed OVH
                              {/*<span style={{textDecoration:'underline', color:'teal', marginLeft:'3px', cursor:'pointer', color:'#3f51b5', display:'none'}} onClick={() =>  this.setState({shopVisitModal:true})}> View PRSV Data</span>*/}
                            </p>
                          }
                          value={engine.last_performed_sv && engine.last_performed_sv.name ? engine.last_performed_sv.name : 'No OVH Performed'}
                        />
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='OVH Interval' value={engine.interval_fh ? `${engine.interval_fh} FH` : '--'}/>
                        {
                          (checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') &&
                          getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == 'Acumen')?
                            <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='OVH Cost' value={<NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{engine.cost}</NumericalLabel>}   />
                            : null
                        }
                        { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') ?
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={6} label="Remarks" value={engine.remarks ? engine.remarks :'--'} />
                          : null
                        }
                      </Grid>
                     </Fragment> :
                     <Fragment>
                       <Grid container spacing={4}>
                        <Grid item xs={6} md={3}>
                          <TextField
                            id="serial_number"
                            label='Serial Number'
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{shrink: true}}
                            value={engine.serial_number}
                            onChange={(e) => updateEngineField(e, 'serial_number', e.target.value, engineIndex)}
                            error={error && error[engineIndex] && error[engineIndex].serial_number_engine ? true:false}
                            helperText={error && error[engineIndex] && error[engineIndex].serial_number_engine ? error[engineIndex].serial_number_engine : ''}
                            onFocus={(e) => error && error[engineIndex] && error[engineIndex].serial_number_engine ?  updateEngineErrorField('serial_number_engine','', engineIndex) : e.preventDefault()}
                          />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <TextField
                            required
                            id="tsn"
                            label='Time Since New'
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{shrink: true}}
                            value={engine.tsn}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? updateEngineField(e, 'tsn', e.target.value, engineIndex):e.preventDefault()}}
                            error={error && error[engineIndex] && error[engineIndex].tsnEngine ? true:false}
                            helperText={error && error[engineIndex] && error[engineIndex].tsnEngine ? error[engineIndex].tsnEngine : ''}
                            onFocus={(e) => error && error[engineIndex] && error[engineIndex].tsnEngine ?   updateEngineErrorField('tsnEngine', '', engineIndex) : e.preventDefault()}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <TextField
                            required
                            id="csn"
                            label='Cycles Since New'
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{shrink: true}}
                            value={engine.csn}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? updateEngineField(e, 'csn', e.target.value, engineIndex):e.preventDefault()}}
                            error={error && error[engineIndex] && error[engineIndex].csnEngine ? true:false}
                            helperText={error && error[engineIndex] && error[engineIndex].csnEngine ? error[engineIndex].csnEngine :''}
                            onFocus={(e) => error && error[engineIndex] && error[engineIndex].csnEngine ?   updateEngineErrorField('csnEngine','', engineIndex ) : e.preventDefault()}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                required
                                margin="normal"
                                id="utilization_as_of_date"
                                label="Utilization as of date"
                                placeholder="DD-MM-YYYY"
                                fullWidth
                                format={fieldDateFormat}
                                disableFuture={true}
                                minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                                maxDate={hlaLogDetail.generic.report_date ? moment(hlaLogDetail.generic.report_date) : moment()}
                                InputLabelProps={{shrink: true}}
                                onChange={(data, value) => updateEngineField(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat):data, engineIndex)}
                                value={engine.utilization_as_of_date}
                                error={error && error[engineIndex] && error[engineIndex].utilization_as_of_date_engine ? true:false}
                                helperText={error && error[engineIndex] && error[engineIndex].utilization_as_of_date_engine ? error[engineIndex].utilization_as_of_date_engine  :''}
                                onFocus={(e) => error && error[engineIndex] && error[engineIndex].utilization_as_of_date_engine ?  updateEngineErrorField('utilization_as_of_date_engine', '', engineIndex) : e.preventDefault()}
                                onOpen={(e) => error && error[engineIndex] && error[engineIndex].utilization_as_of_date_engine ?  updateEngineErrorField('utilization_as_of_date_engine', '', engineIndex) : null}

                              />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        {
                          tabIndex === 0 ?
                          <Fragment>
                          {
                            error && error.engine_1 && error.engine_1.length === 1 ?
                            <Fragment>
                              <Grid item xs={6} md={3}>
                                <TextField
                                  id="tsn_at_hsi"
                                  label={
                                    <p>TSN at Last HSI { engine.csn_at_hsi || engine.last_hsi_event_date ? <sup>*</sup> : null }
                                      <Tooltip
                                        title="Time Since New at Last HSI"
                                        arrow
                                        placement="top"
                                      >
                                      <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                      </Tooltip>
                                    </p>
                                  }
                                  fullWidth
                                  margin="normal"
                                  inputProps={{ maxLength: 10 }}
                                  InputLabelProps={{shrink: true}}
                                  value={engine.tsn_at_hsi}
                                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? updateEngineField(e, 'tsn_at_hsi', e.target.value, engineIndex):e.preventDefault()}}
                                  error={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].tsn_at_hsi ? true:false}
                                  helperText={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].tsn_at_hsi ? error.engine_1[0].tsn_at_hsi :''}
                                  onFocus={(e) =>  error && error.engine_1 && error.engine_1[0] && error.engine_1[0].tsn_at_hsi ? updateEngineValidatorField('tsn_at_hsi', 'engine_1', 0) : e.preventDefault()}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <TextField
                                  id="csn_at_hsi"
                                  label={
                                      <p>CSN at Last HSI
                                        <Tooltip
                                          title="Cycles Since New at Last HSI"
                                          arrow
                                          placement="top"
                                        >
                                        <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                        </Tooltip>
                                      </p>
                                    }
                                  fullWidth
                                  margin="normal"
                                  inputProps={{ maxLength: 10 }}
                                  InputLabelProps={{shrink: true}}
                                  value={engine.csn_at_hsi}
                                  onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? updateEngineField(e, 'csn_at_hsi', e.target.value, engineIndex):e.preventDefault()}}
                                  error={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].csn_at_hsi ? true:false}
                                  helperText={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].csn_at_hsi ? error.engine_1[0].csn_at_hsi :''}
                                  onFocus={(e) =>  error && error.engine_1 && error.engine_1[0] && error.engine_1[0].csn_at_hsi ? updateEngineValidatorField('csn_at_hsi', 'engine_1', 0) : e.preventDefault()}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <KeyboardDatePicker
                                    margin="normal"
                                    id="last_hsi_event_date"
                                    label="Last HSI Event Date"
                                    placeholder="DD-MM-YYYY"
                                    fullWidth
                                    format={fieldDateFormat}
                                    minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                                    maxDate={engine.utilization_as_of_date ? moment(engine.utilization_as_of_date) : moment()}
                                    InputLabelProps={{shrink: true}}
                                    onChange={(data, value) => updateEngineField(value, 'last_hsi_event_date', data ? moment(data).format(backendDateFormat):data, engineIndex)}
                                    value={engine.last_hsi_event_date}
                                    error={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].last_hsi_event_date ? true:false}
                                    helperText={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].last_hsi_event_date ? error.engine_1[0].last_hsi_event_date :''}
                                    onFocus={(e) =>  error && error.engine_1 && error.engine_1[0] && error.engine_1[0].last_hsi_event_date ? updateEngineValidatorField('last_hsi_event_date', 'engine_1', 0) : e.preventDefault()}
                                    onOpen={(e) => error && error.engine_1 && error.engine_1[0] && error.engine_1[0].last_hsi_event_date ?  updateEngineValidatorField('last_hsi_event_date', 'engine_1', 0) :null}
                                  />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            </Fragment> :
                          <Fragment>
                            <Grid item xs={6} md={3}>
                              <TextField
                                id="tsn_at_hsi"
                                label={
                                  <p>TSN at Last HSI { engine.csn_at_hsi || engine.last_hsi_event_date ? <sup>*</sup> : null }
                                    <Tooltip
                                      title="Time Since New at Last HSI"
                                      arrow
                                      placement="top"
                                    >
                                    <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                    </Tooltip>
                                  </p>
                                }
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                InputLabelProps={{shrink: true}}
                                value={engine.tsn_at_hsi}
                                onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? updateEngineField(e, 'tsn_at_hsi', e.target.value, engineIndex):e.preventDefault()}}
                                error={error && error.engine_1 && error.engine_1[1] && error.engine_1[1].tsn_at_hsi ? true:false}
                                helperText={error && error.engine_1 && error.engine_1[1] && error.engine_1[1].tsn_at_hsi ? error.engine_1[1].tsn_at_hsi :''}
                                onFocus={(e) =>  error && error.engine_1 && error.engine_1[1] && error.engine_1[1].tsn_at_hsi ? updateEngineValidatorField('tsn_at_hsi', 'engine_1', 1) : e.preventDefault()}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} md={3}>
                              <TextField
                                id="csn_at_hsi"
                                label={
                                    <p>CSN at Last HSI
                                      <Tooltip
                                        title="Cycles Since New at Last HSI"
                                        arrow
                                        placement="top"
                                      >
                                      <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                      </Tooltip>
                                    </p>
                                  }
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                InputLabelProps={{shrink: true}}
                                value={engine.csn_at_hsi}
                                onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? updateEngineField(e, 'csn_at_hsi', e.target.value, engineIndex):e.preventDefault()}}
                                error={error && error.engine_1 && error.engine_1[1] && error.engine_1[1].csn_at_hsi ? true:false}
                                helperText={error && error.engine_1 && error.engine_1[1] && error.engine_1[1].csn_at_hsi ? error.engine_1[1].csn_at_hsi :''}
                                onFocus={(e) =>  error && error.engine_1 && error.engine_1[0] && error.engine_1[1].csn_at_hsi ? updateEngineValidatorField('csn_at_hsi', 'engine_1', 1) : e.preventDefault()}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="last_hsi_event_date"
                                    label="Last HSI Event Date"
                                    placeholder="DD-MM-YYYY"
                                    fullWidth
                                    format={fieldDateFormat}
                                    minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                                    maxDate={engine.utilization_as_of_date ? moment(engine.utilization_as_of_date) : moment()}
                                    InputLabelProps={{shrink: true}}
                                    onChange={(data, value) => updateEngineField(value, 'last_hsi_event_date', data ? moment(data).format(backendDateFormat):data, engineIndex)}
                                    value={engine.last_hsi_event_date}
                                    error={error && error.engine_1 && error.engine_1[1] && error.engine_1[1].last_hsi_event_date ? true:false}
                                    helperText={error && error.engine_1 && error.engine_1[1] && error.engine_1[1].last_hsi_event_date ? error.engine_1[1].last_hsi_event_date :''}
                                    onFocus={(e) =>  error && error.engine_1 && error.engine_1[1] && error.engine_1[1].last_hsi_event_date ? updateEngineValidatorField('last_hsi_event_date', 'engine_1', 1) : e.preventDefault()}
                                    onOpen={(e) => error && error.engine_1 && error.engine_1[1] && error.engine_1[1].last_hsi_event_date ?  updateEngineValidatorField('last_hsi_event_date', 'engine_1', 1) :null}
                                  />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Fragment>

                          }
                          </Fragment>: null
                        }
                        {
                          tabIndex === 1 ?
                          <Fragment>
                            {
                              error && error.engine_2 && error.engine_2.length === 1 ?
                              <Fragment>
                                <Grid item xs={6} md={3}>
                                  <TextField
                                    id="tsn_at_hsi"
                                    label={
                                      <p>TSN at Last HSI { engine.csn_at_hsi || engine.last_hsi_event_date ? <sup>*</sup> : null }
                                        <Tooltip
                                          title="Time Since New at Last HSI"
                                          arrow
                                          placement="top"
                                        >
                                        <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                        </Tooltip>
                                      </p>
                                    }
                                    fullWidth
                                    margin="normal"
                                    inputProps={{ maxLength: 10 }}
                                    InputLabelProps={{shrink: true}}
                                    value={engine.tsn_at_hsi}
                                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? updateEngineField(e, 'tsn_at_hsi', e.target.value, engineIndex):e.preventDefault()}}
                                    error={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].tsn_at_hsi ? true:false}
                                    helperText={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].tsn_at_hsi ? error.engine_2[0].tsn_at_hsi :''}
                                    onFocus={(e) =>  error && error.engine_2 && error.engine_2[0] && error.engine_2[0].tsn_at_hsi ? updateEngineValidatorField('tsn_at_hsi', 'engine_2', 0) : e.preventDefault()}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                  <TextField
                                    id="csn_at_hsi"
                                    label={
                                        <p>CSN at Last HSI
                                          <Tooltip
                                            title="Cycles Since New at Last HSI"
                                            arrow
                                            placement="top"
                                          >
                                          <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                          </Tooltip>
                                        </p>
                                      }
                                    fullWidth
                                    margin="normal"
                                    inputProps={{ maxLength: 10 }}
                                    InputLabelProps={{shrink: true}}
                                    value={engine.csn_at_hsi}
                                    onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? updateEngineField(e, 'csn_at_hsi', e.target.value, engineIndex):e.preventDefault()}}
                                    error={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].csn_at_hsi ? true:false}
                                    helperText={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].csn_at_hsi ? error.engine_2[0].csn_at_hsi :''}
                                    onFocus={(e) =>  error && error.engine_2 && error.engine_2[0] && error.engine_2[0].csn_at_hsi ? updateEngineValidatorField('csn_at_hsi', 'engine_2', 0) : e.preventDefault()}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                  <KeyboardDatePicker
                                      margin="normal"
                                      id="last_hsi_event_date"
                                      label="Last HSI Event Date"
                                      placeholder="DD-MM-YYYY"
                                      fullWidth
                                      format={fieldDateFormat}
                                      minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                                      maxDate={engine.utilization_as_of_date ? moment(engine.utilization_as_of_date) : moment()}
                                      InputLabelProps={{shrink: true}}
                                      onChange={(data, value) => updateEngineField(value, 'last_hsi_event_date', data ? moment(data).format(backendDateFormat):data, engineIndex)}
                                      value={engine.last_hsi_event_date}
                                      error={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].last_hsi_event_date ? true:false}
                                      helperText={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].last_hsi_event_date ? error.engine_2[0].last_hsi_event_date :''}
                                      onFocus={(e) =>  error && error.engine_2 && error.engine_2[0] && error.engine_2[0].last_hsi_event_date ? updateEngineErrorField('last_hsi_event_date', 'engine_2', 0) : e.preventDefault()}
                                      onOpen={(e) => error && error.engine_2 && error.engine_2[0] && error.engine_2[0].last_hsi_event_date ?  updateEngineErrorField('last_hsi_event_date', 'engine_2', 0) :null}
                                  />
                                </MuiPickersUtilsProvider>
                              </Grid>
                              </Fragment>
                               :
                            <Fragment>
                              <Grid item xs={6} md={3}>
                                <TextField
                                  id="tsn_at_hsi"
                                  label={
                                    <p>TSN at Last HSI { engine.csn_at_hsi || engine.last_hsi_event_date ? <sup>*</sup> : null }
                                      <Tooltip
                                        title="Time Since New at Last HSI"
                                        arrow
                                        placement="top"
                                      >
                                      <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                      </Tooltip>
                                    </p>
                                  }
                                  fullWidth
                                  margin="normal"
                                  inputProps={{ maxLength: 10 }}
                                  InputLabelProps={{shrink: true}}
                                  value={engine.tsn_at_hsi}
                                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? updateEngineField(e, 'tsn_at_hsi', e.target.value, engineIndex):e.preventDefault()}}
                                  error={error && error.engine_2 && error.engine_2[1] && error.engine_2[1].tsn_at_hsi ? true:false}
                                  helperText={error && error.engine_2 && error.engine_2[1] && error.engine_2[1].tsn_at_hsi ? error.engine_2[1].tsn_at_hsi :''}
                                  onFocus={(e) =>  error && error.engine_2 && error.engine_2[1] && error.engine_2[1].tsn_at_hsi ? updateEngineValidatorField('tsn_at_hsi', 'engine_2', 1) : e.preventDefault()}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <TextField
                                  id="csn_at_hsi"
                                  label={
                                      <p>CSN at Last HSI
                                        <Tooltip
                                          title="Cycles Since New at Last HSI"
                                          arrow
                                          placement="top"
                                        >
                                        <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                        </Tooltip>
                                      </p>
                                    }
                                  fullWidth
                                  margin="normal"
                                  inputProps={{ maxLength: 10 }}
                                  InputLabelProps={{shrink: true}}
                                  value={engine.csn_at_hsi}
                                  onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? updateEngineField(e, 'csn_at_hsi', e.target.value, engineIndex):e.preventDefault()}}
                                  error={error && error.engine_2 && error.engine_2[1] && error.engine_2[1].csn_at_hsi ? true:false}
                                  helperText={error && error.engine_2 && error.engine_2[1] && error.engine_2[1].csn_at_hsi ? error.engine_2[1].csn_at_hsi :''}
                                  onFocus={(e) =>  error && error.engine_2 && error.engine_2[1] && error.engine_2[1].csn_at_hsi ? updateEngineValidatorField('csn_at_hsi', 'engine_2', 1) : e.preventDefault()}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                              <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="last_hsi_event_date"
                                    label="Last HSI Event Date"
                                    placeholder="DD-MM-YYYY"
                                    fullWidth
                                    format={fieldDateFormat}
                                    minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                                    maxDate={engine.utilization_as_of_date ? moment(engine.utilization_as_of_date) : moment()}
                                    InputLabelProps={{shrink: true}}
                                    onChange={(data, value) => updateEngineField(value, 'last_hsi_event_date', data ? moment(data).format(backendDateFormat):data, engineIndex)}
                                    value={engine.last_hsi_event_date}
                                    error={error && error.engine_2 && error.engine_2[1] && error.engine_2[1].last_hsi_event_date ? true:false}
                                    helperText={error && error.engine_2 && error.engine_2[1] && error.engine_2[1].last_hsi_event_date ? error.engine_2[1].last_hsi_event_date :''}
                                    onFocus={(e) =>  error && error.engine_2 && error.engine_2[1] && error.engine_2[1].last_hsi_event_date ? updateEngineValidatorField('last_hsi_event_date', 'engine_2', 1) : e.preventDefault()}
                                    onOpen={(e) => error && error.engine_2 && error.engine_2[1] && error.engine_2[1].last_hsi_event_date ?  updateEngineValidatorField('last_hsi_event_date', 'engine_2', 1) :null}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                            </Fragment>
                            }
                          </Fragment>
                          : null
                        }
                        <Grid item xs={12} md={3}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Last Performed HSI</FormLabel>
                            <RadioGroup aria-label="engine" name="engine" row>
                              <FormControlLabel  control={<Radio checked={!engine.last_performed_hsi} onChange={(e) => updateHSINone(e, 'last_performed_hsi', 'None', engineIndex)} color="primary" />} label='No HSI Performed' />
                              {engine.engine_hsi.map((hsi, prIndex) =>
                                <FormControlLabel  control={<Radio checked={engine.last_performed_hsi ?  hsi.event === engine.last_performed_hsi.name : null  } onChange={(e) => updateHSIEngineField(e, 'last_performed_hsi', hsi, engineIndex, prIndex)} color="primary" />} label={hsi.event} />
                              )}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                       </Grid>
                       <Grid container spacing={4}>
                        { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') && getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == 'Acumen' ?
                        <Grid item xs={6} md={3}>
                          <TextField
                            required
                            id="interval_fh_hsi"
                            label="HSI Interval"
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{shrink: true}}
                            value={engine.interval_fh_hsi}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? updateEngineField(e, 'interval_fh_hsi', e.target.value, engineIndex):e.preventDefault(); updateEngineField(e, 'engineHsiRemarksFlag', true, engineIndex) }}
                            error={error && error[engineIndex] && error[engineIndex].interval_fh_hsi ? true:false}
                            helperText={error && error[engineIndex] && error[engineIndex].interval_fh_hsi ? error[engineIndex].interval_fh_hsi :''}
                            onFocus={(e) => error && error[engineIndex] && error[engineIndex].interval_fh_hsi ?  updateEngineErrorField('interval_fh_hsi', '', engineIndex) : e.preventDefault()}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                            }}
                          />
                      </Grid> : <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='HSI Interval' value={engine.interval_fh_hsi ? `${engine.interval_fh_hsi} FH` : '--'}/>
                        }
                        {
                          checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') &&
                          (getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == "Acumen") ?
                          <Grid item xs={6} md={3}>
                            <TextField
                              required
                              id="cost"
                              label="HSI Cost"
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 12 }}
                              InputLabelProps={{shrink: true}}
                              value={engine.cost_hsi}
                              onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? updateEngineField(e, 'cost_hsi', e.target.value, engineIndex):e.preventDefault(); updateEngineField(e, 'engineHsiRemarksFlag', true, engineIndex) }}
                              error={error && error[engineIndex] && error[engineIndex].cost_engine_hsi ? true:false}
                              helperText={error && error[engineIndex] && error[engineIndex].cost_engine_hsi ? error[engineIndex].cost_engine_hsi :''}
                              onFocus={(e) => error && error[engineIndex] && error[engineIndex].cost_engine_hsi ?  updateEngineErrorField('cost_engine_hsi', '', engineIndex) : e.preventDefault()}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                            />
                        </Grid> :
                        (checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') || [13].includes(getGlobalCookie(config.cookiesNaming.key).lessor.id)) ?
                          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='HSI Cost' value={<NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{engine.cost_hsi}</NumericalLabel>}   />
                          : <Grid item xs={6} md={3}></Grid>
                        }
                        { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') ?
                        <Grid item xs={6} md={4}>
                          <TextField
                            required={checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') && engine.engineHsiRemarksFlag }
                            id="remarks_hsi"
                            label="Remarks HSI"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 250 }}
                            InputLabelProps={{shrink: true}}
                            value={engine.remarks_hsi}
                            onChange={(e) =>updateEngineField(e, 'remarks_hsi', e.target.value, engineIndex)}
                            error={error && error[engineIndex] && error[engineIndex].remarks_engine_hsi ? true:false}
                            helperText={error && error[engineIndex] && error[engineIndex].remarks_engine_hsi ? error[engineIndex].remarks_engine_hsi :''}
                            onFocus={(e) => error && error[engineIndex] && error[engineIndex].remarks_engine_hsi ?  updateEngineErrorField('remarks_engine_hsi', '', engineIndex) : e.preventDefault()}
                          />
                        </Grid>
                        : null
                      }
                    </Grid>
                    <Grid container spacing={4}>
                     {tabIndex === 0 ?
                     <Fragment>
                       {
                         error && error.engine_1 && error.engine_1.length === 1 ?
                         <Fragment>
                           <Grid item xs={6} md={3}>
                            <TextField
                              id="tsn_at_lsv"
                              label={
                               <p>TSN at Last OVH { engine.csn_at_lsv || engine.last_event_date ? <sup>*</sup> : null }
                                 <Tooltip
                                   title="Time Since New at Last OVH"
                                   arrow
                                   placement="top"
                                 >
                                 <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                 </Tooltip>
                               </p>
                              }
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 10 }}
                              InputLabelProps={{shrink: true}}
                              value={engine.tsn_at_lsv}
                              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? updateEngineField(e, 'tsn_at_lsv', e.target.value, engineIndex):e.preventDefault()}}
                              error={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].tsn_at_lsv ? true:false}
                              helperText={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].tsn_at_lsv ? error.engine_1[0].tsn_at_lsv :''}
                              onFocus={(e) =>  error && error.engine_1 && error.engine_1[0] && error.engine_1[0].tsn_at_lsv ? updateEngineValidatorField('tsn_at_lsv', 'engine_1', 0) : e.preventDefault()}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <TextField
                              id="csn_at_lsv"
                              label={
                                 <p>CSN at Last OVH
                                   <Tooltip
                                     title="Cycles Since New at Last OVH"
                                     arrow
                                     placement="top"
                                   >
                                   <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                   </Tooltip>
                                 </p>
                                }
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 10 }}
                              InputLabelProps={{shrink: true}}
                              value={engine.csn_at_lsv}
                              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? updateEngineField(e, 'csn_at_lsv', e.target.value, engineIndex):e.preventDefault()}}
                              error={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].csn_at_lsv ? true:false}
                              helperText={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].csn_at_lsv ? error.engine_1[0].csn_at_lsv :''}
                              onFocus={(e) =>  error && error.engine_1 && error.engine_1[0] && error.engine_1[0].csn_at_lsv ? updateEngineValidatorField('csn_at_lsv', 'engine_1', 0) : e.preventDefault()}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                              }}
                            />
                          </Grid>
                           <Grid item xs={12} md={3}>
                             <MuiPickersUtilsProvider utils={MomentUtils}>
                               <KeyboardDatePicker
                                margin="normal"
                                id="last_event_date"
                                label="Last OVH Event Date"
                                placeholder="DD-MM-YYYY"
                                fullWidth
                                format={fieldDateFormat}
                                minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                                maxDate={engine.utilization_as_of_date ? moment(engine.utilization_as_of_date) : moment()}
                                InputLabelProps={{shrink: true}}
                                onChange={(data, value) => updateEngineField(value, 'last_event_date', data ? moment(data).format(backendDateFormat):data, engineIndex)}
                                value={engine.last_event_date}
                                error={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].last_event_date ? true:false}
                                helperText={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].last_event_date ? error.engine_1[0].last_event_date :''}
                                onFocus={(e) =>  error && error.engine_1 && error.engine_1[0] && error.engine_1[0].last_event_date ? updateEngineValidatorField('last_event_date', 'engine_1', 0) : e.preventDefault()}
                                onOpen={(e) => error && error.engine_1 && error.engine_1[0] && error.engine_1[0].last_event_date ?  updateEngineValidatorField('last_event_date', 'engine_1', 0) :null}
                              />
                           </MuiPickersUtilsProvider>
                          </Grid>
                         </Fragment>
                         :
                         <Fragment>
                           <Grid item xs={6} md={3}>
                            <TextField
                              id="tsn_at_lsv"
                              label={
                               <p>TSN at Last OVH { engine.csn_at_lsv || engine.last_event_date ? <sup>*</sup> : null }
                                 <Tooltip
                                   title="Time Since New at Last OVH"
                                   arrow
                                   placement="top"
                                 >
                                 <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                 </Tooltip>
                               </p>
                              }
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 10 }}
                              InputLabelProps={{shrink: true}}
                              value={engine.tsn_at_lsv}
                              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? updateEngineField(e, 'tsn_at_lsv', e.target.value, engineIndex):e.preventDefault()}}
                              error={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].tsn_at_lsv ? true:false}
                              helperText={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].tsn_at_lsv ? error.engine_1[0].tsn_at_lsv :''}
                              onFocus={(e) =>  error && error.engine_1 && error.engine_1[0] && error.engine_1[0].tsn_at_lsv ? updateEngineValidatorField('tsn_at_lsv', 'engine_1', 0) : e.preventDefault()}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <TextField
                              id="csn_at_lsv"
                              label={
                                 <p>CSN at Last OVH
                                   <Tooltip
                                     title="Cycles Since New at Last OVH"
                                     arrow
                                     placement="top"
                                   >
                                   <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                   </Tooltip>
                                 </p>
                                }
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 10 }}
                              InputLabelProps={{shrink: true}}
                              value={engine.csn_at_lsv}
                              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? updateEngineField(e, 'csn_at_lsv', e.target.value, engineIndex):e.preventDefault()}}
                              error={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].csn_at_lsv ? true:false}
                              helperText={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].csn_at_lsv ? error.engine_1[0].csn_at_lsv :''}
                              onFocus={(e) =>  error && error.engine_1 && error.engine_1[0] && error.engine_1[0].csn_at_lsv ? updateEngineValidatorField('csn_at_lsv', 'engine_1', 0) : e.preventDefault()}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                              }}
                            />
                          </Grid>
                           <Grid item xs={12} md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                  margin="normal"
                                  id="last_event_date"
                                  label="Last OVH Event Date"
                                  placeholder="DD-MM-YYYY"
                                  fullWidth
                                  format={fieldDateFormat}
                                  minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                                  maxDate={engine.utilization_as_of_date ? moment(engine.utilization_as_of_date) : moment()}
                                  InputLabelProps={{shrink: true}}
                                  onChange={(data, value) => updateEngineField(value, 'last_event_date', data ? moment(data).format(backendDateFormat):data, engineIndex)}
                                  value={engine.last_event_date}
                                  error={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].last_event_date ? true:false}
                                  helperText={error && error.engine_1 && error.engine_1[0] && error.engine_1[0].last_event_date ? error.engine_1[0].last_event_date :''}
                                  onFocus={(e) =>  error && error.engine_1 && error.engine_1[0] && error.engine_1[0].last_event_date ? updateEngineValidatorField('last_event_date', 'engine_1', 0) : e.preventDefault()}
                                  onOpen={(e) => error && error.engine_1 && error.engine_1[0] && error.engine_1[0].last_event_date ?  updateEngineValidatorField('last_event_date', 'engine_1', 0) :null}
                                />
                            </MuiPickersUtilsProvider>
                            </Grid>
                         </Fragment>

                       }
                     </Fragment>
                     : null
                     }
                     {tabIndex === 1 ?
                     <Fragment>
                       {
                         error && error.engine_2 && error.engine_2.length === 1 ?
                         <Fragment>
                           <Grid item xs={6} md={3}>
                            <TextField
                              id="tsn_at_lsv"
                              label={
                               <p>TSN at Last OVH { engine.csn_at_lsv || engine.last_event_date ? <sup>*</sup> : null }
                                 <Tooltip
                                   title="Time Since New at Last OVH"
                                   arrow
                                   placement="top"
                                 >
                                 <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                 </Tooltip>
                               </p>
                              }
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 10 }}
                              InputLabelProps={{shrink: true}}
                              value={engine.tsn_at_lsv}
                              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? updateEngineField(e, 'tsn_at_lsv', e.target.value, engineIndex):e.preventDefault()}}
                              error={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].tsn_at_lsv ? true:false}
                              helperText={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].tsn_at_lsv ? error.engine_2[0].tsn_at_lsv :''}
                              onFocus={(e) =>  error && error.engine_2 && error.engine_2[0] && error.engine_2[0].tsn_at_lsv ? updateEngineValidatorField('tsn_at_lsv', 'engine_2', 0) : e.preventDefault()}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <TextField
                              id="csn_at_lsv"
                              label={
                                 <p>CSN at Last OVH
                                   <Tooltip
                                     title="Cycles Since New at Last OVH"
                                     arrow
                                     placement="top"
                                   >
                                   <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                   </Tooltip>
                                 </p>
                                }
                              fullWidth
                              margin="normal"
                              inputProps={{ maxLength: 10 }}
                              InputLabelProps={{shrink: true}}
                              value={engine.csn_at_lsv}
                              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? updateEngineField(e, 'csn_at_lsv', e.target.value, engineIndex):e.preventDefault()}}
                              error={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].csn_at_lsv ? true:false}
                              helperText={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].csn_at_lsv ? error.engine_2[0].csn_at_lsv :''}
                              onFocus={(e) =>  error && error.engine_2 && error.engine_2[0] && error.engine_2q[0].csn_at_lsv ? updateEngineValidatorField('csn_at_lsv', 'engine_2', 0) : e.preventDefault()}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                margin="normal"
                                id="last_event_date"
                                label="Last OVH Event Date"
                                placeholder="DD-MM-YYYY"
                                fullWidth
                                format={fieldDateFormat}
                                minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                                maxDate={engine.utilization_as_of_date ? moment(engine.utilization_as_of_date) : moment()}
                                InputLabelProps={{shrink: true}}
                                onChange={(data, value) => updateEngineField(value, 'last_event_date', data ? moment(data).format(backendDateFormat):data, engineIndex)}
                                value={engine.last_event_date}
                                error={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].last_event_date ? true:false}
                                helperText={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].last_event_date ? error.engine_2[0].last_event_date :''}
                                onFocus={(e) =>  error && error.engine_2 && error.engine_2[0] && error.engine_2[0].last_event_date ? updateEngineValidatorField('last_event_date', 'engine_2', 0) : e.preventDefault()}
                                onOpen={(e) => error && error.engine_2 && error.engine_2[0] && error.engine_2[0].last_event_date ?  updateEngineValidatorField('last_event_date', 'engine_2', 0) :null}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                         </Fragment>
                        :
                        <Fragment>
                          <Grid item xs={6} md={3}>
                           <TextField
                             id="tsn_at_lsv"
                             label={
                              <p>TSN at Last OVH { engine.csn_at_lsv || engine.last_event_date ? <sup>*</sup> : null }
                                <Tooltip
                                  title="Time Since New at Last OVH"
                                  arrow
                                  placement="top"
                                >
                                <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                </Tooltip>
                              </p>
                             }
                             fullWidth
                             margin="normal"
                             inputProps={{ maxLength: 10 }}
                             InputLabelProps={{shrink: true}}
                             value={engine.tsn_at_lsv}
                             onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? updateEngineField(e, 'tsn_at_lsv', e.target.value, engineIndex):e.preventDefault()}}
                             error={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].tsn_at_lsv ? true:false}
                             helperText={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].tsn_at_lsv ? error.engine_2[0].tsn_at_lsv :''}
                             onFocus={(e) =>  error && error.engine_2 && error.engine_2[0] && error.engine_2[0].tsn_at_lsv ? updateEngineValidatorField('tsn_at_lsv', 'engine_2', 0) : e.preventDefault()}
                             InputProps={{
                               endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                             }}
                           />
                         </Grid>
                         <Grid item xs={6} md={3}>
                           <TextField
                             id="csn_at_lsv"
                             label={
                                <p>CSN at Last OVH
                                  <Tooltip
                                    title="Cycles Since New at Last OVH"
                                    arrow
                                    placement="top"
                                  >
                                  <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                  </Tooltip>
                                </p>
                               }
                             fullWidth
                             margin="normal"
                             inputProps={{ maxLength: 10 }}
                             InputLabelProps={{shrink: true}}
                             value={engine.csn_at_lsv}
                             onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? updateEngineField(e, 'csn_at_lsv', e.target.value, engineIndex):e.preventDefault()}}
                             error={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].csn_at_lsv ? true:false}
                             helperText={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].csn_at_lsv ? error.engine_2[0].csn_at_lsv :''}
                             onFocus={(e) =>  error && error.engine_2 && error.engine_2[0] && error.engine_2[0].csn_at_lsv ? updateEngineValidatorField('csn_at_lsv', 'engine_2', 0) : e.preventDefault()}
                             InputProps={{
                               endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                             }}
                           />
                         </Grid>
                         <Grid item xs={12} md={3}>
                           <MuiPickersUtilsProvider utils={MomentUtils}>
                             <KeyboardDatePicker
                                margin="normal"
                                id="last_event_date"
                                label="Last OVH Event Date"
                                placeholder="DD-MM-YYYY"
                                fullWidth
                                format={fieldDateFormat}
                                minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                                maxDate={engine.utilization_as_of_date ? moment(engine.utilization_as_of_date) : moment()}
                                InputLabelProps={{shrink: true}}
                                onChange={(data, value) => updateEngineField(value, 'last_event_date', data ? moment(data).format(backendDateFormat):data, engineIndex)}
                                value={engine.last_event_date}
                                error={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].last_event_date ? true:false}
                                helperText={error && error.engine_2 && error.engine_2[0] && error.engine_2[0].last_event_date ? error.engine_2[0].last_event_date :''}
                                onFocus={(e) =>  error && error.engine_2 && error.engine_2[0] && error.engine_2[0].last_event_date ? updateEngineValidatorField('last_event_date', 'engine_2', 0) : e.preventDefault()}
                                onOpen={(e) => error && error.engine_2 && error.engine_2[0] && error.engine_2[0].last_event_date ?  updateEngineErrorField('last_event_date', 'engine_1', 0) :null}
                              />
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </Fragment>
                       }
                     </Fragment>
                     : null
                     }
                     <Grid item xs={12} md={3}>
                       <FormControl component="fieldset">
                         <FormLabel component="legend">Last Performed OVH</FormLabel>
                        <RadioGroup aria-label="engine" name="engine" row>
                          <FormControlLabel  control={<Radio checked={!engine.last_performed_sv} onChange={(e) => updatePrsvNone(e, 'last_performed_sv', 'None', engineIndex)} color="primary" />} label='No OVH Performed' />
                          {engine.engine_prsv.map((prsv, prIndex) => {
                            return (
                              <FormControlLabel  control={<Radio checked={engine.last_performed_sv ?  prsv.event === engine.last_performed_sv.name : null  } onChange={(e) => updateEngineField(e, 'last_performed_sv', prsv, engineIndex, prIndex)} color="primary" />} label={prsv.event} />
                            )
                          }
                          )}
                        </RadioGroup>
                      </FormControl>
                     </Grid>
                     {
                       checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') &&
                       getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == "Acumen" ?
                     <Grid item xs={6} md={3}>
                       <TextField
                         required
                         id="interval_fh"
                         label="OVH Interval"
                         fullWidth
                         margin="normal"
                         inputProps={{ maxLength: 10 }}
                         InputLabelProps={{shrink: true}}
                         value={engine.interval_fh}
                         onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? updateEngineField(e, 'interval_fh', e.target.value, engineIndex):e.preventDefault(); updateEngineField(e, 'engineRemarksFlag', true, engineIndex) }}
                         error={error && error[engineIndex] && error[engineIndex].interval_fh ? true:false}
                         helperText={error && error[engineIndex] && error[engineIndex].interval_fh ? error[engineIndex].interval_fh :''}
                         onFocus={(e) => error && error[engineIndex] && error[engineIndex].interval_fh ?  updateEngineErrorField('interval_fh', '', engineIndex) : e.preventDefault()}
                         InputProps={{
                           endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                         }}
                       />
                   </Grid> : <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='OVH Interval' value={engine.interval_fh ? `${engine.interval_fh} FH` : '--'}/>
                    }
                      {
                        checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') &&
                        (getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == "Acumen") ?
                        <Grid item xs={6} md={3}>
                          <TextField
                            required
                            id="cost"
                            label="OVH Cost"
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 12 }}
                            InputLabelProps={{shrink: true}}
                            value={engine.cost}
                            onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? updateEngineField(e, 'cost', e.target.value, engineIndex):e.preventDefault(); updateEngineField(e, 'engineRemarksFlag', true, engineIndex) }}
                            error={error && error[engineIndex] && error[engineIndex].cost_engine ? true:false}
                            helperText={error && error[engineIndex] && error[engineIndex].cost_engine ? error[engineIndex].cost_engine :''}
                            onFocus={(e) => error && error[engineIndex] && error[engineIndex].cost_engine ?  updateEngineErrorField('cost_engine', '', engineIndex) : e.preventDefault()}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                          />
                      </Grid> :
                      (checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') ||
                      [13].includes(getGlobalCookie(config.cookiesNaming.key).lessor.id)) ?
                        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='OVH Cost' value={<NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{engine.cost}</NumericalLabel>}   />
                        : null
                      }
                      { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') ?
                      <Grid item xs={6} md={4}>
                        <TextField
                          required={checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') && engine.engineRemarksFlag }
                          id="remarks"
                          label="Remarks OVH"
                          fullWidth
                          margin="normal"
                          multiline
                          rows={3}
                          inputProps={{ maxLength: 250 }}
                          InputLabelProps={{shrink: true}}
                          value={engine.remarks}
                          onChange={(e) =>updateEngineField(e, 'remarks', e.target.value, engineIndex)}
                          error={error && error[engineIndex] && error[engineIndex].remarks_engine ? true:false}
                          helperText={error && error[engineIndex] && error[engineIndex].remarks_engine ? error[engineIndex].remarks_engine :''}
                          onFocus={(e) => error && error[engineIndex] && error[engineIndex].remarks_engine ?  updateEngineErrorField('remarks_engine', '', engineIndex) : e.preventDefault()}
                        />
                      </Grid>
                      : null
                    }
                       </Grid>
                     </Fragment>
                    }
                    <Grid container spacing={4}>
                    <Grid item xs={12} md={12} className="llp-table-modal" style={{position: 'relative'}}>
                      <Button variant="outlined" color="primary" size="small" onClick={() => toggleLLpModal(engineIndex, 'hsi')}>
                        {enginesEdit ? 'Edit LLP' : 'View LLP'}
                      </Button>
                      <Dialog
                      aria-labelledby="scroll-dialog-title"
                      open={llpModal}
                      fullWidth
                      fullScreen
                      >
                       <DialogTitle id="scroll-dialog-title" style={{padding:'0px'}}>
                          <Grid container spacing={isMobile ? 1 : 3} style={{padding:'12px 24px'}}>
                            <Grid item xs={12}>
                              { tabIndex==0 ? <h4>Engine #1</h4> : <h4>Engine #2</h4>}
                            </Grid>
                            {!enginesEdit ?
                              <Fragment>
                                <LabelValueCard skeletonLoader={skeletonLoader} xs={12} md={2} label='Serial Number' value={engine.serial_number ? engine.serial_number : '--'}/>
                                <LabelValueCard skeletonLoader={skeletonLoader} xs={12} md={2} label='TSN as of LLP Status (FH)' value={engine.tsn_as_of_llp ? `${engine.tsn_as_of_llp} FH` : '--'}/>
                                <LabelValueCard skeletonLoader={skeletonLoader} xs={12} md={2} label='CSN as of LLP Status (FC)' value={engine.csn_as_of_llp ? `${engine.csn_as_of_llp} FC`: '--'}/>
                                <LabelValueCard skeletonLoader={skeletonLoader} xs={12} md={2} label='LLP Status as of Date' value={engine.llp_status_as_of_date ? moment(engine.llp_status_as_of_date).format(displayDateFormatShort) : '--'}/>
                              </Fragment> :
                              <Fragment>
                                <LabelValueCard skeletonLoader={skeletonLoader} xs={12} md={1} label='Serial Number' value={engine.serial_number ? engine.serial_number : '--'}/>
                                { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'lev', 'OV') ?
                                  <Grid item xs={12} md={4}>
                                  <TextField
                                    id="llp_life_percentage"
                                    label="Percentage LLP Life Compare to PRSV Interval"
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{shrink: true}}
                                    value={engine.llp_life_percentage}
                                    onChange={(e) => regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ?  updateEngineField(e, 'llp_life_percentage', e.target.value, engineIndex) : e.preventDefault}
                                    error={error && error.llp_life_percentage ? true:false}
                                    helperText={error && error.llp_life_percentage?error.llp_life_percentage:''}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                  />
                                </Grid>:null
                                }
                                <Grid item xs={12} md={2}>
                                  <TextField
                                    id="tsn_as_of_llp"
                                    label={
                                      <p>TSN as of LLP Status
                                        <Tooltip
                                        title="Time Since New at LLP Status"
                                        arrow
                                        placement="top"
                                        >
                                        <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                        </Tooltip>
                                      </p>
                                    }
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{shrink: true}}
                                    value={engine.tsn_as_of_llp}
                                    onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ?  updateEngineField(e, 'tsn_as_of_llp', e.target.value, engineIndex) : e.preventDefault}
                                    error={error && error.serial_number ? true:false}
                                    onBlur={(e) => updateEngineField(e, 'llp_csn_flag', true, engineIndex)}
                                    helperText={error && error.serial_number?error.serial_number:''}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <TextField
                                    id="csn_as_of_llp"
                                    label={
                                      <p>CSN as of LLP Status
                                        <Tooltip
                                        title="Cycles Since New at LLP Status"
                                        arrow
                                        placement="top"
                                        >
                                        <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                        </Tooltip>
                                      </p>
                                    }
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{shrink: true}}
                                    value={engine.csn_as_of_llp}
                                    onChange={(e) => regexConstants.onlyNumeric.test(e.target.value)  ?   updateEngineField(e, 'csn_as_of_llp', e.target.value, engineIndex): e.preventDefault() }
                                    error={error && error.serial_number ? true:false}
                                    onBlur={(e) => updateEngineField(e, 'llp_csn_flag', true, engineIndex)}
                                    helperText={error && error.serial_number?error.serial_number:''}
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <MuiPickersUtilsProvider utils={MomentUtils}>
                                     <KeyboardDatePicker
                                        margin="normal"
                                        id="llp_as_of_date"
                                        label="LLP Status as of Date"
                                        placeholder="DD-MM-YYYY"
                                        fullWidth
                                        minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                                        maxDate={hlaLogDetail.generic.report_date ? moment(hlaLogDetail.generic.report_date) : moment()}
                                        format={fieldDateFormat}
                                        InputLabelProps={{shrink: true}}
                                        onBlur={(e) => updateEngineField(e, 'llp_csn_flag', true, engineIndex)}
                                        value={engine.llp_status_as_of_date ? engine.llp_status_as_of_date  : null }
                                        onChange={(data, value) => updateEngineField(value, 'llp_status_as_of_date', data ? moment(data).format(backendDateFormat):data, engineIndex)}
                                      />
                                  </MuiPickersUtilsProvider>
                                </Grid>
                              </Fragment>
                            }
                          </Grid>
                          <Divider varient='fullWidth'/>
                          <Grid container spacing={0} style={{padding:'16px 24px'}}>
                            <Grid item xs={12} md={8}>
                              <LLPTabs llpTabIndex={llpTabIndex} changeTabs={changeLLPTabs} SecondaryLLPList={secondaryLlpList} staticLlpList={staticLlpList}/>
                            </Grid>
                            { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'EXP_PDF') ?
                              <Grid item xs={12} md={4}>
                                <ul className="list-inline" style={{float:'right', position:'relative', top:'5px'}}>
                                { enginesEdit ?
                                <li className="list-inline-item" style={{marginRight: '10px'}}>
                                  <Button color="primary" variant="contained" style={{position:'relative'}} size="small"><ImportIcon/>Import
                                    <input type="file" className="custom-file-input" accept='.xls, .xlsx, .csv' style={{position:'absolute', padding:'10px 0px' ,cursor:'pointer', opacity:'0'}} onChange={(e) => {handleLLpImport(e);this.handleEventValue(e)}}/>
                                  </Button>
                                </li>: null}
                                <li className="list-inline-item">
                                  <Button onClick={() => this.exportLLPApi(this.props.logId, this.props.airframeModel, hlaLogDetail.engine.engineInfo[engineIndex], {extension:'xlsx'})} color="primary" variant="contained" size="small" ><SaveAltIcon /> Export</Button>
                                </li>
                              </ul>
                            </Grid>:null
                            }
                          </Grid>
                        </DialogTitle>
                        <DialogContent dividers={true}>
                          { skeletonLoader ? <STableLoader count={6} /> :
                            llpTabIndex === 0 ?
                             <TableListComp
                               heads={ ( checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') ||
                               [13].includes(getGlobalCookie(config.cookiesNaming.key).lessor.id))? llpHlaHeads : filteredHeads}
                               data={engineLlpList.map((item, index) =>
                                 <EngineLLPList
                                   item={item}
                                   key={index}
                                   enginesEdit={enginesEdit}
                                   onChangeLLP={onChangeLLP}
                                   llpIndex={index}
                                   engineIndex={engineIndex}
                                   cyclesRemaining={cyclesRemaining}
                                   updateEngineField={updateEngineField}
                                   applicationPermission={this.props.applicationPermission}
                                 />)}
                            />: null
                          }
                          { pageLoader ? <STableLoader count={6} /> :
                             llpTabName.includes('SECONDARY LLP') && llpTabIndex != 0  ?
                             <Fragment>
                              { enginesEdit && secondaryLlpList.length ?
                             <div className="checkbox-block">
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    checkBoxLoader ? <CircularProgress color="primary" size={32} style={{margin:'5px'}} /> :
                                    <Checkbox
                                      name="checkedA"
                                      color="primary"
                                      onChange={(event) =>  secondaryllpUpdate(event, engine, engineIndex, 'overall')}
                                      checked={secondaryLLpEnableFlag}
                                    />
                                  }
                                  label={checkBoxLoader ? 'Loading..':secondaryLlpList.every(llpItem =>  llpItem.status) ?  "Disable All" : 'Enable All'}
                                />
                              </FormGroup>
                             </div>: null}
                             <TableListComp
                               heads={ (checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV')||
                               [13].includes(getGlobalCookie(config.cookiesNaming.key).lessor.id))? secondaryLLpHeads : secondaryFilterHeads}
                               data={secondaryLlpList.map((item, index) =>
                                 <SecondaryLLPList
                                   item={item}
                                   key={index}
                                   enginesEdit={enginesEdit}
                                   onChangeLLP={onChangeLLP}
                                   llpIndex={index}
                                   engineIndex={engineIndex}
                                   cyclesRemaining={cyclesRemaining}
                                   applicationPermission={this.props.applicationPermission}
                                 />)}
                                noRecord={ secondaryLlpList.length ? null:
                                   <EmptyCollection
                                     title="No records found"
                                   />
                                 }
                            /> </Fragment>: null
                          }
                          { pageLoader ? <STableLoader count={6} /> :
                            llpTabName.includes('STATIC LLP') && llpTabIndex != 0 ?
                             <TableListComp
                               heads={ ( checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') ||
                               [13].includes(getGlobalCookie(config.cookiesNaming.key).lessor.id))? staticLLpHeads : staticLLpFilterHeads}
                               data={staticLlpList.map((item, index) =>
                                 <StaticLLPList
                                   item={item}
                                   key={index}
                                   toggleBulkOps={(llpId, event) => staticLLpUpdate(llpId,event,engine,engineIndex, 'overall')}
                                   enginesEdit={enginesEdit}
                                   onChangeLLP={onChangeLLP}
                                   llpIndex={index}
                                   engineIndex={engineIndex}
                                   cyclesRemaining={cyclesRemaining}
                                   selectedStaticLLps={selectedStaticLLps.map(item => item.id)}
                                   engine={engine}
                                   checkBoxLoader={checkBoxLoader}
                                   applicationPermission={this.props.applicationPermission}
                                 />)}
                                 noRecord={ staticLlpList.length ? null:
                                    <EmptyCollection
                                      title="No records found"
                                    />
                                  }
                            /> : null
                          }
                        </DialogContent>
                        <DialogActions>
                        {
                            ( checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') ||
                            [13].includes(getGlobalCookie(config.cookiesNaming.key).lessor.id)) && llpTabIndex == 0  ?
                            <p style={{marginRight:'15px'}}>Total Primary LLP Cost: <span style={{fontWeight:'bold'}}><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{llpCostSum}</NumericalLabel></span></p>
                            :null
                          }
                          {
                            (checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') ||
                            [13].includes(getGlobalCookie(config.cookiesNaming.key).lessor.id)) && llpTabIndex == 1 ?
                            <p style={{marginRight:'15px'}}>Total Secondary LLP Cost: <span style={{fontWeight:'bold'}}><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{llpSecondaryCostSum}</NumericalLabel></span></p> : null
                          }
                          {
                            (checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'OV') ||
                            [13].includes(getGlobalCookie(config.cookiesNaming.key).lessor.id)) && llpTabIndex == 2 ?
                            <p style={{marginRight:'15px'}}>Total Static LLP Cost: <span style={{fontWeight:'bold'}}><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{llpStaticCostSum}</NumericalLabel></span></p> : null
                          }
                        <div>
                          {
                            enginesEdit ?
                            <Button className="btn-save"variant="contained" disabled={(enginesEdit && (flagCheckArry.includes(true) || flagSecondaryCheckArry.includes(true) || flagStaticCheckArry.includes(true))) || llpCsnValidationFlag || llpStaticValidationFlag || llpSecondaryValidationFlag }  style={(enginesEdit && flagCheckArry.includes(true))  ?  {display:'inline-block', marginLeft:'28px'} :{}}  color="primary" onClick={() => updateLLP(engine)}>Save</Button>
                              :null
                          }
                          <Button variant="outlined"   color="primary" onClick={closeLLLpModal} style={{marginLeft:'5px'}}>Close</Button>
                          {(enginesEdit && (flagCheckArry.includes(true) || flagSecondaryCheckArry.includes(true) || flagStaticCheckArry.includes(true))) ? <span style={{fontSize: '10px', clear:'both', display:'block',}}>Please enter valid CSN to close</span> : null}
                        </div>
                        </DialogActions>
                      </Dialog>
                     </Grid>
                     <ConfirmationModal
                       modal={confirmationModal}
                       openConfirmationModal={()=> this.setState({confirmationModal:true})}
                       toggleModalFn={() => {this.setState({confirmationModal:false })}}
                       importLLP={() =>  importLLP(engine, engineIndex, 'hsi')}
                     />
                    </Grid>
                 </section>
                : null
             )

            })
          }
      </Paper>
    )
  }
}
export default withSnackbar(EngineHlaHsiIntermediate);
