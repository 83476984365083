import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import config from '../../config';
import ChangePassword from './ChangePassword'
import homeIcon from '../../assets/images/home_icon.png';
import settingIcon from '../../assets/images/setting_new.svg';
import { getGlobalCookie } from '../../utils';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import plansIcon from '../../assets/images/plans_icon.svg';
import { isMobile } from 'react-device-detect';
const MainNavLink = ({applicationPermission}) => {
  return(
    <ul className="list-unstyled" style={{marginTop:'15px'}}>
      <li>
        <a href={config.domain.subDomian} target="_blank" rel="noreferrer"  >
          <img src={homeIcon} alt="Home" />
          <p>Home</p>
        </a>
      </li>
      <li className={!window.location.href.includes('setting') ? 'active':''}>
        <Link to="/logs" className={!window.location.href.includes('setting') ? 'active':''}>
          <img src={`${config.api.s3ImageUrl}bulk_report-icon.svg`} alt="Valuation" />
          <p>Logs</p>
        </Link>
      </li>
      { getGlobalCookie(config.cookiesNaming.key) && getGlobalCookie(config.cookiesNaming.key).lessor && getGlobalCookie(config.cookiesNaming.key).lessor.id === 242 ?
        applicationPermission && applicationPermission.security_groups && applicationPermission.security_groups.filter(group => group.id === 29).length ?
          <li className={window.location.href.includes('settings') ? 'active':''}>
            <Link to="/settings/users"  className={!window.location.href.includes('settings') ? '':'active'}>
              <img src={settingIcon} alt="Setting" />
              <p>Settings</p>
            </Link>
          </li>:null:
          <li className={window.location.href.includes('settings') ? 'active':''}>
            <Link to="/settings/users"  className={!window.location.href.includes('settings') ? '':'active'}>
              <img src={settingIcon} alt="Setting" />
              <p>Settings</p>
            </Link>
          </li>
        }
    </ul>
  )
}
export default MainNavLink;
