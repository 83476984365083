import React from 'react';
import { Tooltip, TableRow, TableCell, IconButton, Chip } from '@material-ui/core';
import { Link } from 'react-router-dom';
const UserList = (props) => {
  return(
      <TableRow hover tabIndex={-1}  onClick={props.viewDetail} >
        <TableCell>{props.item.first_name}</TableCell>
        <TableCell>{props.item.email}</TableCell>
        <TableCell>{props.item.designation ? props.item.designation:'--'}</TableCell>
        <TableCell>{props.item.is_active ? props.item.is_new_user ? "Invitation Sent":'Active':'Deactivated'}</TableCell>
      </TableRow>

  )
}
export default UserList;
