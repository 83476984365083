import React, { Fragment, Component } from 'react'
import { Paper, Grid, TextField, Button, InputAdornment, Tooltip, FormControl, FormLabel,FormControlLabel, Radio, Checkbox, FormGroup, RadioGroup } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { regexConstants } from '../../../constants/regEx';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';

class EngineHlaInitiate extends Component {
  constructor(props){
    super(props)
    this.state = {
    }
  }
  componentDidMount(){
    this.props.updateEngineGenInfo(1,1)
  }
render() {
  const {engineGenInfo, onFieldChange, updateErrorField, error, engineTypes, airframeHlaGenInfo, updateEngineGenInfo, updateEngineInfoField, updateCheckBox, engineInfoArr, updateEngineInfoError, genericHlaInfo} = this.props
  const { dataPopulate } = this.state
  let engineRadio = [1, 2]
  var foo = [];
  if(airframeHlaGenInfo.aircraft_type && airframeHlaGenInfo.aircraft_type.no_of_engines !== null ){
    for (var i = 1; i <= airframeHlaGenInfo.aircraft_type.no_of_engines; i++) {
      foo.push(i);
    }
    engineRadio = foo
  }
  return (
      <Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <h4 className="hla-initiate-card-title" ><strong>Engine</strong></h4>
          </Grid>
        </Grid>
        <Paper className="hla-initiate-card">
          <Grid container spacing={isMobile? 2:3} style={isMobile?{padding:'0px'}:{padding:'10px'}}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                options = {engineTypes}
                getOptionLabel={option => option.name}
                id="engineType"
                value={engineGenInfo.engineType}
                disabled={!airframeHlaGenInfo.aircraftType}
                onChange={(e, value) => onFieldChange(e, 'engineType', value)}
                renderInput={params => <TextField required error={error.engine_type ? true :false} helperText={error.engine_type ? error.engine_type : ''} onFocus={() =>  updateErrorField('engine_type', '')} {...params} label="Engine Type" margin="normal" InputLabelProps={{shrink:true}} fullWidth />}
                />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl component="fieldset" style={{float:"left", marginLeft:'3px'}} disabled={!engineGenInfo.engineType}>
                <FormLabel component="legend" style={{float:'left'}}>No. of Engines</FormLabel>
                <RadioGroup aria-label="engine" name="engine" value={engineGenInfo.no_of_engines} row>
                  {engineRadio.map((item, index) =>
                  <FormControlLabel value={item} key={index}  control={<Radio checked={engineGenInfo.no_of_engines == item} onChange={(e) => onFieldChange('e', 'no_of_engines', item) } color="primary"  />} onClick={() => updateEngineGenInfo(item, item)} color="primary" label={item}   />
                 )}
                </RadioGroup>
              </FormControl>
            </Grid>
            {
              engineGenInfo.no_of_engines > 1 ?
              <Grid item xs={12} md={12} style={isMobile?{marginBottom:'auto'}:{marginBottom:'-25px'}}>
                {/*  <FormControlLabel
                    control={<Checkbox checked={engineGenInfo.identical_data} onChange={() => updateCheckBox(!engineGenInfo.identical_data)} name="populateData" color="primary"  />}
                    label="Engines with same utilization"
                  />*/}
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Do all engines have the same utilization?</FormLabel>
                    <RadioGroup aria-label="utilization" name="utilization" row>
                      <FormControlLabel value="female" control={<Radio checked={engineGenInfo.identical_data} onChange={() => updateCheckBox(true, "engine")} color="primary" />} label="Yes" />
                      <FormControlLabel value="male" control={<Radio checked={!engineGenInfo.identical_data} onChange ={() => updateCheckBox(false, "engine")} color="primary" />} label="No" />
                    </RadioGroup>
                  </FormControl>
              </Grid> : null
            }
          </Grid>
          {
            engineGenInfo.engineType  ?
            <Grid container spacing={isMobile? 2:3} style={isMobile?{padding:'0px'}:{padding:'10px'}}>
              {
                engineInfoArr.map((engine, index) =>
                <Fragment key={index}>
                  {
                    engineGenInfo.identical_data && engineGenInfo.no_of_engines !== 1 ? null :
                    <Grid item xs={12} sm={12} md={12}>
                      <h4 style={isMobile?{float:'left', textDecoration:'underline', marginBottom:'auto'}:{float:'left', textDecoration:'underline', marginBottom:'-15px'}} ><strong>Engine #{index+1}</strong></h4>
                    </Grid>
                  }

                  <Grid container spacing={isMobile? 1:3} style={isMobile?{padding:'0px'}:{padding:'10px'}}>
                    <Grid item xs={6} md={4}>
                      <TextField
                        required
                        id="tsn"
                        label='Time Since New'
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 10 }}
                        InputLabelProps={{shrink: true}}
                        value={engine.tsn}
                        onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? updateEngineInfoField(e, 'tsn', e.target.value, index):e.preventDefault()}}
                        error={error && error[`engine${index}`] && error[`engine${index}`].tsnEngine ? true : false }
                        helperText={ error && error[`engine${index}`] && error[`engine${index}`].tsnEngine ? error[`engine${index}`].tsnEngine : '' }
                        onFocus={(e) => error && error[`engine${index}`] && error[`engine${index}`].tsnEngine ? updateEngineInfoError('tsnEngine', '', index) : e.preventDefault() }
                        InputProps={{
                            endAdornment: <InputAdornment position="end">FH</InputAdornment>
                          }}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextField
                        required
                        id="csn"
                        label='Cycles Since New'
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 10 }}
                        InputLabelProps={{shrink: true}}
                        value={engine.csn}
                        onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? updateEngineInfoField(e, 'csn', e.target.value, index):e.preventDefault()}}
                        error={error && error[`engine${index}`] && error[`engine${index}`].csnEngine ? true : false }
                        helperText={ error && error[`engine${index}`] && error[`engine${index}`].csnEngine ? error[`engine${index}`].csnEngine : '' }
                        onFocus={(e) => error && error[`engine${index}`] && error[`engine${index}`].csnEngine ? updateEngineInfoError('csnEngine', '', index) : e.preventDefault() }
                        InputProps={{
                            endAdornment: <InputAdornment position="end">FC</InputAdornment>
                          }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                           required
                           margin="normal"
                           id="as_of_date"
                           label="Utilization as of date"
                           placeholder="DD-MM-YYYY"
                           fullWidth
                           minDate={genericHlaInfo.date_of_manufacture ? moment(genericHlaInfo.date_of_manufacture) : moment().subtract(25, 'years') }
                           maxDate={genericHlaInfo.report_date ? moment(genericHlaInfo.report_date) : moment()}
                           format={fieldDateFormat}
                           InputLabelProps={{shrink: true}}
                           onChange={(data, value) => updateEngineInfoField(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat):data, index)}
                           value={engine.utilization_as_of_date}
                           error={error && error[`engine${index}`] && error[`engine${index}`].utilization_as_of_date_engine ? true : false }
                           helperText={ error && error[`engine${index}`] && error[`engine${index}`].utilization_as_of_date_engine ? error[`engine${index}`].utilization_as_of_date_engine : '' }
                           onFocus={(e) => error && error[`engine${index}`] && error[`engine${index}`].utilization_as_of_date_engine ? updateEngineInfoError('utilization_as_of_date_engine', '', index) : e.preventDefault() }
                         />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Grid>  : null
          }
        </Paper>
      </Fragment>
      )
    }
}
export default EngineHlaInitiate;
