import React, { useState, useEffect, Fragment} from "react";
import { Dialog,DialogTitle, DialogContent, Grid, Button } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import config from '../../config';
import contractsApp from '../../assets/images/LeaseIcon.svg';
import projectsApp from '../../assets/images/ProjectsIcon.svg';
import maintenanceApp from '../../assets/images/MaintenaceIcon.svg';
import recordsApp from '../../assets/images/MaintenaceIcon.svg';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import mrCalculatorApp from '../../assets/images/acumen_commverge.png';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { getApplicationAccessApi } from '../apiServices';
export default function ApplicationSwitcher(){
    const [ applicationSwitcher, toggleTheAppSwitcher] = useState(false)
    const [applicationAccess, setApplicationAccess] = useState(false)
    useEffect(() => {
        if(applicationSwitcher){
            getApplicationAccessApi(setApplicationAccess)
        }
    }, [applicationSwitcher])
    return(
        <Fragment>
            <Button
                className="app-switcher"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={() =>toggleTheAppSwitcher(true)}
                endIcon={<ArrowDropDownIcon />}
            >
              FIN-S
            </Button>
            <Dialog
              open={applicationSwitcher}
              onClose={() => toggleTheAppSwitcher(false)}
              aria-labelledby="scroll-dialog-title"
              fullScreen={true}
            >
              <DialogTitle id="scroll-dialog-title" >
                <strong>Applications</strong>
                <span
                  style={{ float: "right", cursor: "pointer" }}
                  onClick={() => toggleTheAppSwitcher(false)}
                >
                  <CloseIcon color="primary" />
                </span>
              </DialogTitle>
              <DialogContent dividers={true} className="data-upload-modal">
                <Grid container spacing={2}>
                  {[
                    {name:'Lease Management', url:config.domain.lease, access:applicationAccess.lease_management ? true:false, icon:contractsApp, description:'Effectively manage commerical leases and contracts of the entire fleet - aircraft, engines and even sub-modules through the entire economic life of the asset'},
                    {name:'Projects Management', url:config.domain.projects, access:applicationAccess.projects_management ? true:false, icon:projectsApp, description:'Comprehensive solution for planning, executing and managing Technical Projects - from Pre-Purchase to Redeliveries.'},
                    {name:'Records Dataroom',url:config.domain.records,access:applicationAccess.records_dataroom  ? true:false,icon:recordsApp,description:'Centralised, secure online Documents Management System (DMS) for digitised aircraft records storage retrieval and collaboration'},
                    {name:'Maintenance Management',url:config.domain.maintenance,access:applicationAccess.maintenance ? true:false,icon:maintenanceApp,description:'Seamlessiy track, execute and manage maintenance events across the fleet, forecast future events and process commercial claims. '},
                    {name:'CommVerge', url:config.domain.mrCalculator, access:applicationAccess.commverge ? true:false, icon:mrCalculatorApp, description:'A potent decision application for computing commercial exposure and simulating complex scenarios on ~200 aircraft - engine combinations.'},
                  ].map((app) => (
                    <Grid item xs={12} md={4}>
                      <div
                        className="application-card"
                        style={{ opacity: app.access ? 0.9 : 0.7 }}
                      >
                        <div className="application-info">
                          <h3 className="flex-centered">
                            <img width={40} src={app.icon} alt="" />
                            <span>{app.name}</span>
                            {app.beta ? (
                              <sup style={{ color: "rgb(241, 9, 9)" }}>
                                {" "}
                                BETA
                              </sup>
                            ) : null}
                          </h3>
                          <p>{app.description}</p>
                        </div>
                        { app.access ?
                            <Button onClick={() => window.open(app.url, '_blank')} size="small" endIcon={<ArrowForwardIosIcon fontSize="small" />}>Enter</Button>:
                            <p className="restrict-access">Please contact support@sparta.aero to access this application</p>
                        }
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </DialogContent>
            </Dialog>
        </Fragment>
        
    )
}