import React, { Component, Fragment } from 'react'
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { experienceCru, errorCodeExperience } from '..';
import { regexConstants } from '../../../constants/regEx';
import { addEditExperienceApi } from '../apiServices';
import { checkApiStatus } from '../../../utils';
import { fieldValidation } from '../../../utils/formValidation';
class AddEditExperience extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal:false,
      experienceObj:{},
      error:{}
    }
    this.addEditExperienceApi = addEditExperienceApi.bind(this)
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      experienceObj:{
        ...prevState.experienceObj,
        [keyParam]:data
      }
    }))
  }
  addEditExperience = () => {
    const { experienceObj } = this.state
    let validationInputs = {
      aircraft_type:errorCodeExperience['aircraft_type'][fieldValidation({...errorCodeExperience['aircraft_typeObj'], fieldval:experienceObj.aircraft_type})],
      year_of_experience:errorCodeExperience['year_of_experience'][fieldValidation({...errorCodeExperience['year_of_experienceObj'], fieldval:experienceObj.year_of_experience})]
    }
    if(Object.keys(validationInputs).every((k)=>{return validationInputs[k] === ''})){
      this.addEditExperienceApi(this.props, experienceObj)
      .then(response => {
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, {variant :'success',anchorOrigin:{vertical:'top', horizontal:'right'}})
          this.setState({ modal:false,experienceObj:{}})
          this.props.getResponseBack();
        }else {
          this.props.enqueueSnackbar(response.data.message, {variant:'error',anchorOrigin:{vertical:'top', horizontal:'right'}})
        }
      })
    } else {
      this.setState({
        error:validationInputs
      })
    }
  }
  render(){
    const { modal, experienceObj, error } = this.state
    const { id, experienceData } = this.props
    return(
      <Fragment>
        { id ? <EditIcon onClick={()=> this.setState({ modal:true ,experienceObj:experienceData})} color="primary" className="cta-mouse" fontSize="small"  /> :
          <Button color="primary" variant="contained" className="buttons-cta" onClick={()=> this.setState({modal:true,experienceObj:experienceCru })} >Add Aircraft Experience Type</Button>
        }
      { modal ?
        <Dialog
          open={modal}
          onClose={()=> this.setState({modal:false})}
          aria-labelledby='scroll-dialog-title'
        >
        <DialogTitle id="scroll-dialog-title">
          Add Aircraft Type Experience
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                id="aircraft_type"
                label="Aircraft Type"
                fullWidth
                margin="normal"
                value={experienceObj.aircraft_type}
                onChange={(e)=> this.onFieldChange(e, 'aircraft_type', e.target.value)}
                error={error.aircraft_type ? true:false}
                onFocus={()=> this.updateErrorField('aircraft_type', '')}
                InputLabelProps={{shrink:true}}
                helperText={error.aircraft_type ? error.aircraft_type : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="year_of_experience"
                label="No of Years"
                fullWidth
                margin="normal"
                value={experienceObj.year_of_experience}
                onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'year_of_experience', e.target.value) : e.preventDefault()}}
                onFocus={() => this.updateErrorField('year_of_experience', '')}
                InputLabelProps={{shrink:true}}
                error={error.year_of_experience ? true:false}
                helperText={error.year_of_experience ? error.year_of_experience : ''}

              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="remarks"
                label="Notes"
                fullWidth
                margin="normal"
                multiline
                rows='4'
                value={experienceObj.remarks}
                onChange={(e) => this.onFieldChange(e,'remarks', e.target.value)}
                onFocus={() => this.updateErrorField('remarks', '')}
                InputLabelProps={{shrink:true}}
                error={error.remarks ? true:false}
                helperText={error.remarks ? error.remarks:''}
              />

            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={()=> this.setState({modal:false})}>Cancel</Button>
          <Button color="primary" variant="contained" onClick={()=> this.addEditExperience()}>Add</Button>
        </DialogActions>
      </Dialog> : null
      }
      </Fragment>
    )

  }
}
export default withSnackbar(withRouter(AddEditExperience));
