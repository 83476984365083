import React, { Fragment, Component} from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { TableListComp, EmptyCollection, DeletePopUp } from '../../../shared_elements';
import { Button, Checkbox } from '@material-ui/core';
import AllowedAssetList from './AllowedAssetList';
import AddAssets from './AddAssets';
import { checkApiStatus } from '../../../utils';
import { deleteUserAssetApi } from '../apiServices';
class AllowedAssets extends Component {
constructor(props){
  super(props)
  this.state = {
    deleteAssets:[],
    deleteModal:false,
  }
   this.deleteUserAssetApi = deleteUserAssetApi.bind(this)
}
updateForm = (value, checked) => {
  if(checked){
    this.setState(prevState => ({
      ...prevState,
      deleteAssets:[...prevState.deleteAssets, value]
    }))
  }else {
    this.setState(prevState => ({
      ...prevState,
      deleteAssets:prevState.deleteAssets.filter(item => item != value)
    }))
  }
}
toggleModalFn = () => {
  this.setState(prevState => ({
    deleteModal:!prevState.deleteModal
  }))
}
deleteSingleAssetFn = (id) => {
  this.setState({
    deleteModal:true,
    deleteAssets:[id]
  })
}
selectAllAssets = () => {
  this.setState(prevState => ({
    ...prevState,
    deleteAssets:this.props.assets.map(item => item.id)
  }))
}
deleteSelectedAssetsFn = () => {
  const { deleteAssets } = this.state
  this.deleteUserAssetApi(this.props, deleteAssets)
  .then(response => {
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{ vertical:'top', horizontal:'right'}})
      this.setState({deleteModal:false, deleteAssets:[]})
      this.props.getResponseBack()
    }else {
      this.props.enqueueSnackbar(response.data.message,{ variant:'error', anchorOrigin:{vertical:'top', horizontal:'right'}})
    }
  })

}
render(){
  const { deleteAssets, deleteModal } = this.state
  const { getResponseBack, assets, skeletonLoader, assetAllowedList, updateAllowedList } = this.props
  return (
    <div className="user-management-module-section">
      <div className="buttons">
        <Checkbox
          edge="start"
          onChange={this.selectAllAssets}
        /> Select all Assets
        <AddAssets getResponseBack={getResponseBack}  />
        { deleteAssets.length ? <Button color="secondary" style={{float:'right'}} onClick={this.toggleModalFn} >Delete Assets ({deleteAssets.length})</Button> : null }
      </div>
      <TableListComp
        heads={[]}
        data={ assets.length ? assets.map((asset, index)=>
          <AllowedAssetList
            item={asset}
            key={index}
            updateForm={(id, checked) => this.updateForm(id, checked)}
            toggleModalFn={this.toggleModalFn}
            deleteSelectedAssetsFn={this.deleteSelectedAssetsFn}
            deleteSingleAssetFn={this.deleteSingleAssetFn}
            deleteAssets={deleteAssets}
          />
        ) : null}
        noRecord={assets.length ? null : <EmptyCollection title="No Assets found" /> }
      />
    <DeletePopUp
      modal={deleteModal}
      toggleModalFn={this.toggleModalFn}
      title='Remove Asset'
      deleteRecordFn={()=> this.deleteSelectedAssetsFn()}
      content={<h4>Are you sure you want to Remove</h4>}
    />
  </div>
  )
}
}
export default withSnackbar(withRouter(AllowedAssets));
