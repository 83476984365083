import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import AddEditExperience from './AddEditExperience';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
export default function ExperienceList({item, getResponseBack, deleteExperience}){
  return (
    <TableRow>
      <TableCell>{item.aircraft_type ? item.aircraft_type : '--'}</TableCell>
      <TableCell>{item.year_of_experience ? item.year_of_experience : '--'}</TableCell>
      <TableCell>{item.remarks ? item.remarks : '--'}</TableCell>
      <TableCell>
        <div style={{float:'right'}}>
          <AddEditExperience
            id={item.id}
            experienceData={item}
            getResponseBack={getResponseBack}
          />
          <DeleteOutlinedIcon
            fontSize="small"
            onClick={(id)=> deleteExperience(item.id)}
            color="secondary"
            className="cta-mouse"
          />
        </div>

    </TableCell>
    </TableRow>
  )
}
