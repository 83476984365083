import React, { useState } from "react";
import { TextField, Tooltip, InputAdornment, Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { LabelValueCard } from '../../../shared_elements'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

export default function SteppedRental({addRows,removeRows, leaseEnCrud, updateStepRental,error, updateStepErrorField, elementIndexStep}) {
  return (
    <div className="stepped-rental-blk">
        <Grid container spacing={3}>
            {leaseEnCrud.step_rentals.map((elementInArray, index) => {
                return (
                  <>
                    <Grid item xs={12} md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                required
                                margin="normal"
                                id="start_date"
                                label="Start Date"
                                placeholder="DD-MM-YYYY"
                                format={fieldDateFormat}
                                fullWidth
                                disabled={leaseEnCrud.id || !leaseEnCrud.lease_start_date || !leaseEnCrud.lease_end_date}
                                minDate={leaseEnCrud.step_rentals[index - 1 ] && leaseEnCrud.step_rentals[index - 1 ].end_date ? moment(leaseEnCrud.step_rentals[index - 1 ].end_date).add(1, 'days') : moment(leaseEnCrud.lease_start_date)}
                                maxDate={elementInArray.end_date ? moment(elementInArray.end_date): leaseEnCrud.lease_end_date}
                                value={elementInArray.start_date}
                                InputLabelProps={{shrink: true}}
                                onChange={(data, value) => updateStepRental(value, 'start_date', data ? moment(data).format(backendDateFormat): data, index)}
                                error={error && error[index] && error[index].start_date ? true : false }
                                helperText={ (!leaseEnCrud.lease_start_date || !leaseEnCrud.lease_end_date) ? 'Please enter Lease Start Date and Lease End Date to enable' : error && error[index] && error[index].start_date ?  error[index].start_date  : ''}
                                onFocus={() => error && error[index] && error[index].start_date ? updateStepErrorField('start_date', index) : null}
                                onOpen={() =>  error && error[index] && error[index].start_date ? updateStepErrorField('start_date', index) : null}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required
                                    margin="normal"
                                    id=""
                                    label="End Date"
                                    placeholder="DD-MM-YYYY"
                                    format={fieldDateFormat}
                                    fullWidth
                                    disabled={leaseEnCrud.id || !leaseEnCrud.lease_start_date || !leaseEnCrud.lease_end_date}
                                    value={elementInArray.end_date}
                                    onChange={(data, value) => updateStepRental(value, 'end_date', data ? moment(data).format(backendDateFormat):data, index)}
                                    InputLabelProps={{shrink: true}}
                                    minDate={elementInArray.start_date ? moment(elementInArray.start_date).add(1, 'days') : moment(leaseEnCrud.lease_start_date).add(1, 'days') }
                                    maxDate={leaseEnCrud.step_rentals[index + 1] && leaseEnCrud.step_rentals[index + 1].start_date ? moment(leaseEnCrud.step_rentals[index + 1].start_date).subtract(1, 'days') : moment(leaseEnCrud.lease_end_date)}
                                    error={error && error[index] && error[index].end_date ? true : false }
                                    helperText={error && error[index] && error[index].end_date ? error[index].end_date  : ''}
                                    onFocus={() => error && error[index] && error[index].end_date ? updateStepErrorField('end_date', index) : null}
                                    onOpen={() =>  error && error[index] && error[index].end_date ? updateStepErrorField('end_date', index) : null}
                                />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} md={3}>
                            <TextField
                                required
                                id=""
                                label='Monthly Rent'
                                fullWidth
                                margin="normal"
                                InputLabelProps={{shrink: true}}
                                value={elementInArray.rent}
                                onChange={(e) => regexConstants.NumberUptoNineDigitsWithDotsUpto2.test(e.target.value) ?  updateStepRental(e, 'rent', e.target.value, index) : e.preventDefault()}
                                error={error && error[index] && error[index].rent ? true : false }
                                helperText={error && error[index] && error[index].rent ?   error[index].rent  : ''}
                                InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                onFocus={() => error && error[index] && error[index].rent ? updateStepErrorField('rent', index) : null }
                            />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        {
                        leaseEnCrud.step_rentals.length > 1 ? leaseEnCrud.id ? null :
                        <p className="stepped-delete flex-centered">
                        <Tooltip title="Delete" arrow>
                            <RemoveCircleOutlineIcon className="delete-icon" onClick={() => removeRows(index)}/>
                        </Tooltip>
                        Delete Step
                        </p> : null
                        }
                    </Grid>
                    </>
                )
            })}
            <Grid item xs={12} md={3}>
                {
                  leaseEnCrud.step_rentals[elementIndexStep] && leaseEnCrud.step_rentals[elementIndexStep].end_date >= leaseEnCrud.lease_end_date ? null :
                  leaseEnCrud.id ? null :
                  !leaseEnCrud.lease_start_date || !leaseEnCrud.lease_end_date ? null :
                  <p className="flex-centered">
                  <Tooltip title="Add" placement="top" arrow>
                      <AddCircleOutlineIcon onClick={() => addRows()} />
                  </Tooltip>
                  Add Step
                </p>
                }
            </Grid>
        </Grid>
    </div>
  );
}
