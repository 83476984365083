import React, { Component, Fragment } from 'react';
import { withSnackbar} from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, TableRow, TableCell, Checkbox, List, ListItemIcon, ListItemText, ListItem } from '@material-ui/core';
import { TableListComp } from '../../../shared_elements';
import { getAssetListApi, addUserAssetsApi } from '../apiServices';
import { checkApiStatus } from '../../../utils';
let list = []
class AddAssets extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal:false,
      assetAllowedList:[],
      selectedAssetList:[]
    }
    this.getAssetListApi = getAssetListApi.bind(this)
    this.addUserAssetsApi = addUserAssetsApi.bind(this)
  }
  componentDidMount(){
    this.getAssetListApi(this.props)
  }
  updateForm = (asset,checked) => {
    if(checked){
      this.setState(prevState => ({
        ...prevState,
        selectedAssetList: [...prevState.selectedAssetList, asset]
      }))
    }else {
      this.setState(prevState => ({
        ...prevState,
        selectedAssetList:prevState.selectedAssetList.filter(item => item.asset_id != asset.asset_id)
      }))
    }
  }
  addAssetToUser = () => {
    const { selectedAssetList } = this.state
    this.addUserAssetsApi(this.props, selectedAssetList)
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message,{ variant:'success', anchorOrigin:{ vertical:'top', horizontal:'right'}})
        this.setState({ modal:false, selectedAssetList:[]})
        this.props.getResponseBack();
        this.getAssetListApi(this.props);
      }else {
        this.props.enqueueSnackbar(response.data.message,{variant:'success', anchorOrigin:{vertical:'top', horizontal:'right'}})
      }
    })
  }
  render() {
    const { modal, assetAllowedList, selectedAssetList } = this.state
    return(
      <Fragment>
        <Button color="primary" variant="contained" className="buttons-cta" onClick={()=> this.setState({modal:true})} >Add Assets</Button>
        { modal ?
          <Dialog
            open={modal}
            onClose={()=> this.setState({modal:false})}
            aria-labelledby='scroll-dialog-title'
            fullWidth
          >
          <DialogTitle>
           Add Assets
           <Grid item xs={12}>
             <TextField
               fullWidth
               placeholder="Search for Assets"
             />
           </Grid>
          </DialogTitle>
          <DialogContent dividers={true}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <List>
                  {assetAllowedList.length ? assetAllowedList.map((asset, index)=>
                    <ListItem key={index}>
                      <ListItemIcon>
                        <Checkbox
                          edge='start'
                          onChange={(e) => this.updateForm(asset, e.target.checked)}
                        />
                      </ListItemIcon>
                      <ListItemText>{asset.asset_name}</ListItemText>
                    </ListItem>
                  ): null}
                </List>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={()=> this.setState({modal:false})}>Cancel</Button>
            <Button color="primary" variant="contained" onClick={()=> this.addAssetToUser()} >Add</Button>
          </DialogActions>
          </Dialog> : null
        }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(AddAssets));
