import Login from './containers/Login';
import LessorSelection from './containers/LessorSelection';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userLoggedCheckHoc from '../../hocs/userLoggedCheckHoc'
export const authRoutes = [
  {
    path: '/',
    component: userLoggedCheckHoc(Login),
    key: 'login',
    apps:'Auth'
  },
  {
    path: '/login',
    component:userLoggedCheckHoc(Login) ,
    key: 'login1',
    apps:'Auth'
  },
  {
    path: '/select-lessor',
    component: LessorSelection,
    key: 'select-lessor',
    apps:'Auth'
  },

]
