import { globalGetService, globalDeleteService, globalPostService, globalPutService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType } from '../../utils';
import { trackActivity } from '../../utils/mixpanel';
import { displayDateFormatShort } from '../../constants';
import moment from 'moment';
export function getLessorTemplatesApi(props) {
  globalGetService(`records/v2/lessor-template-sheet/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({ templates: response.data.data.list, file_auto_publish: response.data.data.file_auto_publish })
      }
    })
}
export function addEditTemplateApi(props, files, templateUpdate) {
  this.setState({ pageLoader: true });
  let uploadFiles = new FormData();
  uploadFiles.append('asset_type', templateUpdate.data.asset_type);
  uploadFiles.append('template_sheet', files[0]);
  if (templateUpdate.data.id) {
    globalPutService(`records/v2/lessor-template-sheet/${templateUpdate.data.id}/`, uploadFiles)
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.setState({ templateUpdate: { modal: false, data: null } });
          this.getLessorTemplatesApi(props);
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  } else {
    globalPostService(`records/v2/lessor-template-sheet/`, uploadFiles)
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.setState({ templateUpdate: { modal: false, data: null } });
          this.getLessorTemplatesApi(props);
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  }
}

export function exportUserListApi(props = {}, query = {}) {
  this.setState({ pageLoader: true });
  globalExportService(`v2/get-user-list/?application=14`, { ...query, download: query.extension })
    .then(response => {
      this.setState({ pageLoader: false, query: {}, type: '' });
      downloadFileType(response.data, (`fin-S_Users`), query.extension);
      trackActivity('Item Exported', { page_title: 'User(s) List', extension: query.extension })
    })
}
// User Management API
export function getAppsUsersApi(props, queryParams, loaderType = 'pageLoader') {
  this.setState({ [loaderType]: true });
  globalGetService(`v2/get-user-list/`, { ...queryParams, application: 14 })
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          users: {
            ...prevState.users,
            list: (queryParams.page && queryParams.page !== 1) ? [...prevState.users.list, ...response.data.data.list] : response.data.data.list,
            pagination: response.data.data.pagination,
            active_users_count: response.data.data.active_users_count,
            inactive_users_count: response.data.data.inactive_users_count,
            invitation_sent_users_count: response.data.data.invitation_sent_users_count
          }
        }));
      }
    })
}

export function revokeUserAccessApi(props, user) {
  this.setState({ pageLoader: true });
  globalDeleteService(`v2/revoke-user-access/${user.slug}/?application=valuation`,)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.getAppsUsersApi(props, {}, 'pageLoader')
        this.setState(prevState => ({
          ...prevState,
          users: {
            ...prevState.users,
            list: prevState.users.list.filter(item => item.slug !== user.slug),
            pagination: {
              ...prevState.users.pagination,
              total: prevState.users.pagination.total - 1
            }
          },
          removeUser: {
            ...prevState.removeUser,
            modal: false,
            user: null
          }
        }));
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function getRolesApi(props, loaderType) {
  this.setState({ [loaderType]: true });
  globalGetService(`v2/get-sparta-groups/`, { application: 14 })
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        this.setState({ roleList: response.data.data })
      }
    })
}
export function getRoleUsersApi(props, role) {
  this.setState({ pageLoader: true });
  globalGetService(`v2/get-sparta-group-users/`, { group_id: role.id })
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState({ roleDetail: { modal: true, data: response.data.data } });
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function getPurchaseDetailApi(props, purchase) {
  this.setState({ pageLoader: true });
  globalGetService(`v2/get-sparta-group-users/`, { group_id: purchase })
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.setState({ purchaseDetail: { modal: true, data: response.data.data } });
        // this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
export function onChangeUserRoleApi(props, editRole) {
  if (editRole.role) {
    this.setState({ pageLoader: true });
    globalPutService(`v2/update-user-sparta-group/${editRole.user.slug}/?application=valuation`, { sparta_group_list: [editRole.role.id] })
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.setState(prevState => ({
            ...prevState,
            editRole: { modal: false, role: null, user: null },
            users: {
              ...prevState.users,
              list: prevState.users.list.map(user => user.slug === editRole.user.slug ? {
                ...user, sparta_group: editRole.role.name
              } : user)
            }
          }))
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
  } else {
    this.props.enqueueSnackbar('Please select the Role', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
  }
}
export function getPermissionListApi(props, role) {
  this.setState({ pageLoader: true })
  globalGetService(`v2/get-sparta-group-permissions/`, { group_id: role.id, application: 'valuation' })
    .then(response => {
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        this.setState({ permissionDetail: { data: response.data.data } })
      }
    })

}
export function getInvoiceListApi(props) {
  this.setState({ pageLoader: true })
  globalGetService(`valuation-license/get-invoice-list/`)
    .then(response => {
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        this.setState({ invoiceData: response.data.data })
      }
    })
}
export function getInvoiceDetailApi(props) {
  this.setState({ pageLoader: true })
  globalGetService(`valuation-license/invoice-detail/${props.match.params.id}/`)
    .then(response => {
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        this.setState({ invoiceDetailData: response.data.data })
      }
    })
}
export function exportInvoiceDetailApi(props, invoiceId) {
  globalExportService(`valuation-license/invoice-detail/${invoiceId}`, { download: "pdf" })
    .then(response => {
      downloadFileType(response.data, (`Invoice_id_${invoiceId}_${moment().format(displayDateFormatShort)}`), 'pdf');
      trackActivity('Item Exported', { page_title: 'Invoice', invoice_id: invoiceId, extension: 'pdf' })
    })
}
