import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, TableRow, TableCell, Checkbox, ListItemIcon, ListItemText, ListItem, List } from '@material-ui/core';
import { TableListComp } from '../../../shared_elements';
import { addUserGroupApi, getGroupsListApi } from '../apiServices';
import { checkApiStatus } from '../../../utils';
let list = []
class AddSecurityGroups extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal:false,
      securityGroupList:[],
      selectedSecurityGroups:[]
    }
    this.addUserGroupApi = addUserGroupApi.bind(this)
    this.getGroupsListApi = getGroupsListApi.bind(this)
  }
  componentDidMount() {
    this.getGroupsListApi(this.props)
  }
  updateForm = (id, checked) => {
    if(checked) {
      list.push(id)
    }else {
      let index = list.indexOf(id)
      list.splice(index, 1)
    }
    this.setState({
      selectedSecurityGroups:list
    })
  }
  addSecurityGroupsToUser = () => {
    const { selectedSecurityGroups } = this.state
    this.addUserGroupApi(this.props, selectedSecurityGroups)
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{vertical:'top', horizontal:'right'}})
        this.setState({ modal:false, selectedSecurityGroups:[]})
        this.props.getResponseBack();
        this.getGroupsListApi(this.props)
      }else {
        this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{ vertical:'top', horizontal:'right'}})
      }
    })
  }
  render() {
    const { modal, securityGroupList, selectedSecurityGroups } = this.state
    return(
      <Fragment>
        <Button color="primary" variant="contained" className="buttons-cta" onClick={()=> this.setState({modal:true})} >Add Security Groups</Button>
        { modal ?
          <Dialog
            open={modal}
            onClose={()=> this.setState({modal:false})}
            aria-labelledby='scroll-dialog-title'
            fullWidth
          >
          <DialogTitle dividers={true}>
           Add Security Groups
           <Grid item xs={12}>
             <TextField
               fullWidth
               placeholder="Search for Security Groups"
             />
           </Grid>
          </DialogTitle>
          <DialogContent dividers={true}>
            <Grid container spacing={0} >
              <Grid item xs={12}>
                <List>
                  {securityGroupList.length ? securityGroupList.map((group, index) =>
                    <ListItem key={index}>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          onChange={(e)=> this.updateForm(group.id, e.target.checked)}
                        />
                      </ListItemIcon>
                      <ListItemText>{group.name}</ListItemText>
                    </ListItem>
                  ): null}
                </List>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={()=> this.setState({modal:false})}>Cancel</Button>
            <Button color="primary" variant="contained" onClick={()=> this.addSecurityGroupsToUser()} >Add</Button>
          </DialogActions>
          </Dialog> : null
        }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(AddSecurityGroups));
