import React, { Component } from 'react';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Paper, Grid, Tooltip, Typography, Breadcrumbs, IconButton } from '@material-ui/core';
import { BasicDetail, OtherLinks } from '../components';
import { ExportManu, PageLoader, RevisionHistory } from '../../../shared_elements';
import { getValuationLogApi, exportBaseValuationLogApi, updateLockApi, getLogsSummaryApi } from '../apiServices';
import { displayDateFormatShort } from '../../../constants';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils';
import { checkApiStatus, checkUserAccessPermission } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
import lockIcon from '../../../assets/images/NewLockIcon.png';
import unlockIcon from '../../../assets/images/unlockicon.png';
class BaseValueCalculation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: true,
      feedbackDialog: false,
      query: {},
      type: '',
      valuationLog: {},
      logsSummaryDetail: {}
    }
    this.getValuationLogApi = getValuationLogApi.bind(this);
    this.exportBaseValuationLogApi = exportBaseValuationLogApi.bind(this);
    this.updateLockApi = updateLockApi.bind(this)
    this.getLogsSummaryApi = getLogsSummaryApi.bind(this)

  }
  componentDidMount() {
    this.setState({ skeletonLoader: true });
    this.getValuationLogApi(this.props, this.props.match.params.id)
      .then(response => {
        this.setState({ skeletonLoader: false });
        if (checkApiStatus(response)) {
          this.setState({ valuationLog: response.data.data })
        }
      })
    this.getLogsSummaryApi()
      .then(response => {
        if (checkApiStatus(response)) {
          this.setState({ logsSummaryDetail: response.data.data });
        }
      })
    trackActivity('Page Visited', {
      page_title: 'Base Value Calculation',
      log_id: this.props.match.params.id
    })
  }
  getLatestBVfn = () => {
    this.setState({ pageLoader: true });
    this.getValuationLogApi(this.props, this.props.match.params.id, { 'bv-refresh': 1 })
      .then(response => {
        this.setState({ pageLoader: false });
        if (checkApiStatus(response)) {
          this.setState({ valuationLog: response.data.data })
        }
      })
  }
  render() {
    const { skeletonLoader, pageLoader, valuationLog } = this.state;
    console.log('this', this.props);
    return (
      <div className="valuation-sections" >
        <Grid container spacing={isMobile ? 1 : 3}>
          <Grid item md={6} xs={12}>
            <Breadcrumbs aria-label="breadcrumb" style={isMobile ? { marginTop: '50px' } : { marginTop: '3px' }}>
              <Link color="inherit" to="/logs" style={{ cursor: 'pointer' }} >
                <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                  Logs
                </Typography>
              </Link>
              {
                checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'base_value', 'C') ||
                checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'future_value', 'C') ||
                checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'C') ||
                checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'lev', 'C')
                  ?
                  <Link color="inherit" to="/create-log" style={{ cursor: 'pointer' }} >
                    <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                      Asset Value Options
                    </Typography>
                  </Link> : null
              }
              <Typography className="flex-centered" component="h1" style={{ fontSize: '14px', color: '#bd10e0' }}>
                Base Value Calculation
              </Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item md={6} xs={12}>
            <ul className="list-inline" style={{ float: 'right' }}>
              <li className="list-inline-item"style={{position:'relative', top:'-11px' }} >
                { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'base_value', 'U') ?
                    valuationLog.lock_status ?
                      <Tooltip title="Click to resume editing." placement="top" arrow>
                        <IconButton>
                          <img src={lockIcon} onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: false })} alt="Icon" style={{ width: '30px' }} />
                        </IconButton>
                      </Tooltip>
                      :
                      <Tooltip title="Click to lock editing." placement="top" arrow>
                        <IconButton>
                          <img src={unlockIcon} onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: true })} alt="Icon" style={{ width: '30px' }} />
                        </IconButton>
                      </Tooltip>
                    :
                    valuationLog.lock_status ? <img src={lockIcon} alt="Icon" style={{ cursor: 'default', position: 'relative', top: '10px', width: '30px' }} /> : <img src={unlockIcon} alt="Icon" style={{ cursor: 'default', position: 'relative', top: '10px', width: '30px' }} />
                }
              </li>
              <li className="list-inline-item"  style={{position:'relative', top:'-10px' }}>
                <RevisionHistory
                  url="audit/valuation/vtutilitylogs/"
                  queryParams={{ action: 1, object_id: this.props.match.params.id }}
                  buttonType={false}
                  component={true}
                  exportRevisionHistory={true}
                  exportQueryparam={{ action: 1, object_id: this.props.match.params.id, download: 'xls' }}
                  moduleType={"Base Value"}
                  exportPermission={checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'base_value', 'EXP_PDF')}
                />
              </li>
              { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'base_value', 'EXP_PDF') ?
                  <li className="list-inline-item" style={{position:'relative', top:'-4px',margin: 'unset' }}>
                    <span>
                      <ExportManu title="Export" variant="contained" files={[{ title: 'PDF', extension: 'pdf', key: '' }, {title:'Excel', extension:'xls'}]} exportReportFn={(file) => this.exportBaseValuationLogApi(this.props, file)} />
                    </span>
                  </li>
                  : null
              }
            </ul>
          </Grid>
        </Grid>
        <div className="base-value-sec">
          <BasicDetail valuationLog={valuationLog} skeletonLoader={skeletonLoader} location={this.props.location.pathname} />
          <div className="base-value-amount">
            {valuationLog.base_value_date ?
              <h6 className="flex-centered">Base value as of {moment(valuationLog.base_value_date).format(displayDateFormatShort)}</h6>
              : null
            }
            {valuationLog.base_value ?
              <Paper>
                <h2><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{valuationLog.base_value}</NumericalLabel> Million</h2>
              </Paper> : null
            }
          </div>
          <div className="base-value-amount">
            {valuationLog.cmv_value ?
              <h6 className="flex-centered">Current Market Value as of {moment(valuationLog.base_value_date).format(displayDateFormatShort)}</h6>
              : null
            }
            {valuationLog.cmv_value ?
              <Paper>
                <h2><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{valuationLog.cmv_value}</NumericalLabel> Million</h2>
              </Paper> : null
            }
          </div>
          <OtherLinks type="base-value" valuationLog={valuationLog} module='base_value' applicationPermission={this.props.applicationPermission} />
        </div>
        {pageLoader ? <PageLoader /> : null}
      </div>
    )
  }
}
export default withSnackbar(BaseValueCalculation);
