import React, {Fragment, useState } from 'react';
import { Tabs, Tab, Paper, Grid, TextField, Button, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { LabelValueCard } from '../../../shared_elements';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { regexConstants } from '../../../constants/regEx';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import EditIcon from '@material-ui/icons/Edit';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat, checkUserAccessPermission, getGlobalCookie } from '../../../utils';
import InfoIcon from '@material-ui/icons/Info';
import config from '../../../config';
const LgHlaIntermediate = ({hlaLogDetail, skeletonLoader, lgEdit, toggleLgEdit, onCloseEdit, lgUpdateFn, updateLgLog , error, updateLgErrorField, tabColor, changeLgTabs, lgTabIndex, isLocked, applicationPermission, lgWarningText}) => {
  const [tab, setTab] = useState(0)
  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }
    return (
      <Paper>
        <Grid container spacing={0} style={{padding:isMobile?'10px 15px 15px':'25px 15px', minHeight:isMobile? 'auto':'355px'}}>
          <Grid item xs={5} sm={6} md={6} style={isMobile ? {marginBottom:'30px'} : {marginBottom:'15px'}}>
            <ul className='list-inline'>
              <li className='list-inline-item'>
                <h4 className="hla-initiate-card-title"><strong>Landing Gear</strong></h4>
              </li>
              {
                lgWarningText ? 
                <li className='list-inline-item'>
                <p className='warning-text'>{lgWarningText}</p>
              </li> : null
              }
            </ul>
            
          </Grid>
          <Grid item xs={7} sm={6} md={6}>
            <div style={{float:'right'}}>
              { checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'U') ?
                !lgEdit ?
                !isLocked ?
                <EditIcon color="primary" style={{cursor:'pointer',zIndex:'1000'}} onClick={toggleLgEdit} />:null :
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Button size="small" color="primary" variant="contained" onClick={updateLgLog}>Save</Button>
                  </li>
                  <li className="list-inline-item" style={{marginLeft:'5px'}}>
                    <Button size="small" color="primary" variant="outlined" onClick={onCloseEdit}>Cancel</Button>
                  </li>
                </ul>
                : null
              }
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <Tabs
              value={lgTabIndex}
              indicatorColor={tabColor}
              textColor={tabColor}
              onChange={changeLgTabs}
              aria-label="disabled tabs example"
              variant="scrollable"
              scrollButtons={isMobile ? 'on':'auto'}
            >
            {
              hlaLogDetail.landing_gear.length > 1 ?
              hlaLogDetail.landing_gear.map((item, index) =>
              <Tab label={item.position == 1 ?  'NLG': item.position == 2 ? "LH MLG" : item.position ==3 ?  "RH MLG"  : "" } />)
                : null
            }
            </Tabs>
          </Grid>
          {
            hlaLogDetail.landing_gear.map((landingGear, index) => {
              if(landingGear.position == (lgTabIndex + 1)) {
                return(
                  <Grid container spacing={4}>
                    {lgEdit ?
                      <Fragment>
                        <Grid item xs={6} md={3}>
                          <TextField
                            id="tsn"
                            label="Time Since New"
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 10 }}
                            value={landingGear.tsn}
                            InputLabelProps={{shrink:true}}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? lgUpdateFn(e, 'tsn', e.target.value, index):e.preventDefault()}}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <TextField
                            required
                            id="csn"
                            label="Cycles Since New"
                            fullWidth
                            margin="normal"
                            value={landingGear.csn}
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{shrink:true}}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? lgUpdateFn(e, 'csn', e.target.value, index):e.preventDefault()}}
                            error={ error && error[index] && error[index].csn ? true:false}
                            helperText={ error && error[index] && error[index].csn ? error[index].csn :''}
                            onFocus={(e) => error && error[index] && error[index].csn ?  updateLgErrorField('csn','', index) : e.preventDefault()}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              required
                               margin="normal"
                               id="as_of_date"
                               label="Utilization as of date"
                               placeholder="DD-MM-YYYY"
                               fullWidth
                               minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                               maxDate={hlaLogDetail.generic.report_date ? moment(hlaLogDetail.generic.report_date) : moment()}
                               format={fieldDateFormat}
                               InputLabelProps={{shrink: true}}
                               onChange={(data, value) => lgUpdateFn(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat):data, index)}
                               value={landingGear.utilization_as_of_date}
                               error={ error && error[index] && error[index].utilization_as_of_date_lg ? true:false}
                               helperText={error && error[index] && error[index].utilization_as_of_date_lg ? error[index].utilization_as_of_date_lg  :''}
                               onFocus={(e) => error &&  error[index] && error[index].utilization_as_of_date_lg ?  updateLgErrorField('utilization_as_of_date_lg','', index) : e.preventDefault()}
                               onOpen={(e) => error &&  error[index] && error[index].utilization_as_of_date_lg ?  updateLgErrorField('utilization_as_of_date_lg','', index) : null}
                             />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <TextField
                            id="tsn_at_lsv"
                            label={
                                    <p>TSN at LSV
                                      <Tooltip
                                        title="Time Since New at Last Shop Visit"
                                        arrow
                                        placement="top"
                                      >
                                      <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                      </Tooltip>
                                    </p>
                                   }
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 10 }}
                            value={landingGear.tsn_at_lsv}
                            InputLabelProps={{shrink:true}}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? lgUpdateFn(e, 'tsn_at_lsv', e.target.value, index):e.preventDefault()}}
                            error={error && error[index] && error[index].lg_tsn_at_lsv ? true : false}
                            helperText={error && error[index] && error[index].lg_tsn_at_lsv ? error[index].lg_tsn_at_lsv : '' }
                            onFocus={(e) => error && error[index] && error[index].lg_tsn_at_lsv ?  updateLgErrorField('lg_tsn_at_lsv', '', index) : e.preventDefault()}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <TextField
                            id="csn_at_lsv"
                            label={
                              <p>CSN at LSV {landingGear.csn_at_lsv || landingGear.lsv_date  ? <sup>*</sup> : null}
                                <Tooltip
                                  title="Cycles Since New at Last Shop Visit"
                                  arrow
                                  placement="top"
                                >
                                <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                </Tooltip>
                              </p>
                            }
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 10 }}
                            value={landingGear.csn_at_lsv}
                            InputLabelProps={{shrink:true}}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? lgUpdateFn(e, 'csn_at_lsv', e.target.value, index):e.preventDefault()}}
                            error={error && error[index] && error[index].lg_csn_at_lsv ? true : false}
                            helperText={error && error[index] && error[index].lg_csn_at_lsv ? error[index].lg_csn_at_lsv : '' }
                            onFocus={(e) => error && error[index] && error[index].lg_csn_at_lsv ?  updateLgErrorField('lg_csn_at_lsv', '', index) : e.preventDefault()}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                               margin="normal"
                               id="lsv_date"
                               label={
                                 <p>LSV date {landingGear.csn_at_lsv || landingGear.lsv_date ?  <sup>*</sup> : ''}
                                   <Tooltip
                                     title="Last Shop Visit details to be entered before the report date"
                                     arrow
                                     placement="top"
                                   >
                                     <InfoIcon style={{fontSize:'18px', verticalAllign:'bottom'}} />
                                   </Tooltip>
                                 </p>
                               }
                               placeholder="DD-MM-YYYY"
                               fullWidth
                               minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                               maxDate={landingGear.utilization_as_of_date ? moment(landingGear.utilization_as_of_date) : moment()}
                               format={fieldDateFormat}
                               InputLabelProps={{shrink: true}}
                               value={landingGear.lsv_date}
                               onChange={(data, value) => lgUpdateFn(value, 'lsv_date', data ? moment(data).format(backendDateFormat):data, index)}
                               error={ error && error[index] && error[index].lg_lsv_date ? true : false}
                               helperText={error && error[index] && error[index].lg_lsv_date ? error[index].lg_lsv_date: '' }
                               onFocus={(e) => error && error[index] && error[index].lg_lsv_date ?  updateLgErrorField('lg_lsv_date', '', index) : e.preventDefault()}
                               onOpen={(e) =>  error && error[index] && error[index].lg_lsv_date ?  updateLgErrorField('lg_lsv_date', '', index) :null}
                             />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        {
                          checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') &&
                          (getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == "Acumen")  ?
                          <Grid item xs={6} md={3}>
                          <TextField
                            required
                            id="interval_fc"
                            label="Interval FC"
                            value={landingGear.interval_fc}
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{shrink:true}}
                            onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? lgUpdateFn(e, 'interval_fc', e.target.value, index):e.preventDefault(); lgUpdateFn(e, 'landingGearRemarksFlag', true, index)}}
                            error={error && error[index] && error[index].interval_fc_lg ? true :false}
                            helperText={ error && error[index] && error[index].interval_fc_lg ? error[index].interval_fc_lg : '' }
                            onFocus={(e) => error && error[index] && error[index].interval_fc_lg ? updateLgErrorField('interval_fc_lg', '', index): e.preventDefault()}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                            }}
                          />
                        </Grid>
                        :   <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Interval FC' value={landingGear.interval_fc ? `${landingGear.interval_fc} ` :'--'}/>
                      }
                      {
                        checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') &&
                        (getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == "Acumen") ?

                        <Grid item xs={6} md={3}>
                          <TextField
                            required
                            id="interval_mo"
                            label="Interval MO"
                            value={landingGear.interval_mo}
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{shrink:true}}
                            onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? lgUpdateFn(e, 'interval_mo', e.target.value, index):e.preventDefault(); lgUpdateFn(e, 'landingGearRemarksFlag', true, index)}}
                            error={error && error[index] && error[index].interval_mo_lg ? true :false}
                            helperText={ error && error[index] && error[index].interval_mo_lg ? error[index].interval_mo_lg : '' }
                            onFocus={(e) => error && error[index] && error[index].interval_mo_lg ? updateLgErrorField('interval_mo_lg', '', index): e.preventDefault()}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">MO</InputAdornment>,
                            }}
                          />
                        </Grid>
                        :   <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Interval MO' value={landingGear.interval_mo ? `${landingGear.interval_mo} ` :'--'}/>
                      }
                          {
                            checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') &&
                            (getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == "Acumen")  ?
                           <Grid item xs={6} md={3}>
                             <TextField
                               required
                               id="cost"
                               label="Event Cost"
                               fullWidth
                               margin="normal"
                               value={landingGear.cost}
                               inputProps={{ maxLength: 12 }}
                               InputLabelProps={{shrink:true}}
                               onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? lgUpdateFn(e, 'cost', e.target.value, index):e.preventDefault();lgUpdateFn(e, 'landingGearRemarksFlag', true, index)}}
                               error={error && error[index] && error[index].cost_lg ? true : false}
                               helperText={error && error[index] && error[index].cost_lg ? error[index].cost_lg : '' }
                               onFocus={(e) => error && error[index] && error[index].cost_lg ?  updateLgErrorField('cost_lg', '', index) : e.preventDefault()}
                               InputProps={{
                                 startAdornment: <InputAdornment position="start">$</InputAdornment>,
                               }}
                             />
                           </Grid>
                             :
                             (checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') ||
                             [13].includes(getGlobalCookie(config.cookiesNaming.key).lessor.id))?
                                <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Event Cost' value={landingGear.cost ? <NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{landingGear.cost}</NumericalLabel>: '--' }/>
                                : null
                          }
                          {
                            checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') ?
                          <Grid item xs={12} md={3}>
                            <TextField
                              required={checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') && (landingGear.landingGearRemarksFlag) }
                              id="remarks"
                              label="Remarks"
                              fullWidth
                              margin="normal"
                              multiline
                              rows={isMobile ? 1 : 4}
                              inputProps={{maxLength:250}}
                              value={landingGear.remarks}
                              InputLabelProps={{shrink:true}}
                              onChange={(e) =>  lgUpdateFn(e, 'remarks', e.target.value, index)}
                              error={error && error[index] && error[index].remarks_lg ? true : false}
                              helperText={error && error[index] && error[index].remarks_lg ? error[index].remarks_lg : '' }
                              onFocus={(e) => error && error[index] && error[index].remarks_lg ?  updateLgErrorField('remarks_lg', '', index) : e.preventDefault()}
                            />
                          </Grid>
                          : null
                        }

                      </Fragment> :
                      <Fragment>
                        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Time Since New' value={landingGear.tsn ?  `${landingGear.tsn} FH` : '--'}/>
                        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label="Cycles Since New" value={landingGear.csn ?  `${landingGear.csn} FC` : '--'}/>
                        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Utilization as of date' value={landingGear.utilization_as_of_date ? moment(landingGear.utilization_as_of_date).format(displayDateFormatShort) : '--'}/>
                        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3}
                          label={
                                 <p>TSN at LSV
                                    <Tooltip
                                      title="Time Since New at Last Shop Visit"
                                      arrow
                                      placement="top"
                                    >
                                    <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                    </Tooltip>
                                  </p>
                                }
                          value={landingGear.tsn_at_lsv ? `${landingGear.tsn_at_lsv} FH`:'--'}
                        />
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3}
                        label={
                              <p>CSN at LSV
                                <Tooltip
                                  title="Cycles Since New at Last Shop Visit"
                                  arrow
                                  placement="top"
                                >
                                <InfoIcon style={{fontSize:'18px', marginLeft:'3px'}} />
                                </Tooltip>
                              </p>
                            }
                        value={landingGear.csn_at_lsv ? `${landingGear.csn_at_lsv} FC` :'--'}/>
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='LSV date' value={landingGear.lsv_date ? moment(landingGear.lsv_date).format(displayDateFormatShort):'--'}/>
                        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Interval FC' value={landingGear.interval_fc ? `${landingGear.interval_fc} ` :'--'}/>
                        <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Interval MO' value={landingGear.interval_mo ? `${landingGear.interval_mo} ` :'--'}/>
                          {
                            (checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') ||
                            [13].includes(getGlobalCookie(config.cookiesNaming.key).lessor.id)) ?
                             <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Event Cost' value={landingGear.cost ? <NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{landingGear.cost}</NumericalLabel>: '--' }/>
                             : null
                          }
                          {
                            checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') ?
                            <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label="Remarks" value={landingGear.remarks ? landingGear.remarks : '--'} /> : null
                          }

                      </Fragment>
                    }


                  </Grid>
                )
              }else {
                return null
              }
            })
          }
        </Grid>
      </Paper>
    )
}
export default LgHlaIntermediate;
