import React from 'react';
import { TableRow, TableCell, Link } from '@material-ui/core';
import AddEditDocument from './AddEditDocument';
import moment from 'moment'
import { displayDateFormat } from '../../../constants';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
export default function DocumentList({item, getResponseBack, deleteContractDocument}){
  return (
    <TableRow>
      <TableCell>{item.name ? item.name : '--'}</TableCell>
      <TableCell>{item.start_date ? moment(item.start_date).format(displayDateFormat) : '--'}</TableCell>
      <TableCell>{item.end_date ? moment(item.end_date).format(displayDateFormat): '--'}</TableCell>
      <TableCell>
        <div style={{float:'right'}}>
          <Link download={`document`} href={item.url} style={{textDecoration:'none', marginRight:'5px'}}><CloudDownloadOutlinedIcon fontSize="small"/></Link>
          <AddEditDocument
            id={item.id}
            getResponseBack={getResponseBack}
            mode="Edit"
            documentData={item}
          />
          <DeleteOutlinedIcon
            fontSize="small"
            onClick={(id)=> deleteContractDocument(item.id)}
            color="secondary"
            className="cta-mouse"
            style={{marginleft:'5px'}}
          />
        </div>


    </TableCell>
    </TableRow>
  )
}
