import React, { Component, Fragment} from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, TableRow, TableCell, Button } from '@material-ui/core'
import { TableListComp } from '../../../shared_elements';
import { getConstantsApi, updateUserSkillRatingsApi, getUserApi } from '../apiServices';
import SkillExperienceList from './SkillExperienceList';
import Rating from '@material-ui/lab/Rating';
import { checkApiStatus } from '../../../utils';
class SkillExperience extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal:false,
      constantTypesList:[],
      competency_rating:[],
      ratingBlur:[]
    }
    this.getConstantsApi = getConstantsApi.bind(this)
    this.updateUserSkillRatingsApi = updateUserSkillRatingsApi.bind(this)
    this.getUserApi = getUserApi.bind(this)
  }
  componentDidMount() {
    this.getConstantsApi({constant_types:['user_competency']})
    this.getUserApi(this.props)
  }
  updateRating = (event, newValue, id) => {
    this.setState(prevState => ({
      ...prevState,
      competency_rating:prevState.competency_rating.filter(item => item.skill_id == id).length ?
        prevState.competency_rating.map(item =>
        item.skill_id == id ?
        {...item, skill_rate:newValue} : item) :
        [...prevState.competency_rating,{ skill_id:id, skill_rate:newValue }]
    }))
  }
  updateRatingOnBlur = () =>{
    this.setState({
      ratingBlur:this.state.competency_rating
    })
  }
  saveRating = ( ) => {
    const { competency_rating } = this.state
    this.updateUserSkillRatingsApi(this.props, competency_rating )
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{ vertical:'top', horizontal:'right'}})
        this.setState({modal:false})
        this.props.getResponseBack();
      }else {
        this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{ vertical:'top', horizontal:'right'}})
      }
    })

  }
  render() {
    const { modal, constantTypesList,competency_rating } = this.state
    const { rating, getResponseBack  } = this.props
    return (
      <Fragment>
        <EditIcon fontSize="small" color="primary" onClick={() => this.setState({modal:true})} style={{position:'absolute', fontSize:'medium', right:'-15px'}} />
        {modal ?
          <Dialog
            open={modal}
            onClose={() =>  this.setState({modal:false})}
            aria-labelledby="scroll-dialog-title"
            maxWidth={'md'}
          >
            <DialogTitle id="scroll-dialog-title">
              Competency / Skill Experience Rating
            </DialogTitle>
            <DialogContent dividers={true}>
                  <TableListComp
                    heads={[
                      {label:'SL no', sortOption:false},
                      {label:'Skill', sortOption:false},
                      {label:'Rating', sortOption:false},
                    ]}
                    data={constantTypesList.length ? constantTypesList.map((constant, index)=> {
                      return (
                        <SkillExperienceList
                          constant={constant}
                          key={index}
                          index={index}
                          rating={rating}
                          getResponseBack={getResponseBack}
                          updateRating={(e, newValue, id)=> this.updateRating(e, newValue, id)}
                          updateRatingOnBlur={()=> this.updateRatingOnBlur()}
                          competencyRating={competency_rating}
                        />
                      )
                    }
                    ): null}
                  />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ modal:false})}  color="primary" >Close</Button>
              <Button onClick={()=> this.saveRating()} color="primary" variant="contained">Save</Button>
            </DialogActions>
          </Dialog> : null
        }

      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(SkillExperience));
