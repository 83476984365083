import React, { Component, Fragment } from 'react';
import { TableListComp, EmptyCollection, FilterUIComp, ExportManu } from '../../../shared_elements';
import { UserList, AddEditUser } from '../components';
import { withSnackbar} from 'notistack';
import { withRouter } from 'react-router';
import { Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { STableLoader } from '../../../shared_elements/loaders';
import { removeEmptyKey, convertFilterObject } from '../../../utils';
import { getUserListApi, exportUserListApi } from '../apiServices';
class UserListing extends Component {
  constructor(props){
    super(props);
    this.state = {
      userInfo:{
        list:[],
        pagination:{}
      },
      filter: {},
      applyingFilter:{},
      sort:'',
      sort_by:'',
    }
    this.getUserListApi = getUserListApi.bind(this);
    this.exportUserListApi = exportUserListApi.bind(this);
  }
  componentDidMount(){
    this.getUserListApi(this.props, {page:1, filter_type:'active'})
  }
  applyFilter = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      applyingFilter: {
        ...prevState.applyingFilter,
        [keyParam]: value
      }
    }))
  }
  removeFilter = (keyParam) => {
    const { filter, applyingFilter } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    this.getUserListApi(this.props, {...query, page:1, filter_type:'active'});
    this.setState({applyingFilter: delete applyingFilter[keyParam]})
  }
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter } = this.state;
    this.setState({
      sort:sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc':'asc' :'asc')
    });
    if(sortField === sort){
      if(sort_by === 'asc'){
        this.getUserListApi(this.props, {...filter, sort: sortField, sort_by: 'desc', page:1, filter_type:'active'});
      }else{
        this.getUserListApi(this.props, {...filter, sort: sortField, sort_by: 'asc', page:1, filter_type:'active'});
      }
    }else{
      this.getUserListApi(this.props, {...filter, sort: sortField, sort_by: 'asc', page:1, filter_type:'active'});
    }
  }
  render(){
    const { userInfo, filter, sort, sort_by, applyingFilter , skeletonLoader } = this.state;
    const filterOptions = {
      search: {
        inputType: 'text',
        placeholder: 'Search by User Name',
        title:'User Name'
      },
    }
    return(
      <Fragment>
        <div className="flex-centered" style={{marginBottom: '20px'}}>
          <div className="page-description">
            <Typography variant="h2" className="page-title">Active Users</Typography>
            { !isMobile ? <p className="page-para">Contains the list of active users</p>:null}
          </div>
        </div>
        <span style={{float: 'right', padding: '5px 0'}}>
          <ExportManu disabled={userInfo.list.length === 0 ? true:false} title="Export Users" files={[{title:'Excel', extension: 'xls', key:''}]} exportReportFn={(file) => this.exportUserListApi(this.props, {...filter, download:file.extension})} />
        </span>
        <AddEditUser  getResponseBack={()=> this.getUserListApi(this.props, {page:1, filter_type:'active'})}  />
        <FilterUIComp
          filter={filter}
          applyingFilter={applyingFilter}
          removeFilter={this.removeFilter}
          removeAllFilter={() => this.getUserListApi(this.props, {page:1, filter_type:'active'})}
          submitFilter={() => this.getUserListApi(this.props, {...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions), page:1, filter_type:'active'})}
          applyFilter={this.applyFilter}
          filterMenu={filterOptions}
        />
        { skeletonLoader ? <STableLoader count={4} /> :
          <TableListComp
            heads={[
              { id: 'name', label: 'User', sortOption: true },
              { id: 'email', label: 'Email', sortOption: true },
              { label: 'Designation', sortOption: false },
              { label: 'Status', sortOption: false },
            ]}
            sort={sort}
            sort_by={sort_by}
            data={userInfo.list.map((item, index) => <UserList item={item} viewDetail={()=> this.props.history.push(`/users/manage/${item.slug}`)} />)}
            noRecord={ userInfo.list.length ? null:
              <EmptyCollection
                title="No records found"
              />
            }
            pagination={userInfo.pagination}
            onChangePage={(event, newPage) => this.getUserListApi(this.props, {...filter,page:newPage+1,per_page:userInfo.pagination.per_page})}
            onChangeRowsPerPage={(event)=> this.getUserListApi(this.props, {...filter, page:1, per_page: event.target.value}, 'pageLoader')}
            createSortHandler={this.createSortHandler}
          />
        }

      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(UserListing));
