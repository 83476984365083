import React from 'react';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
export default function AllowedAssetList({deleteSingleAssetFn, item, deleteAssets, updateForm}){
  return (
    <TableRow hover className="test-hover">
      <TableCell>
        <Checkbox
          edge="start"
          checked={deleteAssets.includes(item.id)}
          onChange={(e) => updateForm(item.id, e.target.checked)}
        /> {item.asset_name}
        <DeleteOutlinedIcon
          fontSize="small"
          style={{float:'right', marginTop:'10px'}}
          onClick={(id)=> deleteSingleAssetFn(item.id)}
          color="secondary"
          className="cta-mouse"
        />
      </TableCell>
    </TableRow>
  )
}
