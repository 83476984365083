import React, { Component } from 'react';
import { getGlobalCookie } from '../utils';
import config from '../config';

export default function userLoggedCheckHoc(HocComponent){
  return class extends Component{
    constructor(props){
      super(props);
      this.state = {}
    }
    componentDidMount(){
      window.addEventListener('visibilitychange', (event) => {
        if(document.visibilityState || document.hasFocus){
          if(getGlobalCookie(config.cookiesNaming.key)){
            window.location.href = '/logs'
          }
        }
      });      
    }
    render(){
      return(
        <HocComponent {...this.props} />
      )
    }
  }
}
