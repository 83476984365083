import React, {Fragment} from 'react'
import { Grid, FormControl, FormControlLabel, RadioGroup, Radio, FormLabel  } from '@material-ui/core';

const AircraftEngineDetail = ({leaseEnCrud, engineNo, updateEngineSvDataNone, updatePRSVData}) => {
  return (
    <Fragment>
      {
        !leaseEnCrud.id ?
        <div className="aircraft-lease-detail" style={{marginTop:'20px'}}>
          {
            leaseEnCrud && leaseEnCrud.engine_sv_details && leaseEnCrud.engine_sv_details.engines && leaseEnCrud.engine_sv_details.engines.length ?
          <Grid container spacing={2} >
            <Grid item md={12}>
              <h4 style={{color:'#bd10e0'}}>Aircraft Engine Details</h4>
            </Grid>
            <Grid item md={8}>
              <Grid container spacing={2}>
              {
                leaseEnCrud.engine_sv_details.engines.slice(0, engineNo).map((engine, engineIndex) => {
                  return (
                    <Grid item md={6}>
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <h4>{engine.name}</h4>
                        </Grid>
                        {
                          engine && engine.engine_hsi && engine.engine_hsi.length ?
                          <Fragment>
                            <Grid item md={6}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">Last Performed HSI</FormLabel>
                                <RadioGroup aria-label="engine" name="engine" >
                                  <FormControlLabel  control={<Radio checked={!engine.last_performed_hsi} onChange={() => updateEngineSvDataNone( 'last_performed_hsi', engineIndex)} color="primary" />} label='No HSI Performed' />
                                  {engine.engine_hsi.map((hsi, hsiIndex) =>
                                    <FormControlLabel  control={<Radio checked={engine.last_performed_hsi && engine.last_performed_hsi.name && engine.last_performed_hsi.name == hsi.event ? true :false}  color="primary" onChange={(e) => updatePRSVData('last_performed_hsi' , hsi,engineIndex, hsiIndex)} />} label={hsi.event} />
                                  )}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item md={6}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">Last Performed OVH</FormLabel>
                                <RadioGroup aria-label="engine" name="engine" >
                                  <FormControlLabel  control={<Radio checked={!engine.last_performed_sv} onChange={() => updateEngineSvDataNone( 'last_performed_sv', engineIndex)} color="primary" />} label='No OVH Performed' />
                                  {engine.engine_prsv.map((prsv, prIndex) =>
                                    <FormControlLabel  control={<Radio checked={engine.last_performed_sv && engine.last_performed_sv.name && engine.last_performed_sv.name == prsv.event ? true :false}  color="primary" onChange={(e) => updatePRSVData('last_performed_sv' , prsv,engineIndex, prIndex)} />} label={prsv.event} />
                                  )}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Fragment>

                          :
                          <Grid item md={12}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">Last Performed PRSV</FormLabel>
                              <RadioGroup aria-label="engine" name="engine" >
                                <FormControlLabel  control={<Radio checked={!engine.last_performed_sv} onChange={() => updateEngineSvDataNone( 'last_performed_sv', engineIndex)} color="primary" />} label='No PRSV Performed' />
                                {engine.engine_prsv.map((prsv, prIndex) =>
                                  <FormControlLabel  control={<Radio checked={engine.last_performed_sv && engine.last_performed_sv.name && engine.last_performed_sv.name == prsv.event ? true :false}  color="primary" onChange={(e) => updatePRSVData('last_performed_sv' , prsv,engineIndex, prIndex)} />} label={prsv.event} />
                                )}
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                        }
                      </Grid>

                    </Grid>
                  )
                })
              }
              </Grid>
            </Grid>
          </Grid> : null
        }
      </div> :
         leaseEnCrud && leaseEnCrud.engine_sv_details  && leaseEnCrud.engine_sv_details.length ?
        <div className="aircraft-engine-details" style={{marginTop:'30px'}}>
          <Grid container spacing={2}>
            <Grid item md={12} >
              <h4 className="value-card-header" >Aircraft Engine Details</h4>
            </Grid>
            <Grid item md={8}>
              <Grid container spacing={2}>
                {
                  leaseEnCrud.engine_sv_details.map(svData =>
                    <Grid item md={6} style={{marginBottom:'20px'}}>
                      <Grid container>
                        <Grid item md={12} style={{marginBottom:'15px'}}>
                          <h4>{svData.name}</h4>
                        </Grid>
                        {
                          svData.engine_hsi && svData.engine_hsi.length ?
                          <Fragment>
                            <Grid item xs={6} md={6} sm={6} className="label-value-card">
                              <p>Last Performed HSI</p>
                              <h6>{svData.last_performed_hsi ? svData.last_performed_hsi.event : 'No HSI Performed'}</h6>
                            </Grid>
                            <Grid item xs={6} md={6} sm={6} className="label-value-card">
                              <p>Last Performed OVH</p>
                              <h6>{svData.last_performed_sv ? svData.last_performed_sv.event : 'No OVH Performed'}</h6>
                            </Grid>
                          </Fragment> :
                          <Grid item xs={6} md={6} sm={6} className="label-value-card">
                            <p>Last Performed SV</p>
                            <h6>{svData.last_performed_sv ? svData.last_performed_sv.event : 'No PRSV Performed'} </h6>
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </div> : null
      }
    </Fragment>
  )
}
export default AircraftEngineDetail
