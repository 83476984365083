import React, { Component } from 'react';
export default class ChangePassword extends Component {
  constructor(props){
    super(props);
  }
  render(){
    return(
      <h1>ChangePassword</h1>
    )
  }
}
