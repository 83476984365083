import React, { Component,Fragment } from 'react';
import { Tabs, Tab, Grid,Card } from '@material-ui/core';
import { SecurityGroups, AllowedAssets, Documents, PreviousInspections, Experience, AvailabilityTracker, UserDetail }  from '../components';
import { getUserApi} from '../apiServices';
import { STableLoader } from '../../../shared_elements/loaders';
export default class EditUser extends Component {
  constructor(props){
    super(props);
    this.state = {
      tabIndex : 0,
      userInfo:{},
      assetAllowedList:[],
      skeletonLoader:false
    }
    this.getUserApi = getUserApi.bind(this)
  }

  componentDidMount() {
    this.getUserApi(this.props)

  }
  handleChange = (event, newValue) => {
    this.setState(prevState => ({
      ...prevState,
      tabIndex:newValue
    }))
  }
  render(){
    const { tabIndex, userInfo, assetAllowedList, skeletonLoader  } = this.state
    return(
        <div className="user-management-module-section">
          <Card style={{marginBottom:'30px'}}>
            <UserDetail userInfo={userInfo} getResponseBack={()=> this.getUserApi(this.props)} />
          </Card>
          <Grid container  >
            <Tabs
              value={tabIndex}
              onChange={this.handleChange}
              indicatorColor='primary'
              variant="normal"
              aria-label="full width tabs example"
              variant="fullWidth"
              fullWidth
              style={{width:'100%'}}
              >
              <Tab label="Security Groups" style={{textTransform:'capitalize'}} />
              <Tab label="Allowed Assets" style={{textTransform:'capitalize'}} />
              <Tab label= "Documents" style={{textTransform:'capitalize'}} />
              <Tab label="Previous Inspections"  style={{textTransform:'capitalize'}} />
              <Tab label="Experience on Aircraft Type" style={{textTransform:'capitalize', width:'100%'}} />
              <Tab label="Availability Tracker" style={{textTransform:'capitalize'}} />
            </Tabs>
            </Grid>
            { tabIndex == 0 ?
              <Fragment>
                {skeletonLoader ? <STableLoader count={1} /> :
                  <SecurityGroups securityGroups={userInfo.sparta_group ? userInfo.sparta_group : []} getResponseBack={()=> this.getUserApi(this.props)} />
                }
              </Fragment> : null
            }
            { tabIndex == 1 ?
              <Fragment>
                {skeletonLoader ? <STableLoader count={1} /> :
                  <AllowedAssets assets={userInfo.asset ? userInfo.asset : []}  getResponseBack={()=> this.getUserApi(this.props)}  />  
                }
              </Fragment> : null
            }
            { tabIndex == 2 ?
              <Fragment>
                <Documents />
              </Fragment>: null
            }
            { tabIndex == 3 ?
              <Fragment>
                <PreviousInspections projectType={userInfo.project_types ? userInfo.project_types :[]} getResponseBack={()=> this.getUserApi(this.props)}  />
              </Fragment> : null
            }
            { tabIndex == 4 ?
              <Fragment>
                <Experience  experiences={userInfo.experience_of_aircraft_type ? userInfo.experience_of_aircraft_type :[]} getResponseBack={() => this.getUserApi(this.props)} />
              </Fragment> : null
            }
            { tabIndex == 5 ?
              <Fragment>
                <AvailabilityTracker />
              </Fragment> : null
            }
        </div>

    )
  }
}
