import React, { Component, Fragment } from 'react';
import { Tabs, Tab, Paper, Grid, TextField, Button, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { LabelValueCard } from '../../../shared_elements';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { regexConstants } from '../../../constants/regEx';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat, checkUserAccessPermission, getGlobalCookie } from '../../../utils';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import config from '../../../config';
const ApuHlaIntermediate = ({ hlaLogDetail, skeletonLoader, apuEdit, toggleApuEdit, onCloseEdit, onFieldChange, updateApuLog, error, updateErrorField, isLocked, applicationPermission, apuWarningText }) => {
  return (
    <Paper>
      <Grid container spacing={0} style={{ padding: isMobile ? '10px 15px 15px' : '25px 15px', minHeight: isMobile ? 'auto' : '355px' }}>
        <Grid item xs={5} sm={6} md={6} style={isMobile ? { marginBottom: '30px' } : { marginBottom: '15px' }}>
          <ul className='list-inline'>
            <li className='list-inline-item'>
              <h4 className="hla-initiate-card-title"><strong>APU</strong></h4>
            </li>
            {
              apuWarningText ?
                <li className='list-inline-item'>
                  <p className='warning-text'>{apuWarningText}</p>
                </li> : null
            }

          </ul>
        </Grid>
        <Grid item xs={7} sm={6} md={6}>
          <div style={{ float: 'right' }}>
            {checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'U') ?
              !apuEdit ?
                !isLocked ?
                  <EditIcon color="primary" style={{ cursor: 'pointer', zIndex: '1000' }} onClick={toggleApuEdit} /> : null :
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Button size="small" color="primary" variant="contained" onClick={updateApuLog}>Save</Button>
                  </li>
                  <li className="list-inline-item" style={{ marginLeft: '5px' }}>
                    <Button size="small" color="primary" variant="outlined" onClick={onCloseEdit} >Cancel</Button>
                  </li>
                </ul>
              : null
            }
          </div>
        </Grid>
        <Grid container spacing={isMobile ? 2 : 4}>
          <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='APU Type' value={hlaLogDetail.apu.apu_type ? hlaLogDetail.apu.apu_type : '--'} />
          {
            apuEdit ?
              <Grid item xs={6} md={3}>
                <TextField
                  required
                  id="hours"
                  label="APU Hours"
                  value={hlaLogDetail.apu.hours}
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 10 }}
                  error={error && error.hours ? true : false}
                  helperText={error && error.hours ? error.hours : ''}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'hours', e.target.value, 'apu') : e.preventDefault() }}
                  onFocus={(e) => error && error.hours ? updateErrorField('hours', '', 'apu') : e.preventDefault()}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">APU H</InputAdornment>,
                  }}
                />
              </Grid> :
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='APU Hours' value={hlaLogDetail.apu.hours ? `${hlaLogDetail.apu.hours} APU H` : '--'} />
          }
          {
            apuEdit ?
              <Grid item xs={12} md={3}>
                <TextField
                  id="cycles"
                  label="APU Cycles"
                  value={hlaLogDetail.apu.cycles}
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cycles', e.target.value, 'apu') : e.preventDefault() }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">APU C</InputAdornment>,
                  }}
                />
              </Grid> :
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='APU Cycles' value={hlaLogDetail.apu.cycles ? `${hlaLogDetail.apu.cycles} APU C` : '--'} />
          }
          {
            apuEdit ?
              <Grid item xs={12} md={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    required
                    margin="normal"
                    id="as_of_date"
                    label=" Utilization as of date"
                    placeholder="DD-MM-YYYY"
                    fullWidth
                    format={fieldDateFormat}
                    InputLabelProps={{ shrink: true }}
                    minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                    maxDate={hlaLogDetail.generic.report_date ? moment(hlaLogDetail.generic.report_date) : moment()}
                    onChange={(data, value) => onFieldChange(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat) : data, 'apu')}
                    value={hlaLogDetail.apu.utilization_as_of_date}
                    error={error && error.utilization_as_of_date_apu ? true : false}
                    helperText={error && error.utilization_as_of_date_apu ? error.utilization_as_of_date_apu : ''}
                    onFocus={(e) => error && error.utilization_as_of_date_apu ? updateErrorField('utilization_as_of_date_apu', '', 'apu') : e.preventDefault()}
                    onOpen={(e) => error && error.utilization_as_of_date_apu ? updateErrorField('utilization_as_of_date_apu', '', 'apu') : null}
                  />
                </MuiPickersUtilsProvider>
              </Grid> :
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label=' Utilization as of date' value={hlaLogDetail.apu.utilization_as_of_date ? moment(hlaLogDetail.apu.utilization_as_of_date).format(displayDateFormatShort) : '--'} />
          }
          {
            apuEdit ?
              <Grid item xs={6} md={3}>
                <TextField
                  id="serial_number"
                  label="Serial Number"
                  value={hlaLogDetail.apu.serial_number}
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 10 }}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => onFieldChange(e, 'serial_number', e.target.value, 'apu')}
                  error={error && error.serial_number_apu ? true : false}
                  helperText={error && error.serial_number_apu ? error.serial_number_apu : ''}
                  onFocus={(e) => error && error.serial_number_apu ? updateErrorField('serial_number_apu', '', 'apu') : e.preventDefault()}
                />
              </Grid> :
              <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='APU Serial Number' value={hlaLogDetail.apu.serial_number ? hlaLogDetail.apu.serial_number : '--'} />
          }
          {
            apuEdit ?
              <Fragment>
                <Grid item xs={6} md={3}>
                  <TextField
                    required={hlaLogDetail.apu.hours_at_lsv || hlaLogDetail.apu.cycles_at_lsv || hlaLogDetail.apu.lsv_date}
                    id="hours_at_lsv"
                    label="APU Hours at LSV"
                    value={hlaLogDetail.apu.hours_at_lsv}
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: 10 }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'hours_at_lsv', e.target.value, 'apu') : e.preventDefault() }}
                    error={error && error.apu_hours_at_lsv ? true : false}
                    helperText={error && error.apu_hours_at_lsv ? error.apu_hours_at_lsv : ''}
                    onFocus={(e) => error && error.apu_hours_at_lsv ? updateErrorField('apu_hours_at_lsv', '', 'apu') : e.preventDefault()}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">APU H</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    id="cycles_at_lsv"
                    label="APU Cycles at LSV"
                    value={hlaLogDetail.apu.cycles_at_lsv}
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: 10 }}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange(e, 'cycles_at_lsv', e.target.value, 'apu') : e.preventDefault() }}
                    error={error && error.apu_cycles_at_lsv ? true : false}
                    helperText={error && error.apu_cycles_at_lsv ? error.apu_cycles_at_lsv : ''}
                    onFocus={(e) => error && error.apu_cycles_at_lsv ? updateErrorField('apu_cycles_at_lsv', '', 'apu') : e.preventDefault()}
                    onOpen={(e) => error && error.apu_cycles_at_lsv ? updateErrorField('apu_cycles_at_lsv', '', 'apu') : null}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">APU C</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="lsv_date"
                      label={
                        <p style={{ position: 'relative', top: '-3px' }}>LSV Date
                          <Tooltip
                            title="Last Shop Visit details to be entered before the report date"
                            arrow
                            placement="top"
                          >
                            <InfoIcon style={{ fontSize: '18px', verticalAllign: 'bottom' }} />
                          </Tooltip>
                        </p>
                      }
                      placeholder="DD-MM-YYYY"
                      fullWidth
                      format={fieldDateFormat}
                      minDate={moment(hlaLogDetail.airframe.date_of_manufacture)}
                      maxDate={hlaLogDetail.apu.utilization_as_of_date ? moment(hlaLogDetail.apu.utilization_as_of_date) : moment()}
                      InputLabelProps={{ shrink: true }}
                      onChange={(data, value) => onFieldChange(value, 'lsv_date', data ? moment(data).format(backendDateFormat) : data, 'apu')}
                      value={hlaLogDetail.apu.lsv_date}
                      error={error && error.apu_lsv_date ? true : false}
                      helperText={error && error.apu_lsv_date ? error.apu_lsv_date : ''}
                      onFocus={(e) => error && error.apu_lsv_date ? updateErrorField('apu_lsv_date', '', 'apu') : e.preventDefault()}
                      onOpen={(e) => error && error.apu_lsv_date ? updateErrorField('apu_lsv_date', '', 'apu') : null}

                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {
                  checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') &&
                    getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == 'Acumen' ?
                    <Grid item xs={6} md={3}>
                      <TextField
                        required
                        id="interval_fh"
                        label="Interval"
                        value={hlaLogDetail.apu.interval_fh}
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 10 }}
                        InputLabelProps={{ shrink: true }}
                        error={error && error.interval_apu ? true : false}
                        helperText={error && error.interval_apu ? error.interval_apu : ''}
                        onFocus={(e) => error && error.interval_apu ? updateErrorField('interval_apu', '', 'apu') : e.preventDefault()}
                        onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'interval_fh', e.target.value, 'apu') : e.preventDefault(); onFieldChange(e, 'apuRemarksFlag', true, 'apu') }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">APU H</InputAdornment>,
                        }}
                      />
                    </Grid> : <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Interval' value={hlaLogDetail.apu.interval_fh ? `${hlaLogDetail.apu.interval_fh} APU H` : '--'} />
                }
                {
                  checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') &&
                    getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == 'Acumen' ?
                    <Grid item xs={12} md={3}>
                      <TextField
                        required
                        id="cost"
                        label="Event Cost"
                        value={hlaLogDetail.apu.cost}
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 12 }}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? onFieldChange(e, 'cost', e.target.value, 'apu') : e.preventDefault(); onFieldChange(e, 'apuRemarksFlag', true, 'apu') }}
                        error={error && error.cost_apu ? true : false}
                        helperText={error && error.cost_apu ? error.cost_apu : ''}
                        onFocus={(e) => error && error.cost_apu ? updateErrorField('cost_apu', '', 'apu') : e.preventDefault()}
                        onOpen={(e) => error && error.cost_apu ? updateErrorField('cost_apu', '', 'apu') : null}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Grid>
                    : (checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') &&
                      getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == 'Acumen') ?
                      <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Event Cost' value={hlaLogDetail.apu.cost ? <NumericalLabel params={getCurrencyFormat({ shortFormat: false })} >{hlaLogDetail.apu.cost}</NumericalLabel> : '--'} /> : null
                }
                {
                  checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') ?
                    <Grid item xs={12} md={3}>
                      <TextField
                        required={checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') && (hlaLogDetail.apu.apuRemarksFlag)}
                        id="remarks"
                        label="Remarks"
                        value={hlaLogDetail.apu.remarks}
                        fullWidth
                        multiline
                        rows={4}
                        InputProps={{ maxLength: 250 }}
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => onFieldChange(e, 'remarks', e.target.value, 'apu')}
                        error={error && error.remarks_apu ? true : false}
                        helperText={error && error.remarks_apu ? error.remarks_apu : ''}
                        onFocus={(e) => error && error.remarks_apu ? updateErrorField('remarks_apu', '', 'apu') : e.preventDefault()}
                        onOpen={(e) => error && error.remarks_apu ? updateErrorField('remarks_apu', '', 'apu') : null}
                      />
                    </Grid> : null

                }
              </Fragment> :
              <Fragment>
                <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='APU Hours at LSV' value={hlaLogDetail.apu.hours_at_lsv ? `${hlaLogDetail.apu.hours_at_lsv} APU H` : '--'} />
                <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='APU Cycles at LSV' value={hlaLogDetail.apu.cycles_at_lsv ? `${hlaLogDetail.apu.cycles_at_lsv} APU C` : '--'} />
                <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='LSV Date' value={hlaLogDetail.apu.lsv_date ? moment(hlaLogDetail.apu.lsv_date).format(displayDateFormatShort) : '--'} />
                <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Interval' value={hlaLogDetail.apu.interval_fh ? `${hlaLogDetail.apu.interval_fh} APU H` : '--'} />
                {
                  (checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') &&
                    getGlobalCookie(config.cookiesNaming.key).user.account_type && getGlobalCookie(config.cookiesNaming.key).user.account_type == 'Acumen') ?
                    <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label='Event Cost' value={hlaLogDetail.apu.cost ? <NumericalLabel params={getCurrencyFormat({ shortFormat: false })} >{hlaLogDetail.apu.cost}</NumericalLabel> : '--'} /> : null
                }
                {
                  checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'OV') ?
                    <LabelValueCard skeletonLoader={skeletonLoader} xs={6} md={3} label="Remarks" value={hlaLogDetail.apu.remarks ? hlaLogDetail.apu.remarks : '--'} />
                    : null
                }

              </Fragment>
          }
        </Grid>
      </Grid>
    </Paper>
  )
}
export default ApuHlaIntermediate;
