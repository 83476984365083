import React, { Fragment, Component } from 'react'
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogTitle, DialogActions,DialogContent, Grid, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getConstantsApi, getUserSkillSetApi, editUserProfileApi } from '../apiServices';
import { errorCodeUserEdit,countries } from '..';
import { useTableStyle } from '../../../utils/materialStyles';
import { isMobile } from 'react-device-detect';
import { fieldValidation } from '../../../utils/formValidation';
import { regexConstants } from '../../../constants/regEx';
import { checkApiStatus } from '../../../utils';
import { AutocompleteCreatable } from '../../../shared_elements'
class EditUserProfileModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      modal:false,
      userObj:{},
      error:{},
      constantTypesList:[],
      skillSetsList:[],
      saveLoader:false
    }
    this.getConstantsApi = getConstantsApi.bind(this)
    this.getUserSkillSetApi = getUserSkillSetApi.bind(this)
    this.editUserProfileApi = editUserProfileApi.bind(this)
  }
  componentDidMount() {
    this.getConstantsApi({constant_types:['user_category','project_departments','user_competency']})
    this.getUserSkillSetApi()
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      userObj:{
        ...prevState.userObj,
        [keyParam]:data
      }
    }))
  }
  editUser = () => {
    this.setState({saveLoader:true})
    const { userObj } = this.state
    let formData = new FormData()
    if(userObj.name != ''){
      formData.append('first_name', userObj.name.substring(0,userObj.name.indexOf(' ')))
      formData.append('last_name', userObj.name.substring(userObj.name.indexOf(' ')))
    }
    formData.append('contact_no', userObj.contact_no == null ? '': userObj.contact_no)
    formData.append('alternative_contact_no', userObj.alternative_contact_no == null ? '' : userObj.alternative_contact_no)
    formData.append('designation', userObj.designation == null ? '' : userObj.designation)
    formData.append('department', userObj.department == null ? '' : userObj.department)
    formData.append('organisation', userObj.organisation == null ? '': userObj.organisation)
    formData.append('base_location', userObj.base_location == null ? '' : userObj.base_location)
    formData.append('city', userObj.city == null ? '':userObj.city)
    formData.append('category', userObj.category == null ? '': userObj.category)
    formData.append('type', userObj.type.value == null ? '' : userObj.type.value)
    let validationInputs = {
      name:errorCodeUserEdit['name'][fieldValidation({...errorCodeUserEdit['nameObj'], fieldval:userObj.name})],
      contact_no: errorCodeUserEdit['contact_no'][fieldValidation({...errorCodeUserEdit['contact_noObj'], fieldval:userObj.contact_no})],
      alternative_contact_no:errorCodeUserEdit['alternative_contact_no'][fieldValidation({...errorCodeUserEdit['alternative_contact_noObj'], fieldval:userObj.alternative_contact_no})]
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.editUserProfileApi(formData)
      .then(response => {
        this.setState({saveLoader:false})
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{vertical:'top', horizontal:'right'}})
          this.setState({ modal:false, userObj:{}})
          this.props.getResponseBack();
        }else {
          this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{ vertical:'top', horizontal:'right'}})
        }
      })
    }else {
      this.setState({
        error:validationInputs,
        saveLoader:false
      })
    }
  }
  render() {
    const { modal, userObj, error, constantTypesList, skillSetsList, saveLoader } = this.state
    const { userData } = this.props
    const departments = constantTypesList.filter(item => item.type == "project_departments")
    const categories = constantTypesList.filter(item =>  item.type == 'user_category')
    return (
      <Fragment>
        <Button color="primary" onClick={()=> this.setState({modal:true, userObj:{...userData, category:userData.category.value ? userData.category.value : '', department:userData.department.value ? userData.department.value : '' }})}>Edit</Button>
        { modal ?
          <Dialog
            open={modal}
            onClose={() =>  this.setState({modal:false,userObj:{}, error:{} })}
            aria-labelledby="scroll-dialog-title"
            fullScreen
          >
          <DialogTitle id="scroll-dialog-title">
            Edit Profile
          </DialogTitle>
          <DialogContent dividers={true} >
            <Grid container spacing={isMobile?1:5} >
              <Grid item xs={12} md={3} style={{marginBottom:'10px'}}>
                <TextField
                  id="name"
                  required
                  label="Name"
                  fullWidth
                  margin="normal"
                  value={userObj.name}
                  error={error.name ? true:false}
                  helperText={error.name ? error.name : ''}
                  InputLabelProps={{shrink : true}}
                  onChange={(e) => this.onFieldChange(e, 'name', e.target.value)}
                  onFocus={() =>  this.updateErrorField('name', '')}
                />
              </Grid>
              <Grid item xs={12} md={3} style={{marginBottom:'10px'}} >
                <TextField
                  id="email"
                  label="Email"
                  fullWidth
                  disabled
                  margin="normal"
                  value={userData.email}
                  InputLabelProps={{shrink:true}}
                />
              </Grid>
              <Grid item xs={12} md={3} style={{marginBottom:'10px'}}>
                <TextField
                  id="contact_no"
                  label="Phone"
                  fullWidth
                  margin="normal"
                  value={userObj.contact_no}
                  error={error.contact_no ? true: false}
                  helperText={error.contact_no ? error.contact_no : ''}
                  InputLabelProps={{shrink:true}}
                  onChange={(e) => this.onFieldChange(e, 'contact_no', e.target.value ) }
                  onFocus={() => this.updateErrorField('contact_no', '')}
                />
              </Grid>
              <Grid item xs={12} md={3} style={{marginBottom:'10px'}}>
                <TextField
                  id="alternative_contact_no"
                  label="Alternate Phone Number"
                  fullWidth
                  margin="normal"
                  value={userObj.alternative_contact_no}
                  error={error.alternative_contact_no ? true: false}
                  helperText={error.alternative_contact_no ? error.alternative_contact_no : ''}
                  InputLabelProps={{shrink:true}}
                  onChange={(e) => this.onFieldChange(e, 'alternative_contact_no', e.target.value ) }
                  onFocus={() => this.updateErrorField('alternative_contact_no', '')}
                />
              </Grid>
              <Grid item xs={12} md={3} style={isMobile ?{marginBottom:'10px'}:{marginBottom:'30px'}} >
                <TextField
                  id="city"
                  label="City"
                  fullWidth
                  margin="normal"
                  value={userObj.city}
                  InputLabelProps={{shrink:true}}
                  onChange={(e)=> this.onFieldChange(e, 'city', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3} style={{marginBottom:'30px'}} >
                <Autocomplete
                  id="base_location"
                  options={countries}
                  getOptionLabel = {option => option.label}
                  autoHighlight
                  renderOption={(option) => (
                    <Fragment>
                      {option.label}
                    </Fragment>
                  )}
                  value={countries.find(item => item.label == userObj.base_location)}
                  onChange={(e, value) => this.onFieldChange(e,'base_location', value ? value.label : '')}
                  renderInput={params =>
                  <TextField   {...params} label='Country' margin="normal" fullWidth InputLabelProps={{shrink: true}}    />}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() =>  this.setState({ modal:false, userObj:{}})} color="primary" >Cancel</Button>
            <Button onClick={()=>  this.editUser()} variant="contained" color="primary">{saveLoader ? 'Saving...': 'Save'}</Button>
          </DialogActions>
        </Dialog> : null
        }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(EditUserProfileModal));
