import React, { Component, Fragment }  from 'react';
import { Grid, Paper } from '@material-ui/core';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils';
import { isMobile } from 'react-device-detect';

class ValueCard extends Component {
  constructor(props){
    super(props)
    this.state = {

    }
  }
  render(){
    const {levOutputData} = this.props;
    console.log(levOutputData, 'levOutputData')
    return (
        <Grid container spacing={isMobile ? 2 : 5}>
            <Grid item xs={12} md={4} alignItems="center">
              <div className="value-card">
                <Paper className="value-info vl color-blue">
                  <h4>Rents</h4>
                  <span></span>
                  <Grid container spacing={2} className="value">
                      <Grid item xs={6} sm={6} md={6}>
                        <p>
                          {
                            levOutputData && levOutputData.lev && levOutputData.lev.rent_nominal ?
                            <NumericalLabel params={getCurrencyFormat({shortFormat:false, precision:2})}>{levOutputData.lev.rent_nominal}</NumericalLabel> : '--'
                          }
                        </p>
                        <span>Nominal Dollar</span>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <p className="right-align">
                          {
                            levOutputData && levOutputData.lev && levOutputData.lev.rent_discounted ?
                            <NumericalLabel params={getCurrencyFormat({shortFormat:false, precision:2})}>{levOutputData.lev.rent_discounted}</NumericalLabel> : '--'
                          }
                          </p>
                        <p className="right-align" style={{fontSize:'13px'}}>Discounted Dollar @ <strong>{levOutputData && levOutputData.lev ? `${levOutputData.lev.annual_discount}%` : "0%"}</strong></p>
                      </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
            <Grid item xs={12} md={4} alignItems="center">
              <div className="value-card">
                <Paper className="value-info vl color-blue">
                  <h4>Residual Value {levOutputData && levOutputData.lev && levOutputData.lev.is_full_life_maintenance_condition ? '(Full life)' :'(Half Life)'  } </h4>
                  {
                    levOutputData && levOutputData.lev && levOutputData.lev.annual_inflation_rate  ?
                    <p style={{fontSize:'13px'}}> @ {levOutputData.lev.annual_inflation_rate}% annual inflation</p> : null
                  }

                  <Grid container spacing={2} className="value">
                      <Grid item xs={6} sm={6} md={6}>
                          <p>{ levOutputData && levOutputData.lev && levOutputData.lev.residual_nominal ?
                              <NumericalLabel params={getCurrencyFormat({shortFormat:false, precision:2})}>{levOutputData.lev.residual_nominal}</NumericalLabel>
                              : '--' }
                          </p>
                        <span>Nominal Dollar</span>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <p className="right-align">
                          {
                            levOutputData && levOutputData.lev && levOutputData.lev.residual_discounted ?
                            <NumericalLabel params={getCurrencyFormat({shortFormat:false, precision:2})}>{levOutputData.lev.residual_discounted}</NumericalLabel> : '--'
                          }
                          </p>
                        <p className="right-align">Discounted Dollar @ <strong>{levOutputData && levOutputData.lev ? `${levOutputData.lev.annual_discount}%` : "0%"}</strong></p>
                      </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
            <Grid item xs={12} md={4} alignItems="center">
              <div className="value-card">
                <Paper className="value-info vl color-blue">
                  <h4>Lease Encumbered Value {levOutputData && levOutputData.lev && levOutputData.lev.is_full_life_maintenance_condition ? '(Full life)' :'(Half Life)'  }</h4>
                  <span></span>
                  <Grid container spacing={2} className="value">
                      <Grid item xs={6} sm={6} md={6}>
                        <p>
                          {
                            levOutputData && levOutputData.lev && levOutputData.lev.lev_nominal ?
                            <NumericalLabel params={getCurrencyFormat({shortFormat:false, precision:2})}>{levOutputData.lev.lev_nominal}</NumericalLabel>: '--'
                          }
                          </p>
                        <span>Nominal Dollar</span>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <p className="right-align">
                          {
                            levOutputData && levOutputData.lev && levOutputData.lev.lev_discounted ?
                            <NumericalLabel params={getCurrencyFormat({shortFormat:false, precision:2})}>{levOutputData.lev.lev_discounted}</NumericalLabel> : '--'
                          }
                          </p>
                        <p className="right-align">Discounted Dollar @ <strong>{levOutputData && levOutputData.lev ? `${levOutputData.lev.annual_discount}%` : "0%"}</strong></p>
                      </Grid>
                  </Grid>
                </Paper>
              </div>
            </Grid>
        </Grid>
    )
  }
}
export default ValueCard;
