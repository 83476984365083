import React, { Component,  Fragment} from 'react';
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { isMobile } from 'react-device-detect';
import { Grid, Typography, List, ListItem, Button, Link, Input, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import { LabelValueCard, DeletePopUp } from '../../../shared_elements';
import EditUserModal from './EditUserModal';
import { editUserApi, removeUserApi, resendInviteApi } from '../apiServices';
import { checkApiStatus } from '../../../utils';
import SkillExperience from './SkillExperience';
import StarIcon from '@material-ui/icons/Star';
class UserDetail extends Component {
  constructor(props){
    super(props)
    this.state = {
      profilepicFlag:false,
      originalResumeFlag:false,
      acumenResumeFlag:false,
      originalResume:'',
      acumenResume:'',
      imageFile:'',
      openDropZoneOriginalResume:false,
      openDropZoneAcumenResume:false,
      deleteModal:false,
      skeletonLoader:false,
      inviteModal:false,
      rateModal:false
    }
    this.editUserApi = editUserApi.bind(this)
    this.removeUserApi = removeUserApi.bind(this)
    this.resendInviteApi = resendInviteApi.bind(this)
  }
  updateImage = (file) => {
    if(file.size >1024000) {
      this.props.enqueueSnackbar('Please upload a picture smaller than 1mb ', { variant:'error', anchorOrigin:{vertical:'top', horizontal:'right'}})
    }else {
      this.setState({
        imageFile:file,
        profilepicFlag:true
      })
      setTimeout(()=>{
        this.uploadImage()
      }, 1000)
    }

  }
  uploadResume = (file, keyParam, fileFlagKey) =>{
    this.setState(prevState => ({
      ...prevState,
      [keyParam]:file,
      [fileFlagKey]:true

    }))
  }
  uploadImage = () => {
    const { imageFile, profilepicFlag, originalResumeFlag, acumenResumeFlag, originalResume, acumenResume } = this.state
    let formData = new FormData()
    if(profilepicFlag){
      formData.append('profile_pic', imageFile)
    }
    this.editUserApi(this.props, formData)
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar('Uploaded profile picture successfully', { variant:'success', anchorOrigin:{ vertical:'top', horizontal:'right'}})
        this.props.getResponseBack();
      }else {
        this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{ vertical:'top', horizontal:'right'}})
      }
    })
  }
  uploadOriginalResume = () => {
    const { originalResume } = this.state
    let formData = new FormData()
    formData.append('original_resume', originalResume)
    this.editUserApi(this.props, formData)
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar('Uploaded resume successfully', { variant:'success', anchorOrigin:{ vertical:'top',horizontal:'right'}})
        this.setState({openDropZoneOriginalResume:false})
        this.props.getResponseBack()
      }else {
        this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{ vertical:"top", horizontal:'right'}})
      }
    })
  }
  uploadAcumenResume = () => {
    const { acumenResume } = this.state
    let formData = new FormData()
    formData.append('acumen_resume', acumenResume)
    this.editUserApi(this.props, formData)
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar('Uploaded resume successfully', { variant:'success', anchorOrigin:{ vertical:'top',horizontal:'right'}})
        this.setState({openDropZoneAcumenResume:false})
        this.props.getResponseBack()
      }else {
        this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{ vertical:"top", horizontal:'right'}})
      }
    })
  }
  toggleDeleteModalFn = () => {
    this.setState(prevState => ({
      ...prevState,
      deleteModal:!prevState.deleteModal
    }))
  }
  toggleInviteFn = () => {
    this.setState(prevState => ({
      ...prevState,
      inviteModal:!prevState.inviteModal
    }))
  }
  deleteUser = () => {
    this.removeUserApi(this.props)
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{vertical:'top', horizontal:'right'}})
        this.setState({deleteModal:false})
        this.props.history.push('/users/manage')
      }else {
        this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{vertical:'top', horizontal:'right'}})
      }
    })
  }
  getRating=(userProfileInfo)=>{
    var total= userProfileInfo.competency_rating && userProfileInfo.competency_rating.length ?
     parseFloat((userProfileInfo.competency_rating.reduce((total, item) => parseFloat(total) + parseFloat(item.skill_rate), 0)/userProfileInfo.competency_rating.length).toFixed(4)) : '--'

     return total;
  }

  render(){
    const { openDropZoneAcumenResume, openDropZoneOriginalResume,originalResume, acumenResume, deleteModal, skeletonLoader, inviteModal, rateModal } = this.state
    const { userInfo, getResponseBack } = this.props
    let loader = Object.keys(userInfo).every((k) => userInfo[k] == '' ? true : false )
    return (
        <Fragment className="user-management-module-section">
          <div className="user-detail">
            <div  className="section-1" style={{borderBottom:'1px solid #dbdbdb'}}>
              <Grid container spacing={0}>
                <Grid item md={2} xs={2} style={{position:'relative'}}>
                  { loader ? <Skeleton variant="circle" width={110}  height={110} style={{position:'relative',top:'23px', left:'35px' }}/>
                : userInfo.profile_pic ?
                    <div alt={userInfo.name} className="profile-pic" style={{backgroundImage: "url(" + userInfo.profile_pic + ")"}} >
                      <label htmlFor={`contained-button-file`} className="edit-profile-pic" ><Link style={{textDecoration:'none', cursor:'pointer'}}><EditIcon  /></Link></label>
                        <input
                        accept="image/*"
                        id={`contained-button-file`}
                        type="file"
                        style={{display:'none'}}
                        onChange={(e) => this.updateImage(e.target.files[0])}
                        />
                    </div> : <Avatar alt={userInfo.name}  src="we" className="profile-pic"  />

                  }

                </Grid>
                <Grid item md={3} xs={3}>
                  <div style={{marginTop:'37px', position:'relative', right:'22px'}}>
                    <h4 style={{marginBottom:'10px'}}>{userInfo.name ? userInfo.name : ''} </h4>
                    {userInfo .contact_no && <h5>{userInfo.contact_no}</h5> }
                    <h5>{userInfo.email ? userInfo.email :''}</h5>
                  </div>
                </Grid>
                <Grid item md={5} xs={5} className="resume">
                  <p style={{fontSize: '12px', color: '#7f7f4a', marginTop: '6px', position:'absolute', top:'28px', right:'8px'}}>Please contact system administrator to deactivate the user from all lessors</p>
                </Grid>

                <Grid item md={2} xs={2}>
                  <div className="btn-user-detail">
                    <EditUserModal userData={userInfo} getResponseBack={getResponseBack} />
                    <Button color="secondary" onClick={()=> this.setState({deleteModal:true})}>Remove</Button>
                    <DeletePopUp
                      modal={deleteModal}
                      toggleModalFn={this.toggleDeleteModalFn}
                      title="Remove User"
                      deleteRecordFn={()=>this.deleteUser()}
                      content={<h4>Are you sure you want to remove</h4>}
                    />
                  { userInfo.is_new_user ?
                    <Fragment>
                      <Button color="primary" onClick={()=> this.setState({inviteModal:true})}> Resend Invite </Button>
                      <DeletePopUp
                        modal={inviteModal}
                        toggleModalFn={this.toggleInviteFn}
                        title="Resend Invitation"
                        deleteRecordFn={() => this.resendInviteApi(this.props)}
                        cancelText="No"
                        confirmText="Yes"
                        cancelColor="secondary"
                        confirmColor="primary"
                        content={<h4>Are you sure, you want to resend invitation ?</h4>}
                      />
                    </Fragment>
                     : null
                  }
                  </div>
                  <div  className={userInfo.is_new_user ? 'resumeNew' : 'resumeOld'} >
                    <div  style={{borderRight:'1px solid #a0a0a0', marginRight:"40px" }}>
                      <LabelValueCard
                        label="Resume"
                        className="resume"
                        skeletonLoader={loader}
                        value={ userInfo.original_resume ?
                          <Fragment>
                            <Link download={`resume`} href={userInfo.original_resume} target="_blank" style={{textDecoration:'none'}}><CloudDownloadOutlinedIcon /></Link>
                            <EditOutlinedIcon fontSize="small" color="primary" onClick={()=> this.setState({openDropZoneOriginalResume:true})} style={{paddingLeft:'4px',marginBottom:'2px'}} />
                          </Fragment> :
                          <CloudUploadOutlinedIcon color="primary" style={{cursor:'pointer'}} onClick={()=> this.setState({openDropZoneOriginalResume:true})} />
                        }
                      />
                      <DropzoneDialog
                        acceteptedFiles={[['image/jpeg', 'image/png', 'image/jpg', 'application/pdf']]}
                        cancelButtonText='Cancel'
                        submitButtonText="Add"
                        maxFileSize={5000000}
                        open={openDropZoneOriginalResume}
                        onClose={()=> this.setState({ openDropZoneOriginalResume:false})}
                        onChange={(file) => this.uploadResume(file[0], 'originalResume', 'originalResumeFlag')}
                        onSave={() => this.uploadOriginalResume()}
                        showPreviews={true}
                        showFileNameinPreview={true}
                        showAlerts={false}
                      />
                    </div>
                    <div style={{position:'relative', bottom:'50.5px',left:'5rem'}} >
                      <LabelValueCard
                        label="Acumen Resume"
                        skeletonLoader={loader}
                        value={ userInfo.acumen_resume ?
                          <Fragment>
                            <Link download={`acumen_resume`} href={userInfo.acumen_resume} target="_blank" style={{textDecoration:'none'}} ><CloudDownloadOutlinedIcon /></Link>
                            <EditOutlinedIcon fontSize="small" color="primary" onClick={() => this.setState({openDropZoneAcumenResume:true})} style={{paddingLeft:'4px', marginBottom:'2px'}} />
                          </Fragment>:
                          <CloudUploadOutlinedIcon color="primary" style={{cursor:'pointer'}} onClick={()=> this.setState({openDropZoneOriginalResume:true})} />
                        }
                      />
                      <DropzoneDialog
                        acceteptedFiles={['image/jpeg', 'image/png', 'image/jpg', 'application/pdf']}
                        cancelButtonText="Cancel"
                        submitButtonText="Add"
                        maxFileSize={5000000}
                        open={openDropZoneAcumenResume}
                        onClose={()=> this.setState({openDropZoneAcumenResume:false})}
                        onChange={(file)=> this.uploadResume(file[0], 'acumenResume', 'acumenResumeFlag')}
                        onSave={() => this.uploadAcumenResume()}
                        showAlerts={false}
                        showPreviews={true}
                        showFileNameinPreview={true}
                      />
                    </div>


                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="section-2">
              <Grid container spacing={isMobile?3:6} className="section-2-list"  direction="row" >
                  <LabelValueCard label="Alternate Phone" md={3} value={userInfo.alternative_contact_no ? userInfo.alternative_contact_no : '--'} style={{alignItems:'center'}} skeletonLoader={loader} />
                  <LabelValueCard label="Department" md={3} value={userInfo.department && userInfo.department.label ? userInfo.department.label  : '--' } skeletonLoader={loader} />
                  <LabelValueCard label="Profile Type" md={3} value={userInfo.type ? userInfo.type.label : '--'} skeletonLoader={skeletonLoader} />
                  <LabelValueCard label="Category" md={3} value={userInfo.category && userInfo.category.label ? userInfo.category.label : '--'} skeletonLoader={loader} />
                  <LabelValueCard label="City" md={3} value={userInfo.city ? userInfo.city : '--'} skeletonLoader={loader} />
                  <LabelValueCard label="Country" md={3} value={userInfo.base_location ? userInfo.base_location : '--'} skeletonLoader={loader} />
                  {Object.keys(userInfo).length ? userInfo.type.value == 1 || userInfo.type.value == 3 ?
                    <Fragment>
                      <LabelValueCard label="Overall Experience" md={3} value={userInfo.experience ? userInfo.experience : '--'} skeletonLoader={loader} />
                      <LabelValueCard label="Licence" md={3} value={userInfo.licenses ? userInfo.licenses : '--'} skeletonLoader={loader} />
                      <LabelValueCard label="Licence Issued" md={3} value={userInfo.license_issued_by ? userInfo.license_issued_by : '--'} skeletonLoader={loader} />
                      <LabelValueCard label="Licence type Endorsements" md={3} value={userInfo.license_endorsements ? userInfo.license_endorsements : '--'} skeletonLoader={loader} />
                      <LabelValueCard label="Special Approvals" md={3} value={userInfo.special_approvals ? userInfo.special_approvals : '--'} skeletonLoader={loader} />
                      <LabelValueCard label="Special Tranings" md={3} value={userInfo.special_trainings ? userInfo.special_trainings : '--'} skeletonLoader={loader} />
                      <LabelValueCard label="Passport Issued By" md={3} value={userInfo.passport_issued_by ? userInfo.passport_issued_by : '--'} skeletonLoader={loader} />
                      <LabelValueCard label={<p style={{position:'relative'}}>Competency / Skill Experience Rating<span><SkillExperience  rating={userInfo.competency_rating} getResponseBack={getResponseBack} /></span></p>} md={3} value={userInfo.competency_rating ? <h6>{this.getRating(userInfo)} <span><StarIcon fontSize="small" style={{position:'relative', top:'3px', right:'2px', fontSize:"16px"}} color="action" /></span></h6> : '--'} skeletonLoader={loader} />
                      <LabelValueCard label="Daily Fee Rate" md={3} value={userInfo.fee_rate ? userInfo.fee_rate : '--'}  skeletonLoader={loader}/>
                      <LabelValueCard label="Additional Remarks" md={3} value={userInfo.additional_remarks ? userInfo.additional_remarks : '--'} skeletonLoader={loader} />
                    </Fragment> : null : ''
                  }

              </Grid>
            </div>
          </div>
        </Fragment> : null

    )
  }
}
export default withSnackbar(withRouter(UserDetail));
