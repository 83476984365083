import React, { Fragment, Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { getGlobalCookie, layoutMarginProps} from '../utils';
import { DesktopHeader, MobileHeader,DesktopSideNav, PageLoader, AccessDenied, SecondarySideNav} from '../shared_elements';
import config from '../config';
import * as actions from '../shared_elements/actions'
import { getPermissionAc } from '../shared_elements/actionCreators';
export default function PageLayout(HocComponent, extraProps={}){
  class Layout extends Component{

    constructor(props){
      super(props)
      this.state = {
        modal: false
      }
    }
    componentDidMount(){
      if(!Object.keys(this.props.applicationPermission).length){
        this.props.getPermission();
      }
      window.addEventListener('visibilitychange', (event) => {
        if(getGlobalCookie(config.cookiesNaming.key)){
          if(document.visibilityState || document.hasFocus){
              if(this.props.applicationPermission && this.props.applicationPermission.lessor_details && (getGlobalCookie(config.cookiesNaming.key).lessor.id !== this.props.applicationPermission.lessor_details.id)){
                this.props.togglePermissionLoader();
                this.props.getPermission();
              }
          }
        }else{
          this.props.history.push('/login')
        }
      });
    }
    render() {
      const { applicationPermission, permissionLoading } = this.props
      if(permissionLoading){
        if(applicationPermission){
          return(
            <Fragment>
              {isMobile ?
                <MobileHeader applicationPermission={applicationPermission} />:
                <Fragment>
                  <DesktopHeader />
                  <DesktopSideNav match={this.props.match} applicationPermission={applicationPermission} />
                </Fragment>
              }
              {extraProps.layoutPhase === 2 ? <SecondarySideNav />:null}
              <main
                style={{
                  margin: isMobile ? "10px 0 0 0" : layoutMarginProps(extraProps),
                  padding: isMobile
                    ? "15px 15px 50px 15px"
                    : "20px 20px 58px 20px",background:"#fdfdfd",
                }}
              >
                <div>
                  <HocComponent {...this.props} />
                </div>
              </main>
            </Fragment>
          )
        }else{
          return <AccessDenied />
        }
      }else{
        return <PageLoader />
      }
    }
  }
  const mapStateToProps = (state) => ({
    pltConstants: state.shareReducer.pltConstants,
    applicationSwitcher: state.shareReducer.applicationSwitcher,
    applicationPermission: state.shareReducer.applicationPermission,
    permissionLoading: state.shareReducer.permissionLoading,

  })
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      toggleTheAppSwitcher: (flag) => dispatch({type:actions.APPS_SWITCHER, payload:flag}),
      getPermission:() => dispatch(getPermissionAc({permission:true, security_groups:true, permission_filter:'console,technical_specs,reports,valuation'})),
      togglePermissionLoader:() => dispatch({type:actions.PERMISSION_LOADER})
    }
  }
  return connect(mapStateToProps, mapDispatchToProps)(Layout)
}
