import React from 'react';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import Tooltip from '@material-ui/core/Tooltip';
export default function SecurityGroupList({item, deleteSecurityGroups, deleteSingleGroupFn, updateForm}){
  return (
    <TableRow  >
    <TableCell>
      <Checkbox
        edge="start"
        checked={deleteSecurityGroups.includes(item.id)}
        onChange={(e)=> updateForm(item.id, e.target.checked)}
      />{item.name}
      {item.name.includes('Acumen') || item.name.includes('Global Projects Tracker') ?
        <Tooltip title='Only for Acumen Users'>
          <WarningOutlinedIcon fontSize="small" color="primary" style={{marginLeft:'5px', marginBottom:'-5px'}} />
        </Tooltip>
        :null
      }
      <DeleteOutlinedIcon
        fontSize="small"
        style={{float:'right', marginTop:'10px'}}
        onClick={(id)=> deleteSingleGroupFn(item.id)}
        color="secondary"
        className="cta-mouse"
      />
    </TableCell>
    </TableRow>
  )
}
