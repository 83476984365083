import React, { Component} from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { TableRow, TableCell, TextField } from '@material-ui/core';
import { regexConstants } from '../../../constants/regEx'
import { updateUserProjectsApi } from '../apiServices';
import { checkApiStatus } from '../../../utils';
class PreviousInspectionList extends Component {
  constructor(props){
    super(props)
    this.state = {
      project_types:[]
    }
    this.updateUserProjectsApi = updateUserProjectsApi.bind(this)
  }
  componentDidMount(){
    this.setState({
      project_types: this.props.projectType
    })
  }
  onFieldChange = (id, data) => {
    this.setState(prevState => ({
      ...prevState,
      project_types:prevState.project_types.filter(item => item.project_type_value == id).length ?
        prevState.project_types.map(item =>
          item.project_type_value == id ?
          {...item, no_of_projects: data} : item) :
          [...prevState.project_types,{ project_type_value : id ,no_of_projects: data }]
    }))
  }
  updateProjectNumber = () => {
    const { project_types } = this.state
    let formData = new FormData()
    formData.append('project_types', JSON.stringify(project_types))
    this.updateUserProjectsApi(this.props, formData)
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message,{ variant:'success', anchorOrigin:{ vertical:'top', horizontal:'right'}})
        this.props.getResponseBack();
      }else {
        this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{ vertical:'top', horizontal:'right'}})
      }
    })

  }
  render(){
    const { project } = this.props
    const { project_types } = this.state
    return (
      <TableRow>
        <TableCell>{project.label}</TableCell>
        <TableCell>
          {
            project_types.filter(item => item.project_type_value == project.value).length ? project_types.map(item => item.project_type_value == project.value ?
              <TextField
                value={item.no_of_projects}
                onBlur={()=> this.updateProjectNumber()}
                onChange={(e) =>  { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(project.value, e.target.value) : e.preventDefault()}}
              /> : null) :
              <TextField
                value={0}
                onBlur={()=> this.updateProjectNumber(project.value)}
                onChange={(e) =>  { regexConstants.onlyNumeric.test(e.target.value) ? this.onFieldChange(project.value, e.target.value) : e.preventDefault()}}
              />
          }

        </TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    )
  }
}
export default withSnackbar(withRouter(PreviousInspectionList));
