import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Grid, Breadcrumbs, Typography, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { AircraftBasicDetail, AircraftLeaseDetail, AircraftEngineDetail } from '../components';
import { leaseEnCrud } from '..';
import { LabelValueCard, PageLoader } from '../../../shared_elements'
import { getAircraftModsApi, getRentalTypesApi, getRentalPaymentTypesApi, generateLEVLogApi, getLevDataApi, updateLogApi } from '../apiServices';
import { getEngineTypesAc, getAircraftTypesAc, getLesseeListAc } from '../../../shared_elements/actionCreators';
import { trackActivity } from '../../../utils/mixpanel';

class UpdateLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      leaseEnCrud: leaseEnCrud,
      mods: [],
      levRentalTypes: [],
      levRentalPaymentTypes: [],
      error: {},
      pageLoader: false
    }
    // api calls are written in apiServices file
    // importing the functions and binding it here
    this.getAircraftModsApi = getAircraftModsApi.bind(this) // get the mods list
    this.getRentalTypesApi = getRentalTypesApi.bind(this) // get the rental type constants
    this.getRentalPaymentTypesApi = getRentalPaymentTypesApi.bind(this) // get the rental type payment constants
    this.generateLEVLogApi = generateLEVLogApi.bind(this) //  generate log api - post method
    this.getLevDataApi = getLevDataApi.bind(this)
    this.updateLogApi = updateLogApi.bind(this)
  }
  componentDidMount() {
    this.getLevDataApi(this.props)
    // this.getAircraftModsApi(this.props,{aircraft_type:leaseEnCrud.aircraft_type_id.id})
    trackActivity('Lease Encumbered Value', {
      event_type: 'Page Visited',
      page_title: 'Update Log',
      log_id: this.props.match.params.id
    })
  }
  // updateErrorField function
  // this function is used for updating the fields if those fields have any error messages
  updateErrorField = (keyParam, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: message
      }
    }))
  }
  // step Rental update error function
  // this function is used for updating the fields if those fields have any error messages
  updateStepErrorField = (keyParam, index) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [index]: {
          ...prevState.error[index],
          [keyParam]: ""
        }
      }
    }))
  }

  /// onFieldChange function --  keyParam - key value - value of that specific property
  onFieldChange = (event, keyParam, value) => {
    this.updateFormFields(keyParam, value)
    //condition 1
    if (keyParam == "aircraft_type_id") {
      this.updateFormFields('mods', [])
      this.updateFormFields('engine_type_id', null)
      if (value) {
        this.getAircraftModsApi(this.props, { aircraft_type: value.id })
        this.props.getEngineTypes({ valuation: 'valuation', 'aircraft_type': value.id });
      }
    }
    // condition 2
    if (keyParam == 'mtow' || keyParam == "mtow_kgs" && value >= 0) {
      if (keyParam == 'mtow_kgs') {
        this.updateFormFields('mtow', (value * 2.2046).toFixed(4));
      } else {
        this.updateFormFields('mtow_kgs', (value * 0.4535970244).toFixed(4));
      }
    }
  }

  // update form fields function -- after all conditions, will call this function to update the this.state.
  // keyParam - key name
  // value - value of the property
  updateFormFields = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        [keyParam]: value
      }
    }))
  }
  // Stepped Rental logic
  // add rows
  addRows = (index) => {
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        step_rentals: [...prevState.leaseEnCrud.step_rentals, { start_date: null, end_date: null, rent: "", }]
      }
    }))
  }
  // remove rows
  removeRows = (index) => {
    const { leaseEnCrud } = this.state
    let rentals = leaseEnCrud.step_rentals
    rentals.splice(index, 1)
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        step_rentals: rentals
      }
    }))
  }
  // update data
  updateStepRental = (event, keyParam, value, elementIndex) => {
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        step_rentals: prevState.leaseEnCrud.step_rentals.map((item, index) => index == elementIndex ? ({
          ...item,
          [keyParam]: value
        }) : item)
      }
    }))
    if (keyParam == 'end_date') {
      if (this.state.leaseEnCrud.step_rentals.length > (elementIndex + 1)) {
        this.setState(prevState => ({
          ...prevState,
          leaseEnCrud: {
            ...prevState.leaseEnCrud,
            step_rentals: prevState.leaseEnCrud.step_rentals.map((item, index) => index == (elementIndex + 1) ? ({
              ...item,
              start_date: moment(value).add(1, 'days').format(backendDateFormat)
            }) : item)
          }
        }))
      }
    }
  }
  render() {
    const { leaseEnCrud, mods, levRentalPaymentTypes, levRentalTypes, error, pageLoader, mntGroups } = this.state
    const { aircraftTypes, lessee, engineTypes, applicationPermission } = this.props
    return (
      <section className="lease-encumbered-section">
        {pageLoader ? <PageLoader /> : null}
        <div className="create-log-breadcrumb">
          <Grid container spacing={0}>
            <Grid xs={12}>
              <Breadcrumbs aria-label="breadcrumb" >
                <Link color="inherit" to="/logs">
                  <Typography className="flex-centered text-underline" component="h1">
                    Logs
                  </Typography>
                </Link>
                <Link color="inherit" to="/create-log">
                  <Typography className="flex-centered text-underline" component="h1">
                    Asset Value Options
                  </Typography>
                </Link>
                <Link color="inherit" to="/lease-encumbered/create-log">
                </Link>
                <Typography className="flex-centered" component="h1">
                  {this.props.match.params.id}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </div>
        <div className="create-log-cta">
          <Grid container spacing={0} className="flex-centered">
            <Grid xs={12} md={12}>
              <Button className="proceed-btn" style={{ float: 'right' }} variant="contained" size="large" color="primary" onClick={() => this.updateLogApi(this.props, leaseEnCrud)}>Get Value</Button>
            </Grid>
          </Grid>
        </div>
        <div className="create-log-basic-detail">
          <AircraftBasicDetail
            leaseEnCrud={leaseEnCrud}
            mods={mods}
            aircraftTypes={aircraftTypes}
            lessee={lessee}
            onFieldChange={this.onFieldChange}
            engineTypes={engineTypes}
            error={error}
            updateErrorField={this.updateErrorField}
            leaseEnCrudId={leaseEnCrud.id}
            applicationPermission={applicationPermission}
          />
          <AircraftLeaseDetail
            leaseEnCrud={leaseEnCrud}
            levRentalTypes={levRentalTypes}
            levRentalPaymentTypes={levRentalPaymentTypes}
            onFieldChange={this.onFieldChange}
            error={error}
            updateErrorField={this.updateErrorField}
            leaseEnCrudId={leaseEnCrud.id}
            addRows={this.addRows}
            removeRows={this.removeRows}
            updateStepRental={this.updateStepRental}
            updateStepErrorField={this.updateStepErrorField}
            mntGroups={mntGroups}
            applicationPermission={applicationPermission}
          />
          <AircraftEngineDetail
            leaseEnCrud={leaseEnCrud}
          />
        </div>
        <div className="create-log-cta">
          <Grid container spacing={0} className="flex-centered">
            <Grid xs={12} md={12}>
              <Button className="proceed-btn" style={{ marginTop: '20px' }} fullWidth={true} variant="contained" size="large" color="primary" onClick={() => this.updateLogApi(this.props, leaseEnCrud)}>Get Value</Button>
            </Grid>
          </Grid>
        </div>
      </section>
    )
  }
}
const mapStateToProps = state => ({
  engineTypes: state.shareReducer.engineTypes,
  aircraftTypes: state.shareReducer.aircraftTypes,
  lessee: state.shareReducer.lessee,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
    getAircraftTypes: (query) => dispatch(getAircraftTypesAc(ownProps, query)),
    getLesseeList: (query) => dispatch(getLesseeListAc(query)),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(UpdateLog))
