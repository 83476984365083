import React, { Fragment, Component } from 'react'
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button, Dialog, DialogTitle, DialogActions,DialogContent, Grid, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getConstantsApi, getUserSkillSetApi, editUserApi } from '../apiServices';
import { errorCodeUserEdit,countries } from '..';
import { useTableStyle } from '../../../utils/materialStyles';
import { fieldValidation } from '../../../utils/formValidation';
import { regexConstants } from '../../../constants/regEx';
import { checkApiStatus } from '../../../utils';
import { isMobile } from 'react-device-detect';
import { AutocompleteCreatable } from '../../../shared_elements'
class EditUserModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      modal:false,
      userObj:{},
      error:{},
      constantTypesList:[],
      skillSetsList:[]
    }
    this.getConstantsApi = getConstantsApi.bind(this)
    this.getUserSkillSetApi = getUserSkillSetApi.bind(this)
    this.editUserApi = editUserApi.bind(this)
  }
  componentDidMount() {
    this.getConstantsApi({constant_types:['user_category','project_departments','user_competency']})
    this.getUserSkillSetApi()
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      userObj:{
        ...prevState.userObj,
        [keyParam]:data
      }
    }))
  }
  editUser = () => {
    const { userObj } = this.state
    let formData = new FormData()
    if(userObj.name != ''){
      formData.append('first_name', userObj.name.substring(0,userObj.name.indexOf(' ')))
      formData.append('last_name', userObj.name.substring(userObj.name.indexOf(' ')))
    }
    formData.append('contact_no', userObj.contact_no == null ? '': userObj.contact_no)
    formData.append('alternative_contact_no', userObj.alternative_contact_no == null ? '' : userObj.alternative_contact_no)
    formData.append('designation', userObj.designation == null ? '' : userObj.designation)
    formData.append('department', userObj.department == null ? '' : userObj.department)
    formData.append('organisation', userObj.organisation == null ? '': userObj.organisation)
    formData.append('base_location', userObj.base_location == null ? '' : userObj.base_location)
    formData.append('city', userObj.city == null ? '':userObj.city)
    formData.append('category', userObj.category == null ? '': userObj.category)
    formData.append('type', userObj.type.value == null ? '' : userObj.type.value)
    if(userObj.type.value == 1 || userObj.type.value == 3){
      formData.append('experience', userObj.experience == null ? '': userObj.experience)
      formData.append('skill_sets', userObj.skill_sets == null ? '' : userObj.skill_sets.map(skill => skill.id))
      formData.append('licenses', userObj.licenses == null ? '' : userObj.licenses)
      formData.append('passport_issued_by', userObj.passport_issued_by == null ? '' :userObj.passport_issued_by)
      formData.append('license_endorsements', userObj.license_endorsements == null ? '' : userObj.license_endorsements)
      formData.append('license_issued_by', userObj.license_issued_by == null ? '' : userObj.license_issued_by)
      formData.append('special_trainings', userObj.special_trainings == null ? '' : userObj.special_trainings)
      formData.append('special_approvals', userObj.special_approvals == null ? '' : userObj.special_approvals)
      formData.append('additional_remarks', userObj.additional_remarks == null ? '' : userObj.additional_remarks)
      formData.append('language_proficiency', userObj.language_proficiency == null ? '' : userObj.language_proficiency)
      formData.append('fee_rate', userObj.fee_rate == null ? '': userObj.fee_rate)
    }
    let validationInputs = {
      name:errorCodeUserEdit['name'][fieldValidation({...errorCodeUserEdit['nameObj'], fieldval:userObj.name})],
      contact_no: errorCodeUserEdit['contact_no'][fieldValidation({...errorCodeUserEdit['contact_noObj'], fieldval:userObj.contact_no})],
      alternative_contact_no:errorCodeUserEdit['alternative_contact_no'][fieldValidation({...errorCodeUserEdit['alternative_contact_noObj'], fieldval:userObj.alternative_contact_no})]
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.editUserApi( this.props, formData)
      .then(response => {
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{vertical:'top', horizontal:'right'}})
          this.setState({ modal:false, userObj:{}})
          this.props.getResponseBack();
        }else {
          this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{ vertical:'top', horizontal:'right'}})
        }
      })
    }else {
      this.setState({
        error:validationInputs
      })
    }
  }
  render() {
    const { modal, userObj, error, constantTypesList, skillSetsList } = this.state
    const { userData } = this.props
    const departments = constantTypesList.filter(item => item.type == "project_departments")
    const categories = constantTypesList.filter(item =>  item.type == 'user_category')
    return (
      <Fragment>
        <Button color="primary" onClick={()=> this.setState({modal:true, userObj:{...userData,category:userData.category.value? userData.category.value : '', department:userData.department.value ? userData.department.value:""}})}>Edit</Button>
        { modal ?
          <Dialog
            open={modal}
            onClose={() =>  this.setState({modal:false,userObj:{}, error:{} })}
            aria-labelledby="scroll-dialog-title"
            maxWidth={'lg'}
          >
          <DialogTitle id="scroll-dialog-title">
            Edit User
          </DialogTitle>
          <DialogContent dividers={true} >
            <Grid container spacing={5} >
              <Grid item xs={12} md={3} style={{marginBottom:'10px'}}>
                <TextField
                  id="name"
                  required
                  label="Name"
                  fullWidth
                  margin="normal"
                  value={userObj.name}
                  error={error.name ? true:false}
                  helperText={error.name ? error.name : ''}
                  InputLabelProps={{shrink : true}}
                  onChange={(e) => this.onFieldChange(e, 'name', e.target.value)}
                  onFocus={() =>  this.updateErrorField('name', '')}
                />
              </Grid>
              <Grid item xs={12} md={3} style={{marginBottom:'10px'}} >
                <TextField
                  id="email"
                  label="Email"
                  fullWidth
                  disabled
                  margin="normal"
                  value={userData.email}
                  InputLabelProps={{shrink:true}}
                />
              </Grid>
              <Grid item xs={12} md={3} style={{marginBottom:'10px'}}>
                <TextField
                  id="contact_no"
                  required
                  label="Phone"
                  fullWidth
                  margin="normal"
                  value={userObj.contact_no}
                  error={error.contact_no ? true: false}
                  helperText={error.contact_no ? error.contact_no : ''}
                  InputLabelProps={{shrink:true}}
                  onChange={(e) => this.onFieldChange(e, 'contact_no', e.target.value ) }
                  onFocus={() => this.updateErrorField('contact_no', '')}
                />
              </Grid>
              <Grid item xs={12} md={3} style={{marginBottom:'10px'}}>
                <TextField
                  id="alternative_contact_no"
                  required
                  label="Alternate Phone Number"
                  fullWidth
                  margin="normal"
                  value={userObj.alternative_contact_no}
                  error={error.alternative_contact_no ? true: false}
                  helperText={error.alternative_contact_no ? error.alternative_contact_no : ''}
                  InputLabelProps={{shrink:true}}
                  onChange={(e) => this.onFieldChange(e, 'alternative_contact_no', e.target.value ) }
                  onFocus={() => this.updateErrorField('alternative_contact_no', '')}
                />
              </Grid>
              <Grid item xs={12} md={3} style={{marginBottom:'10px'}}>
                <Autocomplete
                  options={departments}
                  getOptionLabel = {option => option.label}
                  id="department"
                  value={departments.find(item => item.value == userObj.department)}
                  onChange={(e,value) => this.onFieldChange(e,'department', value ? value.value : '')}
                  renderInput={params =>
                  <TextField   {...params} label='department' margin="normal" fullWidth InputLabelProps={{shrink: true}}    />}
                />
              </Grid>
              <Grid item xs={12} md={3} style={{marginBottom:'10px'}}>
                <FormControl component="fieldset">
                  <FormLabel className="MuiInputLabel-shrink" component="label">Profile Type</FormLabel>
                  <RadioGroup row aria-label="position" name="same_operator_lessee" defaultValue="top">
                    <FormControlLabel value="userType" checked={userObj.type.value == 1} control={<Radio  onChange={(e) => this.onFieldChange(e, 'type', {value: 1})} color="primary" />} label="Acumen" />
                    <FormControlLabel value="userType" checked={userObj.type.value == 2} control={<Radio  onChange={(e) => this.onFieldChange(e, 'type', {value: 2})} color="primary" />} label="Client" />
                    <FormControlLabel value="userType" checked={userObj.type.value == 3} control={<Radio  onChange={(e) => this.onFieldChange(e, 'type', {value: 3})} color="primary" />} label="Contract" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} style={{marginBottom:'10px'}}>
                <Autocomplete
                  options={categories}
                  getOptionLabel = {option =>  option.label}
                  id="category"
                  value={categories.find(item =>  item.value == userObj.category)}
                  onChange={(e, value) => this.onFieldChange(e,'category', value ? value.value : '')}
                  renderInput={params =>
                  <TextField   {...params} label='Category' margin="normal" fullWidth InputLabelProps={{shrink: true}}    />}
                />
              </Grid>
              <Grid item xs={12} md={3} style={{marginBottom:'30px'}} >
                <TextField
                  id="city"
                  label="City"
                  fullWidth
                  margin="normal"
                  value={userObj.city}
                  InputLabelProps={{shrink:true}}
                  onChange={(e)=> this.onFieldChange(e, 'city', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={3} style={{marginBottom:'30px'}} >
                <Autocomplete
                  id="base_location"
                  options={countries}
                  getOptionLabel = {option => option.label}
                  autoHighlight
                  renderOption={(option) => (
                    <Fragment>
                      {option.label}
                    </Fragment>
                  )}
                  value={countries.find(item => item.label == userObj.base_location)}
                  onChange={(e, value) => this.onFieldChange(e,'base_location', value ? value.label : '')}
                  renderInput={params =>
                  <TextField   {...params} label='Country' margin="normal" fullWidth InputLabelProps={{shrink: true}}    />}
                />
              </Grid>
              {userObj.type.value == 1 || userObj.type.value == 3  ?
                <Fragment>
                  <Grid item xs={12} md={3} style={{marginBottom:'30px'}} >
                    <TextField
                      id="experience"
                      label="Overall Experience"
                      fullWidth
                      margin="normal"
                      value={userObj.experience}
                      InputLabelProps={{shrink:true}}
                      onChange={(e)=> this.onFieldChange(e, 'experience', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{marginBottom:'30px'}} >
                    <TextField
                      id="licenses"
                      label="License"
                      fullWidth
                      multiline
                      margin="normal"
                      value={userObj.licenses}
                      InputLabelProps={{shrink:true}}
                      onChange={(e)=> this.onFieldChange(e, 'licenses', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{marginBottom:'30px'}} >
                    <TextField
                      id="license_issued_by"
                      label="License Issued by"
                      fullWidth
                      multiline
                      margin="normal"
                      value={userObj.license_issued_by}
                      InputLabelProps={{shrink:true}}
                      onChange={(e)=> this.onFieldChange(e, 'license_issued_by', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{marginBottom:'30px'}} >
                    <TextField
                      id="license_endorsements"
                      label="License Type Endorsements"
                      fullWidth
                      multiline
                      margin="normal"
                      value={userObj.license_endorsements}
                      InputLabelProps={{shrink:true}}
                      onChange={(e)=> this.onFieldChange(e, 'license_endorsements', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{marginBottom:'30px'}} >
                    <TextField
                      id="special_approvals"
                      label="Special Approvals"
                      fullWidth
                      multiline
                      margin="normal"
                      value={userObj.special_approvals}
                      InputLabelProps={{shrink:true}}
                      onChange={(e)=> this.onFieldChange(e, 'special_approvals', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{marginBottom:'30px'}} >
                    <TextField
                      id="special_trainings"
                      label="Special Trainings"
                      fullWidth
                      multiline
                      margin="normal"
                      value={userObj.special_trainings}
                      InputLabelProps={{shrink:true}}
                      onChange={(e)=> this.onFieldChange(e, 'special_trainings', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{marginBottom:'10px'}}>
                    <AutocompleteCreatable
                      options={skillSetsList}
                      paramsKey="skill_sets"
                      optionKey="name"
                      label="Skill Sets"
                      multiple={true}
                      value={userObj.skill_sets}
                      onFieldChange={this.onFieldChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{marginBottom:'30px'}} >
                    <TextField
                      id="passport_issued_by"
                      label="Passport Issued by"
                      fullWidth
                      margin="normal"
                      value={userObj.passport_issued_by}
                      InputLabelProps={{shrink:true}}
                      onChange={(e)=> this.onFieldChange(e, 'passport_issued_by', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{marginBottom:'30px'}} >
                    <TextField
                      id="fee_rate"
                      label="Daily Fee Rate"
                      fullWidth
                      margin="normal"
                      value={userObj.fee_rate}
                      InputLabelProps={{shrink:true}}
                      onChange={(e)=> this.onFieldChange(e, 'fee_rate', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{marginBottom:'30px'}} >
                    <TextField
                      id="language_proficiency"
                      label="Language Proficiency"
                      fullWidth
                      margin="normal"
                      value={userObj.language_proficiency}
                      InputLabelProps={{shrink:true}}
                      onChange={(e)=> this.onFieldChange(e, 'language_proficiency', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} style={{marginBottom:'30px'}} >
                    <TextField
                      id="additional_remarks"
                      label="Additional Remarks"
                      fullWidth
                      multiline
                      margin="normal"
                      value={userObj.additional_remarks}
                      InputLabelProps={{shrink:true}}
                      onChange={(e)=> this.onFieldChange(e, 'additional_remarks', e.target.value)}
                    />
                </Grid>
              </Fragment>
               : null
              }

            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() =>  this.setState({ modal:false, userObj:{}})} color="primary" >Cancel</Button>
            <Button onClick={()=>  this.editUser()} variant="contained" color="primary">Save</Button>
          </DialogActions>
        </Dialog> : null
        }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(EditUserModal));
