import React, { Component } from 'react';
import { Grid, TextField, FormLabel, FormControlLabel, Radio, RadioGroup, FormControl, InputAdornment, Tooltip, FormGroup, Switch } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { backendDateFormat, displayDateFormatShort, fieldDateFormat } from '../../../constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isMobile } from 'react-device-detect';
import { SteppedRental } from '../components';
import { LabelValueCard } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import { checkUserAccessPermission } from '../../../utils';
import InfoIcon from '@material-ui/icons/Info';
class AircraftLeaseDetail extends Component {
  render() {
    const { leaseEnCrud, levRentalTypes, onFieldChange, levRentalPaymentTypes, error, updateErrorField, leaseEnCrudId, addRows, removeRows, updateStepRental, updateStepErrorField, elementIndexStep, mntGroups, engineNo, updateEngineNo, } = this.props;
    return (
      <div className="aircraft-lease-detail">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h4 className="title">Aircraft Lease Details</h4>
          </Grid>
          {
            !leaseEnCrudId ?
              <Grid item xs={12} md={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    required
                    margin="normal"
                    id="lease_start_date"
                    label="Lease Start Date"
                    placeholder="DD-MM-YYYY"
                    format={fieldDateFormat}
                    fullWidth
                    disableFuture={true}
                    disabled={!leaseEnCrud.base_value_date || !leaseEnCrud.date_of_manufacture}
                    minDate={moment(leaseEnCrud.date_of_manufacture)}
                    maxDate={leaseEnCrud.base_value_date ? moment(leaseEnCrud.base_value_date) : moment()}
                    InputLabelProps={{ shrink: true }}
                    value={leaseEnCrud.lease_start_date}
                    onChange={(data, value) => onFieldChange(value, 'lease_start_date', data ? moment(data).format(backendDateFormat) : data)}
                    error={error.lease_start_date ? true : false}
                    helperText={((!leaseEnCrud.base_value_date || !leaseEnCrud.date_of_manufacture) && !error.lease_start_date) ? "Please enter Report Date and Date of Manufacture to enable" : error.lease_start_date ? error.lease_start_date : ''}
                    onFocus={() => updateErrorField('lease_start_date', '')}
                    onOpen={() => updateErrorField('lease_start_date', '')}
                  />
                </MuiPickersUtilsProvider>
              </Grid> :
              <LabelValueCard xs={6} md={3} label="Lease Start Date" value={leaseEnCrud.lease_start_date ? moment(leaseEnCrud.lease_start_date).format(displayDateFormatShort) : '--'} />
          }
          {
            !leaseEnCrudId ?
              <Grid item xs={12} md={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    required
                    margin="normal"
                    id="lease_end_date"
                    label="Lease End Date"
                    placeholder="DD-MM-YYYY"
                    format={fieldDateFormat}
                    fullWidth
                    disabled={!leaseEnCrud.base_value_date || !leaseEnCrud.date_of_manufacture}
                    minDate={leaseEnCrud.lease_start_date ? moment(leaseEnCrud.lease_start_date).add(30, 'days') : leaseEnCrud.base_value_date ? moment(leaseEnCrud.base_value_date) : moment(leaseEnCrud.date_of_manufacture)}
                    maxDate={moment(leaseEnCrud.date_of_manufacture).add(9131.25, 'days')}
                    InputLabelProps={{ shrink: true }}
                    value={leaseEnCrud.lease_end_date}
                    onChange={(data, value) => onFieldChange(value, 'lease_end_date', data ? moment(data).format(backendDateFormat) : data)}
                    error={error.lease_end_date ? true : false}
                    helperText={error.lease_end_date ? error.lease_end_date : ''}
                    onFocus={() => updateErrorField('lease_end_date', '')}
                    onOpen={() => updateErrorField('lease_end_date', '')}
                  />
                </MuiPickersUtilsProvider>
              </Grid> :
              <LabelValueCard xs={6} md={3} label="Lease End Date" value={leaseEnCrud.lease_end_date ? moment(leaseEnCrud.lease_end_date).format(displayDateFormatShort) : '--'} />
          }
          {
            !leaseEnCrudId ?
              <Grid item xs={12} md={3}>
                <Autocomplete
                  options={levRentalTypes}
                  getOptionLabel={option => option.label}
                  value={leaseEnCrud.rental_type}
                  id="rental_type"
                  onChange={(e, value) => onFieldChange(e, 'rental_type', value)}
                  renderInput={params => <TextField required {...params} label="Rental Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }}
                    error={error.rental_type ? true : false}
                    helperText={error.rental_type ? error.rental_type : ''}
                    onFocus={() => updateErrorField('rental_type', '')}
                  />
                  }
                />
              </Grid> :
              <LabelValueCard xs={6} md={3} label="Rental Type" value={leaseEnCrud.rental_type ? leaseEnCrud.rental_type.label : '--'} />
          }
          {
            !leaseEnCrudId ?
              <Grid item xs={12} md={3}>
                <FormControl component="fieldset" style={{ marginTop: '16px' }}>
                  <FormLabel style={{ fontSize: '14px' }} >Rental Payment</FormLabel>
                  <RadioGroup aria-label="Rental Payment" required name="Rental Payment" row onChange={(e) => onFieldChange(e, 'rental_payment_type', e.target.value)}>
                    {
                      levRentalPaymentTypes.map(item =>
                        <FormControlLabel value={item.value} control={<Radio checked={leaseEnCrud.rental_payment_type == item.value} color="primary" size="small" />} label={item.label} />
                      )
                    }
                  </RadioGroup>
                </FormControl>
              </Grid> :
              <LabelValueCard xs={6} md={3} label="Rental Payment Type" value={leaseEnCrud.rental_payment_type ? leaseEnCrud.rental_payment_type.label : '--'} />
          }
          <Grid item xs={12}>
            {
              leaseEnCrud.rental_type && leaseEnCrud.rental_type.label == "Stepped" ?
                <>
                  <h4 className="stepped-rental-heading">Rental Step(s)</h4>
                  <SteppedRental
                    addRows={addRows}
                    removeRows={removeRows}
                    leaseEnCrud={leaseEnCrud}
                    updateStepRental={updateStepRental}
                    error={error}
                    updateStepErrorField={updateStepErrorField}
                    elementIndexStep={elementIndexStep}
                  />
                </> : null
            }
          </Grid>
          <Grid item xs={12} md={leaseEnCrud.rental_type && leaseEnCrud.rental_type.label == "Stepped" ? 4 : 6}>
            <div className="aircraft-lease-card">
              <Grid container spacing={isMobile ? 2 : 5}>
                {
                  leaseEnCrud.rental_type && leaseEnCrud.rental_type.label == "Stepped" ? null :
                    <Grid item xs={12} md={6}>
                      <div className="sub-card">
                        {
                          leaseEnCrud.rental_type && leaseEnCrud.rental_type.label == "Stepped" ? null :
                            <TextField
                              required
                              id="rent"
                              label={leaseEnCrud.rental_type && leaseEnCrud.rental_type.label == "Quarterly" ? 'Quarterly Rental' : 'Monthly Rental'}
                              fullWidth
                              margin="normal"
                              InputLabelProps={{ shrink: true }}
                              value={leaseEnCrud.rent}
                              onChange={(e) => { regexConstants.NumberUptoNineDigitsWithDotsUpto2.test(e.target.value) ? onFieldChange(e, 'rent', e.target.value) : e.preventDefault() }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              }}
                              error={error.rent ? true : false}
                              helperText={error.rent ? error.rent : ''}
                              onFocus={() => updateErrorField('rent', '')}
                            />
                        }
                        {
                          leaseEnCrud.rental_type && leaseEnCrud.rental_type.label == "Stepped" ? null :
                            <TextField
                              id="annual_discount"
                              label={
                                <div>
                                  Annual Discount
                                  <Tooltip
                                    title={<div>
                                      <ul className="list-unstyled password-tooltip">
                                        <li>Good: 6.5%</li>
                                        <li>Average: 7.5% </li>
                                        <li>Poor: 8.5%-20% </li>
                                      </ul>
                                    </div>
                                    }>
                                    <InfoIcon fontSize="small" color="primary" style={{ marginLeft: '5px', marginBottom: '-5px' }} />
                                  </Tooltip>
                                </div>
                              }
                              fullWidth
                              margin="normal"
                              InputLabelProps={{ shrink: true }}
                              value={leaseEnCrud.annual_discount}
                              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'annual_discount', e.target.value) : e.preventDefault() }}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                              error={error.annual_discount ? true : false}
                              helperText={error.annual_discount ? error.annual_discount : ''}
                              onFocus={() => updateErrorField('annual_discount', '')}
                            />
                        }
                      </div>
                    </Grid>}
                <Grid item xs={12} md={leaseEnCrud.rental_type && leaseEnCrud.rental_type.label == "Stepped" ? 12 : 6}>
                  <div className="sub-card">
                    {
                      leaseEnCrud.rental_type && leaseEnCrud.rental_type.label == "Stepped" ?
                        <TextField
                          id="annual_discount"
                          label={
                            <div>
                              Annual Discount
                              <Tooltip
                                title={<div>
                                  <ul className="list-unstyled password-tooltip">
                                    <li>Good: 6.5%</li>
                                    <li>Average: 7.5% </li>
                                    <li>Poor: 8.5%-20% </li>
                                  </ul>
                                </div>
                                }>
                                <InfoIcon fontSize="small" color="primary" style={{ marginLeft: '5px', marginBottom: '-5px' }} />
                              </Tooltip>
                            </div>
                          }
                          fullWidth
                          margin="normal"
                          InputLabelProps={{ shrink: true }}
                          value={leaseEnCrud.annual_discount}
                          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'annual_discount', e.target.value) : e.preventDefault() }}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          error={error.annual_discount ? true : false}
                          helperText={error.annual_discount ? error.annual_discount : ''}
                          onFocus={() => updateErrorField('annual_discount', '')}
                        /> : null
                    }
                    <TextField
                      id="markdown_on_residual_value"
                      label={
                        <div>
                          Markdown on Residual Value <sup>*</sup>
                          <Tooltip
                            title="The input value should be between 0 and 100"
                            arrow
                            placement="top"
                          >
                            <InfoIcon fontSize="small" color="primary" style={{ marginLeft: '5px', marginBottom: '-5px' }} />
                          </Tooltip>
                        </div>
                      }
                      fullWidth
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      value={leaseEnCrud.markdown_on_residual_value}
                      onChange={(e) => { regexConstants.NumberWithNegative.test(e.target.value) ? onFieldChange(e, 'markdown_on_residual_value', e.target.value) : e.preventDefault() }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      error={error.markdown_on_residual_value ? true : false}
                      helperText={error.markdown_on_residual_value ? error.markdown_on_residual_value : ''}
                      onFocus={() => updateErrorField('markdown_on_residual_value', '')}
                    />
                    <TextField
                      id="annual_inflation_rate"
                      className="annual-inflation-rate"
                      label={
                        <div>
                          Annual Inflation Rate
                          <Tooltip
                            title="The input value should be between 0% and 5.0%"
                            arrow
                            placement="top"
                          >
                            <InfoIcon fontSize="small" color="primary" style={{ marginLeft: '5px', marginBottom: '-5px' }} />
                          </Tooltip>
                        </div>
                      }
                      fullWidth
                      margin="normal"
                      InputLabelProps={{ shrink: true }}
                      value={leaseEnCrud.annual_inflation_rate}
                      onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 5 ? onFieldChange(e, 'annual_inflation_rate', e.target.value) : e.preventDefault() }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      error={leaseEnCrud.annual_inflation_rate > 3.5 ? true : false}
                      helperText={leaseEnCrud.annual_inflation_rate > 3.5 ? "Generic range exceeded, please verify the value and proceed" : ''}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <>
                { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'lev', 'OV') ?
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="asset_management_charges"
                        label='Asset Management Charges'
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        value={leaseEnCrud.asset_management_charges}
                        onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) && e.target.value <= 100 ? onFieldChange(e, 'asset_management_charges', e.target.value) : e.preventDefault() }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid> : null
                }
                {
                  !leaseEnCrud.id ?
                  <Grid item xs={12} md={6}>
                    <FormControl component="fieldset" style={{marginTop:'16px'}}>
                      <FormLabel style={{fontSize:'14px'}} >Maintenance Condition at Lease End</FormLabel>
                        <RadioGroup aria-label="Maintenance Condition at Lease End" required name="Maintenance Condition at Lease End" row onChange="">
                          <FormControlLabel value={leaseEnCrud.is_full_life_maintenance_condition} control={<Radio checked={!leaseEnCrud.is_full_life_maintenance_condition} color="primary" size="small"/>} onChange={(e) => onFieldChange(e,'is_full_life_maintenance_condition', false ) } label="50%"/>
                          <FormControlLabel value={leaseEnCrud.is_full_life_maintenance_condition} control={<Radio checked={leaseEnCrud.is_full_life_maintenance_condition} color="primary"  size="small" disabled={!leaseEnCrud.aircraft_type_id || !leaseEnCrud.engine_type_id}  />} onChange={(e) => onFieldChange(e, 'is_full_life_maintenance_condition', true) } label="100%" />
                        </RadioGroup>
                    </FormControl>
                  </Grid> :
                  <LabelValueCard xs={12} md={6} value={leaseEnCrud.is_full_life_maintenance_condition ? '100%' : '50%'} label="Maintenance Condition at Lease End" />
                }
                {
                  leaseEnCrud.is_full_life_maintenance_condition ?
                    !leaseEnCrud.id ?
                      <Grid item xs={12} md={6}>
                        <Autocomplete
                          options={mntGroups}
                          getOptionLabel={option => option.label}
                          id="maintenance"
                          value={leaseEnCrud.maintenance_program}
                          onChange={(e, value) => onFieldChange(e, 'maintenance_program', value)}
                          renderInput={params => <TextField required error={error.maintenance_program ? true : false} helperText={error.maintenance_program ? error.maintenance_program : ''} onFocus={() => updateErrorField('maintenance_program', '')} {...params} label="Maintenance Program" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                        />
                      </Grid> :
                      <LabelValueCard xs={12} md={6} value={leaseEnCrud.maintenance_program ? leaseEnCrud.maintenance_program.label : '--'} label={"Maintenance Program"} /> : null
                }

                {
                  leaseEnCrud.is_full_life_maintenance_condition ?
                    !leaseEnCrud.id ?
                      <Grid item xs={12} md={6}>
                        <FormControl component="fieldset">
                          <FormLabel style={{ fontSize: '14px' }} >No of Engines</FormLabel>
                          <RadioGroup aria-label="No of Engines" required name="No of Engines" row onChange="">
                            <FormControlLabel value="" control={<Radio checked={engineNo == 1} color="primary" size="small" disabled={!leaseEnCrud.is_full_life_maintenance_condition} />} onChange={() => updateEngineNo(1)} label="1" />
                            <FormControlLabel value="" control={<Radio checked={engineNo == 2} color="primary" size="small" disabled={!leaseEnCrud.is_full_life_maintenance_condition} />} onChange={() => updateEngineNo(2)} label="2" />
                          </RadioGroup>
                        </FormControl>
                      </Grid> :
                      <LabelValueCard xs={12} md={6} value={leaseEnCrud.no_of_engines ? leaseEnCrud.no_of_engines : ''} label="No of Engine" /> : null
                }

                {!leaseEnCrud.id ?
                  leaseEnCrud.aircraft_type_id && leaseEnCrud.aircraft_type_id.oem == "Bombardier Aerospace" && leaseEnCrud.is_full_life_maintenance_condition ?
                    <Grid item xs={12} md={6}>
                      <FormGroup className="disable-apu-label">
                        <FormControlLabel control={<Switch color="primary" checked={leaseEnCrud.is_apu_disabled ? true : false} onChange={(e) => onFieldChange(e, 'is_apu_disabled', e.target.checked)} size="small" />}
                          label={leaseEnCrud.is_apu_disabled ? 'Disable APU' : 'Enable APU'} />
                      </FormGroup>
                    </Grid> : null
                  : leaseEnCrud.aircraft_type_id && leaseEnCrud.aircraft_type_id.oem == "Bombardier Aerospace" && leaseEnCrud.is_full_life_maintenance_condition ?
                    <LabelValueCard xs={12} md={6} value={leaseEnCrud.is_apu_disabled ? "Enabled" : 'Disabled'} label="APU Status" /> : null
                }
              </>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default AircraftLeaseDetail;
