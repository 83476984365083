import React, { Component, Fragment } from 'react';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { LabelValueCard, AutocompleteCreatable } from '../../../shared_elements';
import { regexConstants } from '../../../constants/regEx';

const AircraftBasicDetail = ({ leaseEnCrud, mods, aircraftTypes, lessee, onFieldChange, engineTypes, error, updateErrorField, leaseEnCrudId, onLesseeFieldChange, newLessee, uploadLogo, applicationPermission }) => {
  return (
    <div className="aircraft-basic-detail">
      <Grid container spacing={3}>
        {leaseEnCrudId ?
          <LabelValueCard xs={6} md={6} label="Report Date" value={leaseEnCrud.base_value_date ? moment(leaseEnCrud.base_value_date).format(displayDateFormatShort) : '--'} />
          : null
        }
        <Grid item xs={12}>
          <h4 className="title">Aircraft Basic Details</h4>
        </Grid>
        {!leaseEnCrudId ?
          <Grid item xs={6} md={3}>
            <TextField
              id="serial_number"
              label='Serial Number'
              fullWidth
              margin="normal"
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={leaseEnCrud.serial_number}
              onChange={(e) => onFieldChange(e, 'serial_number', e.target.value)}
              error={error.serial_number ? true : false}
              helperText={error.serial_number ? error.serial_number : ''}
              onFocus={() => updateErrorField('serial_number', '')}
            />
          </Grid> :
          <LabelValueCard xs={6} sm={6} md={3} label='Serial Number' value={leaseEnCrud.serial_number ? leaseEnCrud.serial_number : '--'} />
        }
        {
          !leaseEnCrudId ?
            <Grid item xs={6} md={3}>
              <TextField
                id="registration_number"
                label='Registration'
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 10 }}
                InputLabelProps={{ shrink: true }}
                value={leaseEnCrud.registration_number}
                onChange={(e) => onFieldChange(e, 'registration_number', e.target.value)}
                error={error.registration_number ? true : false}
                helperText={error.registration_number ? error.registration_number : ''}
                onFocus={() => updateErrorField('registration_number', '')}
              />
            </Grid> :
            <LabelValueCard xs={6} sm={6} md={3} label='Registration Number' value={leaseEnCrud.registration_number ? leaseEnCrud.registration_number : '--'} />
        }
        {
          !leaseEnCrudId ?
            <Grid item xs={12} md={3}>
              <Autocomplete
                options={aircraftTypes}
                getOptionLabel={option => option.name}
                id="mods"
                value={leaseEnCrud.aircraft_type_id}
                onChange={(e, value) => onFieldChange(e, 'aircraft_type_id', value)}
                renderInput={params => <TextField {...params} required label="Aircraft Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }}
                  error={error.aircraft_type_id ? true : false}
                  helperText={error.aircraft_type_id ? error.aircraft_type_id : ''}
                  onFocus={() => updateErrorField('aircraft_type_id', '')}
                />
                }
                filterSelectedOptions={true}
                openOnFocus={true}
                error={error.aircraft_type_id ? true : false}
                helperText={error.aircraft_type_id ? error.aircraft_type_id : ''}
              />
            </Grid>
            :
            <LabelValueCard xs={6} sm={6} md={3} label="Aircraft Type" value={leaseEnCrud.aircraft_type_id ? leaseEnCrud.aircraft_type_id.name : '--'} />
        }
        {
          !leaseEnCrudId ?
            <Grid item xs={12} md={3}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  required
                  margin="normal"
                  id="date_of_manufacture"
                  label="Date of Manufacture"
                  placeholder="DD-MM-YYYY"
                  format={fieldDateFormat}
                  fullWidth
                  disableFuture={true}
                  minDate={leaseEnCrud.base_value_date ? moment(leaseEnCrud.base_value_date).subtract(25, 'years') : moment().subtract(25, 'years')}
                  maxDate={leaseEnCrud.lease_start_date ? moment(leaseEnCrud.lease_start_date) : leaseEnCrud.base_value_date ? moment(leaseEnCrud.base_value_date) : moment()}
                  InputLabelProps={{ shrink: true }}
                  value={leaseEnCrud.date_of_manufacture}
                  onChange={(data, value) => onFieldChange(value, 'date_of_manufacture', data ? moment(data).format(backendDateFormat) : data)}
                  error={error.date_of_manufacture ? true : false}
                  helperText={error.date_of_manufacture ? error.date_of_manufacture : ''}
                  onFocus={() => updateErrorField('date_of_manufacture', '')}
                  onOpen={() => updateErrorField('date_of_manufacture', '')}
                />
              </MuiPickersUtilsProvider>
            </Grid> :
            <LabelValueCard xs={6} md={3} label="Date of Manufacture" value={leaseEnCrud.date_of_manufacture ? moment(leaseEnCrud.date_of_manufacture).format(displayDateFormatShort) : '--'} />
        }
        {
          !leaseEnCrudId ?
            <Grid item xs={12} md={3}>
              <Autocomplete
                options={engineTypes}
                getOptionLabel={option => option.name}
                id="engine_type_id"
                value={leaseEnCrud.engine_type_id}
                onChange={(e, value) => onFieldChange(e, 'engine_type_id', value)}
                renderInput={params => <TextField {...params} required label="Engine Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }}
                  error={error.engine_type_id ? true : false}
                  helperText={error.engine_type_id ? error.engine_type_id : ''}
                  onFocus={() => updateErrorField('engine_type_id', '')}
                />
                }
                filterSelectedOptions={true}
                openOnFocus={true}
              />
            </Grid> :
            <LabelValueCard xs={6} md={3} label="Engine Type" value={leaseEnCrud.engine_type_id ? leaseEnCrud.engine_type_id.name : '--'} />
        }
        {
          !leaseEnCrudId ?
            <Grid item xs={6} md={3}>
              <TextField
                required
                id="mtow"
                label='Max Take Off Weight'
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 10 }}
                InputLabelProps={{ shrink: true }}
                value={leaseEnCrud.mtow}
                onChange={(e) => regexConstants.numberWithDotUpto4.test(e.target.value) ? onFieldChange(e, 'mtow', e.target.value) : e.preventDefault()}
                InputProps={{
                  endAdornment: <InputAdornment position="end">Lbs</InputAdornment>,
                }}
                error={error.mtow_lbs ? true : false}
                helperText={error.mtow_lbs ? error.mtow_lbs : ''}
                onFocus={() => { updateErrorField('mtow_lbs', ''); updateErrorField('mtow_kgs', '') }}
              />
            </Grid> :
            <LabelValueCard xs={6} md={3} label="Max Take Off Weight (lbs)" value={leaseEnCrud.mtow ? parseFloat(leaseEnCrud.mtow).toFixed(2) : '--'} />
        }

        {
          !leaseEnCrudId ?
            <Grid item xs={6} md={3}>
              <TextField
                required
                id="mtow_kgs"
                label='Max Take Off Weight'
                fullWidth
                margin="normal"
                inputProps={{ maxLength: 10 }}
                InputLabelProps={{ shrink: true }}
                value={leaseEnCrud.mtow_kgs}
                onChange={(e) => regexConstants.numberWithDotUpto4.test(e.target.value) ? onFieldChange(e, 'mtow_kgs', e.target.value) : e.preventDefault()}
                InputProps={{
                  endAdornment: <InputAdornment position="end">Kgs</InputAdornment>,
                }}
                error={error.mtow_kgs ? true : false}
                helperText={error.mtow_kgs ? error.mtow_kgs : ''}
                onFocus={() => { updateErrorField('mtow_kgs', ''); updateErrorField('mtow_lbs', '') }}
              />
            </Grid>
            :
            <LabelValueCard xs={6} md={3} label="Max Take Off Weight (kgs)" value={leaseEnCrud.mtow ? parseFloat(leaseEnCrud.mtow * 0.4535970244).toFixed(2) : '--'} />
        }
        {
          !leaseEnCrudId ?
            <Grid item xs={12} md={3}>
              <AutocompleteCreatable
                options={lessee}
                paramsKey='lessee_id'
                optionKey="name"
                label='Operator / Lessee'
                value={leaseEnCrud.lessee_id}
                onFieldChange={onFieldChange}
                error={error.lessee_id ? true : false}
                helperText={error.lessee_id ? error.lessee_id : ''}
                resetErrorKey={() => updateErrorField('lessee_id', '')}
              />
            </Grid> :
            <LabelValueCard xs={6} md={3} label="Operator / Lessee" value={leaseEnCrud.lessee_id ? leaseEnCrud.lessee_id : '--'} />
        }
        <Grid item xs={12} md={3}>
          <Autocomplete
            options={mods}
            getOptionLabel={option => option.name}
            id="mods"
            value={mods.length && leaseEnCrud.mods ? leaseEnCrud.mods : []}
            onChange={(e, value) => onFieldChange(e, 'mods', value)}
            renderInput={params => <TextField {...params} label="Modification / Enhancements" margin="normal" fullWidth InputLabelProps={{ shrink: true }}
              error={error.mods ? true : false}
              helperText={error.mods ? error.mods : ''}
              onFocus={() => updateErrorField('mods', '')}
            />
            }
            multiple={true}
            filterSelectedOptions={true}
            openOnFocus={true}
          />
        </Grid>
         {
          leaseEnCrudId &&
          applicationPermission && applicationPermission.security_groups && applicationPermission.security_groups.filter(group => group.id === 46).length?
            <Grid item md={3} style={{marginTop:'13px'}}>
              <TextField
                label='Assumption'
                multiline
                fullWidth
                onChange={(e) => onFieldChange(e, 'assumptions', e.target.value)}
                InputLabelProps={{ shrink: true }}
                value={leaseEnCrud.assumptions}
              />
            </Grid>
            :
            null
        }
        

       
      </Grid>
      {
        newLessee ?
          <div className="operator-lessee-blk">
            <Grid container spacing={3}>
              <Fragment>
                <Grid item xs={6} md={4}>
                  <TextField
                    id="country"
                    label='Country'
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    value={leaseEnCrud.lessee_id.country}
                    onChange={(e) => onLesseeFieldChange('country', e.target.value)}
                    error={error.country ? true : false}
                    helperText={error.country ? error.country : ''}
                    onFocus={() => updateErrorField('country', '')}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    id="contact_address"
                    label='Contact Address'
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    value={leaseEnCrud.lessee_id.contact_address}
                    onChange={(e) => onLesseeFieldChange('contact_address', e.target.value)}
                    error={error.contact_address ? true : false}
                    helperText={error.contact_address ? true : false}
                    onFocus={() => updateErrorField('mods', '')}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <p style={{ color: error.logo ? "#f44335" : ' rgba(0, 0, 0, 0.54)', fontSize: '1rem', marginTop: '12px' }}>
                    Upload logo
                  </p>
                  <span style={{ position: 'relative' }}>
                    <CloudUploadIcon color={error.logo ? "secondary" : "primary"} style={{ marginTop: '10px', cursor: 'pointer' }} />
                    <input type="file" accept="image/*" onChange={(e) => uploadLogo(e.target.files[0])} style={{ position: 'absolute', left: '0px', opacity: '0', top: '-5px', cursor: 'pointer' }} />
                    <span style={{ fontSize: '10px', position: 'absolute', top: '-10px', marginLeft: '10px' }} >{leaseEnCrud && leaseEnCrud.lessee_id && leaseEnCrud.lessee_id.logo ? `(${leaseEnCrud.lessee_id.logo})` : ''}</span>
                  </span>
                </Grid>
              </Fragment>
            </Grid>
          </div> : null
      }
    </div>
  )
}

export default AircraftBasicDetail;
