import { regexConstants } from '../constants/regEx';
export const errorCode = {
  old_password:{
    0:'',
    1:'Please enter Password',
    4:'Password does not meet the policy requirements'
  },
  old_passwordObj:{
    required:true,
    regexPattern:regexConstants.password
  },
  password:{
    0:'',
    1:'Please enter Password',
    4:'Password does not meet the policy requirements'
  },
  passwordObj:{
    required:true,
    regexPattern:regexConstants.password
  },
  confirm_password:{
    0:'',
    1:'Please enter Password',
    4:'Password does not meet the policy requirements'
  },
  confirm_passwordObj:{
    required:true,
    regexPattern:regexConstants.password
  },

}
