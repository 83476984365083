import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { Grid, Typography, List, ListItem, Button, Link, Input, Box, Dialog, DialogActions, DialogContent, DialogTitle, Card } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { DropzoneDialog } from 'material-ui-dropzone';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import { LabelValueCard, DeletePopUp } from '../../../shared_elements';
import { getUserProfileApi, editUserProfileApi } from '../apiServices';
import { EditUserProfileModal } from '../components';
import { isMobile } from 'react-device-detect';
import { checkApiStatus } from '../../../utils';
class UserProfile extends Component {
  constructor(props){
    super(props);
    this.state = {
      userInfo:{},
      skeletonLoader:false,
      deleteModal:false,
      openDropZoneAcumenResume:false,
      openDropZoneOriginalResume:false,
      imageFile:'',
      profilepicFlag:false
    }
    this.getUserProfileApi = getUserProfileApi.bind(this)
    this.editUserProfileApi = editUserProfileApi.bind(this)
  }
  componentDidMount(){
    this.getUserProfileApi()
  }
  updateImage = (file) => {
    if(file.size >5120000) {
      this.props.enqueueSnackbar('Please upload a picture smaller than 5mb ', { variant:'error', anchorOrigin:{vertical:'top', horizontal:'right'}})
    }else {
      this.setState({
        imageFile:file,
        profilepicFlag:true
      })
      setTimeout(()=>{
        this.uploadImage()
      }, 1000)
    }
  }
  uploadImage = () => {
    const { imageFile, profilepicFlag, originalResumeFlag, acumenResumeFlag, originalResume, acumenResume } = this.state
    let formData = new FormData()
    if(profilepicFlag){
      formData.append('profile_pic', imageFile)
    }
    this.editUserProfileApi(formData)
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar('Uploaded profile picture successfully', { variant:'success', anchorOrigin:{ vertical:'top', horizontal:'right'}})
        this.getUserProfileApi()
      }else {
        this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{ vertical:'top', horizontal:'right'}})
      }
    })
  }
  render(){
    const { userInfo, skeletonLoader, deleteModal, openDropZoneAcumenResume, openDropZoneOriginalResume } = this.state
    return(
      <div className="user-management-module-section">
        <Card>
          <div className="user-detail">
            <div  className="section-1 flex-centered" style={{borderBottom:'1px solid #dbdbdb'}}>
              <Grid container spacing={0}>
                <Grid item md={2} xs={4} style={{position:'relative'}}>
                  { skeletonLoader ? <Skeleton variant="circle" width={110}  height={110} style={{position:'relative',top:'23px', left:'35px' }}/>
                      : userInfo.profile_pic ?
                        <div alt={userInfo.name} className="profile-pic" style={{backgroundImage: "url(" + userInfo.profile_pic + ")"}} >
                          <label htmlFor={`contained-button-file`} className="edit-profile-pic" ><Link style={{textDecoration:'none', cursor:'pointer'}}><EditIcon  /></Link></label>
                            <input
                            accept="image/*"
                            id={`contained-button-file`}
                            type="file"
                            style={{display:'none'}}
                            onChange={(e) => this.updateImage(e.target.files[0])}
                            />
                        </div> :
                        <div>
                         <Avatar alt={userInfo.name}  src="we" className="profile-pic"  />
                         <label htmlFor={`contained-button-file`} style={{position:'absolute', bottom:'-24px',left:'52px', width:'80px'}}   ><Link style={{textDecoration:'none', cursor:'pointer', fontSize:'12px'}}>Upload image</Link></label>
                           <input
                           accept="image/*"
                           id={`contained-button-file`}
                           type="file"
                           style={{display:'none'}}
                           onChange={(e) => this.updateImage(e.target.files[0])}
                           />
                       </div>
                  }

                </Grid>
                <Grid item md={2} xs={6}>
                  <div style={{marginTop: isMobile ? '32px':'37px', position:'relative', right:isMobile ? '0px':'22px', wordBreak:isMobile?'break-all':'normal'}}>
                    <h4 style={{marginBottom:'10px'}}>{userInfo.name ? userInfo.name : ''} </h4>
                    {userInfo .contact_no && <h5>{userInfo.contact_no}</h5> }
                    <h5>{userInfo.email ? userInfo.email :''}</h5>
                  </div>
                </Grid>
                <Grid item xs={5} className="resume" style={{display:'none'}}>
                </Grid>

                <Grid item md={3} xs={2}>
                  <div className="btn-user-detail">
                    {  Object.keys(userInfo).length ?
                      <EditUserProfileModal userData={userInfo} getResponseBack={() => this.getUserProfileApi()} />: null
                    }
                  </div>
                  <div  className={userInfo.is_new_user ? 'resumeNew' : 'resumeOld'} >
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="section-2">
              <Grid container spacing={isMobile?3:6} className="section-2-list"  direction="row" >
                  <LabelValueCard label="Alternate Phone" md={3} value={userInfo.alternative_contact_no ? userInfo.alternative_contact_no : '--'} style={{alignItems:'center'}} skeletonLoader={skeletonLoader} />
                  <LabelValueCard label="City" md={3} value={userInfo.city ? userInfo.city : '--'} skeletonLoader={skeletonLoader} />
                  <LabelValueCard label="Country" md={3} value={userInfo.base_location ? userInfo.base_location : '--'} skeletonLoader={skeletonLoader} />
              </Grid>
            </div>
          </div>
        </Card>
      </div>
    )
  }
}
export default withSnackbar(UserProfile);
