import React from 'react';
import { withRouter } from 'react-router-dom';
import { TableRow, TableCell } from '@material-ui/core';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat, checkUserAccessPermission } from '../../../utils';
import { displayDateFormatShort } from '../../../constants';
import moment from 'moment';
const LevList = ({item, applicationPermission}) => {
  console.log('item', item);
  return(
    <TableRow hover tabIndex={-1}>
        <TableCell>{item.date ? moment(item.date).format(displayDateFormatShort) : '--'}</TableCell>
        <TableCell>{item.payment_number ? item.payment_number : '--'}</TableCell>
        <TableCell><NumericalLabel params={getCurrencyFormat({shortFormat:false, precision:2})}>{item.rent}</NumericalLabel></TableCell>
        {  applicationPermission && applicationPermission.security_groups && applicationPermission.security_groups.filter(group => group.id === 46).length ?
          <TableCell><NumericalLabel params={getCurrencyFormat({shortFormat:false, precision:2})}>{item.asset_management_expenses}</NumericalLabel></TableCell> : null
        }
        {  applicationPermission && applicationPermission.security_groups && applicationPermission.security_groups.filter(group => group.id === 46).length ?
          <TableCell>{item.pvf ? item.pvf.toFixed(6) : '--'}</TableCell> : null
        }
        <TableCell><NumericalLabel params={getCurrencyFormat({shortFormat:false, precision:2})}>{item.pv_of_rent}</NumericalLabel></TableCell>
    </TableRow>
  )
}
export default withRouter(LevList);
