import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
function MntSideNav(props){
  return(
    <div className="">
      <ul className="list-unstyled">
        {[
            {label: 'Claims', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/maintenance/claims`},
        ].map((item, index) =>
          <li key={index}>
            <NavLink to={item.path}>{item.label}</NavLink>
          </li>
        )}
      </ul>
      <div>
        <h4>Major Assemblies</h4>
        <ul className="list-unstyled">
          { props.match.params.assetType == 'aircraft' ?
            [
                {label: 'Airframe', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/maintenance/airframe`},
                {label: 'APU', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/maintenance/apu`},
                {label: 'Landing Gears', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/maintenance/landing-gears`},
                {label: 'Engines', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/maintenance/engines`}
            ].map((item, index) =>
              <li key={index}>
                <NavLink activeClassName="active" to={item.path}>{item.label}</NavLink>
              </li>
            ) :
            [{label: 'Engines', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/maintenance/engines`}].map((item, index) =>
              <li key={index}>
                <NavLink activeClassName="active" to={item.path}>{item.label}</NavLink>
              </li>
            )
          }

        </ul>
      </div>
    </div>
  )
}

export default withRouter(MntSideNav)
