import React, { Component } from 'react'
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { TableCell, TableRow} from '@material-ui/core'
import Rating from '@material-ui/lab/Rating';
import { updateUserSkillRatingsApi } from '../apiServices';
import { checkApiStatus } from '../../../utils';
class  SkillExperienceList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      competency_rating:[],
      ratingBlur:[]
    }
    this.updateUserSkillRatingsApi = updateUserSkillRatingsApi.bind(this)
  }
  componentDidMount(){
    this.setState({
      competency_rating:this.props.rating
    })
  }
  updateRating = (event, newValue, id) => {
    this.setState(prevState => ({
      ...prevState,
      competency_rating:prevState.competency_rating.filter(item => item.skill_id == id).length ?
        prevState.competency_rating.map(item =>
        item.skill_id == id ?
        {...item, skill_rate:newValue} : item) :
        [...prevState.competency_rating,{ skill_id:id, skill_rate:newValue }]
    }))
  }
  updateRatingOnBlur = (id) =>{
    this.setState({
      ratingBlur:this.state.competency_rating
    })

  }
  render(){
    const { constant, index, updateRating, updateRatingOnBlur, competencyRating } = this.props
    const { competency_rating, ratingBlur } = this.state
    return(
      <TableRow>
        <TableCell>{index+ 1}</TableCell>
        <TableCell>{constant.label}</TableCell>
        <TableCell>
          {  competencyRating && competencyRating.filter(item => item.skill_id == constant.value).length ? competencyRating.map(item => item.skill_id == constant.value ?
            <Rating
              name={`simple-controlled-${constant.value}`}
              value={item.skill_rate}
              onChange={(e, newValue)=> updateRating(e, newValue, constant.value)}
              onBlur={() => updateRatingOnBlur() }
            />: null ):
            <Rating
             name={`simple-controlled-${constant.value}`}
             value={0}
             onChange={(e, newValue)=> updateRating(e, newValue, constant.value) }
             onBlur={() => updateRatingOnBlur() }
           />
          }
           </TableCell>
      </TableRow>
    )
  }

}

export default withSnackbar(withRouter(SkillExperienceList));
