import React, { Component, Fragment} from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button , Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import { fieldDateFormat, backendDateFormat, displayDateFormat } from '../../../constants';
import { availabilityTrackerCru, availabilityStatus, errorCodeAvailablityTracker } from '..';
import { updateUserStatusApi } from '../apiServices';
import { fieldValidation } from '../../../utils/formValidation';
import { checkApiStatus } from '../../../utils';
import MomentUtils from '@date-io/moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
class AddEditAvailabilityTracker extends Component{
  constructor(props) {
    super(props)
    this.state = {
      modal:false,
      error:{},
      availabilityTrackerObj:availabilityTrackerCru
    }
    this.updateUserStatusApi = updateUserStatusApi.bind(this)
  }
  updateErrorField = () => {
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      availabilityTrackerObj:{
        ...prevState.availabilityTrackerObj,
        [keyParam]:data
      }
    }))
  }
  addEditAvailabilityTracker = () => {
    const { availabilityTrackerObj } = this.state
    let validationInputs = {
      status:errorCodeAvailablityTracker['status'][fieldValidation({...errorCodeAvailablityTracker['statusObj'], fieldval:availabilityTrackerObj.status})],
      from_date:errorCodeAvailablityTracker['from_date'][fieldValidation({...errorCodeAvailablityTracker['from_dateObj'], fieldval:availabilityTrackerObj.from_date})],
      to_date:errorCodeAvailablityTracker['to_date'][fieldValidation({...errorCodeAvailablityTracker['to_dateObj'], fieldval:availabilityTrackerObj.to_date})],
      remarks:errorCodeAvailablityTracker['remarks'][fieldValidation({...errorCodeAvailablityTracker['remarksObj'], fieldval:availabilityTrackerObj.remarks})]
    }
    if(Object.keys(validationInputs).every((k)=> {return validationInputs[k] ===''})){
      this.updateUserStatusApi(this.props, availabilityTrackerObj )
      .then(response => {
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{ vertical:'top', horizontal:'right'}})
          this.setState({ modal:false, availabilityTrackerObj:{}})
          this.props.getResponseBack()
        }else {
          this.props.enqueueSnackbar(response.data.message, { variant:'error',anchorOrigin:{ vertical:'top', horizontal:'right'}})
        }
      })
    }else {
      this.setState({
        error:validationInputs
      })
    }
  }
  render(){
    const { modal, error, availabilityTrackerObj } = this.state
    const { id, mode, availabilityTrackerData, status } = this.props
    return(
      <Fragment>
        { id ? <EditIcon color='primary' onClick={()=>this.setState({ modal:true, availabilityTrackerObj:{...availabilityTrackerData, status}})} fontSize="small" className="cta-mouse" /> :
          <Button color="primary" variant="contained" className="buttons-cta" onClick={()=> this.setState({modal:true, availabilityTrackerObj:availabilityTrackerCru })}>Add Availability Status</Button>
        }
      { modal ?
        <Dialog
          open={modal}
          onClose={()=> this.setState({ modal: false})}
          aria-labelledby='scroll-dialog-title'
          >
        <DialogTitle>
          {mode} Availability Status
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
            <Autocomplete
              options = {availabilityStatus}
              getOptionLabel={option => option.label }
              id="status"
              value={availabilityStatus.find(item => item.status == availabilityTrackerObj.status )}
              onChange={(e, value) => this.onFieldChange(e, 'status', value.status)}
              renderInput={params =>
                <TextField  required {...params} label='Status' margin="normal" fullWidth InputLabelProps={{shrink: true}} error={error.status ?true:false } helperText={error.status ? error.status : ''} onFocus={()=>this.updateErrorField('status', '')}  />}

            />
            </Grid>
            <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 required
                 margin="normal"
                 id="start_date"
                 label='From Date'
                 format={fieldDateFormat}
                 fullWidth
                 InputLabelProps={{shrink: true}}
                 value={availabilityTrackerObj.from_date}
                 onChange={(data, value) => this.onFieldChange(value, 'from_date', moment(data).format(backendDateFormat))}
                 KeyboardButtonProps={{
                   'aria-label': 'Start Date',
                 }}
                 onFocus={()=>this.updateErrorField('from_date', '')}
                 error={error.from_date ? true:false}
                 helperText={error.from_date ? error.from_date : ''}
                 maxDate={moment(availabilityTrackerObj.to_date)}
               />
            </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 required
                 margin="normal"
                 id="end_date"
                 label='To Date'
                 format={fieldDateFormat}
                 fullWidth
                 InputLabelProps={{shrink: true}}
                 value={availabilityTrackerObj.to_date}
                 onChange={(data, value) => this.onFieldChange(value, 'to_date', moment(data).format(backendDateFormat))}
                 KeyboardButtonProps={{
                   'aria-label': 'Start Date',
                 }}
                 onFocus={()=>this.updateErrorField('to_date', '')}
                 error={error.to_date ? true:false}
                 helperText={error.to_date ? error.to_date : ''}
                 minDate={moment(availabilityTrackerObj.from_date)}
               />
            </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="remarks"
                label="Remarks"
                fullWidth
                multiline
                rows="4"
                margin="normal"
                value={availabilityTrackerObj.remarks}
                onChange={(e) => this.onFieldChange(e, 'remarks', e.target.value)}
                InputLabelProps={{shrink: true}}
                error={error.remarks ? true : false}
                helperText={error.remarks ? error.remarks : ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={()=> this.setState({modal:false})}>Cancel</Button>
          <Button color='primary' variant="contained" onClick={()=> this.addEditAvailabilityTracker()}>Save</Button>
        </DialogActions>
        </Dialog>: null
      }

      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(AddEditAvailabilityTracker));
