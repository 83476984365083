import mixpanel from 'mixpanel-browser';
import { getGlobalCookie } from './'
import config from '../config';
mixpanel.init(
    config.mixpanel.key, {
      debug: true,
      loaded: function(mixpanel){
            console.log('mixpanel', mixpanel)
      }
  },
);

export const trackLogin = (user, callback) => {
    trackUser(user, () => mixpanel.track('Selected Lessor', {
        lessor_name: user.lessor && user.lessor.name?user.lessor.name:''
    }, callback()))
}

export const trackLogOut = (user) => {
    trackUser(user, () => {
        mixpanel.track('Log Out')
    })
}

export const trackUser = (user, callback) => {
    if (user !== undefined && user !== null) {
        mixpanel.people.set({
            name: user.user.name,
            user_id: user.user.id,
            lessor_name: user.lessor.name,
            lessor_id: user.lessor.id,
            user_type: user.user.account_type
        });
        mixpanel.identify(user.user.id)
        callback()
    }
}

export const trackActivity = (activityName, metadata) => {
    trackUser(getGlobalCookie(config.cookiesNaming.key), () => {
        mixpanel.track(
            activityName,
            {
                ...metadata,
                application_module: 'fin-S',
                lessor_name: getGlobalCookie(config.cookiesNaming.key).lessor && getGlobalCookie(config.cookiesNaming.key).lessor.name ? getGlobalCookie(config.cookiesNaming.key).lessor.name : '',
                name: getGlobalCookie(config.cookiesNaming.key).user.name,
                user_type: getGlobalCookie(config.cookiesNaming.key).user.account_type
            }
        )
    })
}
