import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import {  withRouter } from 'react-router-dom';
import { TableListComp, EmptyCollection, DeletePopUp } from '../../../shared_elements';
import ExperienceList from './ExperienceList';
import AddEditExperience from './AddEditExperience';
import { checkApiStatus } from '../../../utils';
import { deleteExperienceApi } from '../apiServices';
class Experience extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deleteModal:false,
      deleteId:''
    }
    this.deleteExperienceApi = deleteExperienceApi.bind(this)
  }
  deleteExperienceFn = (id) => {
    this.setState(prevState => ({
      deleteModal:!prevState.deleteModal,
      deleteId:id
    }))
  }
  deleteExperienceType = () => {
    const { deleteId } = this.state
    this.deleteExperienceApi(this.props, deleteId)
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message,{ variant:'success', anchorOrigin:{vertical:'top', horizontal:'right'}})
        this.setState({deleteModal:false, deleteId:''})
        this.props.getResponseBack();
      }else {
        this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{vertical:'top', horizontal:'right'}})
      }
    })
  }
  render() {
    const { deleteModal } = this.state
    const { experiences, getResponseBack } = this.props
    return (
      <Fragment>
        <AddEditExperience  getResponseBack={getResponseBack} />
        <TableListComp
          heads={[
            {label:'Aircraft Type', sortOption:false},
            {label:'No. of Years', sortOption:false},
            {label:'Notes', sortOption:false},
            {label:'', sortOption:false}
          ]}
          data={experiences.length ?  experiences.map((experience, index) =>
                <ExperienceList
                  item={experience}
                  key={index}
                  getResponseBack={getResponseBack}
                  deleteExperience={(id) => this.deleteExperienceFn(id)}
                />
          ) : null }
          noRecord={experiences.length ? null : <EmptyCollection title="No Experience added" />}
        />
      <DeletePopUp
        modal={deleteModal}
        toggleModalFn={(id) => this.deleteExperienceFn(id)}
        title="Delete Experience"
        deleteRecordFn={() => this.deleteExperienceType()}
        content={<h4>Are you sure you want to delete</h4>}
      />
      </Fragment>

    )
  }
}
export default withSnackbar(withRouter(Experience));
