import React, { Component } from 'react';
import { Drawer, Grid, Container, Avatar, Paper, TextField, Link, Tooltip, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import SettingsPowerIcon from '@material-ui/icons/SettingsPower';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import HeaderNotifyMenu from './HeaderNotifyMenu';
import MainNavLink from './MainNavLink';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import FAQsList from './FAQsList';
import WhatsNewCompleteList from './WhatsNewCompleteList';
import ReportAnIssue from './ReportAnIssue';
import { globalGetService } from '../../utils/globalApiServices';
import { getGlobalCookie, clearCookie, checkApiStatus } from '../../utils';
import { getWhatsNewFeaturesListApi, getFaqsListApi } from '../apiServices';
import { lessorUpdateApi } from '../../application/auth/apiServices'
import Cookies from 'universal-cookie';
import config from '../../config';
const cookies = new Cookies();
class MobileHeader extends Component {
  constructor(props){
    super(props);
    this.state = {
      left:false,
      whtsNewModal:false,
      faqsModal:false,
      whtsNewList:[],
      faqsList:[],
    }
    this.getWhatsNewFeaturesListApi = getWhatsNewFeaturesListApi.bind(this);
    this.getFaqsListApi = getFaqsListApi.bind(this);
    this.lessorUpdateApi = lessorUpdateApi.bind(this);
  }
  logout = () => {
    // cookies.remove(config.cookiesNaming.key , {path: "/", domain: "sparta.aero"});
    cookies.remove(config.cookiesNaming.key);
    this.props.history.push('/login');
  }
  render(){
    const { left, whtsNewModal, faqsModal, whtsNewList,faqsList} = this.state
    return (
      <div>
        <header className="mobile-header">
          <Container maxWidth={false}  style={{padding:'0px 15px'}}>
            <Grid container  spacing={0} alignItems="center" justify="center">
              <Grid item xs={12}>
                <MenuIcon
                  color="primary"
                  onClick={() => this.setState({left:true})}
                />
              <Link onClick={() => this.props.history.push('/logs')}>
                  <img src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/sparta_logo.png" alt="Sparta" style={{width:'35px', paddingLeft:'10px'}} />
                </Link>
                <ul className="list-inline">
                  <li style={{display: 'none'}} className="list-inline-item">
                    <Tooltip title="Report an Issue" arrow>
                      <IconButton size="small" color="primary">
                        <HeadsetMicIcon />
                      </IconButton>
                    </Tooltip>
                  </li>
                  <li className="list-inline-item">
                    <Tooltip title="FAQs" arrow>
                      <IconButton  size="small" color="primary">
                        <QuestionAnswerIcon color="primary" fontSize="small" onClick={() => this.getFaqsListApi(this.props)} />
                      </IconButton>
                    </Tooltip>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Container>
        </header>
        <Drawer open={left} onClose={() =>  this.setState({left:false})}>
          <div className="mobile-main-navs" style={{width: '280px'}}>
            <Paper className="user-info">
              <div className="user-card">
                {
                  getGlobalCookie(config.cookiesNaming.key) && getGlobalCookie(config.cookiesNaming.key).user && getGlobalCookie(config.cookiesNaming.key).user.profile_pic ?
                  <Avatar className="user-avatar" alt={getGlobalCookie(config.cookiesNaming.key).user.name} src={getGlobalCookie(config.cookiesNaming.key).user.profile_pic} /> : null
                }

                <div>
                  <h3>
                    {getGlobalCookie(config.cookiesNaming.key) && getGlobalCookie(config.cookiesNaming.key).user && getGlobalCookie(config.cookiesNaming.key).user.name ? getGlobalCookie(config.cookiesNaming.key).user.name  : '' }
                    <span onClick={this.logout}><SettingsPowerIcon color="primary" /></span>
                  </h3>
                  <span>{getGlobalCookie(config.cookiesNaming.key) && getGlobalCookie(config.cookiesNaming.key).user &&  getGlobalCookie(config.cookiesNaming.key).user.designation?getGlobalCookie(config.cookiesNaming.key).user.designation:''}</span>
                </div>
              </div>
            </Paper>
            <Paper className="mobile-py-link" elevation={0}>
              <MainNavLink type="mobile" applicationPermission={this.props.applicationPermission} />
            </Paper>
          </div>
        </Drawer>
        { whtsNewModal ?
          <WhatsNewCompleteList toggleModalFn={() => this.setState({whtsNewModal:false})} modal={whtsNewModal} whtsNewList={whtsNewList} />:null
        }
        { faqsModal ?
          <FAQsList medialLink={this.state.medialLink} toggleModalFn={() => this.setState({faqsModal:false})} modal={faqsModal} faqsList={faqsList} />:null
        }
        <ReportAnIssue type="foot" />
        <div style={{height:'50px'}}></div>
      </div>
    );
  }

}
export default withRouter(MobileHeader);
