import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Grid, Breadcrumbs, Typography, Button, Chip, Tooltip, TextField, Paper } from '@material-ui/core';
import { ExportManu, LabelValueCard, TableListComp, EmptyCollection, PageLoader } from '../../../shared_elements';
import { ValueCard, LEVList } from '../components';
import { globalPutService, } from '../../../utils/globalApiServices';
import { LEVListHd, LEVListPerHd } from '../';
import { checkApiStatus } from '../../../utils';
import { displayDateFormatShort } from '../../../constants';
import moment from 'moment';
import { getLevOutputDataApi, exportResultApi, updateLockApi, updateAssumptionApi } from '../apiServices';
import { checkUserAccessPermission, getCurrencyFormat } from '../../../utils';
import NumericalLabel from 'react-pretty-numbers';
import { trackActivity } from '../../../utils/mixpanel';
import lockIcon from '../../../assets/images/NewLockIcon.png';
import unlockIcon from '../../../assets/images/unlockicon.png';
import { Edit, Check, Clear } from '@material-ui/icons';

class LeaseEncumberedValue extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editAsumpFlag: false,
      levOutputData: {},
      exportLoader: false,
      pageLoader: false
    }
    this.getLevOutputDataApi = getLevOutputDataApi.bind(this);
    this.exportResultApi = exportResultApi.bind(this);
    this.updateLockApi = updateLockApi.bind(this)
    this.updateAssumptionApi = updateAssumptionApi.bind(this)
  }
  componentDidMount() {
    this.getLevOutputDataApi(this.props);
    trackActivity('Lease Encumbered Value', {
      event_type: 'Page Visited',
      page_title: 'Output',
      log_id: this.props.match.params.id
    })
  }
  updateAssumption(key, value) {
    const { levOutputData } = this.state
    if (key === 'assumptions') {
      this.setState(prevstate => ({
        ...prevstate,
        levOutputData: {
          ...prevstate.levOutputData,
          generic: {
            ...prevstate.levOutputData.generic,
            [key]: value
          }
        }
      }))
    }
  }
  render() {
    const { levOutputData, exportLoader, pageLoader } = this.state;
    const { applicationPermission } = this.props
    let a = moment(levOutputData && levOutputData.lev && levOutputData.lev.lease_end_date);
    let b = moment(levOutputData && levOutputData.lev && levOutputData.lev.lease_start_date);
    let actual_lease_term = (a.diff(b, 'days') / 30.4375);

    let x = moment(levOutputData && levOutputData.generic && levOutputData.generic.base_value_date);
    let y = moment(levOutputData && levOutputData.lev && levOutputData.lev.lease_start_date);
    let lease_term_remaining = actual_lease_term - ((x.diff(y, 'days') / 30.4375));
    let isLocked = levOutputData.generic && levOutputData.generic.lock_status;
    return (

      <section className="lev-output-section">
        {exportLoader || pageLoader ? <PageLoader /> : null}
        <div className="lev-output-breadcrumb">
          <Grid container spacing={0}>
            <Grid xs={9} md={9}>
              <Breadcrumbs aria-label="breadcrumb" >
                <Link color="inherit" to="/logs">
                  <Typography className="flex-centered text-underline" component="h1">
                    Logs
                  </Typography>
                </Link>
                {
                  checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'base_value', 'C') ||
                    checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'future_value', 'C') ||
                    checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'C') ||
                    checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'lev', 'C') ?
                    <Link color="inherit" to="/create-log" >
                      <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline' }}>
                        Asset Value Options
                      </Typography>
                    </Link> : null
                }
                <Typography className="flex-centered" component="h1">
                  {this.props.match.params.id}
                </Typography>
              </Breadcrumbs>
            </Grid>
            {checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'lev', 'EXP_PDF') && checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'lev', 'EXP_XLS') ?
              <Grid item xs={3} md={3} >
                <ul className="list-inline" style={{ float: 'right' }}>
                  <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                    {levOutputData.generic && levOutputData.generic.lock_status ?
                      <Tooltip title="Click to resume editing." placement="top" arrow>
                        <img src={lockIcon} width="35" onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: false })} alt="Icon" />
                      </Tooltip>
                      :
                      <Tooltip title="Click to lock editing." placement="top" arrow>
                        <img src={unlockIcon} width="35" onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: true })} alt="Icon" />
                      </Tooltip>
                    }
                  </li>
                  <li className="list-inline-item">
                    <div>
                      <ExportManu variant="contained" title="Export" files={[{ title: 'PDF', extension: 'pdf' }, { title: 'Excel', extension: 'xls' }]} exportReportFn={(file) => this.exportResultApi(this.props, file)} />
                    </div>
                  </li>
                </ul>
              </Grid> :
              checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'lev', 'EXP_PDF') ?
                <Grid item xs={3} md={3} >
                  <ul className="list-inline" style={{ float: 'right' }}>
                    <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                      {
                        levOutputData.generic && levOutputData.generic.lock_status ?
                          <Tooltip title="Click to resume editing." placement="top" arrow>
                            <img src={lockIcon} width="35" onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: false })} alt="Icon" />
                          </Tooltip>
                          :
                          <Tooltip title="Click to lock editing." placement="top" arrow>
                            <img src={unlockIcon} width="35" onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: true })} alt="Icon" />
                          </Tooltip>
                      }
                    </li>
                    <li className="list-inline">
                      <div >
                        <ExportManu variant="contained" title="Export" files={[{ title: 'PDF', extension: 'pdf' }, { title: 'Excel', extension: 'xls' }]} exportReportFn={(file) => this.exportResultApi(this.props, file)} />
                      </div>
                    </li>
                  </ul>
                </Grid> :
                <ul className="list-inline">
                  <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                    {checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'U') ?
                      levOutputData.generic && levOutputData.generic.lock_status ?
                        <Tooltip title="Click to resume editing." placement="top" arrow>
                          <img src={lockIcon} width="35" onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: false })} alt="Icon" />
                        </Tooltip>
                        :
                        <Tooltip title="Click to lock editing." placement="top" arrow>
                          <img src={unlockIcon} width="35" onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: true })} alt="Icon" />
                        </Tooltip>
                      : null
                    }
                  </li>
                </ul>
            }
          </Grid>
        </div>
        <div className="output-view-blk">
          <Grid container spacing={3}>
            <LabelValueCard xs={6} sm={6} md={3} label='Serial Number' value={levOutputData && levOutputData.generic && levOutputData.generic.serial_number ? levOutputData.generic.serial_number : '--'} />
            <LabelValueCard xs={6} sm={6} md={3} label='Aircraft Type' value={levOutputData && levOutputData.generic && levOutputData.generic.aircraft_type ? levOutputData.generic.aircraft_type.name : '--'} />
            <LabelValueCard xs={6} sm={6} md={3} label='Registration' value={levOutputData && levOutputData.lev && levOutputData.lev.registration_number ? levOutputData.lev.registration_number : '--'} />
            <LabelValueCard xs={6} sm={6} md={3} label='Date of Manufacture' value={levOutputData && levOutputData.generic ? moment(levOutputData.generic.date_of_manufacture).format(displayDateFormatShort) : '--'} />
            <LabelValueCard xs={6} sm={6} md={3} label='Max Take Off Weight' value={levOutputData && levOutputData.generic ? `${levOutputData.generic.mtow.toFixed(2)} Lbs` : '--'} />
            <LabelValueCard xs={6} sm={6} md={3} label='Max Take Off Weight' value={levOutputData && levOutputData.generic ? `${(levOutputData.generic.mtow * 0.4535970244).toFixed(2)} Kgs` : '--'} />
            <LabelValueCard xs={6} sm={6} md={3} label='Engine Type' value={levOutputData && levOutputData.generic ? levOutputData.generic.engine_type.name : '--'} />
            <LabelValueCard xs={6} sm={6} md={3} label='Operator / Lessee' value={levOutputData && levOutputData.generic && levOutputData.generic.lessee ? levOutputData.generic.lessee : '--'} />
            {levOutputData && levOutputData.generic && levOutputData.generic.mods && levOutputData.generic.mods.length ?
              <LabelValueCard xs={6} md={3} label='Modifications / Enhancements' value={levOutputData.generic.mods.map(mod => <Chip size="small" style={{ margin: '0 4px 4px 0', fontFamily: 'Conv_IBMPlexSans-Medium' }} label={mod.name} variant="outlined" />)} /> :
              <LabelValueCard xs={6} md={3} label='Modifications / Enhancements' value="--" />
            }
            <LabelValueCard xs={6} sm={6} md={3} label='Lease Start Date' value={levOutputData && levOutputData.lev ? moment(levOutputData.lev.lease_start_date).format(displayDateFormatShort) : '--'} />
            <LabelValueCard xs={6} sm={6} md={3} label='Lease End Date' value={levOutputData && levOutputData.lev ? moment(levOutputData.lev.lease_end_date).format(displayDateFormatShort) : '--'} />
            <LabelValueCard xs={6} sm={6} md={3} label='Rental Type' value={levOutputData && levOutputData.lev ? levOutputData.lev.rental_type.label : '--'} />
            {
              levOutputData && levOutputData.lev && levOutputData.lev.rental_type && levOutputData.lev.rental_type.label !== "Stepped" ?
                <LabelValueCard xs={6} sm={6} md={3} label={levOutputData && levOutputData.lev && levOutputData.lev.rental_type && levOutputData.lev.rental_type.id == 3 ? 'Quarterly Rental' : 'Monthly Rental'} value={levOutputData && levOutputData.lev && levOutputData.lev.rent ? <NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 2 })}>{levOutputData.lev.rent}</NumericalLabel> : '--'} />
                : null
            }
            <LabelValueCard xs={6} sm={6} md={3} label='Rental Payment' value={levOutputData && levOutputData.lev ? levOutputData.lev.rental_payment_type.label : '--'} />
            <LabelValueCard xs={6} sm={6} md={3} label='Markdown on Residual Value' value={levOutputData && levOutputData.lev && levOutputData.lev.markdown_on_residual_value ? `${levOutputData.lev.markdown_on_residual_value.toFixed(2)} %` : '--'} />
            <LabelValueCard xs={6} sm={6} md={3} label='Remaining Lease Term' value={lease_term_remaining >= 0 ? `${Math.ceil(lease_term_remaining)} Month(s)` : `${0} Month`} />
            {applicationPermission && applicationPermission.security_groups && applicationPermission.security_groups.filter(group => group.id === 46).length ?
              <LabelValueCard xs={6} sm={6} md={3} label='Asset Management Charges' value={levOutputData && levOutputData.lev && levOutputData.lev.asset_management_charges ? `${levOutputData.lev.asset_management_charges.toFixed(2)} %` : '--'} /> : null
            }
            <LabelValueCard item xs={6} sm={6} md={3} label="Maintenance Condition at Lease End" value={levOutputData.lev && levOutputData.lev.is_full_life_maintenance_condition ? '100%' : '50%'} />            
            {
              levOutputData.lev && levOutputData.lev.is_full_life_maintenance_condition == true ?
                <LabelValueCard xs={6} sm={6} md={3} label="Maintenance Program" value={levOutputData.lev && levOutputData.lev.maintenance_program ? levOutputData.lev.maintenance_program.label : '--'} /> : null
            }
            {
              levOutputData.generic && levOutputData.generic.aircraft_type && levOutputData.generic.aircraft_type.oem == "Bombardier Aerospace" && levOutputData.lev.is_full_life_maintenance_condition ?
                <LabelValueCard xs={6} sm={6} md={3} label="APU Status" value={levOutputData.lev.is_apu_disabled ? 'Disabled' : 'Enabled'} /> : null
            }
             {
              applicationPermission && applicationPermission.security_groups && applicationPermission.security_groups.filter(group => group.id === 46).length ? 
              <LabelValueCard item xs={6} sm={6} md={3} label='Assumption' value={levOutputData && levOutputData.generic && levOutputData.generic.assumptions ?  levOutputData.generic.assumptions  : '--'} /> : null
            }
          </Grid>
        </div>
        {
          levOutputData.lev && levOutputData.lev.engine_sv_details && levOutputData.lev.engine_sv_details.length ?
            <div className="aircraft-engine-details" style={{ marginTop: '30px' }}>
              <Grid container spacing={2}>
                <Grid item md={12} >
                  <h4 className="value-card-header">Aircraft Engine Details</h4>
                </Grid>
                <Grid item md={8}>
                  <Grid container spacing={2}>
                    {
                      levOutputData.lev.engine_sv_details.map(svData =>
                        <Grid item md={6} style={{ marginBottom: '20px' }}>
                          <Grid container>
                            <Grid item md={12} style={{ marginBottom: '15px' }}>
                              <h4>{svData.name}</h4>
                            </Grid>
                            {
                              svData.engine_hsi && svData.engine_hsi.length ?
                                <Fragment>
                                  <Grid item xs={6} md={6} sm={6} className="label-value-card">
                                    <p>Last Performed HSI</p>
                                    <h6>{svData.last_performed_hsi ? svData.last_performed_hsi.event : 'No HSI Performed'}</h6>
                                  </Grid>
                                  <Grid item xs={6} md={6} sm={6} className="label-value-card">
                                    <p>Last Performed OVH</p>
                                    <h6>{svData.last_performed_sv ? svData.last_performed_sv.event : 'No OVH Performed'}</h6>
                                  </Grid>
                                </Fragment> :
                                <Grid item xs={6} md={6} sm={6} className="label-value-card">
                                  <p>Last Performed SV</p>
                                  <h6>{svData.last_performed_sv ? svData.last_performed_sv.event : 'No PRSV Performed'} </h6>
                                </Grid>
                            }
                          </Grid>
                        </Grid>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </div> : null
        }
        {
          levOutputData.lev && levOutputData.lev.step_rentals && levOutputData.lev.step_rentals.length ?
            <div className="stepped-rental-data-blk">
              <h4 className="stepped-head">Rental Details :</h4>
              <table width="100%">
                <tr align="left">
                  <th><strong>Start Date </strong></th>
                  <th><strong> End Date</strong></th>
                  <th><strong>Monthly Rent</strong></th>
                </tr>
                {levOutputData.lev && levOutputData.lev.step_rentals.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td width="25%">{moment(item.start_date).format(displayDateFormatShort)}</td>
                      <td width="25%">{moment(item.end_date).format(displayDateFormatShort)}</td>
                      <td width="25%"><NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 2 })}>{item.rent}</NumericalLabel></td>
                    </tr>
                  )
                })}
              </table>
            </div> : null
        }
        <div className="lev-value-card-blk">
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <h3 className="value-card-header">Values as of <span>{moment(levOutputData && levOutputData.generic && levOutputData.generic.base_value_date).format(displayDateFormatShort)}:</span></h3>
            </Grid>
          </Grid>
          <ValueCard levOutputData={levOutputData} />
        </div>
        <TableListComp
          heads={applicationPermission && applicationPermission.security_groups && applicationPermission.security_groups.filter(group => group.id === 46).length ? LEVListPerHd : LEVListHd}
          data={levOutputData && levOutputData.lev && levOutputData.lev.monthly_pv.map((item, index) =>
            <LEVList
              item={item}
              index={index}
              applicationPermission={this.props.applicationPermission}
            />
          )}
          noRecord={levOutputData && levOutputData.lev && levOutputData.lev.monthly_pv && levOutputData.lev.monthly_pv.length ? null :
            <EmptyCollection
              title="No Records Found"
            />
          }
        />
        {checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'lev', 'U') ?
          !isLocked ?
            <Button style={{ marginTop: '20px' }} onClick={() => this.props.history.push(`/lease-encumbered/update-log/${this.props.match.params.id}`)} fullWidth={true} variant="contained" color="primary">Modify Query</Button> : null : null
        }
      </section>
    )
  }
}
export default withSnackbar(LeaseEncumberedValue);
