import React from 'react';
import {TableRow, TableCell} from '@material-ui/core';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat, checkUserTypeFn } from '../../../utils'

const LLPBreakTable = ({item, tabIndexLlp, llpTabName}) => {
  console.log('item', item);
  const style = {
    color: item.module == "Fan" ? 'rgb(74, 153, 254)' : item.module == 'HPC' ? 'rgb(151, 152, 156)': item.module == 'HPT' ? 'rgb(93, 173, 94)':item.module == "LPT" ? 'rgb(252, 152, 37)':''
  }
  let rowStyle = {}
  if(item.remaining_cycle < 0){
    rowStyle = {
      color:"red",
    }
  }else{
    rowStyle = {
      whiteSpace:"nowrap"
    }
  }
  return (
    <>
    { tabIndexLlp === 0 && item.llp_type === 1 ?
    <TableRow>
    <TableCell style={item.remaining_cycle < 0 ? rowStyle :  style}>{item.module ? item.module  : '--' }</TableCell>
    <TableCell style={rowStyle}>
      {item.description ? item.description : '--' } <br />
      {
        item.remaining_cycle < 0 ?
        <span style={{fontSize:'10px',padding:'2px 4px', color:'#000', borderRadius:'3px', background:'#fff1b6' }}>LLPs have crossed life limit, hence full life remaining is considered 0%</span>
        :null
      }

    </TableCell>
    {
      checkUserTypeFn() ?
      <TableCell style={rowStyle}>{ item.cost ?  <NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{item.cost}</NumericalLabel> : '--'}</TableCell>
      : null

    }
    <TableCell style={rowStyle}>{item.cycles_limit ? `${item.cycles_limit} FC` : 0  }</TableCell>
    <TableCell style={rowStyle}>{item.tcsn ? `${item.tcsn} FC` : 0 }</TableCell>
    <TableCell style={rowStyle}>{item.remaining_cycle ? `${item.remaining_cycle} FC`: 0 }</TableCell>
    <TableCell style={rowStyle}>{item.full_life_remaining_percentage ? `${parseFloat(item.full_life_remaining_percentage).toFixed(2)} %`:'0 %' }</TableCell>
    <TableCell style={rowStyle}>{item.half_life_remaining_percentage ? `${parseFloat(item.half_life_remaining_percentage).toFixed(2)} %`: '--' }</TableCell>
    <TableCell style={rowStyle}><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{item.half_life_adjustment}</NumericalLabel></TableCell>
    </TableRow> : null }

    { llpTabName.includes('SECONDARY LLP') && item.llp_type === 3 ?
    <TableRow>
    <TableCell style={item.remaining_cycle < 0 ? rowStyle :  style}>{item.module ? item.module  : '--' }</TableCell>
    <TableCell style={rowStyle} style={item.remaining_cycle < 0 ?  {width:'340px'} : {}}>
      {item.description ? item.description : '--' } <br />
      {
        item.remaining_cycle < 0 ?
        <span style={{fontSize:'10px',padding:'2px 4px', color:'#000', borderRadius:'3px', background:'#fff1b6' }}>LLPs have crossed life limit, hence full life remaining is considered 0%</span>
        :null
      }

    </TableCell>
    {/* <TableCell style={rowStyle}>{item.llp_type ? item.llp_type : '--'  }</TableCell> */}
    {
      checkUserTypeFn() ?
      <TableCell style={rowStyle}>{ item.cost ?  <NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{item.cost}</NumericalLabel> : '--'}</TableCell>
      : null

    }
    <TableCell style={rowStyle}>{item.cycles_limit ? `${item.cycles_limit} FC` : 0  }</TableCell>
    <TableCell style={rowStyle}>{item.tcsn ? `${item.tcsn} FC` : 0 }</TableCell>
    <TableCell style={rowStyle}>{item.remaining_cycle ? `${item.remaining_cycle} FC`: 0 }</TableCell>
    <TableCell style={rowStyle}>{item.full_life_remaining_percentage ? `${parseFloat(item.full_life_remaining_percentage).toFixed(2)} %`:'0 %' }</TableCell>
    <TableCell style={rowStyle}>{item.half_life_remaining_percentage ? `${parseFloat(item.half_life_remaining_percentage).toFixed(2)} %`: '--' }</TableCell>
    <TableCell style={rowStyle}><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{item.half_life_adjustment}</NumericalLabel></TableCell>
    </TableRow> : null }

    { llpTabName.includes('STATIC LLP') && item.llp_type === 2 ?
    <TableRow>
    <TableCell style={item.remaining_cycle < 0 ? rowStyle :  style}>{item.module ? item.module  : '--' }</TableCell>
    <TableCell style={rowStyle} style={item.remaining_cycle < 0 ?  {width:'340px'} : {}}>
      {item.description ? item.description : '--' } <br />
      {
        item.remaining_cycle < 0 ?
        <span style={{fontSize:'10px',padding:'2px 4px', color:'#000', borderRadius:'3px', background:'#fff1b6' }}>LLPs have crossed life limit, hence full life remaining is considered 0%</span>
        :null
      }

    </TableCell>
    {
      checkUserTypeFn() ?
      <TableCell style={rowStyle}>{ item.cost ?  <NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{item.cost}</NumericalLabel> : '--'}</TableCell>
      : null

    }
    <TableCell style={rowStyle}>{item.cycles_limit ? `${item.cycles_limit} FC` : 0  }</TableCell>
    <TableCell style={rowStyle}>{item.tcsn ? `${item.tcsn} FC` : 0 }</TableCell>
    <TableCell style={rowStyle}>{item.remaining_cycle ? `${item.remaining_cycle} FC`: 0 }</TableCell>
    <TableCell style={rowStyle}>{item.full_life_remaining_percentage ? `${parseFloat(item.full_life_remaining_percentage).toFixed(2)} %`:'0 %' }</TableCell>
    <TableCell style={rowStyle}>{item.half_life_remaining_percentage ? `${parseFloat(item.half_life_remaining_percentage).toFixed(2)} %`: '--' }</TableCell>
    <TableCell style={rowStyle}><NumericalLabel params={getCurrencyFormat({shortFormat:false})}>{item.half_life_adjustment}</NumericalLabel></TableCell>
    </TableRow> : null }
    </>
  )
}
export default LLPBreakTable;
