import { globalGetService, globalPostService, globalExportService } from '../utils/globalApiServices';
import { checkApiStatus , setGlobalCookie} from '../utils';
import { trackActivity } from '../utils/mixpanel';
import config from '../config';
import Cookies from 'universal-cookie'
const cookies = new Cookies();
export function getApplicationAccessApi(setApplicationAccess){
  globalGetService(`api/user/permission/?applications=true`)
  .then(response => {
    if(checkApiStatus(response)){
      setApplicationAccess(response.data.data.applications)
    }
  })
}
export const getLessorsListApi = () =>{
  return new Promise(function(resolve,reject){
    globalGetService('sso-lessor/list/')
    .then(response => {
      resolve(response);
    })
  })

}
export function getWhatsNewFeaturesListApi(props={}){
  this.setState({pageLoader:true});
  globalGetService('api/features/')
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        whtsNewModal:true,
        whtsNewList: response.data.data
      })
    }
  })
}

export function saveFeedback(){
  this.setState({pageLoader:true});
  globalPostService('valuation/user-feedback/',{questions: [this.state.question1,this.state.question2,this.state.question3,this.state.question4,this.state.question5]})
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
      this.setState({
        feedbackDialog: false,
        question1: {
          id: 1,
          answers:[
            {
                id: 17,
                comments: "",
                rating: 3
            },
            {
                id: 18,
                comments: "",
                rating: 3
            },
            {
                id: 19,
                comments: "",
                rating: 3
            },
            {
                id: 20,
                comments: "",
                rating: 3
            },
          ]
        },
        question2: {
          id: 2,
          answers:[]
        },
        question3: {
          id: 3,
          answers:[{
            id: 26
          }]
        },
        question4: {
          id: 4,
          answers:[{
            id: 31
          }]
        },
        question5: {
          id: 5,
          answers:[{
            id: 32
          }]
        },
      })
    }
  })
}

export function getFaqsListApi(props={}){
  this.setState({pageLoader:true});
  globalGetService('console/faq/?application=14',)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({
        faqsModal:true,
        faqsList: response.data.data,
        medialLink: response.data.media_link[0]
      })
    }
  })
}
export function getReportAnIssueConstantsApi(props={}, data){
  globalPostService('console/get-constants/', data)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        pltConstants:response.data.data
      })
    }
  })
}
export function submitAnIssueApi(props, data){
  let formData = new FormData();

  formData.append('title', data.title);
  formData.append('description', data.description);
  formData.append('severity', data.severity);
  formData.append('module', 11);
  formData.append('classification', data.classification);
  formData.append('current_page_url',window.location.href);
  data.attachments.map((file, index) => {
    formData.append('attachments', file);
  });
  this.setState({loader: true})
  globalPostService('console/report-bug/?module=valuation', formData)
  .then(response => {
    this.setState({loader: false})
    if(checkApiStatus(response)){
      this.setState({modal:false, error:{}, issueInfo:{}});
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else{
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function getRevisionHistoryApi(url, queryParams){
  this.setState({pageLoader:true});
  globalGetService(url, queryParams)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.setState({revisionHistories:response.data.data, open:true})
    }
  })
}
export function exportRevisionHistory(exportUrl, exportQueryparam, moduleType){
  this.setState({pageLoader:true})
  globalExportService(`${exportUrl}`, exportQueryparam)
  .then(response => {
    this.setState({pageLoader:false})
    trackActivity('Exported Revision History',{
      type:moduleType,
      log_id: exportQueryparam.object_id
    })
    this.props.enqueueSnackbar('Log file will be sent to your registered email', { variant: 'success', anchorOrigin:{ vertical:'top', horizontal:'right'}})
  })
}
export function changePasswordApi(data = {}){
  globalPostService(`api/password-change/`, data)
  .then(response => {
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{ vertical:'top', horizontal:'right'}})
      this.setState({modal:false, passwordObj:{}})
      this.props.history.push('/logs')

    }else if(response.data.statusCode == 500){
      this.setState(prevState => ({
        ...prevState,
        error:{
          ...prevState.error,
          old_password:response.data.message
        }
      }))
    }else if(response.data.statusCode == 1008){
      this.setState(prevState => ({
        ...prevState,
        error:{
          ...prevState.error,
          old_password:response.data.message
        }
      }))
    }else {
      this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{ vertical:'top', horizontal:'right'}})
    }
  })
}

export function getHeaderCartCountApi(){
  globalGetService(`console/cart/count/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        headerCount:response.data.data
      })
    }
  })
}
export function getLessorListApi(setLessor){
  globalGetService(`api/user/lessors/`, {})
  .then(response => {
    if(checkApiStatus(response)){
      setLessor(lessorList => response.data.data)
    }
  })
}
export function changeLessorApi( data={}, setLoader, setAnchorEl, handleClick){
  debugger;
  setLoader(true)
  globalPostService(`api/user-authorization/`, data)
  .then(response => {
    setLoader(false)
    cookies.remove(config.cookiesNaming.key , {path: "/", domain: "sparta.aero"})
    if(checkApiStatus(response)){
      let accessData = {
        access:response.data.data.lessor_access_token,
        id:response.data.data.current_lessor.id,
        lessor:{
          id:response.data.data.current_lessor.id,
          name:response.data.data.current_lessor.name,
          slug:response.data.data.current_lessor.slug,
          lessor_type:response.data.data.current_lessor.lessor_type,
          multi_lessor_user: response.data.data.multi_lessor_user
        },
        user:{
          id:response.data.data.user.id,
          slug:response.data.data.user.slug,
          name:response.data.data.user.name,
          designation:response.data.data.user.designation,
          profile_pic:response.data.data.user.profile_pic,
          account_type:response.data.data.user.account_type,
          is_root_user:response.data.data.user.is_root_user,
          is_super_admin:response.data.data.user.is_super_admin,
        }
      }
      let cookieData = JSON.stringify(accessData)
      // let cookieData = JSON.stringify({access:response.data.data.lessor_access_token, id:response.data.data.current_lessor.id,slug: response.data.data.current_lessor.slug, refresh:'', environment: checkEnvironment()})
      setGlobalCookie(config.cookiesNaming.key, cookieData, 3)
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      userInfo = {
        ...userInfo,
        access: response.data.data.lessor_access_token,
        defaultLessor: response.data.data.current_lessor,
        user: response.data.data.user,
      }
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      setAnchorEl(selectLessorEl => false);
      handleClick();
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    else {
      setTimeout(() => {
        window.location.href = '/'
      }, 500);
    }
  })
}