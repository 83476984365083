import React from 'react';
import { withRouter } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import { checkUserAccessPermission } from '../../../utils';
const OtherLinks = ({history, match, type, valuationLog, module, applicationPermission}) => {
  console.log('twet', checkUserAccessPermission(applicationPermission, 'valuation', module, 'U'));
  return(
    <div className="other-links-options">
      { match.path.includes('future-value-projection') ?
        !valuationLog.lock_status ?
        <Paper style={{textAlign:'center', cursor:'pointer'}} onClick={() => history.push(`/base-value/${match.params.id}`)}>
          Get Base Value
        </Paper> : null
        :
        checkUserAccessPermission(applicationPermission, 'valuation', 'future_value', 'C') ?
        !valuationLog.lock_status ?
        <Paper style={{textAlign:'center', cursor:'pointer'}} onClick={() => valuationLog.projection_to ? history.push(`/future-value-projection/${match.params.id}`):history.push(`/update-log/${match.params.id}/future-value`)}>
          Project Future Base Values
        </Paper> : null : valuationLog.projection_to ? !valuationLog.lock_status ?
        <Paper style={{textAlign:'center', cursor:'pointer'}} onClick={() => history.push(`/future-value-projection/${match.params.id}`)}>
          Project Future Base Values
        </Paper> : null : null
      }
      <Paper style={{display:'none'}}>
        Calculate Maintenance Adjusted Base Value
      </Paper>
      { checkUserAccessPermission(applicationPermission, 'valuation', module, 'U') ?
        !valuationLog.lock_status ?
        <Paper style={{textAlign:'center', cursor:'pointer'}} disabled={!valuationLog.lock_status} onClick={() => history.push(`/update-log/${match.params.id}/${type}`)}>
          Modify Query
        </Paper> : null : null
      }
      <p>
        <span>Disclaimer: </span>
        The value displayed here is based on algorithms defined in SPARTA. For certified Appraiser Opinion, please contact <a href="mailto:valuation@acumen.aero" target="_blank">valuation@acumen.aero</a>
      </p>
    </div>
  )
}
export default withRouter(OtherLinks);
