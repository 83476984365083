import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
function ContractInnerNav(props){
  return(
    <div>
      <div style={{background:'rgba(0,0,0,.2)', padding:'15px',}}>
        <NavLink to={`/${props.match.params.assetType}/${props.match.params.assetSlug}/contracts`} className="flex-centered" style={{color:'#fff'}}><ArrowBackIosIcon style={{color:'#fff'}} /> Contract</NavLink>
      </div>
      <div>
        <h4>Key Information</h4>
        <ul className="list-unstyled">
          {[
              {label: 'Headlines', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/detail/${props.match.params.contractSlug}`},
              {label: 'Automated Content Extractor', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/extract-file-list`},
              {label: 'Working Groups', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/working-group`},
              {label: 'Parties', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/parties`},
              {label: 'Subleases', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/sub-lease`},
              {label: 'Security Deposit', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/security-deposit`},
              {label: 'Insurance', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/insurance`},
              {label: 'Cape Town Convention', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/cape-town-convention`},
              {label: 'EOL Options', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/lease-option`},
              {label: 'Obligations', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/obligations`},
          ].map((item, index) =>
            <li key={index}>
              <NavLink activeClassName="active" to={item.path}>{item.label}</NavLink>
            </li>
          )}
        </ul>
      </div>
      <div>
        <h4>Transition</h4>
        <ul className="list-unstyled">
          {[
            {label:'Induction Conditions', path:`/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/induction-condition`},
            {label:'Delivery Conditions', path:`/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/delivery-condition`},
            {label:'Return Condition', path:`/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/return-condition`}

          ].map((item,index) =>
            <li key={index}>
              <NavLink activeClassName="active" to={item.path}>{item.label}</NavLink>
            </li>
          )}
        </ul>
      </div>
      <div>
        <h4>Finanace</h4>
        <ul className="list-unstyled">
          {[
              {label: 'MR Rates', path:`/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/mr-rate`},
              {label: 'Lease Rental Rates', path:`/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/lease-rental-condition`},
              {label: 'Qualified Maintenance Events', path:`/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/qualified-maintenance`},
              {label: 'Financing Details', path:`/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/${props.match.params.contractSlug}/finance-detail`},

            ].map((item, index) =>
            <li key={index}>
              <NavLink activeClassName="active" to={item.path}>{item.label}</NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
export default withRouter(ContractInnerNav)
