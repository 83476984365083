import UserListing from './containers';
import ChangePassword from './containers/ChangePassword';
import EditUser from './containers/EditUser';
import UserInvDiteList from './containers/UserInviteList';
import UserProfile from './containers/UserProfile';

import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
export const usersRoutes = [
  {
    path: '/change-password',
    component: pageLayoutHoc(userRoleBaseAccessHoc(ChangePassword,[]), {apps: 'User', layoutPhase: 2}),
    key: 'ChangePassword'
  },
  {
    path: '/user-profile',
    component: pageLayoutHoc(userRoleBaseAccessHoc(UserProfile,[]), {apps: 'User Profile', layoutPhase: 1}),
    key: 'UserProfile'
  },
  {
    path: '/users/manage/active',
    component: pageLayoutHoc(userRoleBaseAccessHoc(UserListing,[]), {apps: 'User', layoutPhase: 2}),
    key: 'ActiveUsers'
  },
  {
    path: '/users/manage/inactive',
    component: pageLayoutHoc(userRoleBaseAccessHoc(UserListing,[]), {apps: 'User', layoutPhase: 2}),
    key: 'InActiveUsers'
  },
  {
    path: '/users/manage/active/:id',
    component: pageLayoutHoc(userRoleBaseAccessHoc(EditUser,[]), {apps: 'User', layoutPhase: 2}),
    key: 'EditUser'
  },
  {
    path: '/users/invites/',
    component: pageLayoutHoc(userRoleBaseAccessHoc(UserInvDiteList,[]), {apps: 'User', layoutPhase: 2}),
    key: 'UserInvDiteList'
  }
]
