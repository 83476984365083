const env = process.env.REACT_APP_ENV
export const appConfig = {
  api: {
    networkInterface: ({
      development: 'https://dev.beta.sparta.aero:8222/',
      staging: 'https://qa.beta.sparta.aero:8223/',
      production: 'https://api.sparta.aero/',
    })[env],
    // add more here
    s3ImageUrl: ({
      development: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
      staging: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
      production: 'https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/',
    })[env]
  },
  mixpanel: {
    key: ({
      production: '9c36c607247cf6b5dda89c83beba8035',
      staging: 'c4e6387ced1f81d89691c3c39701abfc',
      development: 'c4e6387ced1f81d89691c3c39701abfc',
    })[env]
  },
  cookiesNaming:{
    key:({
      development:'lessorAccess_dev',
      staging:'lessorAccess_qa',
      production:'lessorAccess'
    })[env]
  },
  domain: {
    subDomian: ({
      development: 'https://dev.console.sparta.aero/',
      staging: 'https://qa.console.sparta.aero/',
      production: 'https://sparta.aero/',
    })[env],
    lease: ({
      development: 'https://dev.lease.sparta.aero/',
      staging: 'https://qa.lease.sparta.aero/',
      production: 'https://lease.sparta.aero/',
    })[env],
    maintenance: ({
      development: 'https://dev.maintenance.sparta.aero/',
      staging: 'https://qa.maintenance.sparta.aero/',
      production: 'https://maintenance.sparta.aero/',
    })[env],
    projects: ({
      development: 'https://dev.projects.sparta.aero/',
      staging: 'https://qa.projects.sparta.aero/',
      production: 'https://projects.sparta.aero/',
    })[env],
    valuation: ({
      development: 'https://dev.fin-s.sparta.aero/',
      staging: 'https://qa.fin-s.sparta.aero/',
      production: 'https://fin-s.sparta.aero/',
    })[env],
    commVerge: ({
      development: 'https://dev.commverge.sparta.aero/',
      staging: 'https://qa.commverge.sparta.aero/',
      production: 'https://commverge.sparta.aero/',
    })[env],
    records: ({
      development: 'https://dev.records.sparta.aero/',
      staging: 'https://qa.records.sparta.aero/',
      production: 'https://records.sparta.aero/',
    })[env],
  }
}

export default appConfig
