import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
function RecordSideNav(props){
  return(
    <div className="">
      <ul className="list-unstyled">
        {[
            {label: 'Dashboard', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/records/dashboard`},
            {label: 'Data Room', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/records/dataRoom`},
            {label: 'Action Dock', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/records/action-dock`},
            {label: 'Resources', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/records/resources`},
            {label: 'Report', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/records/report`},
            {label: 'Recylce Bin', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/records/recycle-bin`},
            {label: 'Auto Organiser & Renaming', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/records/auto-organiser-and-renaming`}
        ].map((item, index) =>
          <li key={index}>
            <NavLink activeClassName="active" to="">{item.label}</NavLink>
          </li>
        )}
      </ul>
    </div>
  )
}
export default withRouter(RecordSideNav);
