import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Paper, Grid, Button, Breadcrumbs, Typography, Tooltip } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { AirframeHlaIntermediate, EngineHlaIntermediate, ApuHlaIntermediate, LgHlaIntermediate, PropellerIntermediate, EngineHlaHsiIntermediate } from '../components';
import { getCurrencyFormat, checkUserAccessPermission } from '../../../utils';
import NumericalLabel from 'react-pretty-numbers';
import { getHlaLogApi, updateAirframeLogApi, updateApuLogApi, updateLgLogApi, updateEngineLogApi, validateIntermediateApi, updateLLPApi, getEngineLLpListApi, updatePropellerLogApi, updateEngineHsiLogApi, importLLPApi, secondaryllpUpdateApi, staticLLpUpdateApi, exportLLPApi, getSecondaryLLpListApi, getStaticLLpListApi, updateForecastDateApi, updateLockApi } from '../apiServices';
import { PageLoader, RevisionHistory } from '../../../shared_elements';
import { displayDateFormatShort } from '../../../constants';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import MomentUtils from '@date-io/moment';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import lockIcon from '../../../assets/images/NewLockIcon.png';
import unlockIcon from '../../../assets/images/unlockicon.png';
import { trackActivity } from '../../../utils/mixpanel';
import { intermediateStateData } from '..';
let selectedLLps = []
class IntermediateHla extends Component {
  constructor(props) {
    super(props)
    this.state = intermediateStateData
    this.getHlaLogApi = getHlaLogApi.bind(this)
    this.updateAirframeLogApi = updateAirframeLogApi.bind(this)
    this.updateApuLogApi = updateApuLogApi.bind(this)
    this.updateLgLogApi = updateLgLogApi.bind(this)
    this.updateEngineLogApi = updateEngineLogApi.bind(this)
    this.validateIntermediateApi = validateIntermediateApi.bind(this)
    this.updateLLPApi = updateLLPApi.bind(this)
    this.updateEngineHsiLogApi = updateEngineHsiLogApi.bind(this)
    this.getEngineLLpListApi = getEngineLLpListApi.bind(this)
    this.updatePropellerLogApi = updatePropellerLogApi.bind(this)
    this.secondaryllpUpdateApi = secondaryllpUpdateApi.bind(this)
    this.staticLLpUpdateApi = staticLLpUpdateApi.bind(this)
    this.importLLPApi = importLLPApi.bind(this);
    this.exportLLPApi = exportLLPApi.bind(this);
    this.getSecondaryLLpListApi = getSecondaryLLpListApi.bind(this);
    this.getStaticLLpListApi = getStaticLLpListApi.bind(this);
    this.updateForecastDateApi = updateForecastDateApi.bind(this);
    this.updateLockApi = updateLockApi.bind(this);
  }
  componentDidMount() {
    if (['?edit-mode'].includes(this.props.location.search)) {
      this.getHlaLogApi(this.props, { validatorConstant: 1 })
    } else {
      this.getHlaLogApi(this.props)
    }

    trackActivity('Page Visited', {
      page_title: 'Intermediate Half Life Adjustment',
      log_id: this.props.match.params.id
    })
  }
  changeEngineTabs = (event, newValue) => {
    this.setState({ tabIndex: newValue })
  }
  changeLgTabs = (event, newValue) => {
    this.setState({ lgTabIndex: newValue })
  }
  handleChangeA = (e) => {
    this.setState(prevState => ({
      ...prevState,
      checkedA: e.target.checked,
      llpTabIndex: 0
    }))
  }
  handleChangeB = (e) => {
    this.setState(prevState => ({
      ...prevState,
      checkedB: e.target.checked,
      llpTabIndex: 0
    }))
  }

  onFieldChange = (event, keyParam, value, tableType) => {
    const { hlaLogDetail } = this.state
    this.updateFormFields(keyParam, value, tableType)
    if (keyParam == "mtow" || keyParam == "mtow_kgs" && value >= 0) {
      if (keyParam == "mtow_kgs") {
        this.updateFormFields('mtow', (value * 2.2046).toFixed(2), 'generic')
      } else {
        this.updateFormFields('mtow_kgs', (value * 0.4535970244).toFixed(2), 'generic')
      }
    }
    if (keyParam == "lsv_date" && tableType == "apu" && value == hlaLogDetail.airframe.date_of_manufacture) {
      this.updateFormFields('lsv_date', value, 'apu')
      this.updateFormFields('hours_at_lsv', 0, 'apu')
      this.updateFormFields('cycles_at_lsv', 0, 'apu')
    }
    if (keyParam == "lsv_date" && tableType == "landing_gear" && value == hlaLogDetail.airframe.date_of_manufacture) {
      this.updateFormFields('lsv_date', value, 'landing_gear')
      this.updateFormFields('tsn_at_lsv', 0, 'landing_gear')
      this.updateFormFields('csn_at_lsv', 0, 'landing_gear')
    }
  }
  updateErrorField = (keyParam, message, tableType) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [tableType]: {
          ...prevState.error[tableType],
          [keyParam]: message
        }
      }
    }))
  }
  updateEngineErrorField = (keyParam, message, errorIndex) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        engine: {
          ...prevState.error.engine,
          [errorIndex]: {
            ...prevState.error.engine[errorIndex],
            [keyParam]: message
          }
        }
      }
    }))
  }
  updateEngineValidatorField = (keyParam, tableType, objIndex) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        engine: {
          ...prevState.error.engine,
          [tableType]: prevState.error.engine[tableType].map((err, index) => index == objIndex ? ({ ...err, [keyParam]: "" }) : err)
        }
      }
    }))
  }
  updateLgErrorField = (keyParam, message, errorIndex) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        lg: {
          ...prevState.error.lg,
          [errorIndex]: {
            ...prevState.error.lg[errorIndex],
            [keyParam]: message
          }
        }
      }
    }))
  }
  updateAirframeErrorField = (keyParam, message, errorIndex) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        airframe: {
          ...prevState.error.airframe,
          [errorIndex]: {
            ...prevState.error.airframe[errorIndex],
            [keyParam]: message
          }
        }
      }
    }))
  }
  updatePropellerError = (keyParam, message, errorIndex) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        propeller: {
          ...prevState.error.propeller,
          [errorIndex]: {
            ...prevState.error.propeller[errorIndex],
            [keyParam]: message
          }
        }
      }
    }))
  }
  updateFormFields = (keyParam, value, tableType) => {
    this.setState(prevState => ({
      ...prevState,
      hlaLogDetail: {
        ...prevState.hlaLogDetail,
        [tableType]: {
          ...prevState.hlaLogDetail[tableType],
          [keyParam]: value
        }
      }
    }))
  }

  changeTabs = (event, newValue) => {
    this.setState(prevState => ({
      ...prevState,
      changeTab: newValue
    }))
  }
  changeLLPTabs = (event, newValue, tabLabel) => {
    this.setState(prevState => ({
      ...prevState,
      llpTabIndex: newValue,
      llpTabName: tabLabel
    }))
  }
  //engine
  updateEngineField = (event, keyParam, value, engineIndex, prIndex) => {
    const { hlaLogDetail } = this.state
    if (keyParam == "last_event_date" && value == hlaLogDetail.airframe.date_of_manufacture) {
      this.setState(prevState => ({
        ...prevState,
        hlaLogDetail: {
          ...prevState.hlaLogDetail,
          engine: {
            ...prevState.hlaLogDetail.engine,
            engineInfo: prevState.hlaLogDetail.engine.engineInfo.map((item, index) => index === engineIndex ?
              {
                ...item,
                last_event_date: value,
                tsn_at_lsv: 0,
                csn_at_lsv: 0
              } : item
            )
          }
        }
      }))
    }
    if ((keyParam == 'last_performed_sv' && value)) {
      if (value == "None") {
      } else if (value && value.event) {
        let data = value
        data = {
          ...data,
          name: value.event
        }
        if (value.event == "Mature Run") {
          this.setState(prevState => ({
            ...prevState,
            hlaLogDetail: {
              ...prevState.hlaLogDetail,
              engine: {
                ...prevState.hlaLogDetail.engine,
                engineInfo: prevState.hlaLogDetail.engine.engineInfo.map((item, index) => index === engineIndex ?
                  {
                    ...item,
                    [keyParam]: data,
                    interval_fh: data.interval,
                    cost: data.cost,
                  } : item
                )
              }
            }
          }))
        } else {
          this.setState(prevState => ({
            ...prevState,
            hlaLogDetail: {
              ...prevState.hlaLogDetail,
              engine: {
                ...prevState.hlaLogDetail.engine,
                engineInfo: prevState.hlaLogDetail.engine.engineInfo.map((item, index) => index === engineIndex ?
                  {
                    ...item,
                    [keyParam]: data,
                    interval_fh: prevState.hlaLogDetail.engine.engineInfo[index].engine_prsv[prIndex + 1].interval,
                    // interval_fh_hsi: prevState.hlaLogDetail.engine.engineInfo[index].engine_prsv[prIndex + 1].interval,
                    cost: prevState.hlaLogDetail.engine.engineInfo[index].engine_prsv[prIndex + 1].cost,
                    // cost_hsi:prevState.hlaLogDetail.engine.engineInfo[index].engine_prsv[prIndex + 1].cost
                  } : item)
              }
            }
          }))
        }
      }
    } else {
      if (keyParam == "csn_as_of_llp") {
        this.updateLlpCsn(value, engineIndex)
      }
      this.setState(prevState => ({
        ...prevState,
        hlaLogDetail: {
          ...prevState.hlaLogDetail,
          engine: {
            ...prevState.hlaLogDetail.engine,
            engineInfo: prevState.hlaLogDetail.engine.engineInfo.map((item, index) => index === engineIndex ? { ...item, [keyParam]: value } : item)
          }
        }
      }))
    }
  }
  updatePrsvNone = (event, keyParam, value, engineIndex, prIndex) => {
    this.setState(prevState => ({
      ...prevState,
      hlaLogDetail: {
        ...prevState.hlaLogDetail,
        engine: {
          ...prevState.hlaLogDetail.engine,
          engineInfo: prevState.hlaLogDetail.engine.engineInfo.map((item, index) => index == engineIndex ?
            {
              ...item,
              [keyParam]: null,
              interval_fh: prevState.hlaLogDetail.engine.engineInfo[engineIndex].engine_prsv[0].interval,
              cost: prevState.hlaLogDetail.engine.engineInfo[index].engine_prsv[0].cost,
            } : item

          )
        }
      }
    }))
  }
  updateHSIEngineField = (event, keyParam, value, engineIndex, prIndex) => {
    let data = value
    data = {
      ...data,
      name: value.event
    }
    if (value.event == "Mature Run") {
      this.setState(prevState => ({
        ...prevState,
        hlaLogDetail: {
          ...prevState.hlaLogDetail,
          engine: {
            ...prevState.hlaLogDetail.engine,
            engineInfo: prevState.hlaLogDetail.engine.engineInfo.map((item, index) => index === engineIndex ?
              {
                ...item,
                [keyParam]: { ...data, name: data.event },
                interval_fh_hsi: data.interval,
                cost_hsi: data.cost,
              } : item
            )
          }
        }
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        hlaLogDetail: {
          ...prevState.hlaLogDetail,
          engine: {
            ...prevState.hlaLogDetail.engine,
            engineInfo: prevState.hlaLogDetail.engine.engineInfo.map((item, index) => index === engineIndex ?
              {
                ...item,
                [keyParam]: { ...data, name: data.event },
                interval_fh_hsi: prevState.hlaLogDetail.engine.engineInfo[index].engine_hsi[prIndex + 1].interval,
                cost_hsi: prevState.hlaLogDetail.engine.engineInfo[index].engine_hsi[prIndex + 1].cost
              } : item)
          }
        }
      }))
    }
  }
  updateHSINone = (event, keyParam, value, engineIndex, prIndex) => {
    this.setState(prevState => ({
      ...prevState,
      hlaLogDetail: {
        ...prevState.hlaLogDetail,
        engine: {
          ...prevState.hlaLogDetail.engine,
          engineInfo: prevState.hlaLogDetail.engine.engineInfo.map((item, index) => index == engineIndex ?
            {
              ...item,
              [keyParam]: null,
              interval_fh_hsi: prevState.hlaLogDetail.engine.engineInfo[engineIndex].engine_hsi[0].interval,
              cost_hsi: prevState.hlaLogDetail.engine.engineInfo[index].engine_hsi[0].cost,
            } : item

          )
        }
      }
    }))
  }
  maintenanceEventChange = (event, keyParam, value, eventIndex) => {
    this.setState(prevState => ({
      ...prevState,
      hlaLogDetail: {
        ...prevState.hlaLogDetail,
        airframe: {
          ...prevState.hlaLogDetail.airframe,
          maintenance_events: prevState.hlaLogDetail.airframe.maintenance_events.map((item, index) => index === eventIndex ?
            {
              ...item,
              [keyParam]: value
            } : item)
        }
      }
    }))
  }
  onChangeLLP = (event, keyParam, value, llpIndex, engineIndex, llpSection) => {
    this.setState(prevState => ({
      ...prevState,
      llpCsnValidationFlag: llpSection == "primary" && !value ? true : false,
      llpSecondaryValidationFlag: llpSection == "secondary" && !value ? true : false,
      llpStaticValidationFlag: llpSection == "static" && !value ? true : false,
      engineLLP: {
        ...prevState.engineLLP,
        [llpSection]: prevState.engineLLP[llpSection].map((llpItem, index) => index == llpIndex ? ({
          ...llpItem,
          [keyParam]: value,
          llpCsnValFlag: keyParam != 'cost' ? !value ? true : false : false,
          llpCostFlag: keyParam == "cost" ? !value ? true : false : false,
          lifeLimitvalFlag: keyParam == "cycles_limit" && !value ? true : false,
        }) : llpItem),
      },
    }))
  }
  saveLLpData = (engineData, engineIndex) => {
    const { engineLLP } = this.state
    this.updateLLPApi(this.props, engineData, engineLLP)
    this.updateEngineField('e', 'tsn_as_of_llp', engineData.tsn_as_of_llp, engineIndex)
    this.updateEngineField('e', 'csn_as_of_llp', engineData.csn_as_of_llp, engineIndex)
    this.updateEngineField('e', 'llp_status_as_of_date', engineData.llp_status_as_of_date, engineIndex)
  }
  updateLlpCsn = (value, engineIndex) => {
    this.setState(prevState => ({
      ...prevState,
      llpCsnValidationFlag: !value ? true : false,
      engineLLP: {
        ...prevState.engineLLP,
        primary: prevState.engineLLP.primary.map(item => ({ ...item, csn: value, llpCsnValFlag: !value ? true : false })),
        secondary: prevState.engineLLP && prevState.engineLLP.secondary && prevState.engineLLP.secondary.length ? prevState.engineLLP.secondary.map(item => ({ ...item, csn: item.status ? value : item.csn, llpCsnValFlag: !value ? true : false })) : [],
        static: prevState.engineLLP && prevState.engineLLP.secondary && prevState.engineLLP.static.length ? prevState.engineLLP.static.map(item => ({ ...item, csn: item.status ? value : item.csn, llpCsnValFlag: !value ? true : false })) : [],
      },
    }))
  }
  toggleLLpModal = (engineIndex, component) => {
    const { hlaLogDetail } = this.state
    this.getEngineLLpListApi(this.props, hlaLogDetail.engine.engineInfo[engineIndex], engineIndex, component)
  }
  lgUpdateFn = (event, keyParam, value, lgIndex) => {
    this.setState(prevState => ({
      ...prevState,
      hlaLogDetail: {
        ...prevState.hlaLogDetail,
        landing_gear: prevState.hlaLogDetail.landing_gear.map((item, index) => index == lgIndex ?
          {
            ...item,
            [keyParam]: value
          } : item
        )
      }
    }))
  }
  propellerUpdateFn = (event, keyParam, value, propellerIndex) => {
    this.setState(prevState => ({
      ...prevState,
      hlaLogDetail: {
        ...prevState.hlaLogDetail,
        propeller: prevState.hlaLogDetail.propeller.map((item, index) => index == propellerIndex ?
          {
            ...item,
            [keyParam]: value
          } : item
        )
      }
    }))
  }
  changePropellerTabs = (event, newValue) => {
    this.setState({ propellerTabIndex: newValue })
  }
  toggleBulkOpsFn = (id, checked, llp) => {
    const { engineLLP } = this.state
    if (checked) {
      selectedLLps = [...selectedLLps, llp]
      if (selectedLLps.length) {
        if (selectedLLps[0].status) {
          this.setState(prevState => ({
            ...prevState,
            staticLLPDisableFlag: false,
            selectedStaticLLps: selectedLLps,
            engineLLP: {
              ...prevState.engineLLP,
              static: prevState.engineLLP.static.map(item => !item.status ? ({
                ...item,
                checkBoxDisableFlag: true
              }) : item)
            }
          }))
        } else {
          this.setState(prevState => ({
            ...prevState,
            staticLLPEnableFlag: false,
            selectedStaticLLps: selectedLLps,
            engineLLP: {
              ...prevState.engineLLP,
              static: prevState.engineLLP.static.map(item => item.status ? ({
                ...item,
                checkBoxDisableFlag: true
              }) : item)
            }
          }))
        }
      }
    } else {
      selectedLLps = selectedLLps.filter(item => item.id != id)
      if (!selectedLLps.length) {
        this.setState(prevState => ({
          ...prevState,
          staticLLPEnableFlag: true,
          staticLLPDisableFlag: true,
          selectedStaticLLps: [],
          engineLLP: {
            ...prevState.engineLLP,
            static: prevState.engineLLP.static.map(item => ({ ...item, checkBoxDisableFlag: false }))
          }
        }))
      }
    }
  }
  enableStaticLLp = () => {
    const { engineLLP } = this.state
    let staticArr = engineLLP.static
    if (this.state.engineLLP.static.map(item => selectedLLps.map(llp => llp.id).includes(item.id))) {
      staticArr = staticArr.map(item => selectedLLps.map(llp => llp.id).includes(item.id) ? ({ ...item, status: true }) : { ...item, checkBoxDisableFlag: item.status ? false : true })
      this.setState(prevState => ({
        ...prevState,
        staticLLPEnableFlag: true,
        staticLLPDisableFlag: false,
        engineLLP: {
          ...prevState.engineLLP,
          static: staticArr
        }
      }))
    }
  }

  disableStaticLLp = () => {
    const { engineLLP } = this.state
    let staticArr = engineLLP.static
    if (this.state.engineLLP.static.map(item => selectedLLps.map(llp => llp.id).includes(item.id))) {
      staticArr = staticArr.map(item => selectedLLps.map(llp => llp.id).includes(item.id) ? ({ ...item, status: false }) : { ...item, checkBoxDisableFlag: !item.status ? false : true })
      this.setState(prevState => ({
        ...prevState,
        staticLLPEnableFlag: false,
        staticLLPDisableFlag: true,
        engineLLP: {
          ...prevState.engineLLP,
          static: staticArr
        }
      }))
    }
  }
  handleCheckBoxloader = (llpId) => {
    this.setState(prevState => ({
      ...prevState,
      engineLLP: {
        ...prevState.engineLLP,
        static: prevState.engineLLP.static.map(item => item.id == llpId ? ({ ...item, checkBoxLoader: true }) : item)
      }
    }))
  }
  handleLLpImport = (e) => {
    this.setState(prevState => ({
      ...prevState,
      files: e.target.files,
      confirmationModal: true
    }))
    setTimeout(() => this.handleEventValue(e), 6000);
  }
  handleEventValue = (e) => {
    let a = ""
    e.target.value = a
  }
  render() {
    const { hlaLogDetail, airframeEdit, apuEdit, lgEdit, pageLoader, enginesEdit, error, validatorConstant, tabIndex, llpModal, logDetailCopy, lgTabIndex, tabColor, engineTabColor, airframeRemarksFlag, llpCsnValidationFlag, propellerEdit, propellerTabIndex, engineLLP, selectedStaticLLps, staticLLPEnableFlag, staticLLPDisableFlag, llpSecondaryValidationFlag, llpStaticValidationFlag, checkedA, checkedB, llpTabIndex, llpTabName, secondaryLLpEnableFlag, checkBoxLoader, confirmationModal, files, forecastEdit, lockEdit, forecastDate, apuWarningText, airframeWarningText,propellerWarningText, engineWarningText, lgWarningText } = this.state;
    const  { applicationPermission } = this.props
    const flagCheck = `${[{ type: 'Airframe', flag: airframeEdit }, { type: ' APU', flag: apuEdit }, { type: ' Engine', flag: enginesEdit }, { type: ' Landing Gear', flag: lgEdit },].filter(item => item.flag == true).map(item => item.type).join()} to Get Value.`;
    if (Object.keys(hlaLogDetail).length) {
      return (
        <div className="valuation-sections">
          {pageLoader ? <PageLoader /> : null}
          <div className="valuation-title">
            <Breadcrumbs aria-label="breadcrumb" >
              <Link color="inherit" to="/logs" style={{ cursor: 'pointer' }} >
                <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px' }}>
                  Logs
                </Typography>
              </Link>
              { checkUserAccessPermission(applicationPermission, 'valuation', 'base_value' ,'C') ||
                checkUserAccessPermission(applicationPermission, 'valuation', 'future_value' ,'C') ||
                checkUserAccessPermission(applicationPermission, 'valuation', 'hthl' ,'C')||
                checkUserAccessPermission(applicationPermission, 'valuation', 'lev' ,'C') ?
                <Link color="inherit" to="/create-log" style={{ cursor: 'pointer' }} >
                  <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px' }}>
                    Asset Value Options
                  </Typography>
                </Link> : null
              }
              <h1 className="flex-centered" color="primary" style={{ fontSize: '14px' }}>{this.props.match.params.id}</h1>
            </Breadcrumbs>
          </div>
          <Paper className="valuation-log-info">
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <ul className="list-inline top-info">
                  <li className="list-inline-item" style={{ cursor: 'pointer' }}>
                    { checkUserAccessPermission(applicationPermission, 'valuation', 'hthl' ,'U') ?
                      hlaLogDetail.generic && hlaLogDetail.generic.lock_status ?
                        <Tooltip title="Click to resume editing." placement="top" arrow>
                          <img src={lockIcon} width="35" onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: false })} alt="Icon" />
                        </Tooltip>
                        :
                        <Tooltip title="Click to lock editing." placement="top" arrow>
                          <img src={unlockIcon} width="35" onClick={() => this.updateLockApi(this.props, { log_id: this.props.match.params.id, is_locked: true })} alt="Icon" />
                        </Tooltip>
                      :
                      hlaLogDetail.generic && hlaLogDetail.generic.lock_status ? <img src={lockIcon} width="30" alt="Icon" style={{ cursor: 'default' }} /> : <img src={unlockIcon} width="35" alt="Icon" style={{ cursor: 'default' }} />
                    }
                  </li>
                  <li className="list-inline-item" style={{ paddingLeft: '10px' }} >
                    <p>Log ID</p>
                    <h6 style={{ fontWeight: '800' }}>{this.props.match.params.id}</h6>
                  </li>
                  <li className="list-inline-item" style={{ paddingLeft: '10px' }}>
                    <p>Created at</p>
                    <h6>{moment(hlaLogDetail.generic.created_at).format(displayDateFormatShort)}</h6>
                  </li>
                  <li className="list-inline-item" style={{ paddingLeft: '10px' }}>
                    <p>Updated at</p>
                    <h6>{moment(hlaLogDetail.generic.updated_at).format(displayDateFormatShort)}</h6>
                  </li>
                  <li className="list-inline-item" style={{ paddingLeft: '10px' }}>
                    <p>Report Date</p>
                    <h6>{moment(hlaLogDetail.generic.report_date).format(displayDateFormatShort)}</h6>
                  </li>
                  <li className="list-inline-item forecast-li" style={{ paddingLeft: '10px' }}>
                    {forecastDate ?
                      !forecastEdit ?
                        !hlaLogDetail.generic.lock_status ?
                          <>
                            <p>Forecast Date
                              { checkUserAccessPermission(applicationPermission, 'valuation', 'hthl' ,'U') ?
                                <span>
                                  <EditIcon className="forecast-edit-icon" color="primary" onClick={() => this.setState(prevState => ({ forecastEdit: true }))} />
                                </span> : null
                              }
                            </p>
                            <h6>{moment(hlaLogDetail.generic.forecast_date).format(displayDateFormatShort)}</h6>
                          </> : null
                        :
                        <ul>
                          <li className="list-inline-item forcast-edit-mode">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                required
                                margin="normal"
                                id="forecast_date"
                                label="Forecast Date"
                                placeholder="DD-MM-YYYY"
                                fullWidth
                                minDate={moment(hlaLogDetail.generic.report_date) ? moment(hlaLogDetail.generic.report_date).add(1, 'days') : moment()}
                                maxDate={hlaLogDetail.airframe.date_of_manufacture ? moment(hlaLogDetail.airframe.date_of_manufacture).add(25.0136986301, 'years') : moment().add(25, 'years')}
                                format={fieldDateFormat}
                                InputLabelProps={{ shrink: true }}
                                error={error && error.generic && error.generic.forecast_date ? true : false}
                                helperText={error && error.generic && error.generic.forecast_date ? <span style={{ color: '#f44336' }}>{error.generic.forecast_date}</span> : ''}
                                onChange={(data, value) => this.onFieldChange(value, 'forecast_date', data ? moment(data).format(backendDateFormat) : data, 'generic')}
                                onFocus={() => this.updateErrorField('forecast_date', '', 'generic')}
                                value={hlaLogDetail.generic.forecast_date}
                              />
                            </MuiPickersUtilsProvider>
                          </li>
                          <li className="list-inline-item" >
                            <CancelIcon className="forcast-cancel-icon" color="primary" onClick={() => { this.getHlaLogApi(this.props); this.setState({ forecastEdit: false }) }} />
                          </li>
                          {
                            hlaLogDetail.generic.forecast_date ?
                              <li className="list-inline-item">
                                <DoneIcon className="forcast-done-icon" color="primary" onClick={() => this.updateForecastDateApi(this.props, { forecast_date: hlaLogDetail.generic.forecast_date })} />
                              </li> : null
                          }

                        </ul> : null
                    }
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={4}>
                <ul className="list-inline" style={{ float: 'right', position: 'relative' }}>
                  <li className="list-inline-item">
                    <RevisionHistory
                      url="audit/valuation/vtutilitylogs/"
                      queryParams={{ action: 1, object_id: this.props.match.params.id }}
                      buttonType={false}
                      component={true}
                      exportRevisionHistory={true}
                      exportQueryparam={{ action: 1, object_id: this.props.match.params.id, download: 'xls' }}
                      moduleType={"Half life Adjustment"}
                      exportPermission={ checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'EXP_PDF')}
                    />
                  </li>
                  <li className="list-inline-item">
                    { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl' ,'U') ?
                        <div>
                          <Button
                            color="primary"
                            variant="contained"
                            style={{ float: 'right' }}
                            onClick={() => this.validateIntermediateApi(this.props, { validatorConstant: validatorConstant })}
                            disabled={validatorConstant == 1 && (airframeEdit || apuEdit || propellerEdit || enginesEdit || lgEdit || forecastEdit)}
                          >
                            Get Value</Button>
                          {validatorConstant == 1 && (airframeEdit || apuEdit || propellerEdit || enginesEdit || lgEdit) ?
                            <p style={{ fontSize: '10px', color: '#ff0000', clear: 'both', float: 'right', marginBottom: '5px', position: 'relative', left: '', bottom: '' }}>Please Click on Save or Cancel for <br />{flagCheck} </p>
                            : forecastEdit ? <p style={{ fontSize: '10px', color: '#ff0000', clear: 'both', float: 'right', marginBottom: '5px', position: 'relative', left: '', bottom: '' }}> Please update Forecast date to Get Value</p> : null
                          }
                        </div> :
                        <div>
                          <Button
                            color="primary"
                            variant="contained"
                            style={{ float: 'right' }}
                            onClick={() => this.validateIntermediateApi(this.props, { validatorConstant: 0 })}
                            disabled={!hlaLogDetail.generic.log_compiled}
                          >
                            Get Value</Button>
                          {
                            !hlaLogDetail.generic.log_compiled ?
                              <p style={{ fontSize: '10px', clear: 'both', display: 'block', float: 'right' }}>Log under processing by <span style={{ textTransform: "capitalize" }}>{hlaLogDetail.generic.created_by}</span> </p>
                              : null
                          }
                        </div>
                    }
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Paper>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <div className="base-value-sec-hla-intermediate">
                <div className="base-value-amount">
                  <Grid container spacing={2}>
                    <Grid item md={hlaLogDetail.generic.future_base_value ? 6 : 12}>
                      <Paper className="value-card">
                        <h2 align="center"><NumericalLabel params={getCurrencyFormat({ shortFormat: false })}>{hlaLogDetail.generic.base_value}</NumericalLabel> Million</h2>
                        <h4 align="center" >Base Value </h4>
                      </Paper>
                    </Grid>
                    {
                      hlaLogDetail.generic.future_base_value ?
                        <Grid item md={6}>
                          <Paper className="value-card">
                            <h2 align="center"><NumericalLabel params={getCurrencyFormat({ shortFormat: false })}>{hlaLogDetail.generic.future_base_value}</NumericalLabel> Million</h2>
                            <h4 align="center" >Future Base Value </h4>
                          </Paper>
                        </Grid> : null
                    }
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <AirframeHlaIntermediate
                hlaLogDetail={hlaLogDetail}
                airframeWarningText={airframeWarningText}
                airframeEdit={airframeEdit}
                toggleAirframeEdit={() => this.setState({ airframeEdit: true })}
                onCloseEdit={() => { this.setState({ airframeEdit: false, error: {} }); this.getHlaLogApi(this.props) }}
                onFieldChange={this.onFieldChange}
                error={error && error.airframe ? error.airframe : null}
                updateAirframeLog={() => this.updateAirframeLogApi(this.props, hlaLogDetail.airframe, hlaLogDetail.generic)}
                maintenanceEventChange={this.maintenanceEventChange}
                updateErrorField={this.updateErrorField}
                updateAirframeErrorField={this.updateAirframeErrorField}
                remarksMandatory={() => this.setState({ airframeRemarksFlag: true })}
                airframeRemarksFlag={airframeRemarksFlag}
                lockEdit={lockEdit}
                isLocked={hlaLogDetail.generic.lock_status}
                applicationPermission={applicationPermission}
                editMode ={ ['?edit-mode'].includes(this.props.location.search) }
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: '20px' }} >
              {
                hlaLogDetail.generic.propeller_aircraft ?
                  <EngineHlaHsiIntermediate
                    hlaLogDetail={hlaLogDetail}
                    engineWarningText={engineWarningText}
                    updateEngineField={this.updateEngineField}
                    updateEngineLog={() => this.updateEngineHsiLogApi(this.props, hlaLogDetail.engine, hlaLogDetail.airframe.date_of_manufacture)}
                    toggleEngineEdit={() => this.setState({ enginesEdit: true })}
                    onCloseEdit={() => { this.setState({ enginesEdit: false, error: {} }); this.getHlaLogApi(this.props) }}
                    enginesEdit={enginesEdit}
                    onChangeLLP={this.onChangeLLP}
                    saveLLpData={this.saveLLpData}
                    updateLlpCsn={this.updateLlpCsn}
                    updateHSINone={this.updateHSINone}
                    updatePrsvNone={this.updatePrsvNone}
                    updateHSIEngineField={this.updateHSIEngineField}
                    error={error && error.engine ? error.engine : null}
                    updateEngineErrorField={this.updateEngineErrorField}
                    changeEngineTabs={this.changeEngineTabs}
                    engineTabColor={engineTabColor}
                    tabIndex={tabIndex}
                    toggleLLpModal={this.toggleLLpModal}
                    llpModal={llpModal}
                    closeLLLpModal={() => this.setState({ llpModal: false, hlaLogDetail: logDetailCopy, engineLLP: { primary: [], secondary: [], static: [] }, checkedA: false, checkedB: false })}
                    updateLLP={(data) => this.updateLLPApi(this.props, data, engineLLP, hlaLogDetail)}
                    engineLlpList={engineLLP && engineLLP.primary}
                    secondaryLlpList={engineLLP && engineLLP.secondary}
                    staticLlpList={engineLLP && engineLLP.static}
                    llpCsnValidationFlag={llpCsnValidationFlag}
                    staticLLPEnableFlag={staticLLPEnableFlag}
                    staticLLPDisableFlag={staticLLPDisableFlag}
                    enableStaticLLp={this.enableStaticLLp}
                    disableStaticLLp={this.disableStaticLLp}
                    llpStaticValidationFlag={llpStaticValidationFlag}
                    llpSecondaryValidationFlag={llpSecondaryValidationFlag}
                    selectedStaticLLps={selectedStaticLLps}
                    handleChangeSwitch1={(e) => this.handleChangeA(e)}
                    handleChangeSwitch2={(e) => this.handleChangeB(e)}
                    checkedSwitch1={checkedA}
                    checkedSwitch2={checkedB}
                    changeLLPTabs={this.changeLLPTabs}
                    llpTabIndex={llpTabIndex}
                    airframeModel={hlaLogDetail.airframe}
                    handleLLpImport={this.handleLLpImport}
                    confirmationModal={confirmationModal}
                    files={files}
                    updateEngineValidatorField={this.updateEngineValidatorField}
                    logId={this.props}
                    importLLP={(engine, engineIndex, props, component) => this.importLLPApi(files, engine, engineIndex, this.props, component)}
                    llpTabName={llpTabName}
                    secondaryllpUpdate={(event, engine, engineIndex, component) => this.secondaryllpUpdateApi(this.props, engine, engineIndex, component, { engine_log: engine.id, secondary: event.target.checked })}
                    staticLLpUpdate={(llpId, event, engine, engineIndex, component) => { this.staticLLpUpdateApi(this.props, engine, engineIndex, component, { engine_log: engine.id, static_id: llpId, status: event.target.checked }); this.handleCheckBoxloader(llpId) }}
                    secondaryLLpEnableFlag={secondaryLLpEnableFlag}
                    checkBoxLoader={checkBoxLoader}
                    lockEdit={lockEdit}
                    isLocked={hlaLogDetail.generic.lock_status}
                    applicationPermission={this.props.applicationPermission}
                  />
                  :
                  <EngineHlaIntermediate
                    hlaLogDetail={hlaLogDetail}
                    engineWarningText={engineWarningText}
                    updateEngineField={this.updateEngineField}
                    updateEngineLog={() => this.updateEngineLogApi(this.props, hlaLogDetail.engine, hlaLogDetail.airframe.date_of_manufacture)}
                    toggleEngineEdit={() => this.setState({ enginesEdit: true })}
                    onCloseEdit={() => { this.setState({ enginesEdit: false, error: {} }); this.getHlaLogApi(this.props) }}
                    enginesEdit={enginesEdit}
                    onChangeLLP={this.onChangeLLP}
                    saveLLpData={this.saveLLpData}
                    updateLlpCsn={this.updateLlpCsn}
                    updatePrsvNone={this.updatePrsvNone}
                    error={error && error.engine ? error.engine : null}
                    updateEngineErrorField={this.updateEngineErrorField}
                    changeEngineTabs={this.changeEngineTabs}
                    engineTabColor={engineTabColor}
                    toggleBulkOpsFn={this.toggleBulkOpsFn}
                    tabIndex={tabIndex}
                    toggleLLpModal={this.toggleLLpModal}
                    llpModal={llpModal}
                    closeLLLpModal={() => this.setState({ llpModal: false, hlaLogDetail: logDetailCopy, engineLLP: { primary: [], secondary: [], static: [] }, checkedA: false, checkedB: false })}
                    updateLLP={(data) => this.updateLLPApi(this.props, data, engineLLP, hlaLogDetail)}
                    engineLlpList={engineLLP && engineLLP.primary}
                    secondaryLlpList={engineLLP && engineLLP.secondary}
                    staticLlpList={engineLLP && engineLLP.static}
                    llpCsnValidationFlag={llpCsnValidationFlag}
                    staticLLPEnableFlag={staticLLPEnableFlag}
                    staticLLPDisableFlag={staticLLPDisableFlag}
                    enableStaticLLp={this.enableStaticLLp}
                    disableStaticLLp={this.disableStaticLLp}
                    llpStaticValidationFlag={llpStaticValidationFlag}
                    llpSecondaryValidationFlag={llpSecondaryValidationFlag}
                    selectedStaticLLps={selectedStaticLLps}
                    handleChangeSwitch1={(e) => this.handleChangeA(e)}
                    handleChangeSwitch2={(e) => this.handleChangeB(e)}
                    checkedSwitch1={checkedA}
                    checkedSwitch2={checkedB}
                    changeLLPTabs={this.changeLLPTabs}
                    llpTabIndex={llpTabIndex}
                    airframeModel={hlaLogDetail.airframe}
                    logId={this.props}
                    pageLoader={pageLoader}
                    llpTabName={llpTabName}
                    secondaryllpUpdate={(event, engine, engineIndex, component) => this.secondaryllpUpdateApi(this.props, engine, engineIndex, component, { engine_log: engine.id, secondary: event.target.checked })}
                    staticLLpUpdate={(llpId, event, engine, engineIndex, component) => { this.staticLLpUpdateApi(this.props, engine, engineIndex, component, { engine_log: engine.id, static_id: llpId, status: event.target.checked }); this.handleCheckBoxloader(llpId) }}
                    secondaryLLpEnableFlag={secondaryLLpEnableFlag}
                    checkBoxLoader={checkBoxLoader}
                    importLLP={(engine, engineIndex, props, component) => this.importLLPApi(files, engine, engineIndex, this.props, component)}
                    handleLLpImport={this.handleLLpImport}
                    confirmationModal={confirmationModal}
                    files={files}
                    closeConfirmationModal={() => this.setState({ confirmationModal: false })}
                    updateEngineValidatorField={this.updateEngineValidatorField}
                    lockEdit={lockEdit}
                    isLocked={hlaLogDetail.generic.lock_status}
                    applicationPermission={this.props.applicationPermission}
                  />
              }
            </Grid>
            {
              hlaLogDetail.propeller && Object.keys(hlaLogDetail.propeller).length ?
                <Grid item xs={12} md={12} style={{ marginTop: '20px' }}>
                  <PropellerIntermediate
                    hlaLogDetail={hlaLogDetail}
                    propellerEdit={propellerEdit}
                    propellerWarningText={propellerWarningText}
                    togglePropellerEdit={() => this.setState({ propellerEdit: true })}
                    onCloseEdit={() => { this.setState({ propellerEdit: false, error: {} }); this.getHlaLogApi(this.props) }}
                    propellerUpdateFn={this.propellerUpdateFn}
                    updatePropellerLog={() => this.updatePropellerLogApi(this.props, hlaLogDetail.propeller, hlaLogDetail.airframe.date_of_manufacture)}
                    error={error && error.propeller ? error.propeller : null}
                    updatePropellerError={this.updatePropellerError}
                    tabColor={tabColor}
                    propellerTabIndex={propellerTabIndex}
                    changePropellerTabs={this.changePropellerTabs}
                    lockEdit={lockEdit}
                    isLocked={hlaLogDetail.generic.lock_status}
                    applicationPermission={this.props.applicationPermission}
                  />
                </Grid>
                : null
            }
            {
              Object.keys(hlaLogDetail.apu).length ?
                <Grid item xs={12} md={12} style={{ marginTop: '20px' }}>
                  <ApuHlaIntermediate
                    hlaLogDetail={hlaLogDetail}
                    apuEdit={apuEdit}
                    toggleApuEdit={() => this.setState({ apuEdit: true })}
                    onCloseEdit={() => { this.setState({ apuEdit: false, error: {} }); this.getHlaLogApi(this.props) }}
                    onFieldChange={this.onFieldChange}
                    updateApuLog={() => this.updateApuLogApi(this.props, hlaLogDetail.apu, hlaLogDetail.airframe.date_of_manufacture)}
                    error={error && error.apu ? error.apu : null}
                    updateErrorField={this.updateErrorField}
                    lockEdit={lockEdit}
                    isLocked={hlaLogDetail.generic.lock_status}
                    applicationPermission={this.props.applicationPermission}
                    apuWarningText={apuWarningText}
                  />
                </Grid> : null
            }
            <Grid item xs={12} md={12} style={isMobile ? { marginTop: 'auto' } : { marginTop: '20px' }}>
              <LgHlaIntermediate
                hlaLogDetail={hlaLogDetail}
                lgEdit={lgEdit}
                lgWarningText={lgWarningText}
                toggleLgEdit={() => this.setState({ lgEdit: true })}
                onCloseEdit={() => { this.setState({ lgEdit: false, error: {} }); this.getHlaLogApi(this.props) }}
                lgUpdateFn={this.lgUpdateFn}
                updateLgLog={() => this.updateLgLogApi(this.props, hlaLogDetail.landing_gear, hlaLogDetail.airframe.date_of_manufacture)}
                error={error && error.lg ? error.lg : null}
                updateLgErrorField={this.updateLgErrorField}
                tabColor={tabColor}
                lgTabIndex={lgTabIndex}
                changeLgTabs={this.changeLgTabs}
                lockEdit={lockEdit}
                isLocked={hlaLogDetail.generic.lock_status}
                applicationPermission={this.props.applicationPermission}
              />
            </Grid>
          </Grid>
        </div>
      )
    } else {
      return <PageLoader />
    }

  }
}
export default withSnackbar(IntermediateHla);
