import React, { Component, Fragment} from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button , Dialog, DialogTitle, DialogContent, DialogActions, Grid, TextField } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { fieldDateFormat, backendDateFormat, displayDateFormat } from '../../../constants';
import moment from 'moment';
import {DropzoneArea} from 'material-ui-dropzone';
import EditIcon from '@material-ui/icons/Edit';
import { documentCru, errorCodeDocument } from '..';
import { addEditContractDocumentApi } from '../apiServices';
import { checkApiStatus } from '../../../utils';
class AddEditDocument extends Component{
  constructor(props) {
    super(props)
    this.state = {
      modal:false,
      error:{},
      documentObj:{},
      contractImageFlag:false,
      contractFileName:''
    }
    this.addEditContractDocumentApi = addEditContractDocumentApi.bind(this)
  }
  uploadDataFile = (file) => {
    this.setState(prevState => ({
      ...prevState,
      documentObj:{
        ...prevState.documentObj,
        contract_file:file
      },
      contractImageFlag:true,
      contractFileName:file.name
    }))
  }
  updateErrorField = (key, message) => {

  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      documentObj:{
        ...prevState.documentObj,
        [keyParam]:data
      }
    }))
  }
  eddEditContractDocumentFn = () => {
    const { documentObj, contractImageFlag } = this.state
    let formData = new FormData()
    if(contractImageFlag){
      formData.append('contract_file', documentObj.contract_file)
    }
    if(documentObj.start_date != null && documentObj.start_date.trim()!= ''){
      formData.append('start_date', documentObj.start_date)
    }
    if(documentObj.end_date != null && documentObj.end_date.trim() != ''){
      formData.append('end_date', documentObj.end_date)
    }
    if(documentObj.name != null && documentObj.name !=''){
      formData.append('name', documentObj.name)
    }
    let validationInputs = {
      contract_file: ''
    }
    if(Object.keys(validationInputs).every((k)=> { return validationInputs[k] === ''})){
      this.addEditContractDocumentApi(this.props,formData, documentObj.id)
      .then(response => {
        if(checkApiStatus(response)){
          this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{vertical:'top',horizontal:'right'}})
          this.setState({ modal:false, documentObj:{}})
          this.props.getResponseBack();
        }else {
          this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{ vertical:'top', horizontal:'right'}})
        }
      })
    }else {
      this.setState({
        error: validationInputs
      })
    }
  }
  render(){
    const { modal, error, documentObj,contractFileName } = this.state
    const { id, documentData, mode } = this.props
    return(
      <Fragment>
        { id ?
          <EditIcon fontSize="small" color='primary' onClick={()=> this.setState({modal:true, documentObj:documentData, contractFileName:documentData.file_name})} className="cta-mouse" />
            :<Button color="primary" variant="contained" className="buttons-cta" onClick={()=> this.setState({modal:true,documentObj: documentCru })}> Add Document </Button>
        }
      { modal ?
        <Dialog
          open={modal}
          onClose={()=> this.setState({ modal: false})}
          aria-labelledby='scroll-dialog-title'
          >
        <DialogTitle>
          {mode} Document
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Title"
                fullWidth
                margin="normal"
                value={documentObj.name}
                onChange={(e)=> this.onFieldChange(e, 'name', e.target.value)}
                InputLabelProps={{shrink:true}}
              />
            </Grid>
            <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 margin="normal"
                 id="start_date"
                 label='Start Date'
                 format={fieldDateFormat}
                 fullWidth
                 InputLabelProps={{shrink: true}}
                 value={documentObj.start_date ? moment(documentObj.start_date).format(displayDateFormat) :null}
                 onChange={(data, value) => this.onFieldChange(value, 'start_date', moment(data).format(backendDateFormat))}
                 KeyboardButtonProps={{
                   'aria-label': 'Start Date',
                 }}
                 maxDate={moment(documentObj.end_date)}
               />
            </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 margin="normal"
                 id="end_date"
                 label='End Date'
                 format={fieldDateFormat}
                 fullWidth
                 InputLabelProps={{shrink: true}}
                 value={documentObj.end_date ? moment(documentObj.end_date).format(displayDateFormat): null}
                 onChange={(data, value) => this.onFieldChange(value, 'end_date', moment(data).format(backendDateFormat))}
                 KeyboardButtonProps={{
                   'aria-label': 'Start Date',
                 }}
                 minDate={ documentObj.start_date ? moment(documentObj.start_date) : null}
               />
             </MuiPickersUtilsProvider>
             </Grid>
             <Grid item xs={12}>
               <label className="for-label">Project Attachment</label>
               { documentObj.url ?  <a style={{marginLeft:'5px'}} download={`${documentObj.start_date}`} href={documentObj.url}>View</a> : null}
               <span style={{fontSize:'12px',color:'#2f8cfe', display:'block', wordBreak:'break-all', marginRight:'30px', marginBottom:'10px'}}>{contractFileName}</span>
               <DropzoneArea
                 dropzoneText="Drag & Drop Attachment or Click"
                 clearOnUnmount={true}
                 filesLimit={1}
                 previewFridProps={{container: { spacing: 1, direction: 'row'}}}
                 maxFileSize={10000000}
                 showPreviews={true}
                 showPreviewsInDropzone={false}
                 showFileNames={true}
                 useChipsForPreview
                 maxWidth="sm"
                 dropzoneClass="drag-drop-cnt"
                 previewText='Selected file'
                 showAlerts={['error', 'info']}
                 onDrop={(files) => this.uploadDataFile(files[0])}
                 alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
               />
             <h6>{error.contract_file}</h6>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={()=> this.setState({modal:false})}>Cancel</Button>
          <Button color='primary' variant="contained" onClick={()=> this.eddEditContractDocumentFn()}>Save</Button>
        </DialogActions>
        </Dialog>: null
      }

      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(AddEditDocument));
