import { globalGetService, globalPostService, globalPutService, globalExportService, globalDeleteService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, removeEmptyKey } from '../../utils';
import moment from 'moment';
import { fieldValidation } from '../../utils/formValidation';
import { trackActivity } from '../../utils/mixpanel';
import { displayDateFormatShort } from '../../constants';
import { LEVErrorCode } from '.';

export function getAircraftModsApi(props = {}, query = {}) {
  globalGetService(`valuation/mods/`, query)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({ mods: response.data.data.mods });
      }
    })
}
export function getMntEventsApi(props = {}, aircraftTypeId) {
  globalGetService(`console/aircraft-type/${aircraftTypeId}/events/`, { module: 'valuation' })
    .then(response => {
      if (checkApiStatus(response)) {
        let maintanenceList = [];
        const data = response.data.data;
        Object.keys(data).map((item, index) => {
          maintanenceList.push({ label: data[item].replace(/,/g, '/') + '', value: item })
        });
        this.setState({ mntGroups: maintanenceList });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function getRentalTypesApi(data) {
  globalPostService(`console/get-constants/`, data)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({
          levRentalTypes: response.data.data
        })
      }
    })
}
export function getRentalPaymentTypesApi(data) {
  globalPostService(`console/get-constants/`, data)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({
          levRentalPaymentTypes: response.data.data
        })
      }
    })
}
export function getEngineSvApi(aircraftId, engineId) {
  globalGetService(`valuation/get-engine-sv-events/${aircraftId}/${engineId}/`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          svData: response.data.data,
          engineNo: response.data.data.no_of_engines,
          leaseEnCrud: {
            ...prevState.leaseEnCrud,
            engine_sv_details: response.data.data,
          }
        }))
        if (response.data.data.is_propeller_aircraft) {
          this.setState(prevState => ({
            ...prevState,
            leaseEnCrud: {
              ...prevState.leaseEnCrud,
              is_apu_disabled: false
            }
          }))
        }
      }
    })
}

export function generateLEVLogApi(props, data) {
  const { engineNo } = this.state
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    serial_number: LEVErrorCode['serial_number'][fieldValidation({ ...LEVErrorCode['serial_numberObj'], fieldval: data.serial_number })],
    aircraft_type_id: LEVErrorCode['aircraft_type_id'][fieldValidation({ ...LEVErrorCode['aircraft_type_idObj'], fieldval: data.aircraft_type_id })],
    date_of_manufacture: moment(data.date_of_manufacture) > moment(data.base_value_date) ? "Please enter Date of Manufacture less than Report Date" : LEVErrorCode['date_of_manufacture'][fieldValidation({ ...LEVErrorCode['date_of_manufactureObj'], fieldval: data.date_of_manufacture })],
    base_value_date: (moment(data.base_value_date) < moment(data.date_of_manufacture) || moment(data.base_value_date).isSame(data.date_of_manufacture)) ? "Report Date cannot be less than or equal to Date of Manufacture" : LEVErrorCode['base_value_date'][fieldValidation({ ...LEVErrorCode['base_value_dateObj'], fieldval: data.base_value_date })],
    engine_type_id: LEVErrorCode['engine_type_id'][fieldValidation({ ...LEVErrorCode['engine_type_idObj'], fieldval: data.engine_type_id })],
    mtow_lbs: LEVErrorCode['mtow_lbs'][fieldValidation({ ...LEVErrorCode['mtow_lbsObj'], fieldval: data.mtow })],
    mtow_kgs: LEVErrorCode['mtow_kgs'][fieldValidation({ ...LEVErrorCode['mtow_kgsObj'], fieldval: data.mtow_kgs })],
    lease_start_date: (moment(data.lease_start_date) > moment(data.base_value_date)) ? "Please enter Lease Start Date less than or equal to Report Date" : (moment(data.lease_start_date) < moment(data.date_of_manufacture)) ? "Please enter Lease Start Date greater than Date of Manufacture" : LEVErrorCode['lease_start_date'][fieldValidation({ ...LEVErrorCode['lease_start_dateObj'], fieldval: data.lease_start_date })],
    lease_end_date: moment(data.lease_end_date).diff(moment(data.lease_start_date), 'days') < 30.44 ? "Lease term should be of one month duration" : (moment(data.lease_end_date) < moment(data.base_value_date)) ? "Please enter Lease End Date greater than or equal to Report Date" : moment(data.date_of_manufacture).add(25, 'years') < moment(data.lease_end_date) ? "Please enter Lease End Date less than 25 years from Date of Manufacture" : LEVErrorCode['lease_end_date'][fieldValidation({ ...LEVErrorCode['lease_end_dateObj'], fieldval: data.lease_end_date })],
    rental_type: LEVErrorCode['rental_type'][fieldValidation({ ...LEVErrorCode['rental_typeObj'], fieldval: data.rental_type })],
    markdown_on_residual_value: !data.markdown_on_residual_value ? "Please enter Markdown Residual Value" : data.markdown_on_residual_value > 100 ? "Please enter a value between 0 and 100" : data.markdown_on_residual_value < 0  ? 'Please enter a value between 0 and 100' : '',
    annual_discount: data.annual_discount < 4.5 ? 'Annual Discount should be in between 4.5% to 20%' : data.annual_discount > 20 ? 'Annual Discount should be in between 4.5% to 20%' : '',
  }
  if (data.rental_type && data.rental_type.label !== "Stepped") {
    validationInputs = {
      ...validationInputs,
      rent: LEVErrorCode['rent'][fieldValidation({ ...LEVErrorCode['rentObj'], fieldval: data.rent })],
    }
  }
  if (data.is_full_life_maintenance_condition) {
    validationInputs = {
      ...validationInputs,
      maintenance_program: LEVErrorCode['maintenance_program'][fieldValidation({ ...LEVErrorCode['maintenance_programObj'], fieldval: data.maintenance_program })]
    }
  }
  // if(data.lessee_id && !data.lessee_id.id){
  //   validationInputs = {
  //     ...validationInputs,
  //     country:LEVErrorCode['country'][fieldValidation({...LEVErrorCode['countryObj'], fieldval: data.lessee_id.country})],
  //     contact_address:LEVErrorCode['contact_address'][fieldValidation({...LEVErrorCode['contact_addressObj'], fieldval: data.lessee_id.contact_address})],
  //     logo:LEVErrorCode['logo'][fieldValidation({...LEVErrorCode['logoObj'], fieldval: data.lessee_id.logo})],
  //   }
  // }
  if (data.step_rentals) {
    data.step_rentals.map((item, index) => {
      validationInputs = {
        ...validationInputs,
        [index]: stepRentalValidation(item, data)
      }
    })
  }

  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    this.setState({ pageLoader: true })
    let payload = Object.assign({}, data)
    payload = {
      ...payload,
      aircraft_type_id: data.aircraft_type_id.id,
      engine_type_id: data.engine_type_id.id,
      lessee_id: data.lessee_id ? data.lessee_id : null,
      rental_type: data.rental_type.value,
      mods: data.mods ? data.mods.map(mod => mod.id) : null,
      rent: data.rent ? parseFloat(data.rent) : null,
      annual_discount: data.annual_discount ? parseFloat(data.annual_discount) : 0,
      markdown_on_residual_value: parseFloat(data.markdown_on_residual_value),
      annual_inflation_rate: data.annual_inflation_rate ? parseFloat(data.annual_inflation_rate) : 0,
      asset_management_charges: data.asset_management_charges ? parseFloat(data.asset_management_charges) : null,
      maintenance_program: data.maintenance_program ? data.maintenance_program.value : null
    }
    if (payload.is_full_life_maintenance_condition) {
      payload = {
        ...payload,
        engine_sv_details: payload.engine_sv_details.engines.filter((engine, index) => engineNo >= index + 1).map(item => ({ ...item, last_performed_sv: item.last_performed_sv ? item.last_performed_sv.id : null, last_performed_hsi: item.last_performed_hsi ? item.last_performed_hsi.id : null }))
      }
    }
    if (payload.step_rentals) {
      payload = {
        ...payload,
        step_rentals: payload.step_rentals.map(item => ({ ...item, rent: parseFloat(item.rent) }))
      }
    }
    globalPostService(`valuation/lease-encumbered-value/`, payload)
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          props.history.push(`/lease-encumbered/output/${response.data.data.generic.id}`)
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          trackActivity('Lease Encumbered Value', {
            event_type: 'Log Generated Successfully',
            log_id: response.data.data.generic ? response.data.data.generic.id : '',
            rental_type: payload.rental_type === 1 ? 'Fixed' : payload.rental_type === 2 ? 'Stepped' : payload.rental_type === 3 ? 'Quarterly' : '',
            rental_payment_type: payload.rental_payment_type === 1 ? 'In Advance' : 'In Arrear'
          })
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          trackActivity('Lease Encumbered Value', {
            event_type: 'Log Generation Failed',
            error_details: response.data.message,
            log_id: response.data.data.generic ? response.data.data.generic.id : '',
            rental_type: payload.rental_type === 1 ? 'Fixed' : payload.rental_type === 2 ? 'Stepped' : payload.rental_type === 3 ? 'Quarterly' : '',
            rental_payment_type: payload.rental_payment_type === 1 ? 'In Advance' : 'In Arrear',
            error_source: 'Backened'
          })
        }
      })
  } else {
    this.setState({
      error: validationInputs
    })
    trackActivity('Lease Encumbered Value', { event_type: 'Log Generation Failed', error_details: validationInputs, error_type: 'Validation', error_source: 'Frontend' })
  }

}
export function getLevDataApi(props = {}) {
  this.setState({ pageLoader: true })
  globalGetService(`valuation/lease-encumbered-value/${props.match.params.id}/`)
    .then(response => {
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        let data = response.data.data
        this.setState(prevState => ({
          ...prevState,
          leaseEnCrud: {
            ...prevState.leaseEnCrud,
            serial_number: data.generic.serial_number,
            aircraft_type_id: data.generic.aircraft_type,
            base_value_date: data.generic.base_value_date,
            date_of_manufacture: data.generic.date_of_manufacture,
            engine_type_id: data.generic.engine_type,
            lessee_id: data.generic.lessee,
            mods: data.generic.mods,
            mtow: data.generic.mtow,
            annual_discount: data.lev.annual_discount,
            annual_inflation_rate: data.lev.annual_inflation_rate,
            rent: data.lev.rent,
            id: data.generic.id,
            lease_start_date: data.lev.lease_start_date,
            lease_end_date: data.lev.lease_end_date,
            rental_type: data.lev.rental_type,
            rental_payment_type: data.lev.rental_payment_type,
            asset_management_charges: data.lev.asset_management_charges,
            markdown_on_residual_value: data.lev.markdown_on_residual_value,
            step_rentals: data.lev.step_rentals ? data.lev.step_rentals : [],
            maintenance_program: data.lev.maintenance_program ? data.lev.maintenance_program : '',
            is_full_life_maintenance_condition: data.lev.is_full_life_maintenance_condition,
            assumptions:data.generic.assumptions
          }

        }))
        if (data.lev && data.lev.engine_sv_details && data.lev.engine_sv_details.length) {
          this.setState(prevState => ({
            ...prevState,
            leaseEnCrud: {
              ...prevState.leaseEnCrud,
              engine_sv_details: data.lev.engine_sv_details,
              no_of_engines: data.lev.engine_sv_details.length
            }
          }))
        }
        this.getAircraftModsApi(props, { aircraft_type: data.generic.aircraft_type.id })
      }
    })
}
export function updateLogApi(props = {}, data = {}) {
  debugger
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    markdown_on_residual_value: data.markdown_on_residual_value === 0 ? '' : !data.markdown_on_residual_value ? "Please enter Markdown Residual Value" : data.markdown_on_residual_value > 100 ? "Please enter a value between -100 and 100" : data.markdown_on_residual_value < -100 ? 'Please enter a value between -100 and 100' : '',
    annual_discount: data.annual_discount < 4.5 ? 'Annual Discount should be in between 4.5% to 20%' : data.annual_discount > 20 ? 'Annual Discount should be in between 4.5% to 20%' : '',
  }
  if (data.rental_type.label !== "Stepped") {
    validationInputs = {
      ...validationInputs,
      rent: LEVErrorCode['rent'][fieldValidation({ ...LEVErrorCode['rentObj'], fieldval: data.rent })],
    }
  }
  if (data.step_rentals) {
    data.step_rentals.map((item, index) => {
      validationInputs = {
        ...validationInputs,
        [index]: stepRentalValidation(item, data)
      }
    })
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    let payload = Object.assign({}, data)
    payload = {
      ...payload,
      aircraft_type_id: data.aircraft_type_id.id,
      assumptions: data.assumptions,
      engine_type_id: data.engine_type_id.id,
      lessee_id: data && data.lessee_id && data.lessee_id.id ? data.lessee_id.id : null,
      rental_type: data.rental_type.id,
      rental_payment_type: data.rental_payment_type.id,
      mods: data.mods ? data.mods.map(mod => mod.id) : null,
      rent: parseFloat(data.rent),
      annual_discount: data.annual_discount ? parseFloat(data.annual_discount) : 0,
      markdown_on_residual_value: parseFloat(data.markdown_on_residual_value),
      annual_inflation_rate: data.annual_inflation_rate ? parseFloat(data.annual_inflation_rate) : 0,
      asset_management_charges: data.asset_management_charges ? parseFloat(data.asset_management_charges) : null,
    }
    if (data.engine_sv_details && data.engine_sv_details.length) {
      payload = {
        ...payload,
        engine_sv_details: payload.engine_sv_details.map(engine => ({ ...engine, last_performed_sv: engine.last_performed_sv ? engine.last_performed_sv.id : null, last_performed_hsi: engine.last_performed_hsi ? engine.last_performed_hsi.id : null })),
        maintenance_program: data.maintenance_program ? data.maintenance_program.value : null
      }
    }
    this.setState({ pageLoader: true })
    globalPutService(`valuation/lease-encumbered-value/${props.match.params.id}/`, payload)
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          this.props.history.push(`/lease-encumbered/output/${props.match.params.id}`)
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
      })
  } else {
    this.setState({
      error: validationInputs
    })
  }
}
export function updateAssumptionApi(props, data) {
  debugger
  this.setState({ pageLoader: true })
  globalPutService(`valuation/lease-encumbered-value/${props.match.params.id}/`, { assumptions: data })
    .then(response => {
      if (checkApiStatus(response)) {
        this.getLevOutputDataApi(props)
        this.setState({ editAsumpFlag: false })
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
      this.setState({ pageLoader: false })
    })
}
export function getLevOutputDataApi(props = {}) {
  this.setState({ pageLoader: true })
  globalGetService(`valuation/lease-encumbered-value/${props.match.params.id}/`)
    .then(response => {
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        let data = response.data.data
        this.setState({
          levOutputData: response.data.data,
        })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}

export function exportResultApi(props, query = {}) {
  this.setState({ exportLoader: true })
  globalExportService(`valuation/lease-encumbered-value/${props.match.params.id}/`, { download: query.extension })
    .then(response => {
      trackActivity('Exported Result', {
        type: "Lease Encumbered Value",
        log_id: props.match.params.id,
        extension: query.extension
      })
      this.setState({ exportLoader: false });
      downloadFileType(response.data, (`LogId_${this.props.match.params.id}_Lease_Encumbered_Value_${moment().format(displayDateFormatShort)}`), query.extension);
    })
}
export function stepRentalValidation(data, leaseEnCrud) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    start_date: LEVErrorCode['start_date'][fieldValidation({ ...LEVErrorCode['start_dateObj'], fieldval: data.start_date })],
    end_date: moment(data.end_date) > moment(leaseEnCrud.lease_end_date) ? 'Please enter End Date less than Lease End Date' : LEVErrorCode['end_date'][fieldValidation({ ...LEVErrorCode['end_dateObj'], fieldval: data.end_date })],
    rent: LEVErrorCode['stepRent'][fieldValidation({ ...LEVErrorCode['stepRentObj'], fieldval: data.rent })],
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return ""
  } else {
    return validationInputs
  }
}

export function uploadLesseeLogoApi(file) {
  let formData = new FormData()
  formData.append('file', file)
  globalPostService(`/console/upload-lessee-logo/`, formData)
    .then(response => {
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          leaseEnCrud: {
            ...prevState.leaseEnCrud,
            lessee_id: {
              ...prevState.leaseEnCrud.lessee_id,
              logo: response.data.data.path
            }
          }
        }))
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}

export function updateLockApi(props = {}, data = {}) {
  this.setState({ pageLoader: true })
  globalPutService(`valuation/update-lock/`, data)
    .then(response => {
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.getLevOutputDataApi(props)
        trackActivity(data.is_locked === true ? 'Log Locked' : 'Log Unlocked', { page_title: props.location.pathname.includes('intermediate') ? 'Intermediate' : 'Log Listing', log_id: data.log_id })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });

      }
    })

}
