import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import { TableRowActions } from '../../../shared_elements';
import { checkUserAccessPermission } from '../../../utils';
import { displayDateFormatShort } from '../../../constants';
import baseValueIcon from '../../../assets/images/bv_icon.svg';
import futureValueIcon from '../../../assets/images/fv_icon.svg';
import hlaIcon from '../../../assets/images/hla_icon.svg';
import leaseEncumbIcon from '../../../assets/images/lease_encumbered_value.svg';
import lockIcon from '../../../assets/images/NewLockIcon.png';
import unlockIcon from '../../../assets/images/unlockicon.png';
const ValuationLog = ({item, history, updateLockfn, props, applicationPermission}) => {
  const redirectToApplication = () => {
    if(item.utility_type == 'Half Life Adjustment'){
      history.push(`/half-life/intermediate/${item.id}`)
    }
    if(item.utility_type == "Lease Encumbered Value"){
      history.push(`/lease-encumbered/output/${item.id}`)
    }
    if(item.utility_type == "Base Value" ||item.utility_type == "Future Value"){
      history.push(`/base-value/${item.id}`)
    }
    if(item.utility_type == "Future Value") {
      history.push(`/future-value-projection/${item.id}`)
    }
  }
  return(
    <TableRow hover tabIndex={-1} style={{cursor:'pointer'}}>
      <TableCell onClick={() => redirectToApplication()}>{item.id}</TableCell>
      <TableCell onClick={() => redirectToApplication()}>{item.serial_number ? item.serial_number:'--'}</TableCell>
      <TableCell onClick={() => redirectToApplication()}>{item.aircraft_type}</TableCell>
      <TableCell onClick={() => redirectToApplication()}>{moment(item.date_of_manufacture).format(displayDateFormatShort)}</TableCell>
      <TableCell onClick={() => redirectToApplication()}>{item.engine_type}</TableCell>
      <TableCell onClick={() => redirectToApplication()}>
        {`${item.mtow.toFixed(2)} Lbs`}
        <br/>
        {`${(item.mtow*0.4535970244).toFixed(2)} Kgs`}
      </TableCell>
      <TableCell onClick={() => redirectToApplication()}> {item.created_by.name} <br/>{moment(item.created_at).format(displayDateFormatShort)}</TableCell>
      <TableCell onClick={() => redirectToApplication()}>
        <ul className="list-inline valuation-logs-links">
          { item.is_base_value ?
            <li className="list-inline-item">
              <Link to={`/base-value/${item.id}`}>
                <Tooltip title="Base Value" arrow>
                  <img style={{width:'20px', opacity:'.75'}} src={baseValueIcon} alt=""/>
                </Tooltip>
              </Link>
            </li>:null
          }
          { item.is_future_value ?
            <li className="list-inline-item">
              <Link to={`/future-value-projection/${item.id}`}>
                <Tooltip title="Future Base Values" arrow>
                  <img style={{width:'20px', opacity:'.75'}} src={futureValueIcon} alt=""/>
                </Tooltip>
              </Link>
            </li>:null
          }
          {
            item.utility_type == 'Half Life Adjustment' ?
            <li className="list-inline-item">
              <Link to={`/half-life/intermediate/${item.id}`}>
                <Tooltip title="Maintenance Adjusted Base Value" arrow>
                  <img style={{width:'20px', opacity:'.75'}} src={hlaIcon} alt=""/>
                </Tooltip>
              </Link>
            </li>:null
          }
          {
            item.utility_type == "Lease Encumbered Value" ?
            <li className="list-inline-item">
              <Link to={`/lease-encumbered/output/${item.id}`}>
                <Tooltip title="Lease Encumbered Value" arrow>
                  <img style={{width:'20px', opacity:'.75'}} src={leaseEncumbIcon} alt="" />
                </Tooltip>
              </Link>
            </li> : null
          }
        </ul>
      </TableCell>
      <TableCell>
         { checkUserAccessPermission(applicationPermission, 'valuation', 'hthl', 'U') ?
            item.lock_status ?
           <Tooltip title="Click to resume editing." placement="top" arrow>
              <img src={lockIcon} width="30" onClick={() => updateLockfn(props, {log_id:item.id, is_locked:false })} alt="Icon"/>
           </Tooltip>
           :
           <Tooltip title="Click to lock editing." placement="top" arrow>
             <img src={unlockIcon} width="35" onClick={() => updateLockfn(props, {log_id:item.id, is_locked:true })} alt="Icon"/>
           </Tooltip>
           :
           item.lock_status ? <img src={lockIcon} width="30" alt="Icon" style={{cursor:'default'}} /> : <img src={unlockIcon} width="35" alt="Icon" style={{cursor:'default'}} />
         }
       </TableCell>
    </TableRow>
  )
}
export default ValuationLog;
