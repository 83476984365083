import React, { Component, Fragment } from 'react';
import { TableListComp, EmptyCollection} from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import { InviteList } from '../components';
import { Typography } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { getUserInviteListApi } from '../apiServices';
export default class UserInviteList extends Component {
  constructor(props){
    super(props);
    this.state = {
      userInviteInfo:{
        list:[],
        pagination:{}
      },
      skeletonLoader:false
    }
    this.getUserInviteListApi = getUserInviteListApi.bind(this)
  }
  componentDidMount(){
    this.getUserInviteListApi(this.props, { page:1})
  }
  render(){
    const { userInviteInfo, skeletonLoader} = this.state
    return(
      <Fragment>
        <div className="flex-centered" style={{marginBottom: '20px'}}>
          <div className="page-description">
            <Typography variant="h2" className="page-title">User Invites</Typography>
            { !isMobile ? <p className="page-para">Contains pending requests from users</p>:null}
          </div>
        </div>
        { skeletonLoader ?  <STableLoader count={4} /> :
          <TableListComp
            heads={[
              {label:'Name',sortOption:false},
              {label:'Email',sortOption:false},
              {label:'Contact',sortOption:false},
              {label:'Designation',sortOption:false},
              {label:'Description',sortOption:false},
              {label:'Action',sortOption:false},
            ]}
            data={<InviteList item={userInviteInfo.list} />}
            noRecord={userInviteInfo.list.length ? null : <EmptyCollection title="No users found" />}
            pagination={userInviteInfo.pagination}
          />
        }

      </Fragment>
    )
  }
}
