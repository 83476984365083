import React, {Fragment} from 'react'
import { Paper, Grid, TextField, Button, InputAdornment, Tooltip, FormControl,FormGroup, FormLabel,RadioGroup,FormControlLabel, Radio, Switch } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { regexConstants } from '../../../constants/regEx';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
const ApuHlaInitiate = ({apuGenInfo, updateErrorField, onFieldChange, apuTypes, error, airframeHlaGenInfo, genericHlaInfo, apuOptional,updateApuOptional}) => {
  const dom = moment(airframeHlaGenInfo.date_of_manufacture)
  const currentDate = moment()
  const diffCurrent = (currentDate.diff(dom) /86400000) * 0.00273973
  const maxDate = moment().add(25 - diffCurrent, 'years')
  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <h4 className="hla-initiate-card-title"><strong>APU </strong>
          {
            airframeHlaGenInfo && airframeHlaGenInfo.aircraftType &&  airframeHlaGenInfo.aircraftType.id && [43].includes(airframeHlaGenInfo.aircraftType.id) ?
            <FormControl component="fieldset" style={{position:'relative', top:'-8px', right:'0px'}}>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value={apuOptional}
                  control={<Switch color="primary" checked={apuOptional} onChange={updateApuOptional} />}
                  labelPlacement="start"
                  label={apuOptional ? <span style={{color:'rgba(0, 0, 0, 0.54)'}}>Disable APU</span> : <span style={{color:'rgba(0, 0, 0, 0.54)'}}>Enable APU</span>}
                />
              </FormGroup>
            </FormControl> : null
          }

          </h4>
        </Grid>
      </Grid>
      <Paper className="hla-initiate-card" style={{opacity:!apuOptional ? '0.5':"1"}}>
        <Grid container spacing={isMobile? 1:3} style={isMobile?{padding:'0px'}:{padding:'10px'}}>
          <Grid item xs={12} md={4}>
            <Autocomplete
              options = {apuTypes}
              getOptionLabel={option => option.name}
              id="apuType"
              value={apuGenInfo.apuType}
              disabled={!apuOptional}
              onChange={(e, value) => onFieldChange(e, 'apuType', value)}
              renderInput={params => <TextField error={error.apuType ? true :false} helperText={error.apuType ? error.apuType : ''} onFocus={() =>  updateErrorField('apuType', '')} {...params} label="APU Type" margin="normal" InputLabelProps={{shrink:true}} fullWidth />}
              />
          </Grid>
          <Grid item xs={12} md={4}>
          <TextField
            required
            id="apu_hours"
            label='Time Since New'
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{shrink: true}}
            value={apuGenInfo.hours}
            disabled={!apuOptional}
            onChange={(e) =>  { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'hours', e.target.value):e.preventDefault()}}
            error={error.apu_hours ? true:false}
            helperText={error.apu_hours? error.apu_hours:''}
            onFocus={() => updateErrorField('apu_hours','')}
            InputProps={{
              endAdornment: <InputAdornment position="end">APU H</InputAdornment>,
            }}
          />
          </Grid>
          <Grid item xs={12} md={4}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                 required
                 margin="normal"
                 id="utilization_as_of_date"
                 label=" Utilization as of date"
                 placeholder="DD-MM-YYYY"
                 fullWidth
                 format={fieldDateFormat}
                 disabled={!apuOptional}
                 minDate={genericHlaInfo.date_of_manufacture ? moment(genericHlaInfo.date_of_manufacture) : moment().subtract(25, 'years') }
                 maxDate={genericHlaInfo.report_date ? moment(genericHlaInfo.report_date) : moment()}
                 InputLabelProps={{shrink: true}}
                 onChange={(data, value) => onFieldChange(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat):data)}
                 value={apuGenInfo.utilization_as_of_date}
                 error={error.utilization_as_of_dateApu ? true:false}
                 helperText={error.utilization_as_of_dateApu? error.utilization_as_of_dateApu:''}
                 onFocus={() => updateErrorField('utilization_as_of_dateApu','')}
                 onOpen={() => updateErrorField('utilization_as_of_dateApu','')}
               />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>

  )
}
export default ApuHlaInitiate;
