import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import AddEditAvailabilityTracker from './AddEditAvailabilityTracker';
import moment from 'moment';
import { displayDateFormat } from '../../../constants'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
export default function AvailabilityTrackerList({item, getResponseBack, deleteAvailabilityStatus}){
  return(
    <TableRow>
      <TableCell>{item.status.label}</TableCell>
      <TableCell>{item.from_date ? moment(item.from_date).format(displayDateFormat) : '--'}</TableCell>
      <TableCell>{item.to_date ? moment(item.to_date).format(displayDateFormat):'--'}</TableCell>
      <TableCell>{item.remarks ? item.remarks : '--'}</TableCell>
      <TableCell>
        <div style={{float:'right'}}>
          <AddEditAvailabilityTracker
            id={item.id}
            mode="Edit"
            availabilityTrackerData={item}
            status={item.status.value}
            getResponseBack={getResponseBack}
          />
          <DeleteOutlinedIcon
            color="secondary"
            fontSize='small'
            onClick={(id)=> deleteAvailabilityStatus(item.id)}
            className="cta-mouse"
          />
        </div>

    </TableCell>
    </TableRow>
  )
}
