import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Grid, Breadcrumbs, Typography, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { AircraftBasicDetail, AircraftLeaseDetail, AircraftEngineDetail } from '../components';
import { leaseEnCrud } from '..';
import { trackActivity } from '../../../utils/mixpanel';
import { getAircraftModsApi, getRentalTypesApi, getRentalPaymentTypesApi, generateLEVLogApi, uploadLesseeLogoApi, getMntEventsApi, getEngineSvApi } from '../apiServices';
import { getEngineTypesAc, getAircraftTypesAc, getLesseeListAc } from '../../../shared_elements/actionCreators';

class CreateLog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      leaseEnCrud: leaseEnCrud,
      mods: [],
      levRentalTypes: [],
      levRentalPaymentTypes: [],
      mntGroups: [],
      svData: {},
      error: {},
      noOfRows: 1,
      engineNo: 1,
      newLessee: false
    }
    // api calls are written in apiServices file
    // importing the functions and binding it here
    this.getAircraftModsApi = getAircraftModsApi.bind(this) // get the mods list
    this.getRentalTypesApi = getRentalTypesApi.bind(this) // get the rental type constants
    this.getRentalPaymentTypesApi = getRentalPaymentTypesApi.bind(this) // get the rental type payment constants
    this.generateLEVLogApi = generateLEVLogApi.bind(this) //  generate log api - post method
    this.uploadLesseeLogoApi = uploadLesseeLogoApi.bind(this) // updateLogo api - post method -  formData
    this.getMntEventsApi = getMntEventsApi.bind(this) // maintenance list - get method
    this.getEngineSvApi = getEngineSvApi.bind(this) // get Shop visit data - get method
  }
  componentDidMount() {
    this.props.getAircraftTypes({ valuation: "valuation" })
    this.props.getLesseeList()
    this.getRentalTypesApi({ constant_types: ['lev_rental_type'] })
    this.getRentalPaymentTypesApi({ constant_types: ['lev_rental_payment_type'] })
    trackActivity('Lease Encumbered Value', {
      event_type: 'Page Visited',
      page_title: 'Create Log'
    })
  }
  // updateErrorField function
  // this function is used for updating the fields if those fields have any error messages
  updateErrorField = (keyParam, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: message
      }
    }))
  }
  // step Rental update error function
  // this function is used for updating the fields if those fields have any error messages
  updateStepErrorField = (keyParam, index) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [index]: {
          ...prevState.error[index],
          [keyParam]: ""
        }
      }
    }))
  }

  /// onFieldChange function --  keyParam - key ||  value - value of that specific property
  onFieldChange = (event, keyParam, value) => {
    const { leaseEnCrud } = this.state
    this.updateFormFields(keyParam, value)
    //condition 1
    if (keyParam == "aircraft_type_id") {
      this.updateFormFields('mods', [])
      this.updateFormFields('engine_type_id', null)
      this.updateFormFields('maintenance_program', null)
      this.updateFormFields('is_full_life_maintenance_condition', false)
      this.updateFormFields('engine_sv_details', [])
      if (value) {
        this.getAircraftModsApi(this.props, { aircraft_type: value.id })
        this.getMntEventsApi(this.props, value.id)
        this.props.getEngineTypes({ valuation: 'valuation', 'aircraft_type': value.id });
      }
    }
    // condition 2
    if (keyParam == 'mtow' || keyParam == "mtow_kgs" && value >= 0) {
      if (keyParam == 'mtow_kgs') {
        this.updateFormFields('mtow', (value * 2.2046).toFixed(4));
      } else {
        this.updateFormFields('mtow_kgs', (value * 0.4535970244).toFixed(4));
      }
    }
    // condition 3
    if (keyParam == 'rental_type' && value) {
      if (value.label == "Stepped") {
        this.addSteppedRental()
      } else {
        this.updateFormFields('step_rentals', null)
      }
    }
    // condition 4
    if (keyParam == "lessee_id") {
      if (value) {
        if (value.id) {
          this.setState({ newLessee: false })
        } else {
          this.setState({ newLessee: true })
        }
      } else {
        this.setState({ newLessee: false })
      }
    }
    //condition 5
    if (keyParam == 'is_full_life_maintenance_condition') {

      if (value) {
        this.getEngineSvApi(leaseEnCrud.aircraft_type_id.id, leaseEnCrud.engine_type_id.id)
      } else {
        this.updateFormFields('engine_sv_details', [])
        this.updateFormFields('maintenance_program', null)
      }
    }
  }

  // update form fields function -- after all conditions, will call this function to update the this.state.
  // keyParam - key name
  // value - value of the property
  updateFormFields = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        [keyParam]: value
      }
    }))
  }
  // Add SteppedRental
  addSteppedRental = () => {
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        step_rentals: [
          {
            start_date: null,
            end_date: null,
            rent: "",
          }
        ]
      }
    }))
  }
  // Stepped Rental logic
  // add rows
  addRows = (index) => {
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        step_rentals: [...prevState.leaseEnCrud.step_rentals, { start_date: null, end_date: null, rent: "", }]
      }
    }))
  }
  // remove rows
  removeRows = (index) => {
    const { leaseEnCrud } = this.state
    let rentals = leaseEnCrud.step_rentals
    rentals.splice(index, 1)
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        step_rentals: rentals
      }
    }))
  }
  // update data
  updateStepRental = (event, keyParam, value, elementIndex) => {
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        step_rentals: prevState.leaseEnCrud.step_rentals.map((item, index) => index == elementIndex ? ({
          ...item,
          [keyParam]: value
        }) : item)
      }
    }))
    if (keyParam == 'end_date') {
      if (this.state.leaseEnCrud.step_rentals.length > (elementIndex + 1)) {
        this.setState(prevState => ({
          ...prevState,
          leaseEnCrud: {
            ...prevState.leaseEnCrud,
            step_rentals: prevState.leaseEnCrud.step_rentals.map((item, index) => index == (elementIndex + 1) ? ({
              ...item,
              start_date: moment(value).add(1, 'days').format(backendDateFormat)
            }) : item)
          }
        }))
      }
    }
  }
  // Update Lessee logo
  uploadLogo = (file) => {
    this.uploadLesseeLogoApi(file)
    this.updateErrorField('logo', '')

  }
  onLesseeFieldChange = (keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        lessee_id: {
          ...prevState.leaseEnCrud.lessee_id,
          [keyParam]: value
        }
      }
    }))
  }

  updateEngineSvDataNone = (keyParam, engineIndex) => {
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        engine_sv_details: {
          ...prevState.leaseEnCrud.engine_sv_details,
          engines: prevState.leaseEnCrud.engine_sv_details.engines.map((item, index) => index == engineIndex ?
            {
              ...item,
              [keyParam]: null
            } : item)
        }
      }
    }))
  }
  updatePRSVData = (keyParam, value, engineIndex, prIndex,) => {
    let data = value
    data = {
      ...data,
      name: data.event
    }
    this.setState(prevState => ({
      ...prevState,
      leaseEnCrud: {
        ...prevState.leaseEnCrud,
        engine_sv_details: {
          ...prevState.leaseEnCrud.engine_sv_details,
          engines: prevState.leaseEnCrud.engine_sv_details.engines.map((item, index) => index == engineIndex ?
            {
              ...item,
              [keyParam]: data
            } : item)
        }
      }
    }))
  }



  render() {
    const { leaseEnCrud, mods, levRentalPaymentTypes, levRentalTypes, error, noOfRows, newLessee, mntGroups, svData, engineNo } = this.state;
    const { aircraftTypes, lessee, engineTypes, applicationPermission } = this.props;
    console.log('this.props', this.props);
    return (
      <section className="lease-encumbered-section">
        <div className="create-log-breadcrumb">
          <Grid container spacing={0}>
            <Grid xs={12}>
              <Breadcrumbs aria-label="breadcrumb" >
                <Link color="inherit" to={'/logs'}>
                  <Typography className="flex-centered text-underline" component="h1">
                    Logs
                  </Typography>
                </Link>
                <Link color="inherit" to={'/create-log'}>
                  <Typography className="flex-centered text-underline" component="h1">
                    Asset Value Options
                  </Typography>
                </Link>
                <Typography className="flex-centered" component="h1">
                  Lease Encumbered Value
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </div>
        <div className="create-log-cta">
          <Grid container spacing={0} className="flex-centered">
            <Grid xs={6} md={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  required
                  margin="normal"
                  id="base_value_date"
                  label="Report Date"
                  placeholder="DD-MM-YYYY"
                  format={fieldDateFormat}
                  value={leaseEnCrud.base_value_date}
                  minDate={moment().subtract(1, 'years')}
                  maxDate={leaseEnCrud.date_of_manufacture ? moment().add(1, 'years') > moment(leaseEnCrud.date_of_manufacture).add(25, 'years') ? moment().add(1, 'years') : moment(leaseEnCrud.date_of_manufacture).add(25, 'years') : moment().add(1, 'years')}
                  InputLabelProps={{ shrink: true }}
                  onChange={(data, value) => this.onFieldChange(value, 'base_value_date', data ? moment(data).format(backendDateFormat) : data)}
                  error={error.base_value_date ? true : false}
                  helperText={error.base_value_date ? error.base_value_date : ''}
                  onFocus={() => this.updateErrorField('base_value_date', '')}
                  onOpen={() => this.updateErrorField('base_value_date', '')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid xs={6} md={6}>
              <Button className="proceed-btn" style={{ float: 'right' }} variant="contained" size="large" color="primary" onClick={() => this.generateLEVLogApi(this.props, leaseEnCrud)} >Proceed</Button>
            </Grid>
          </Grid>
        </div>
        <div className="create-log-basic-detail">
          <AircraftBasicDetail
            leaseEnCrud={leaseEnCrud}
            mods={mods}
            aircraftTypes={aircraftTypes}
            lessee={lessee}
            onFieldChange={this.onFieldChange}
            engineTypes={engineTypes}
            error={error}
            updateErrorField={this.updateErrorField}
            newLessee={newLessee}
            uploadLogo={this.uploadLogo}
            onLesseeFieldChange={this.onLesseeFieldChange}
            applicationPermission={applicationPermission}
          />
          <AircraftLeaseDetail
            leaseEnCrud={leaseEnCrud}
            levRentalTypes={levRentalTypes}
            levRentalPaymentTypes={levRentalPaymentTypes}
            onFieldChange={this.onFieldChange}
            error={error}
            updateErrorField={this.updateErrorField}
            noOfRows={noOfRows}
            addRows={this.addRows}
            removeRows={this.removeRows}
            updateStepRental={this.updateStepRental}
            updateStepErrorField={this.updateStepErrorField}
            mntGroups={mntGroups}
            svData={svData}
            engineNo={engineNo}
            updateEngineNo={(engineNo) => this.setState({ engineNo })}
            applicationPermission={applicationPermission}
          />
          <AircraftEngineDetail
            leaseEnCrud={leaseEnCrud}
            engineNo={engineNo}
            updateEngineSvDataNone={this.updateEngineSvDataNone}
            updatePRSVData={this.updatePRSVData}
          />
        </div>
        <Grid container>
          <Grid xs={12}>
            <Button className="flex-centered" style={{ marginTop: '20px' }} onClick={() => this.generateLEVLogApi(this.props, leaseEnCrud)} variant="contained" fullWidth={true} color="primary">Proceed</Button>
          </Grid>
        </Grid>
      </section>
    )
  }
}
const mapStateToProps = state => ({
  engineTypes: state.shareReducer.engineTypes,
  aircraftTypes: state.shareReducer.aircraftTypes,
  lessee: state.shareReducer.lessee,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
    getAircraftTypes: (query) => dispatch(getAircraftTypesAc(ownProps, query)),
    getLesseeList: (query) => dispatch(getLesseeListAc(query)),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CreateLog))
