import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Typography, Dialog, DialogTitle, DialogActions, Grid, TextField, DialogContent, Button, Box } from '@material-ui/core';
import { errorCode } from '../sharedConstants';
import { fieldValidation } from '../../utils/formValidation';
import { changePasswordApi } from '../apiServices';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal:false,
      passwordObj:{
        confirm_password:'',
        old_password:'',
        password:'',
      },
      error:{},
      showOldPassword:false,
      showNewPassword:false,
      showConfirmPassword:false,
      toolTipOld:false,
      tooltipNEw:false
    }
    this.changePasswordApi = changePasswordApi.bind(this)
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  onFieldChange = (event, keyParam, data) => {
    this.setState(prevState => ({
      ...prevState,
      passwordObj:{
        ...prevState.passwordObj,
        [keyParam]:data
      }
    }))
  }
  changePassword = () => {
    const { passwordObj } = this.state
    let validationInputs = {
      old_password:errorCode['old_password'][fieldValidation({...errorCode['old_passwordObj'], fieldval: passwordObj.old_password})],
      password:errorCode['password'][fieldValidation({...errorCode['passwordObj'], fieldval:passwordObj.password})],
      confirm_password:errorCode['confirm_password'][fieldValidation({...errorCode['confirm_passwordObj'], fieldval: passwordObj.confirm_password})]
    }
    if(passwordObj.password != passwordObj.confirm_password) {
      validationInputs = {
        ...validationInputs,
        confirm_password:'Passwords do not match. Try Again'
      }
    }
    if(Object.keys(validationInputs).every((k) => {return validationInputs[k] === ''} )){
      this.changePasswordApi(passwordObj)
    }else {
      this.setState({
        error:validationInputs
      })
    }
  }
  render() {
    const { modal, passwordObj, error, showOldPassword, showNewPassword, showConfirmPassword, toolTipOld, tooltipNEw } = this.state
    return(
      <Fragment>
      <Typography onClick={() => this.setState({ modal:true})}>Change Password </Typography>
      { modal ?
        <Dialog
          open={modal}
          onClose={() =>  this.setState({ modal:false, passwordObj:{}})}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
          Change Password
          </DialogTitle>
          <DialogContent dividers={true} >
            <Grid container spacing={5} >
              <Grid item xs={12}>
                <TextField
                  type={showOldPassword ? "text" :'password'}
                  id="old_password"
                  label={
                    <div>
                      Old Password
                      <sup>*</sup>
                      <Tooltip
                        style={{ fontSize: "1.1em", verticalAlign:"middle", marginLeft:"5px", cursor:'pointer' }}
                        title={
                          <div style={{textAlign: "left" }}>
                            <strong>Password rules</strong>  <br/>
                            <ul className="list-unstyled password-tooltip">
                              <li>Password must contain minimum of 8 characters</li>
                              <li>Password must contain at least 1 digit</li>
                              <li>Password must contain at least 1 uppercase character</li>
                              <li>Password must contain at least 1 special character</li>
                            </ul>
                          </div>
                        }

                        placement="right"
                        onClick={() => this.setState({toolTipOld:true})}
                        open={toolTipOld}
                        onClose={() =>  this.setState({toolTipOld:false})}
                      >
                        <InfoIcon/>
                      </Tooltip>
                    </div>
                  }
                  margin="normal"
                  value={passwordObj.old_password}
                  InputLabelProps={{shrink:true}}
                  fullWidth
                  onFocus={() =>this.updateErrorField('old_password', '')}
                  onChange={(e) => this.onFieldChange('e', 'old_password', e.target.value)}
                  error={error.old_password ? true : false}
                  helperText={error.old_password ? error.old_password : '' }
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                    { showOldPassword ? <VisibilityOff onClick={() => this.setState({showOldPassword:false})} style={{cursor:'pointer'}} /> :<Visibility onClick={() => this.setState({showOldPassword:true})} style={{cursor:'pointer'}} /> }
                  </InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type={showNewPassword ? 'text' : 'password'}
                  id="password"
                  label={
                    <div>
                      New Password
                      <sup>*</sup>
                      <Tooltip
                        style={{ fontSize: "1.1em", verticalAlign:"middle", marginLeft:"5px" }}
                        title={
                          <div style={{ textAlign: "left" }}>
                            <strong>Password rules</strong>  <br/>
                            <ul className="list-unstyled password-tooltip">
                              <li>Password must contain minimum of 8 characters</li>
                              <li>Password must contain at least 1 digit</li>
                              <li>Password must contain at least 1 uppercase character</li>
                              <li>Password must contain at least 1 special character</li>
                            </ul>
                          </div>
                        }
                        placement="right"
                        onClick={() =>  this.setState({tooltipNEw:true})}
                        open={tooltipNEw}
                        onClose={() =>  this.setState({tooltipNEw:false})}
                      >
                        <InfoIcon/>
                      </Tooltip>
                    </div>
                  }
                  margin="normal"
                  value={passwordObj.password}
                  InputLabelProps={{shrink:true}}
                  fullWidth
                  onFocus={() => this.updateErrorField('password', '')}
                  onChange={(e) =>  this.onFieldChange(e, 'password', e.target.value)}
                  error={error.password ? true : false}
                  helperText={error.password ? error.password : ''}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                    { showNewPassword ? <VisibilityOff onClick={() => this.setState({showNewPassword:false})} style={{cursor:'pointer'}} /> :<Visibility onClick={() => this.setState({showNewPassword:true})} style={{cursor:'pointer'}} /> }
                  </InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirm_password"
                  label="Confirm password"
                  margin="normal"
                  value={passwordObj.confirm_password}
                  InputLabelProps={{shrink:true}}
                  fullWidth
                  onFocus={() => this.updateErrorField('confirm_password', '')}
                  onChange={(e) => this.onFieldChange(e, 'confirm_password', e.target.value)}
                  error={error.confirm_password ? true : false}
                  helperText={error.confirm_password ? error.confirm_password : ''}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                    { showConfirmPassword ? <VisibilityOff onClick={() => this.setState({showConfirmPassword:false})} style={{cursor:'pointer'}} /> :<Visibility onClick={() => this.setState({showConfirmPassword:true})} style={{cursor:'pointer'}} /> }
                  </InputAdornment>,
                  }}
                />
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={()=> this.setState({modal:false, passwordObj:{}})} >Cancel</Button>
            <Button color="primary" variant="contained" onClick={()=> this.changePassword()} >Change Password</Button>
          </DialogActions>
        </Dialog> : null
      }
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(ChangePassword));
