import config from '../../config';
import { globalPostService } from '../../utils/globalApiServices';
import { checkApiStatus, setGlobalCookie } from '../../utils';
import mixpanel from 'mixpanel-browser';
export function loginApi(props, data={}) {
  this.setState({formSubmitLoader: true});
  globalPostService('api/login/', data)
  .then(response => {
    this.setState({formSubmitLoader: false});
    if(checkApiStatus(response)){
      mixpanel.track("userLogin",{
        "email":data.email
      })
      if(response.data.data.lessor_list.length){
        let expireAfter = new Date();
        expireAfter.setDate(expireAfter.getDate() + 3);
        let cookieData = JSON.stringify({access:response.data.data.access , lessor_length: response.data.data.lessor_list.length})
        setGlobalCookie(config.cookiesNaming.key, cookieData, 3)
        if(response.data.data.lessor_list.length > 1){
          props.history.push('/select-lessor');
        }else{
          this.lessorUpdateApi(props, {lessor_id: response.data.data.lessor_list[0].id})
        }
      }else{

      }
      this.props.enqueueSnackbar('Login Successful.', {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else if(response.data.statusCode == 1012){
      this.props.enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }else {
      this.props.enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
    }
  })
}
export function lessorUpdateApi(props, data={}){
  this.setState({formSubmitLoader: true});
  globalPostService(`api/user-authorization/?remove_extra=true`, data)
  .then(response => {
    this.setState({formSubmitLoader: false});
    if(checkApiStatus(response)){
      let accessData = {
        id:response.data.data.current_lessor.id,
        access:response.data.data.lessor_access_token,
        lessor:{
          id:response.data.data.current_lessor.id,
          name:response.data.data.current_lessor.name,
          slug:response.data.data.current_lessor.slug,
          lessor_type:response.data.data.current_lessor.lessor_type,
          multi_lessor_user: response.data.data.multi_lessor_user
        },
        user:{
          id:response.data.data.user.id,
          slug:response.data.data.user.slug,
          name:response.data.data.user.name,
          designation:response.data.data.user.designation,
          profile_pic:response.data.data.user.profile_pic,
          account_type:response.data.data.user.account_type,
          is_root_user:response.data.data.user.is_root_user,
          is_super_admin:response.data.data.user.is_super_admin,
        }
      }
      let cookieData = JSON.stringify(accessData)
      setGlobalCookie(config.cookiesNaming.key, cookieData, 3)
      props.history.push('/logs');
      // if(response.data.data.user.permission['valuation']){
      //   if(
      //     response.data.data.user.permission && response.data.data.user.permission['valuation'] && response.data.data.user.permission['valuation']['base_value'] && response.data.data.user.permission['valuation']['base_value'].indexOf('C') != -1 ||
      //     response.data.data.user.permission && response.data.data.user.permission['valuation'] && response.data.data.user.permission['valuation']['future_value'] && response.data.data.user.permission['valuation']['future_value'].indexOf('C') != -1 ||
      //     response.data.data.user.permission && response.data.data.user.permission['valuation'] && response.data.data.user.permission['valuation']['hthl'] && response.data.data.user.permission['valuation']['hthl'].indexOf('C') != -1
      //   ) {
      //     props.history.push('/create-log');
      //   }else {
      //     props.history.push('/logs');
      //   }
      // }else {
      //   props.history.push('/logs');
      // }

    }
  })
}
// State Update Related Functions
export function updateAuthInfoState(value, key){
  this.setState(prevState => ({
      ...prevState,
      authInfo: {
        ...prevState.authInfo,
        [key]: value
      },
      error: {
        ...prevState.error,
        [key]: ''
      }
  }))
}
