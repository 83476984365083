import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
function ContractOuterNav(props){
  return(
    <div>
      <ul className="list-unstyled">
        {[
            {label: 'Dashboard', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/dashboard`},
            {label: 'Utilization', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/utilization`},
            {label: 'Invoices', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/invoice`},
            {label: 'Cashflow', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/cash-flow`},
            {label: 'Mr Accruals', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/mr-accrual`},
            {label: 'Half Life Maintenance Adjustments', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contract/half-life-maintenance-adjustments`},
            {label: 'Contract History', path: `/${props.match.params.assetType}/${props.match.params.assetSlug}/contracts`},
          ].map((item, index) =>
          <li key={index}>
            <NavLink activeClassName="active" to={item.path}>{item.label}</NavLink>
          </li>
        )}
      </ul>
    </div>
  )
}
export default withRouter(ContractOuterNav);
