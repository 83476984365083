import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Paper, Grid, TextField, Button, InputAdornment, Tooltip, Typography, Breadcrumbs, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { LabelValueCard, PageLoader } from '../../../shared_elements';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import baseValueIcon from '../../../assets/images/bv_icon.svg'
import futureValueIcon from '../../../assets/images/fv_icon.svg'
import hlhtAdjIcon from '../../../assets/images/hla_icon.svg'
import portfolioIcon from '../../../assets/images/portfolio_val_icon.svg'
import distressIcon from '../../../assets/images/distress_value.svg'
import leaseRentalIcon from '../../../assets/images/lease_rental_icon.svg'
import leaseEncumbIcon from '../../../assets/images/lease_encumbered_value.svg'
import { getEngineTypesAc } from '../../../shared_elements/actionCreators';
import { regexConstants } from '../../../constants/regEx';
import { checkApiStatus, checkUserAccessPermission } from '../../../utils';
import { fieldValidation } from '../../../utils/formValidation';
import { trackActivity } from '../../../utils/mixpanel';
import { getValuationAircraftsApi, getAircraftModsApi, createValuationLogApi, updateValuationLogApi, getValuationLogApi, getLogsSummaryApi, getLicenseCostApi, paymentCheckoutApi, checkPurchaseCookie } from '../apiServices';
import { errorCode, valuationCrud } from '../'
import Cookies from 'universal-cookie';
const cookies = new Cookies();
class CreateLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skeletonLoader: false,
      pageLoader: false,
      utilsCard: this.props.match.params.id ? true : false,
      utilsTitle: this.props.match.params.type ? (this.props.match.params.type === 'base-value' ? 'Base Value' : 'Future Base Values') : '',
      aircraftTypes: [],
      mods: [],
      valuationCrud: valuationCrud,
      error: {},
      toggleMod: false,
      tooltipEscalation: false,
      logsSummaryDetail: {},
      licensePurchaseInfo: {
        checkoutForm: false,
        modal: false,
        is_address: false,
        purchaseItems: [],
        address_detail: {},
        address_id: null,
        error: {}
      }
    }
    this.getValuationAircraftsApi = getValuationAircraftsApi.bind(this);
    this.getAircraftModsApi = getAircraftModsApi.bind(this);
    this.getValuationLogApi = getValuationLogApi.bind(this);
    this.createValuationLogApi = createValuationLogApi.bind(this);
    this.updateValuationLogApi = updateValuationLogApi.bind(this);
    this.getLogsSummaryApi = getLogsSummaryApi.bind(this)
  }
  componentDidMount() {
    this.getValuationAircraftsApi()
      .then(response => {
        if (checkApiStatus(response)) {
          this.setState({ aircraftTypes: response.data.data.aircraftTypes });
        }
      });
    this.setState({ pageLoader: true })
    this.getLogsSummaryApi()
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          this.setState({ logsSummaryDetail: response.data.data });
        }
      })
    if (this.props.match.params.id) {
      this.getValuationLogApi(this.props, this.props.match.params.id)
        .then(response => {
          if (checkApiStatus(response)) {
            let data = Object.assign({}, response.data.data);
            data = {
              ...data,
              mtow_kgs: (response.data.data.mtow * 0.4535970244).toFixed(2)
            }
            this.setState({ valuationCrud: data });
            this.props.getEngineTypes({ aircraft_type: response.data.data.aircraft_type.id });
            this.getAircraftModsApi(this.props, { aircraft_type: response.data.data.aircraft_type.id });
          }
        })
    }
    trackActivity('Page Visited', {
      page_title: 'Create Log'
    });
    if (this.props.location.search.includes('base-value')) {
      this.toggleUtilsCard(true, 'Base Value')
    }
    if (this.props.location.search.includes('future-value')) {
      this.toggleUtilsCard(true, 'Future Base Values')
    }
  }
  onFieldChange = (e, keyParam, value) => {
    this.updateFormFields(keyParam, value);
    if (keyParam === 'aircraft_type') {
      this.updateFormFields('mods', []);
      this.updateFormFields('engine_type', null);
      if (value) {
        this.getAircraftModsApi(this.props, { aircraft_type: value.id });
        this.props.getEngineTypes({ aircraft_type: value.id, valuation: 'valuation' })
      } else {
        this.setState({ mods: [] })
      }
    }
    if ((keyParam === 'mtow' || keyParam === 'mtow_kgs') && value >= 0) {
      if (keyParam == 'mtow_kgs') {
        this.updateFormFields('mtow', (value * 2.2046).toFixed(2));
      } else {
        this.updateFormFields('mtow_kgs', (value * 0.4535970244).toFixed(2));
      }
    }
    if (keyParam === 'date_of_manufacture') {
      this.updateFormFields('base_value_date', null);
    }
  }
  updateFormFields = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      valuationCrud: {
        ...prevState.valuationCrud,
        [key]: value
      }
    }));
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [key]: message
      }
    }))
  }
  toggleUtilsCard = (flag, title) => {
    this.setState(prevState => ({
      ...prevState,
      utilsCard: flag,
      utilsTitle: title,
      valuationCrud: title === 'Future Base Values' ? { ...valuationCrud, projection_from: valuationCrud.base_value_date, projection_to: null } : valuationCrud
    }));
  }
  createValuationFn = () => {
    const { valuationCrud, utilsTitle } = this.state;
    let validationInputs = {
      aircraft_type: errorCode['aircraft_type'][fieldValidation({ ...errorCode['aircraft_typeObj'], fieldval: valuationCrud.aircraft_type })],
      serial_number: errorCode['serial_number'][fieldValidation({ ...errorCode['serial_numberObj'], fieldval: valuationCrud.serial_number })],
      date_of_manufacture: errorCode['date_of_manufacture'][fieldValidation({ ...errorCode['date_of_manufactureObj'], fieldval: valuationCrud.date_of_manufacture })],
      engine_type: errorCode['engine_type'][fieldValidation({ ...errorCode['engine_typeObj'], fieldval: valuationCrud.engine_type })],
      mtow: errorCode['mtow'][fieldValidation({ ...errorCode['mtowObj'], fieldval: valuationCrud.mtow })],
      base_value_date: utilsTitle === 'Base Value' ? errorCode['base_value_date'][fieldValidation({ ...errorCode['base_value_dateObj'], fieldval: valuationCrud.base_value_date })] : '',
      projection_to: utilsTitle.includes('Future') ? errorCode['projection_to'][fieldValidation({ ...errorCode['projection_toObj'], fieldval: valuationCrud.projection_to })] : '',
      escalation_percentage: valuationCrud.escalation_percentage && valuationCrud.escalation_percentage.length ? valuationCrud.escalation_percentage > 5 || valuationCrud.escalation_percentage < 1 ? "Please enter Annual Inflation between 1.00 and 5.00" : '' : '',
    }
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      let payload = Object.assign({}, valuationCrud);
      delete payload.mtow_kgs;
      if (payload.mods.length) {
        payload = {
          ...payload,
          mods: valuationCrud.mods.map(mod => mod.id)
        }
      }
      if (payload.escalation_percentage) {
        payload = {
          ...payload,
          escalation_percentage: payload.escalation_percentage
        }
      }
      if (this.props.match.params.id) {
        let data = {
          date_of_manufacture: payload.date_of_manufacture,
          aircraft_type: payload.aircraft_type,
          engine_type: payload.engine_type,
          mods: payload.mods,
          mtow: payload.mtow,
          serial_number: payload.serial_number,
          base_value_date: payload.base_value_date,
        }
        if (payload.projection_to) {
          data = {
            ...data,
            projection_from: data.base_value_date,
            projection_to: payload.projection_to
          }
        }
        if (payload.escalation_percentage) {
          data = {
            ...data,
            escalation_percentage: payload.escalation_percentage
          }
        }
        this.updateValuationLogApi(this.props, data);
      } else {
        this.createValuationLogApi(this.props, payload, utilsTitle);
      }
    } else {
      this.setState({ error: validationInputs });
    }
  }

  cardPermissionAccess = (cardType, cardId, url = '') => {
    const { logsSummaryDetail } = this.state;
    if ( checkUserAccessPermission(this.props.applicationPermission, 'valuation', cardType ,'C')) {
      if (logsSummaryDetail.account_type.includes('Enterprise')) {
        if (cardType === 'base_value' || cardType === 'future_value') {
          this.toggleUtilsCard(true, cardType === 'base_value' ? 'Base Value' : 'Future Base Value');
        } else {
          this.props.history.push(url);
        }
      } else {
        let utilityInfo = logsSummaryDetail.utility_info.find(utils => utils.utility_id === cardId);
        if (utilityInfo) {
          if (utilityInfo.total_quantity <= utilityInfo.quantity_consumed) {
            this.setState({ licenseModal: true })
          } else {
            if (cardType === 'base_value' || cardType === 'future_value') {
              this.toggleUtilsCard(true, cardType === 'base_value' ? 'Base Value' : 'Future Base Value');
            } else {
              this.props.history.push(url);
            }
          }
        } else {
          this.props.enqueueSnackbar('Please contact to support@sparta.aero, to upgrade license ', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      }
    } else {
      this.props.enqueueSnackbar('You don\'t have create permission, please check with Admin', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  }

  logUsageUI = (utilityId) => {
    const { logsSummaryDetail } = this.state;
    let utilityInfo = logsSummaryDetail.utility_info.find(utils => utils.utility_id === utilityId);
    return (
      <div className="card-usage-details">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h6>Log Usage</h6>
            {utilityInfo ? <p>{utilityInfo.quantity_consumed}/{utilityInfo.total_quantity}</p> : <p>0/0</p>}
          </Grid>
        </Grid>
      </div>
    )
  }
  render() {
    const { utilsCard, utilsTitle, valuationCrud, aircraftTypes, mods, error, skeletonLoader, pageLoader, tooltipEscalation, logsSummaryDetail, licenseModal } = this.state
    const { engineTypes } = this.props;
    console.log('utilsTitle', utilsTitle);
    return (
      <div className="valuation-sections">
        <div className="create-valuation-logs create-valuation-logs-mobile">
          {!utilsCard ?
            <div className="">
              <Breadcrumbs aria-label="breadcrumb" style={isMobile ? { marginTop: '50px', marginBottom: '10px' } : { marginTop: 'auto', marginBottom: '20px' }}>
                <Link color="inherit" to="/logs" style={{ cursor: 'pointer' }} >
                  <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                    Logs
                  </Typography>
                </Link>
                <Typography className="flex-centered" component="h1" style={{ fontSize: '14px', color: '#bd10e0' }}>
                  Asset Value Options
                </Typography>
              </Breadcrumbs>
              <Grid container spacing={3} direction="row" className="flex-not-centered">
                <Grid item xs={6} className="flex-not-centered">
                  <Paper className="card active" onClick={(e) => { this.cardPermissionAccess('base_value', 2) }}>
                    <img src={baseValueIcon} alt="" />
                    <h3>Base Value and Current Market Value</h3>
                    {logsSummaryDetail.account_type && logsSummaryDetail.account_type === 'SaaS' ?
                      this.logUsageUI(2) : null
                    }
                  </Paper>
                </Grid>
                <Grid item xs={6} className="flex-not-centered">
                  <Paper className="card active" onClick={(e) => { this.cardPermissionAccess('future_value', 2) }}>
                    <img src={futureValueIcon} alt="" />
                    <h3>Future Base Values</h3>
                    {logsSummaryDetail.account_type && logsSummaryDetail.account_type === 'SaaS' ?
                      this.logUsageUI(2) : null
                    }
                  </Paper>
                </Grid>
                <Grid item xs={6} className="flex-not-centered">
                  <Paper className="card acive" onClick={(e) => { this.cardPermissionAccess('hthl', 3, '/half-life/initiate') }} style={{ cursor: 'pointer' }}>
                    <img src={hlhtAdjIcon} alt="" />
                    <h3>Maintenance Adjusted Base Value</h3>
                    {logsSummaryDetail.account_type && logsSummaryDetail.account_type === 'SaaS' ?
                      this.logUsageUI(3) : null
                    }
                  </Paper>
                </Grid>
                <Grid item xs={6} className="flex-not-centered">
                  <Paper className="card active" onClick={(e) => { this.cardPermissionAccess('lev', 4, '/lease-encumbered/create-log') }} style={{ cursor: 'pointer' }}>
                    <img src={leaseEncumbIcon} alt="" />
                    <h3>Lease Encumbered Value</h3>
                    {logsSummaryDetail.account_type && logsSummaryDetail.account_type === 'SaaS' ?
                      this.logUsageUI(4) : null
                    }
                  </Paper>
                </Grid>
                <Grid item xs={6} className="flex-not-centered">
                  <Paper className="card disabled">
                    <img src={portfolioIcon} alt="" />
                    <h3>Portfolio Valuation</h3>
                    <p>Coming Soon</p>
                  </Paper>
                </Grid>
                <Grid item xs={6} className="flex-not-centered">
                  <Paper className="card disabled">
                    <img src={distressIcon} alt="" />
                    <h3>Distress Value</h3>
                    <p>Coming Soon</p>
                  </Paper>
                </Grid>
                <Grid item xs={6} className="flex-not-centered">
                  <Paper className="card disabled">
                    <img src={leaseRentalIcon} alt="" />
                    <h3>Lease Rental Rates</h3>
                    <p>Coming Soon</p>
                  </Paper>
                </Grid>
              </Grid>
            </div> :
            <form>
              {this.props.match.params.id ?
                <Fragment>
                  <Breadcrumbs aria-label="breadcrumb" style={isMobile ? { marginTop: '50px', marginBottom: '10px' } : { marginTop: 'auto', marginBottom: '20px' }}>
                    <Link color="inherit" to="/logs" style={{ cursor: 'pointer' }} >
                      <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                        Logs
                      </Typography>
                    </Link>
                    { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'base_value', 'F') ||
                      checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'future_value', 'f') ||
                      checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'f') ||
                      checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'lev', 'f') ?
                      <Link color="inherit" to="/create-log" style={{ cursor: 'pointer' }} onClick={() => this.toggleUtilsCard(false, '')}>
                        <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                          Asset Value Options
                        </Typography>
                      </Link> : null
                    }
                    <Typography className="flex-centered" component="h1" style={{ fontSize: '14px', color: '#bd10e0' }}>
                      {utilsTitle}
                    </Typography>
                  </Breadcrumbs>
                  {/* <h1 className="flex-centered" style={{marginBottom:'10px'}}>{utilsTitle}</h1> */}
                </Fragment>
                :
                <Fragment>
                  <Breadcrumbs aria-label="breadcrumb" style={isMobile ? { marginTop: '50px', marginBottom: '10px' } : { marginTop: 'auto', marginBottom: '20px' }}>
                    <Link color="inherit" to="/logs" style={{ cursor: 'pointer' }} >
                      <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                        Logs
                      </Typography>
                    </Link>
                    { checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'base_value', 'C') ||
                      checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'future_value', 'C') ||
                      checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'hthl', 'C') ||
                      checkUserAccessPermission(this.props.applicationPermission, 'valuation', 'lev', 'C') ?
                      <Link color="inherit" to="/create-log" style={{ cursor: 'pointer' }} onClick={() => this.toggleUtilsCard(false, '')}>
                        <Typography className="flex-centered" component="h1" style={{ textDecoration: 'underline', fontSize: '14px', color: '#bd10e0' }}>
                          Asset Value Options
                        </Typography>
                      </Link> : null
                    }
                    <Typography className="flex-centered" component="h1" style={{ fontSize: '14px', color: '#bd10e0' }}>
                      {utilsTitle}
                    </Typography>
                  </Breadcrumbs>
                </Fragment>
              }
              <Grid container spacing={1}>
                {this.props.match.params.id ?
                  <LabelValueCard skeletonLoader={skeletonLoader} md={3} label='MSN' value={valuationCrud.serial_number ? valuationCrud.serial_number : '--'} /> :
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="serial_number"
                      label='Serial Number'
                      fullWidth
                      margin="normal"
                      inputProps={{ maxLength: 10 }}
                      InputLabelProps={{ shrink: true }}
                      value={valuationCrud.serial_number}
                      onChange={(e) => this.onFieldChange(e, 'serial_number', e.target.value)}
                      error={error.serial_number ? true : false}
                      helperText={error.serial_number ? error.serial_number : ''}
                      onFocus={() => this.updateErrorField('serial_number', '')}
                    />
                  </Grid>
                }
                {this.props.match.params.id ?
                  <LabelValueCard skeletonLoader={skeletonLoader} md={3} label='Aircraft Type' value={valuationCrud.aircraft_type && valuationCrud.aircraft_type.name ? valuationCrud.aircraft_type.name : '--'} /> :
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      options={aircraftTypes}
                      getOptionLabel={option => option.name}
                      id="aircraft_type"
                      value={valuationCrud.aircraft_type}
                      onChange={(e, value) => this.onFieldChange(e, 'aircraft_type', value)}
                      renderInput={params => <TextField required error={error.aircraft_type ? true : false} helperText={error.aircraft_type ? error.aircraft_type : ''} onFocus={() => this.updateErrorField('aircraft_type', '')} {...params} label="Aircraft Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                    />
                  </Grid>
                }
                <Grid item xs={12} md={6}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      required
                      margin="normal"
                      id="date_of_manufacture"
                      label="Date of Manufacture"
                      placeholder="DD-MM-YYYY"
                      format={fieldDateFormat}
                      fullWidth
                      disableFuture={true}
                      InputLabelProps={{ shrink: true }}
                      minDate={moment().subtract(25, 'years')}
                      onChange={(data, value) => this.onFieldChange(value, 'date_of_manufacture', data ? moment(data).format(backendDateFormat) : data)}
                      value={valuationCrud.date_of_manufacture}
                      error={error.date_of_manufacture ? true : false}
                      helperText={error.date_of_manufacture ? error.date_of_manufacture : ''}
                      onFocus={() => this.updateErrorField('date_of_manufacture', '')}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={engineTypes}
                    getOptionLabel={option => option.name}
                    id="engine_type"
                    value={valuationCrud.engine_type}
                    onChange={(e, value) => this.onFieldChange(e, 'engine_type', value)}
                    renderInput={params => <TextField required error={error.engine_type ? true : false} helperText={error.engine_type ? error.engine_type : ''} onFocus={() => this.updateErrorField('engine_type', '')} {...params} label="Engine Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="mtow"
                    label='Max Take Off Weight'
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: 10 }}
                    InputLabelProps={{ shrink: true }}
                    value={valuationCrud.mtow}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'mtow', e.target.value) : e.preventDefault() }}
                    error={error.mtow ? true : false}
                    helperText={error.mtow ? error.mtow : ''}
                    onFocus={() => this.updateErrorField('mtow', '')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Lbs</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    id="mtow_kgs"
                    label='Max Take Off Weight'
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: 10 }}
                    InputLabelProps={{ shrink: true }}
                    value={valuationCrud.mtow_kgs}
                    onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'mtow_kgs', e.target.value) : e.preventDefault() }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">Kgs</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options={mods}
                    getOptionLabel={option => option.name}
                    id="mods"
                    value={mods.length && valuationCrud.mods ? valuationCrud.mods : []}
                    onChange={(e, value) => this.onFieldChange(e, 'mods', value)}
                    renderInput={params => <TextField {...params} label="Modifications/Enhancements" margin="normal" fullWidth InputLabelProps={{ shrink: true }} />}
                    multiple={true}
                    filterSelectedOptions={true}
                    openOnFocus={true}
                  />
                </Grid>
                {
                  utilsTitle !== "Base Value" ?
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="escalation_percentage"
                        label={
                          <div>
                            Annual Inflation
                            <Tooltip
                              style={{ fontSize: "1em", verticalAlign: "middle", marginLeft: "5px" }}
                              title="The input value should be between 1.0% and 5.0%"
                              arrow
                              placement="top"
                              onClick={() => this.setState({ tooltipEscalation: true })}
                              open={tooltipEscalation}
                              onClose={() => this.setState({ tooltipEscalation: false })}
                            >
                              <InfoIcon />
                            </Tooltip>
                          </div>
                        }
                        fullWidth
                        margin="normal"
                        inputProps={{ maxLength: 10 }}
                        InputLabelProps={{ shrink: true }}
                        value={valuationCrud.escalation_percentage}
                        onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? this.onFieldChange(e, 'escalation_percentage', e.target.value) : e.preventDefault() }}
                        error={error.escalation_percentage ? true : false}
                        helperText={error.escalation_percentage ? error.escalation_percentage : ''}
                        onFocus={() => this.updateErrorField('escalation_percentage', '')}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                    </Grid> : null
                }

                {utilsTitle === 'Base Value' ?
                  <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        required
                        margin="normal"
                        id="base_value_date"
                        placeholder="DD-MM-YYYY"
                        label="Base Value as Of"
                        format={fieldDateFormat}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        minDate={moment().subtract(1, 'years')}
                        disableFuture={true}
                        onChange={(data, value) => this.onFieldChange(value, 'base_value_date', data ? moment(data).format(backendDateFormat) : data)}
                        value={valuationCrud.base_value_date}
                        error={error.base_value_date ? true : false}
                        helperText={error.base_value_date ? error.base_value_date : ''}
                        onFocus={() => this.updateErrorField('base_value_date', '')}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid> : null
                }
                {utilsTitle.includes('Future') ?
                  <Fragment>
                    <Grid item xs={12} md={6}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          required
                          margin="normal"
                          id="projection_to"
                          placeholder="DD-MM-YYYY"
                          label="Projection Date"
                          format={fieldDateFormat}
                          fullWidth
                          disablePast={true}
                          InputLabelProps={{ shrink: true }}
                          maxDate={valuationCrud.date_of_manufacture ? moment(valuationCrud.date_of_manufacture).add(9131.25, 'days') : moment().add(9125, 'days')}
                          onChange={(data, value) => this.onFieldChange(value, 'projection_to', data ? moment(data).format(backendDateFormat) : data)}
                          value={valuationCrud.projection_to}
                          error={error.projection_to ? true : false}
                          helperText={error.projection_to ? error.projection_to : ''}
                          onFocus={() => this.updateErrorField('projection_to', '')}
                          disabled={valuationCrud.date_of_manufacture ? false : true}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Fragment> : null
                }
              </Grid>
              <Button style={{ marginTop: '20px' }} onClick={() => this.createValuationFn()} fullWidth={true} variant="contained" color="primary">Get Value</Button>
            </form>
          }
        </div>
        {pageLoader ? <PageLoader /> : null}

      </div>
    )
  }
}

const mapStateToProps = state => ({
  engineTypes: state.shareReducer.engineTypes,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(CreateLog))
