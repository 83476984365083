import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, TextField, Tooltip, IconButton } from '@material-ui/core';
import CkEditorText from './CkEditorText'
import {DropzoneArea} from 'material-ui-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import { withRouter } from 'react-router-dom';
import { getReportAnIssueConstantsApi, submitAnIssueApi, saveFeedback } from '../apiServices';
import { fieldValidation } from '../../utils/formValidation';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Rating from '@material-ui/lab/Rating';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
const errorCode = {
  title:{
    0:'',
    1:'Please enter Title'
  },
  titleObj:{
    required:true
  },
  moduleObj:{
    required:true
  },
  severity:{
    0:'',
    1:'Please select Severity'
  },
  severityObj:{
    required:true
  },
  classification:{
    0:'',
    1:'Please select Classification'
  },
  classificationObj:{
    required:true
  },
}
const issue =  {
  title:'',
  description:'',
  module: null,
  severity:null,
  classification: null,
  attachments: []
}
class ReportAnIssue extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal:false,
      error:{},
      issueInfo:{},
      pltConstants:[],
      feedbackDialog: false,
      question1: {
        id: 1,
        answers:[
          {
              id: 17,
              comments: "",
              rating: 3
          },
          {
              id: 18,
              comments: "",
              rating: 3
          },
          {
              id: 19,
              comments: "",
              rating: 3
          },
          {
              id: 20,
              comments: "",
              rating: 3
          },
        ]
      },
      question2: {
        id: 2,
        answers:[]
      },
      question3: {
        id: 3,
        answers:[{
          id: 26
        }]
      },
      question4: {
        id: 4,
        answers:[{
          id: 31
        }]
      },
      question5: {
        id: 5,
        answers:[{
          id: 32
        }]
      },
    }
    this.getReportAnIssueConstantsApi = getReportAnIssueConstantsApi.bind(this);
    this.submitAnIssueApi = submitAnIssueApi.bind(this);
    this.saveFeedback = saveFeedback.bind(this);
  }
  onFieldChange = (e, keyParam, value) => {
    this.setState(prevState => ({
      ...prevState,
      issueInfo: {
        ...prevState.issueInfo,
        [keyParam]:value
      }
    }))
  }

  updateQuestion1 = (id, value) =>{
    this.setState({
      question1:{
        ...this.state.question1,
        answers: this.state.question1.answers.map(item => {
          let data = item
          if(item.id === id){
             data = {...data, rating: value}
          }
          return data
        })
      }
    })
  }

  updateQuestion2 = (id) =>{
    this.setState({
      question2:{
        ...this.state.question2,
        answers: this.state.question2.answers.filter(item => item.id === id).length ? this.state.question2.answers.filter(item => item.id !== id) : [...this.state.question2.answers, {id: id, comments: '', rating: null}]
      }
    })
  }

  updateQuestion3 = (id) =>{
    this.setState({
      question3: {
        ...this.state.question3,
        answers:[
          {
            id: id,
            comments: "",
            rating: null
          }
        ]
      }
    })
  }

  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  reportAnIssueFn = () => {
    const { issueInfo } = this.state;
    let validationInputs = {
      title:errorCode['title'][fieldValidation({...errorCode['titleObj'], fieldval: issueInfo.title})],
      severity: errorCode['severity'][fieldValidation({...errorCode['severityObj'], fieldval: issueInfo.severity})],
      classification:errorCode['classification'][fieldValidation({...errorCode['classificationObj'], fieldval: issueInfo.classification})]
    }
    if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
      this.submitAnIssueApi(this.props, issueInfo);
    }else{
      this.setState({error:validationInputs});
    }
  }
  render(){
    const { modal, issueInfo, error, pltConstants, loader } = this.state;
    const { type } = this.props;
    return(
      <Fragment>
        { type === 'head' ?
          <Tooltip title="Report an Issue" arrow>
            <IconButton size="small" color="primary" onClick={() => {this.setState({modal:true, issueInfo:issue}); this.getReportAnIssueConstantsApi(this.props, {constant_types:['bug_classification','bug_severity','bug_module']})}}>
              <HeadsetMicIcon color="primary" fontSize="small" />
            </IconButton>
          </Tooltip>
          :null
        }
        {
          <span className="rpt-an-issue"
            style={{
              position:'fixed',
              bottom:'22px',
              right:'80px',
              background: '#2f8cfe',
              padding:'3px 5px',
              borderRadius:'2px',
              fontSize:'11px',
              color:'#fff',
              display:'inline-block',
              zIndex:10,
              cursor:'pointer',
              boxShadow:'rgba(0, 0, 0, 0.2) 0px 4px 6px'
            }}
            onClick={() => {this.setState({modal:true, issueInfo:issue}); this.getReportAnIssueConstantsApi(this.props, {constant_types:['bug_classification','bug_severity','bug_module']})}}
          >
            Report an Issue
          </span>
        }
        { modal ?
          <Dialog
            open={modal}
            onClose={() => this.setState({modal:false, issueInfo:{}})}
            aria-labelledby="scroll-dialog-title"
          >
            <DialogTitle id="scroll-dialog-title">
              Report An Issue
            </DialogTitle>
            <DialogContent dividers={true}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    id="title"
                    label="Title"
                    fullWidth
                    margin="normal"
                    value={issueInfo.title ? issueInfo.title:''}
                    error={error.title ? true:false}
                    helperText={error.title ? error.title:''}
                    onFocus={() => this.updateErrorField('title','')}
                    onChange={(e) => this.onFieldChange(e, 'title', e.target.value)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <p style={{fontSize:'16px', color:'rgba(0, 0, 0, 0.54)', marginBottom:'4px'}}>Description</p>
                  <CkEditorText
                    htmlData={''}
                    onChangeData={(data) => this.onFieldChange('', 'description', data)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="module"
                    label="Module"
                    fullWidth
                    margin="normal"
                    value={'fin-S'}
                    disabled={true}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options = {pltConstants.filter(item => item.type === 'bug_severity')}
                    getOptionLabel={option => option.label}
                    id="severity"
                    value={pltConstants.length && issueInfo.severity ? pltConstants.find(item => item.type==='bug_severity' && item.value === issueInfo.severity):null}
                    onChange={(e, value) => this.onFieldChange(e, 'severity', value ? value.value:value)}
                    renderInput={params => <TextField required error={error.severity ? true:false} helperText={error.severity ? error.severity:''} onFocus={() => this.updateErrorField('severity','')} {...params} label="Severity" placeholder="Select Severity" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    options = {pltConstants.filter(item => item.type === 'bug_classification')}
                    getOptionLabel={option => option.label}
                    id="classification"
                    value={pltConstants.length && issueInfo.classification ? pltConstants.find(item => item.type==='bug_classification' && item.value === issueInfo.classification):null}
                    onChange={(e, value) => this.onFieldChange(e, 'classification', value ? value.value:value)}
                    renderInput={params => <TextField required error={error.classification ? true:false} helperText={error.classification ? error.classification:''} onFocus={() => this.updateErrorField('classification','')} {...params} label="Classification" placeholder="Select Classification" margin="normal" fullWidth InputLabelProps={{shrink: true}} />}
                  />
                </Grid>
                <Grid item xs={12}>
                <div className="" style={{display:'inline-block', width:'300px', position:'relative'}}>
                  <DropzoneArea
                    filesLimit={1}
                    acceptedFiles={['image/jpg', 'image/jpeg', 'image/png']}
                    showPreviewsInDropzone={false}
                    showPreviews={true}
                    dropzoneText={<p>Drag & Drop Pictures<br/> OR <br/> Click Here</p>}
                    dropzoneClass="drag-drop-cnt"
                    maxWidth="sm"
                    showAlerts={['error', 'info']}
                    onChange={(files) => this.onFieldChange('', 'attachments', files)}
                    alertSnackbarProps={{anchorOrigin: {vertical:'top', horizontal:'right'}}}
                  />
                  <p className="file-upload-note">Supported Extension .jpg, jpeg, .png</p>
                </div>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => this.setState({modal:false, issueInfo:{}})}>Cancel</Button>
              <Button color="primary" onClick={this.reportAnIssueFn} disabled={loader} variant="contained">{loader ? 'Processing...' : 'Submit'}</Button>
            </DialogActions>
          </Dialog>:null
        }
        <Dialog fullScreen open={false} onClose={() => this.setState({feedbackDialog: false})} >
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => this.setState({feedbackDialog: false})} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              Feedback
            </Typography>
            <Button autoFocus color="inherit" style={{position: 'absolute', right: '30px'}} onClick={() => this.saveFeedback()}>
              save
            </Button>
          </Toolbar>
        </AppBar>
        <Grid container className="feedback-container" spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <h3 className="scale-rating"><FiberManualRecordIcon style={{fontSize: '13px', marginRight: '3px'}}/>On a scale of 1-5 (1 being Poor and 5 being Excellent) How do you rate the SPARTA Asset Values BETA product on the following:</h3>
            </Grid>
            <Grid style={{marginLeft: '20px', marginBottom: '5px'}} item xs={12}>
              <h5 className="rating-options"><CheckCircleIcon style={{fontSize: '12px'}}/> Ease of generating Base Value</h5>
                <Rating
                  name="simple-controlled"
                  value={this.state.question1.answers.filter(item => item.id === 17)[0].rating}
                  onChange={(event, newValue) => this.updateQuestion1(17, newValue)}
                />
            </Grid>
            <Grid style={{marginLeft: '20px', marginBottom: '5px'}} item xs={12}>
              <h5 className="rating-options"><CheckCircleIcon style={{fontSize: '12px'}}/> Ease of generating Future Values</h5>
                <Rating
                  name="simple-controlled1"
                  value={this.state.question1.answers.filter(item => item.id === 18)[0].rating}
                  onChange={(event, newValue) => this.updateQuestion1(18, newValue)}
                />
            </Grid>
            <Grid style={{marginLeft: '20px', marginBottom: '5px'}} item xs={12}>
              <h5 className="rating-options"><CheckCircleIcon style={{fontSize: '12px'}}/> Export function for the graph and table</h5>
                <Rating
                  name="simple-controlled2"
                  value={this.state.question1.answers.filter(item => item.id === 19)[0].rating}
                  onChange={(event, newValue) => this.updateQuestion1(19, newValue)}
                />
            </Grid>
            <Grid style={{marginLeft: '20px', marginBottom: '5px'}} item xs={12}>
              <h5 className="rating-options"><CheckCircleIcon style={{fontSize: '12px'}}/> UX Design on Mobile devices</h5>
                <Rating
                  name="simple-controlled3"
                  value={this.state.question1.answers.filter(item => item.id === 20)[0].rating}
                  onChange={(event, newValue) => this.updateQuestion1(20, newValue)}
                />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginTop: '30px'}}>
            <Grid item xs={12}>
              <h3 className="scale-rating rating"><FiberManualRecordIcon style={{fontSize: '13px', marginRight: '3px'}}/>Which other functions would suit you the most (Choose Top 3)?</h3>
            </Grid>
            <Grid style={{marginLeft: '20px'}} item xs={12}>
              <h5>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.question2.answers.filter(item => item.id === 6).length ? true : false}
                      onChange={() => this.updateQuestion2(6)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={<h5 className="rating-options">Half Life Adjustments</h5>}
                />
              </h5>
            </Grid>
            <Grid style={{marginLeft: '20px'}} item xs={12}>
              <h5>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.question2.answers.filter(item => item.id === 7).length ? true : false}
                      onChange={() => this.updateQuestion2(7)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={<h5 className="rating-options">Distress Values</h5>}
                />
              </h5>
            </Grid>
            <Grid style={{marginLeft: '20px'}} item xs={12}>
              <h5>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.question2.answers.filter(item => item.id === 8).length ? true : false}
                      onChange={() => this.updateQuestion2(8)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={<h5 className="rating-options">Lease Encumbered Values</h5>}
                />
              </h5>
            </Grid>
            <Grid style={{marginLeft: '20px'}} item xs={12}>
              <h5>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.question2.answers.filter(item => item.id === 9).length ? true : false}
                      onChange={() => this.updateQuestion2(9)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={<h5 className="rating-options">Portfolio Values</h5>}
                />
              </h5>
            </Grid>
            <Grid style={{marginLeft: '20px'}} item xs={12}>
              <h5>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.question2.answers.filter(item => item.id === 10).length ? true : false}
                      onChange={() => this.updateQuestion2(10)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={<h5 className="rating-options">Lease Rates</h5>}
                />
              </h5>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginTop: '30px'}}>
            <Grid item xs={12}>
              <h3 className="scale-rating rating"><FiberManualRecordIcon style={{fontSize: '13px', marginRight: '3px'}}/>Which System Generated Reports would be of key interest to you?</h3>
            </Grid>
            <Grid style={{marginLeft: '20px'}} item xs={12}>
              <h6>
                <RadioGroup aria-label="gender" className="rating-options" name="gender1" value={this.state.question3.answers[0].id} onChange={(evt) => this.updateQuestion3(parseInt(evt.target.value))}>
                  <FormControlLabel value={26} control={<Radio />} label="Letter report for Base Value and Future Values" />
                  <FormControlLabel value={27} control={<Radio />} label="PDF Based Portfolio Values Report" />
                  <FormControlLabel value={28} control={<Radio />} label="Lease Encumbered Value Report" />
                  <FormControlLabel value={29} control={<Radio />} label="Other" />
                </RadioGroup>
              </h6>
              {
                this.state.question3.answers[0].id === 29 ?
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  fullWidth={true}
                  variant="outlined"
                  onChange={(evt) => this.setState({question3: {...this.state.question3, answers: [{id: this.state.question3.answers[0].id, rating: null, comments: evt.target.value}]}})}
                />
                :null
              }
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginTop: '30px'}}>
            <Grid item xs={12}>
              <h3 className="scale-rating rating"><FiberManualRecordIcon style={{fontSize: '13px', marginRight: '3px'}}/>How likely are you to subscribe to the SPARTA Asset Values product when it is released?</h3>
            </Grid>
            <Grid item xs={12} style={{marginLeft: '20px'}}>
              <h6>
                <RadioGroup aria-label="gender" style={{fontSize: '14px'}} name="gender1" value={this.state.question4.answers[0].id} onChange={(evt) => this.setState({question4: {...this.state.question4, answers: [{id: parseInt(evt.target.value), rating: null, comments: ""}]}})}>
                  <FormControlLabel value={31} control={<Radio />} label="Highly Likely" />
                  <FormControlLabel value={30} control={<Radio />} label="Highly Unlikely" />
                </RadioGroup>
              </h6>
              {
                this.state.question4.answers[0].id === 30 ?
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  fullWidth={true}
                  variant="outlined"
                  onChange={(evt) => this.setState({question4: {...this.state.question4, answers: [{id: this.state.question4.answers[0].id, rating: null, comments: evt.target.value}]}})}
                />
                :null
              }
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <h3 className="scale-rating"><FiberManualRecordIcon style={{fontSize: '13px', marginRight: '3px'}}/>Any other suggestions, comments or concerns.</h3>
            </Grid>
            <Grid item xs={12}>
                <TextField
                  id="outlined-multiline-static"
                  multiline
                  rows={4}
                  cols={30}
                  fullWidth={true}
                  variant="outlined"
                  onChange={(evt) => this.setState({question5: {...this.state.question5, answers: [{id: this.state.question5.answers[0].id, rating: null, comments: evt.target.value}]}})}
                />

            </Grid>
          </Grid>
        </Grid>
      </Dialog>
      </Fragment>
    )
  }
}
export default withSnackbar(ReportAnIssue);
