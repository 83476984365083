import React, { Component, Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { TableListComp, EmptyCollection,DeletePopUp } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import DocumentList from './DocumentList';
import AddEditDocument from './AddEditDocument';
import { getContractDocumentApi, deleteContractDocumentApi } from '../apiServices';
import Skeleton from '@material-ui/lab/Skeleton';
import { checkApiStatus } from '../../../utils';
class Documents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      getContractDocumentList:[],
      deleteModal:false,
      deleteId:'',
      skeletonLoader:false
    }
    this.getContractDocumentApi = getContractDocumentApi.bind(this)
    this.deleteContractDocumentApi = deleteContractDocumentApi.bind(this)
  }
  componentDidMount(){
    this.getContractDocumentApi(this.props)
  }
  deleteContractDocument=(id) => {
    this.setState(prevState => ({
      ...prevState,
      deleteModal:!prevState.deleteModal,
      deleteId:id
    }))
  }
  deleteContractDocumentFn = () => {
    const { deleteId } = this.state
    this.deleteContractDocumentApi(this.props, deleteId)
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{ vertical:'top', horizontal:'right'}})
        this.setState({ deleteModal:false, deleteId:''})
        this.getContractDocumentApi(this.props)
      }else {
        this.props.enqueueSnackbar(response.data.message, { variant:'error', anchorOrigin:{vertical:'top', horizontal:'right'}})
      }
    })

  }
  render() {
    const { getContractDocumentList, deleteModal, deleteId, skeletonLoader } = this.state
    return(
      <Fragment>

        {skeletonLoader ? <Skeleton width={140} height={50} style={{float:'right'}} /> : <AddEditDocument mode="Add" getResponseBack={()=> this.getContractDocumentApi(this.props)} />}
        {skeletonLoader ? <STableLoader count={3} /> :
          <TableListComp
            heads={[
              { label:'Title', sortOption:false},
              { label:'Start Date', sortOption:false},
              { label:'End Date', sortOption:false},
              { label:'', sortOption:false}
            ]}
            data={ getContractDocumentList.length ? getContractDocumentList.map((contractDocument, index)=>
                  <DocumentList
                    item={contractDocument}
                    key={index}
                    getResponseBack={()=> this.getContractDocumentApi(this.props)}
                    deleteContractDocument={(id)=> this.deleteContractDocument(id)}
                  />
            ): null }
            noRecord={getContractDocumentList.length ? null : <EmptyCollection title="No Document added" /> }
          />
        }

      <DeletePopUp
        modal={deleteModal}
        toggleModalFn={this.deleteContractDocument}
        title="Delete Contract Document"
        deleteRecordFn={()=> this.deleteContractDocumentFn()}
        content={<h4>Are you sure you want to delete</h4>}
      />
      </Fragment>
    )
  }
}
export default withSnackbar(withRouter(Documents));
