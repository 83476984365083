import React, { Component, Fragment} from 'react';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Button, Checkbox } from '@material-ui/core';
import { TableListComp, EmptyCollection, DeletePopUp } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import SecurityGroupList from './SecurityGroupList';
import AddSecurityGroups from './AddSecurityGroups';
import { deleteUserGroupApi } from '../apiServices';
import { checkApiStatus } from '../../../utils';
let list = []
class SecurityGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteSecurityGroups:[],
      deleteModal:false,
      skeletonLoader:false
    }
    this.deleteUserGroupApi = deleteUserGroupApi.bind(this)
  }
  updateForm= (value, checked) => {
    if(checked){
      list.push(value)
    }else {
      let index = list.indexOf(value)
      list.splice(index, 1)
    }
    this.setState({
      deleteSecurityGroups:list
    })
  }
  toggleModalFn = () => {
    this.setState(prevState => ({
      deleteModal:!prevState.deleteModal
    }))
  }
  deleteSingleGroupFn = (id) => {
    this.setState({
      deleteModal:true,
      deleteSecurityGroups:[id]
    })
  }
  selectAll = () => {
    this.setState(prevState => ({
      deleteSecurityGroups:this.props.securityGroups.map(item => item.id)
    }))
  }
  deleteSelectedSecurityGroups = () => {
    const { deleteSecurityGroups } = this.state
    this.deleteUserGroupApi(this.props, deleteSecurityGroups)
    .then(response => {
      if(checkApiStatus(response)){
        this.props.enqueueSnackbar(response.data.message, {variant:'success', anchorOrigin:{vertical:'top', horizontal:'right'}})
        this.setState({deleteModal:false, deleteSecurityGroups:[]})
        this.props.getResponseBack();
      }else {
        this.props.enqueueSnackbar(response.data.message, {variant:'error', anchorOrigin:{vertical:'top', horizontal:'right'}})
      }
    })
  }
  render(){
    const { deleteSecurityGroups, deleteModal, skeletonLoader} = this.state
    const { securityGroups, getResponseBack } = this.props
    return(
      <div className="user-management-module-section">
        {securityGroups.length ?
          <Fragment>
            <Checkbox
              edge="Start"
              onClick={this.selectAll}
              checked={deleteSecurityGroups.length == securityGroups.length}
            /> Select all Security Groups
          </Fragment>
          : null
        }

          <AddSecurityGroups getResponseBack={getResponseBack} />
          { deleteSecurityGroups.length ? <Button color="secondary" style={{float:'right'}} className="buttons-cta" onClick={this.toggleModalFn} >Delete Security Groups ({ deleteSecurityGroups.length})</Button> : null}
          { skeletonLoader ? <STableLoader  count={1}/> :
            <TableListComp
              heads={[]}
              data={securityGroups.length ? securityGroups.map((group,index)=>
                    <SecurityGroupList
                      item={group}
                      key={index}
                      updateForm={(id, checked)=> this.updateForm(id, checked)}
                      toggleModalFn={this.toggleModalFn}
                      deleteSingleGroupFn={this.deleteSingleGroupFn}
                      deleteSecurityGroups={deleteSecurityGroups}
                    />
                ) :null
          }
            noRecord={securityGroups.length ?null : <EmptyCollection title="No Security groups added" /> }
          />
          }

      <DeletePopUp
        modal={deleteModal}
        toggleModalFn={this.toggleModalFn}
        title="Remove Securit Groups"
        deleteRecordFn={()=> this.deleteSelectedSecurityGroups()}
        content={<h4>Are you sure you want to remove</h4>}
      />
    </div>
    )
  }
}
export default withSnackbar(withRouter(SecurityGroups));
