import React from 'react';
import { TableRow, TableCell } from '@material-ui/core';

const InviteList = ({item}) => {
  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>{item.name ? item.name:'--'}</TableCell>
      <TableCell>{item.email ? item.email:'--'}</TableCell>
      <TableCell>{item.contact_number ? item.contact_number:'--'}</TableCell>
      <TableCell>{item.designation ? item.designation:'--'}</TableCell>
      <TableCell>{item.description ? item.description:'--'}</TableCell>
      <TableCell><span style={{color:"#fe6465",marginRight:'5px'}}>Reject</span><span style={{color:"#72ca2d"}}>Approve</span></TableCell>



    </TableRow>
  )
}
export default InviteList;
