import { globalGetService, globalPostService, globalPutService, globalExportService, globalDeleteService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getGlobalCookie } from '../../utils';
import config from '../../config';
import moment from 'moment';
export function getUserListApi(props={}, query={}){
  this.setState({ skeletonLoader: true})
  globalGetService(`console/get-user-list/`, query)
  .then(response => {
    this.setState({skeletonLoader:false})
    if(checkApiStatus(response)){
      delete query.page;
      delete query.per_page;
      delete query.filter_type;
      delete query.sort;
      delete query.sort_by;
      this.setState(prevState => ({
        userInfo:response.data.data,
        filter:query
      }))
    }
  })
}
export function exportUserListApi(props={}, query={}){
  globalExportService(`console/get-user-list/`, query)
  .then(response => {
    downloadFileType(response.data.message, (`User_${getGlobalCookie(config.cookiesNaming.key).lessor.name}_${moment().format('YYYY-MM-DD')}`), query.download);
    this.props.enqueueSnackbar('User List exported Successfully ', { variant: 'success', anchorOrigin:{ vertical:'top', horizontal:'right'}})
  })
}
export function createUserApi(data={}){
  return new Promise(
    function(resolve, reject){
      globalPostService(`console/create-user/`, data)
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function getUserApi(props={}, query={}){
  this.setState({skeletonLoader:true})
  globalGetService(`console/get-user-details/${props.match.params.id}/`, query)
  .then(response => {
    this.setState({skeletonLoader:false})
    if(checkApiStatus(response)){
      this.setState({
        userInfo:response.data.data,
        competency_rating:response.data.data.competency_rating
      })
    }
  })
}
export function editUserApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      globalPutService(`console/get-user-details/${props.match.params.id}/`, data)
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function getUserInviteListApi(props={}, query={}){
  this.setState({skeletonLoader:true})
  globalGetService(`console/invite-user-request/`,query)
  .then(response => {
    this.setState({skeletonLoader: false})
    if(checkApiStatus(response)){
      this.setState({
        userInviteInfo:response.data.data
      })
    }
  })
}
export function getUserEmailApi(){
    globalGetService('console/get-user-list/', {filter_type: 'add_lessor'})
    .then(response => {
      let data = response.data.data.map(data => ({ id: data.id, name:data.email}))
      this.setState({userEmail: data})
    })
}
export function getAssetListApi(props={}){
  globalGetService(`console/asset-access-list/user/${props.match.params.id}/add-asset/`)
  .then(response => {
    this.setState({
      assetAllowedList: response.data.data
    })
  })
}
export function addUserAssetsApi(props={}, userAssets=[]){
  return new Promise(
    function(resolve, reject){
      globalPostService(`console/asset-access-list/user/${props.match.params.id}/`, {asset_list : userAssets})
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function deleteUserAssetApi(props={}, userAssets=[]){
  return new Promise(
    function(resolve, reject){
      globalDeleteService(`console/asset-access-list/user/${props.match.params.id}/`, {ids:userAssets})
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function getGroupsListApi(props={}){
  if(props.match.params.id){
    globalGetService(`console/sparta-group-list/user/${props.match.params.id}/`)
    .then(response => {
      this.setState({
        securityGroupList:response.data.data
      })
    })
  }else {
    globalGetService('console/sparta-groups/', {add_group_user: true})
    .then(response => {
      this.setState({
        securityGroupList:response.data.data
      })
    })
  }

}
export function addUserGroupApi(props={}, userGroup=[]){
  return new Promise(
    function(resolve, reject) {
      globalPutService(`console/sparta-group-list/user/${props.match.params.id}/`, {sparta_group_list:userGroup})
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function deleteUserGroupApi(props={}, userGroup=[]){
  return new Promise(
    function(resolve, reject){
      globalDeleteService(`console/sparta-group-list/user/${props.match.params.id}/`, {sparta_group_list:userGroup})
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function addEditExperienceApi(props={}, data = {}){
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`console/user-details/${props.match.params.id}/create-experience-of-act/${data.id}/`, data)
        .then(response => {
          resolve(response)
        })
        }else {
        globalPostService(`console/user-details/${props.match.params.id}/create-experience-of-act/`, data)
        .then(response => {
          resolve(response)
        })
      }
    }
  )
}
export function deleteExperienceApi(props={}, deleteId=''){
  return new Promise(
    function(resolve, reject){
      globalDeleteService(`console/user-details/${props.match.params.id}/create-experience-of-act/${deleteId}/`)
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function userStatusListApi(props={}){
  this.setState({skeletonLoader:true})
  globalGetService(`console/user_status_update/${props.match.params.id}/`)
  .then(response => {
    this.setState({skeletonLoader:false})
    if(checkApiStatus(response)){
      this.setState({
        userStatusList:response.data.data
      })
    }

  })
}
export function updateUserStatusApi(props={}, data={}){
  return new Promise(
    function(resolve, reject){
      if(data.id){
        globalPutService(`console/user_status_update/${props.match.params.id}/${data.id}/`, data)
        .then(response => {
          resolve(response)
        })
      }else {
        globalPostService(`console/user_status_update/${props.match.params.id}/`, data)
        .then(response => {
          resolve(response)
        })
      }
    }
  )
}
export function deleteUserStatusApi(props={}, deleteId=''){
  return new Promise(
    function(resolve, reject){
      globalDeleteService(`console/user_status_update/${props.match.params.id}/${deleteId}/`)
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function getContractDocumentApi(props={}){
  this.setState({ skeletonLoader:true})
  globalGetService(`console/contract-user/${props.match.params.id}/`)
  .then(response => {
    this.setState({ skeletonLoader:false})
    if(checkApiStatus(response)){
      this.setState({
        getContractDocumentList:response.data.data
      })
    }
  })
}
export function addEditContractDocumentApi(props={}, data={}, id=''){
  return new Promise(
    function(resolve, reject){
      if(id){
        globalPutService(`console/contract-user/${props.match.params.id}/${id}/`, data)
        .then(response => {
          resolve(response)
        })
      }else {
        globalPostService(`console/contract-user/${props.match.params.id}/`, data)
        .then(response => {
          resolve(response)
        })
      }
    }
  )
}
export function deleteContractDocumentApi(props={}, deleteId=""){
  return new Promise(
    function(resolve, reject){
      globalDeleteService(`console/contract-user/${props.match.params.id}/${deleteId}/`)
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function getConstantsApi(constantType){
  globalPostService(`console/get-constants/`, constantType)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        constantTypesList:response.data.data
      })
    }
  })
}
export function updateUserProjectsApi(props={}, data){
  return new Promise(
    function(resolve, reject){
      globalPutService(`console/get-user-details/${props.match.params.id}/`, data)
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function getUserSkillSetApi(){
  globalGetService(`console/user-skills/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.setState({
        skillSetsList:response.data.data
      })
    }
  })
}
export function removeUserApi(props={}){
  return new Promise(
    function(resolve, reject){
      globalGetService(`console/lessor-unlink-user/${props.match.params.id}/`, {})
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function resendInviteApi(props={}){
  globalGetService(`console/resend-user-invite/${props.match.params.id}/`)
  .then(response => {
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, { variant:'success', anchorOrigin:{vertical:'top', horizontal:'right'}})
    }else {
      this.props.enqueueSnackbar(response.data.message,{ variant :'error', anchorOrigin:{ vertical:'top', horizontal:'right'}})
    }
  })
}
export function updateUserSkillRatingsApi(props={},data={}){
  return new Promise(
    function(resolve, reject){
      globalPutService(`console/user-competency-rating/${props.match.params.id}/`, data)
      .then(response => {
        resolve(response)
      })
    }
  )
}
export function getUserProfileApi(){
  this.setState({skeletonLoader:true})
  globalGetService(`console/user/`, {})
  .then(response => {
    this.setState({skeletonLoader:false})
    if(checkApiStatus(response)){
      this.setState({
        userInfo:response.data.data
      })
    }
  })
}
export function editUserProfileApi(data = {}){
  return new Promise(
    function(resolve, reject){
      globalPutService(`console/user/`, data)
      .then(response => {
        resolve(response)
      })
    }
  )
}
